import React, { useState } from "react";
import "./popup.css";
import iconChat from "../../../../assets/images/icon.png";
import { Button, Modal, ModalBody } from "reactstrap";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from "axios";
import Toast from "views/pages/common-components/Toast";
import { Slide, toast } from "react-toastify";
import { getTranslatedName } from "config/functions";

const Support = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!props.isOpen);


  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().required(getTranslatedName('Required field')),
    }),
    onSubmit: values => {
      handleFormSubmit(values)
    }
  })


  const handleFormSubmit = (values) => {
    let { message } = values;
    axios
      .post("/api/sendCustomerMessage", {
        message,
      })
      .then((response) => {
        if (response.data.status === false) {
          const status = 'error'
          const message = response.data.message
          toast.error(
              <Toast message={message} status={status} />,
              { icon: true, theme: 'dark', transition: Slide, hideProgressBar: true, autoClose: 5000, position: toast.POSITION.BOTTOM_CENTER }
          )
          props.handleClose()
        } else {
          const status = 'success'
          const message = response.data.message
          toast.success(
              <Toast message={message} status={status} />,
              { icon: true, theme: 'dark', transition: Slide, hideProgressBar: true, autoClose: 5000, position: toast.POSITION.BOTTOM_CENTER }
          )
          formik.resetForm()
          props.handleClose()
        }
      });
  }

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        className=" modal-dialog modal-dialog-centered modal-md"
        centered
        show={true}
        backdrop={`static`}
      >
        <div className="bg-modal">

          <ModalBody className="pauseContent">
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex flex-column">
                <button type="button" className="close text-end" onClick={props.handleClose}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="w-100 text-center ">
                  <img src={iconChat} className="text-center" />

                </div>

                <p className="mt-5 title-modal">How can we help you?</p>
                <p className="mt-3 content-modal">
                  Please write your message to our support team in the box below. We reply within 24 hours on weekdays.
                </p>


                <textarea
                  className="mt-3 form-control textArea"
                  rows="2"
                  name='message'
                  placeholder="Write to us...."
                  onChange={formik.handleChange}
                  value={formik.values.message}
                ></textarea>
                <div className="text-danger formik-errors-css--">
                  {formik.errors.message && formik.touched.message ? (
                    <div className="m-0 p-0 error">{formik.errors.message}</div>
                  ) : (
                    <div className="p-0 m-0 error"></div>
                  )}
                </div>
              </div>
              <div className="w-100 text-center my-3">
                <button
                  color="primary"
                  className="btn-cancel border-0 mx-1"
                  onClick={props.handleClose}
                >
                  cancel
                </button>{" "}
                <button
                  type='submit'
                  color="secondary"
                  className="btn-submit border-0 mx-1"
                >
                  Submit
                </button>

              </div>
            </form>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

export default Support;
