import React, { useEffect, Suspense, useState } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { registerStudent } from "routes"
import { isEmpty, saveCookie } from "../../config/functions"
import NoMatchPage from "views/pages/NoMatchPage"
import Header from "./header/Header"
import Footer from "./footer/FooterNew"
import { useDispatch, useSelector } from "react-redux"
import { userProfile } from "./action"
import PageLoader from "../common/Loader"
import ShowUpdateCTABlog from "../common/ShowUpdateCTABlog"
import MemberShipPaused from "../common/MemberShipPaused"


export default function Register(props) {
  const [isCustomerPlanActivated, setIsCustomerPlanActivated] = useState(null)
  const [showCTABlog, setShowCTABlog] = useState(false)
  const isSubscriptionPage = useSelector(state => state.register.header.isSubscriptionPage)
  const userProfileData = useSelector(state => state.register.userProfileData)
  const [isMemberShipPaused, setIsMemberShipPaused] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(userProfile())
  }, [])

  useEffect(() => {

    //  This code will be commenteed for dev/staging server


    const script = document.createElement("script")
    script.type = "text/javascript"
    script.async = true
    script.innerHTML =
      "(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3507002,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
    document.head.appendChild(script)
    //   above hot jar script 25 may 23'
    //  This code will be commenteed for dev/staging server



    if (!isEmpty(userProfileData)) {
      setIsCustomerPlanActivated(userProfileData.isActiveSubscrion)
      saveCookie("isUserPlanActive", userProfileData.isActiveSubscrion ? "yes" : "no")

      if (userProfileData.isPause === 1 && userProfileData.cPMStatus) {
        setIsMemberShipPaused(true)
      } else if (userProfileData.grace_period) {
        setShowCTABlog(true)
        setIsMemberShipPaused(false)
      }
      if (!userProfileData.isPause) {
        setIsMemberShipPaused(false)
      }
    }
  }, [userProfileData])

  // if (!isEmpty(userProfile_data) && !isEmpty(groupwiseCourses)) loading = false
  // if (userProfileData != null ) loading = false
  console.log("isSubscriptionPage", isSubscriptionPage)
  return isCustomerPlanActivated === null ? (
    <PageLoader />
  ) : (
    <React.Fragment>
      <div className="artist-section customer-wrapper-css">
        {<Header {...props} />}

        <div className="page-inner">
          {showCTABlog && (
            <div className="  col-12 pt-4 billing-cta-wrapper">
              <ShowUpdateCTABlog />
            </div>
          )}
          <main className="main-content-css">
            <Suspense fallback={props.pageLoader()}>
              <Switch>
                {isMemberShipPaused ? (
                  <Route component={MemberShipPaused} userInfo={userProfileData} {...props} />
                ) : !isCustomerPlanActivated ? (
                  registerStudent.map((route, idx) => {
                    return typeof route.component !== "undefined" && !route.onlyForsubscribedUser ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => <route.component {...props} />}
                      />
                    ) : null
                  })
                ) : (
                  registerStudent.map((route, idx) => {
                    return typeof route.component !== "undefined" && !route.notForsubscribedUser ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => <route.component {...props} />}
                      />
                    ) : null
                  })
                )}
                <Route component={NoMatchPage} />
              </Switch>
            </Suspense>
          </main>
        </div>

        <Footer userInfo={userProfileData} />
      </div>
    </React.Fragment>
  )
}
