import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getCookie, isEmpty } from "config/functions";
import { httpGetRequest, httpPostRequest } from "config/services";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import DataTable from "react-data-table-component";
import moment from "moment";
import { date } from "yup";
import base64 from "base-64";
import ConfirmationPopup from "views/pages/common-components/ConfirmationPopup";
import { CSVLink } from "react-csv";
import FlashMessages from "views/common/FlashMessages";
import { data } from "jquery";
import { BsDash, BsFillCaretDownFill } from "react-icons/bs";
import { updateCustomerAccessLengthDate } from "../manageCustomer/apiStore";
import Toast from "views/pages/common-components/Toast";
import { Slide, toast } from "react-toastify";
import AccessLengthPopUp from "../manageCustomer/popUp/AccessLengthPopUp";

export default class ManageCustomersLibrary extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      startDate: "",
      endDate: "",
      flashMessage: "",
      csvLoading: false,
      customerList: { data: [], count: 0 },
      customerName: "",
      firstTimeDataLoaded: false,
      openConfirmation: false,
      currentPage: 0,
      customerInfo: {},
      CustomerCsvData: [],
      SymptomCsvData: [],
      searchTerm: "",
      sort: "DESC",
      sortColumn: "createdOn",
      currentPage: 1,
      rowsPerPage: 10,
      adminID: getCookie("adminID"),
      first3DaysCustomer: [],
      later3DaysCustomer: [],
      customerAccessLengthData: {},
      showAccessLengthPopUp: false,

      CustomerHeaders: [
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Last Login Date", key: "lastLoginDate" },
        { label: "Number Of Logins", key: "totalLogins" },
        { label: "Last Chat Date", key: "lastChatDate" },
        { label: "Last Content Watch Date", key: "lastWatchDate" },
        { label: "Total Minutes Watched", key: "totalWatchTime" },
        { label: "Content Pause Status", key: "contentPause" },
        { label: "Billing Pause Status", key: "memberShipPause" },

        { label: "Customer Initial Symptoms", key: "initialSymptoms" },
        { label: "Customer Today Symptoms", key: "todaySymptomsList" },
        { label: "Customer age", key: "age" },
        { label: "Customer Tried So Far", key: "triedSoFar" },
        { label: "Customer message for 90 days", key: "msgFor90Days" },

        // {label: 'Highest Symptom score', key: 'Severe Symptom Score'},
        // {label: 'Highest Symptom score', key: 'Symptom_score'},
      ],
      SymptomHeaders: [
        { label: "Name", key: "customer" },
        { label: "Email", key: "email" },
        { label: "Package Assigned", key: "package" },
        { label: " Mood Rating", key: "moodRating" },
        { label: "Symptoms ", key: "Symptoms" },
        { label: "Symptoms score ", key: "score" },
        { label: "Scored date", key: "score_date" },
        { label: "Week", key: "week" },
        // {label: ' Individual Symptom score', key: 'Individual Symptom score'},
        // {label: ' Highest  Symptom score', key: 'Highest Symptom Score'},
      ],
      isHttpRequest: true,
      isCustomverCSVLoad: false,
      isSymptomCSVLoad: false,
    };
    this.state = this.initialState;
  }

  async componentDidMount() {
    let { currentPage, sort, sortColumn, rowsPerPage, searchTerm } = this.state;
    let bodyData = {
      order: sort,
      orderBy: sortColumn,
      page: currentPage,
      perPageItem: rowsPerPage,
      keyword: searchTerm,
    };
    this.apiCall({
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      customerName: "",
      courseFilterType: 0,
      adminID: this.state.adminID,
      ...bodyData,
    });
    // this.getCustomerDataCsv()

    // this.getCustomerDataCsv3daysonMount()

    // this.getCustomerDataCsv3days({
    //   fromDate: moment()
    //     .subtract(3, 'd')
    //     .format('YYYY-MM-DD')
    //     .slice(0, 10),
    //   toDate: moment()
    //     .format('YYYY-MM-DD')
    //     .slice(0, 10),
    // })

    // this.getCustomerDataCsv({
    //   fromDate: moment()
    //     .subtract(6, 'd')
    //     .format('YYYY-MM-DD')
    //     .slice(0, 10),
    //   toDate: moment()
    //     .format('YYYY-MM-DD')
    //     .slice(0, 10),
    // })

    // this.getSymptomDataCsv({
    //   fromDate: moment()
    //     .subtract(6, 'd')
    //     .format('YYYY-MM-DD')
    //     .slice(0, 10),
    //   toDate: moment()
    //     .format('YYYY-MM-DD')
    //     .slice(0, 10),
    // })
  }

  //   getCustomerDataCsv3daysonMount() {
  //     let first3days = []
  //     let second3days = []
  //     let combinedData

  //     console.log('calling 1st 3 days')
  //     this.get3dayscsv(
  //       {
  //         fromDate: moment()
  //           .subtract(3, 'd')
  //           .format('YYYY-MM-DD')
  //           .slice(0, 10),
  //         toDate: moment()
  //           .format('YYYY-MM-DD')
  //           .slice(0, 10),
  //       },
  //       (err, data) => {
  //         if (err) {
  //           console.log(err)
  //         }
  //         if (data) {
  //           first3days = data
  //           console.log(first3days, 'calling 2nd 3 days')

  //           this.get3dayscsv(
  //             {
  //               fromDate: moment()
  //                 .subtract(6, 'd')
  //                 .format('YYYY-MM-DD')
  //                 .slice(0, 10),
  //               toDate: moment()
  //                 .subtract(3, 'd')
  //                 .format('YYYY-MM-DD')
  //                 .slice(0, 10),
  //             },
  //             (err1, data1) => {
  //               if (err1) {
  //                 console.log('second time fetch error')
  //                 console.log(err1)
  //                 if (err1 === 'Record Not Found!') {
  //                   combinedData = first3days.concat(second3days)
  //                   console.log(combinedData)
  //                   let stateObj = {
  //                     CustomerCsvData: combinedData,
  //                     // CustomerCsvData: result.data,
  //                     isCustomverCSVLoad: true,
  //                   }

  //                   this.setState(stateObj)
  //                 }
  //               }
  //               if (data1) {
  //                 second3days = data1
  //                 console.log(second3days)

  //                 if (first3days.length || second3days.length) {
  //                   combinedData = first3days.concat(second3days)
  //                   console.log(combinedData)
  //                   let stateObj = {
  //                     CustomerCsvData: combinedData,
  //                     // CustomerCsvData: result.data,
  //                     isCustomverCSVLoad: true,
  //                   }

  //                   this.setState(stateObj)
  //                 } else {
  //                   console.log('length not tracked')
  //                 }
  //               }
  //             }
  //           )
  //         }
  //       }
  //     )
  //   }
  //   get3dayscsv(payload, cb) {
  //     console.log(payload)
  //     try {
  //       httpPostRequest('admin/send-weekly-customerdata-csv', payload, (error, result) => {
  //         let stateObj = {}
  //         if (!isEmpty(error)) {
  //           stateObj = {
  //             messageType: 'error',
  //             flashMessage: error.message,
  //           }
  //         } else {
  //           if (result.status) {
  //             const rawData = result.data.map((res, ind) => {
  //               let rd = {}
  //               // console.log(rd.contentPause)
  //               // rd.totalWatchTime = Math.round(res.totalWatchTime)
  //               rd.totalWatchTime = Math.ceil(res.totalWatchTime / 60)
  //               rd.totalWatchTimeWeek = res.totalWatchTimeWeek
  //               rd.name = res.name
  //               rd.email = res.email
  //               rd.lastWatchDate = res.lastWatchDate
  //               rd.lastChatDate = res.lastChatDate
  //               rd.totalLogins = res.totalLogins
  //               rd.contentPause = res.contentPause
  //               rd.memberShipPause = res.memberShipPause
  //               // if (res.memberShipPause == 1) {
  //               //   rd.memberShipPause = 'yes'
  //               // } else {
  //               //   rd.memberShipPause = 'no'
  //               // }
  //               rd.lastLoginDate = res.lastLoginDate
  //               if (res.meta) {
  //                 const meta = JSON.parse(res.meta)
  //                 meta.forEach((symptomElem) => {
  //                   if (symptomElem.key == 'paid-option') {
  //                     let _symps
  //                     // rd.initialSymptoms = symptomElem.value.value;
  //                     if (symptomElem && symptomElem.value && symptomElem.value.value) {
  //                       _symps = JSON.parse(symptomElem.value.value)
  //                       rd.initialSymptoms = Array.prototype.map
  //                         .call(_symps, function(item) {
  //                           return item.name
  //                         })
  //                         .join(',')
  //                     }
  //                   }
  //                   if (symptomElem.key == 'selected-symptoms') {
  //                     // rd.todaySymptomsList = symptomElem.value.value
  //                     if (symptomElem && symptomElem.value && symptomElem.value.value) {
  //                       let _todaysymps
  //                       _todaysymps = JSON.parse(symptomElem.value.value)
  //                       rd.todaySymptomsList = Array.prototype.map
  //                         .call(_todaysymps, function(item) {
  //                           return item.name
  //                         })
  //                         .join(',')
  //                     }
  //                   }
  //                   if (symptomElem.key == 'age') {
  //                     rd.age = symptomElem.value.value
  //                   }
  //                   if (symptomElem.key == 'duration') {
  //                     rd.symptomDuration = symptomElem.value.value
  //                   }
  //                   if (symptomElem.key == 'date') {
  //                     rd.courseStartDate = symptomElem.value.value
  //                   }
  //                   if (symptomElem.key == 'paid-text3') {
  //                     rd.msgFor90Days = symptomElem.value.value
  //                   }
  //                   if (symptomElem.key == 'method') {
  //                     rd.triedSoFar = symptomElem.value.value
  //                   }
  //                 })
  //               }
  //               return rd
  //             })

  //             // stateObj = {
  //             //   CustomerCsvData: rawData,
  //             //   // CustomerCsvData: result.data,
  //             //   isCustomverCSVLoad: true,
  //             // }
  //             cb(null, rawData)
  //           } else {
  //             stateObj = {
  //               messageType: 'error',
  //               flashMessage: result.message,
  //             }
  //             cb(result.message, null)
  //             console.log('second time fetch error', result)
  //           }
  //         }

  //         this.setState(stateObj)
  //         //done(true);
  //       })
  //     } catch (err) {
  //       console.log('err', err)
  //       this.setState({
  //         isCustomverCSVLoad: false,
  //       })
  //       cb('error msg 2', null)
  //       //done(false);
  //     }
  //   }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.endDate !== this.state.endDate ||
      prevState.startDate !== this.state.startDate
    ) {
      // this.setState({log: 'dummy'})
      // console.log('array')
      if (this.state.startDate && this.state.endDate) {
        this.filterDatewise();
      }
      if (
        (!this.state.startDate && !this.state.endDate) ||
        (this.state.startDate == "" && this.state.endDate == "")
      ) {
        //   console.log('ok')
        this.apiCall({
          fromDate: this.state.startDate,
          toDate: this.state.endDate,
          customerName: "",
          courseFilterType: 0,
          adminID: this.state.adminID,
        });
      }
    }
  }

  //   getCustomerDataCsv(payload) {
  //     // console.log(payload)
  //     try {
  //       httpPostRequest('admin/send-weekly-customerdata-csv', payload, (error, result) => {
  //         let stateObj = {}
  //         if (!isEmpty(error)) {
  //           stateObj = {
  //             messageType: 'error',
  //             flashMessage: error.message,
  //           }
  //         } else {
  //           if (result.status) {
  //             const rawData = result.data.map((res, ind) => {
  //               let rd = {}
  //               // console.log(rd.contentPause)
  //               // rd.totalWatchTime = Math.round(res.totalWatchTime)
  //               rd.totalWatchTime = Math.ceil(res.totalWatchTime / 60)
  //               rd.totalWatchTimeWeek = res.totalWatchTimeWeek
  //               rd.name = res.name
  //               rd.email = res.email
  //               rd.lastWatchDate = res.lastWatchDate
  //               rd.lastChatDate = res.lastChatDate
  //               rd.totalLogins = res.totalLogins
  //               rd.contentPause = res.contentPause
  //               rd.memberShipPause = res.memberShipPause
  //               // if (res.memberShipPause == 1) {
  //               //   rd.memberShipPause = 'yes'
  //               // } else {
  //               //   rd.memberShipPause = 'no'
  //               // }
  //               rd.lastLoginDate = res.lastLoginDate
  //               if (res.meta) {
  //                 const meta = JSON.parse(res.meta)
  //                 meta.forEach((symptomElem) => {
  //                   if (symptomElem.key == 'paid-option') {
  //                     let _symps
  //                     // rd.initialSymptoms = symptomElem.value.value;
  //                     if (symptomElem && symptomElem.value && symptomElem.value.value) {
  //                       _symps = JSON.parse(symptomElem.value.value)
  //                       rd.initialSymptoms = Array.prototype.map
  //                         .call(_symps, function(item) {
  //                           return item.name
  //                         })
  //                         .join(',')
  //                     }
  //                   }
  //                   if (symptomElem.key == 'selected-symptoms') {
  //                     // rd.todaySymptomsList = symptomElem.value.value
  //                     if (symptomElem && symptomElem.value && symptomElem.value.value) {
  //                       let _todaysymps
  //                       _todaysymps = JSON.parse(symptomElem.value.value)
  //                       rd.todaySymptomsList = Array.prototype.map
  //                         .call(_todaysymps, function(item) {
  //                           return item.name
  //                         })
  //                         .join(',')
  //                     }
  //                   }
  //                   if (symptomElem.key == 'age') {
  //                     rd.age = symptomElem.value.value
  //                   }
  //                   if (symptomElem.key == 'duration') {
  //                     rd.symptomDuration = symptomElem.value.value
  //                   }
  //                   if (symptomElem.key == 'date') {
  //                     rd.courseStartDate = symptomElem.value.value
  //                   }
  //                   if (symptomElem.key == 'paid-text3') {
  //                     rd.msgFor90Days = symptomElem.value.value
  //                   }
  //                   if (symptomElem.key == 'method') {
  //                     rd.triedSoFar = symptomElem.value.value
  //                   }
  //                 })
  //               }
  //               return rd
  //             })

  //             stateObj = {
  //               CustomerCsvData: rawData,
  //               // CustomerCsvData: result.data,
  //               isCustomverCSVLoad: true,
  //             }
  //           } else {
  //             stateObj = {
  //               messageType: 'error',
  //               flashMessage: result.message,
  //             }
  //           }
  //         }

  //         this.setState(stateObj)
  //         //done(true);
  //       })
  //     } catch (err) {
  //       console.log('err', err)
  //       this.setState({
  //         isCustomverCSVLoad: false,
  //       })
  //       //done(false);
  //     }
  //   }

  //   getSymptomDataCsv(payload) {
  //     // console.log(payload)
  //     try {
  //       httpPostRequest('admin/send-weekly-customerSymptom-csv', payload, (error, result) => {
  //         let stateObj = {}
  //         if (!isEmpty(error)) {
  //           stateObj = {
  //             messageType: 'error',
  //             flashMessage: error.message,
  //           }
  //         } else {
  //           if (result.status) {
  //             stateObj = {
  //               SymptomCsvData: result.data,
  //               isSymptomCSVLoad: true,
  //             }
  //           } else {
  //             stateObj = {
  //               messageType: 'error',
  //               flashMessage: result.message,
  //               isSymptomCSVLoad: false,
  //             }
  //           }
  //         }
  //         this.setState(stateObj)
  //       })
  //     } catch (err) {
  //       console.log('err', err)
  //       this.setState({
  //         isSymptomCSVLoad: false,
  //       })
  //     }
  //   }

  apiCall(payload) {
    try {
      httpPostRequest("admin/libraryCustomerList", payload, (error, result) => {
        let stateObj = {};
        if (!isEmpty(error)) {
          stateObj = {
            messageType: "error",
            flashMessage: error.message,
            btnDisabled: false,
          };
        } else {
          if (result.status) {
            //this.setState({ customerList: [] })
            stateObj.customerList = !isEmpty(result.data)
              ? result.data
              : { data: [], count: 0 };
          } else {
            stateObj = {
              messageType: "error",
              flashMessage: result.message,
              btnDisabled: false,
            };
          }
        }
        stateObj.isHttpRequest = false;
        this.setState(stateObj);
      });
    } catch (err) {
      console.log("err", err);
      this.setState({
        messageType: "error",
        flashMessage: "Network Error!",
        btnDisabled: false,
      });
    }
  }

  filterDatewise() {
    console.log(this.state.startDate, this.state.endDate);
    this.setState({
      isSymptomCSVLoad: false,
    });
    this.setState({
      isCustomverCSVLoad: false,
    });
    if (this.state.startDate && this.state.endDate) {
      if (
        this.today !== this.state.endDate ||
        this.firstDay !== this.state.startDate
      ) {
        this.apiCall({
          fromDate: moment(this.state.startDate)
            .format("YYYY-MM-DD")
            .slice(0, 10),
          toDate: moment(this.state.endDate)
            .format("YYYY-MM-DD")
            .slice(0, 10),
          customerName: this.state.customerName,
          courseFilterType: 0,
          adminID: this.state.adminID,
        });
        // this.getCustomerDataCsv({
        //   fromDate: moment(this.state.startDate)
        //     .format('YYYY-MM-DD')
        //     .slice(0, 10),
        //   toDate: moment(this.state.endDate)
        //     .format('YYYY-MM-DD')
        //     .slice(0, 10),
        // })
        // this.getSymptomDataCsv({
        //   fromDate: moment(this.state.startDate)
        //     .format('YYYY-MM-DD')
        //     .slice(0, 10),
        //   toDate: moment(this.state.endDate)
        //     .format('YYYY-MM-DD')
        //     .slice(0, 10),
        // })
      }
    } else if (!this.state.startDate && !this.state.endDate) {
      this.apiCall({
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        customerName: "",
        courseFilterType: 0,
        adminID: this.state.adminID,
      });
    } else {
      this.getCustomerDataCsv();
      this.getSymptomDataCsv();
    }
  }

  isValidDate = (current) => {
    // package end selector
    // console.log(current)
    // console.log(this.state.endDate - this.state.startDate)
    // return current.isAfter(this.state.startDate) && moment(current).diff(this.state.startDate, 'days') <= 6 && current.isBefore(moment())
    return current.isAfter(this.state.startDate) && current.isBefore(moment());
  };

  onDateChange = (e) => {
    let stateObj = { startDate: e._d };
    let startDate = moment(e._d);
    let endDate = moment(this.state.endDate);
    if (endDate < startDate) {
      stateObj.endDate = e._d;
    }
    this.setState(stateObj);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page.selected });
  };

  SortColumn(column, sortDirection, event) {
    if (!isEmpty(column.keyName) && !isEmpty(sortDirection)) {
      this.apiCall({
        fromDate: !isEmpty(this.state.startDate)
          ? moment(this.state.startDate)
            .format("YYYY-MM-DD")
            .slice(0, 10)
          : this.state.startDate,
        toDate: !isEmpty(this.state.endDate)
          ? moment(this.state.endDate)
            .format("YYYY-MM-DD")
            .slice(0, 10)
          : this.state.endDate,
        customerName: this.state.customerName,
        courseFilterType: 0,
        columnName: column.keyName,
        filterType: sortDirection,
        adminID: this.state.adminID,
      });
    }
  }

  openPopup = (e, customerInfo) => {
    e.preventDefault();
    this.setState({
      openConfirmation: true,
      customerInfo: customerInfo,
    });
  };

  closePopup = () => {
    this.setState({
      openConfirmation: false,
      customerInfo: {},
    });
  };

  deleteCustomer = (customerInfo) => {
    try {
      httpPostRequest(
        "admin/deleteCustomer",
        {
          customerID: customerInfo.customerID,
        },
        (error, result) => {
          let stateObj = {};
          if (!isEmpty(error)) {
            stateObj = {
              messageType: "error",
              flashMessage: error.message,
              btnDisabled: false,
            };
          } else {
            if (result.status) {
              this.setState({
                openConfirmation: false,
                customerInfo: {},
              });
              this.apiCall({
                fromDate: this.state.startDate,
                toDate: this.state.endDate,
                customerName: this.state.customerName,
                courseFilterType: 0,
              });
            } else {
              stateObj = {
                messageType: "error",
                flashMessage: result.message,
                btnDisabled: false,
              };
            }
          }
          this.setState(stateObj);
        }
      );
    } catch (err) {
      console.log("err", err);
      this.props.history.push("/admin/courses");
    }
  };

  handleSort = (column, sortDirection) => {
    this.setState({
      sort: sortDirection,
      sortColumn: column.sortField,
    });
    let data = {
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      customerName: "",
      courseFilterType: 0,
      adminID: this.state.adminID,
    };
    let { currentPage, sort, sortColumn, rowsPerPage, searchTerm } = this.state;
    let bodyData = {
      ...data,
      order: sortDirection,
      orderBy: column.sortField,
      page: currentPage,
      perPageItem: rowsPerPage,
      keyword: searchTerm,
    };
    this.apiCall(bodyData);
  };

  handleRowsPerPageChange = (row) => {
    this.setState({ rowsPerPage: row });
    let {
      currentPage,
      sort,
      sortColumn,
      rowsPerPage,
      searchTerm,
      creatorID,
    } = this.state;
    let data = {
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      customerName: "",
      courseFilterType: 0,
      adminID: this.state.adminID,
    };
    let bodyData = {
      ...data,
      order: sort,
      orderBy: sortColumn,
      page: currentPage,
      perPageItem: row,
      keyword: searchTerm,
    };
    this.apiCall(bodyData);
  };

  handlePagination = (page) => {
    this.setState({ currentPage: page });
    let data = {
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      customerName: "",
      courseFilterType: 0,
      adminID: this.state.adminID,
    };
    let { currentPage, sort, sortColumn, rowsPerPage, searchTerm } = this.state;
    let bodyData = {
      ...data,
      order: sort,
      orderBy: sortColumn,
      page: page,
      perPageItem: rowsPerPage,
      keyword: searchTerm,
    };
    this.apiCall(bodyData);
  };

  filterSearchWise(val) {
    this.setState({ searchTerm: val });
    let data = {
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      customerName: "",
      courseFilterType: 0,
      adminID: this.state.adminID,
    };
    let { currentPage, sort, sortColumn, rowsPerPage, searchTerm } = this.state;
    let bodyData = {
      ...data,
      order: sort,
      orderBy: sortColumn,
      page: currentPage,
      perPageItem: rowsPerPage,
      keyword: val,
    };
    this.apiCall(bodyData);
  }


  handleAccessLengthClick = (row) => {
    this.setState({
      customerAccessLengthData: row,
      showAccessLengthPopUp: true
    })
  }

  handleAccessLengthSubmit = async (data) => {
    let { date } = data
    let { customerAccessLengthData } = this.state
    let bodyData = {
      customerID: customerAccessLengthData.customerID,
      lastPaymentDate: moment(customerAccessLengthData.subcriptionExpirationDate, 'DD-MM-YYYY').format('YYYY-MM-DD HH:mm:ss'),
      newLastPaymentDate: moment(date).format('YYYY-MM-DD HH:mm:ss')
    }
    const apiResponse = await updateCustomerAccessLengthDate(bodyData)
    if (apiResponse.status === true) {
      this.setState({
        customerAccessLengthData: {},
        showAccessLengthPopUp: false
      })
      const status = 'success'
      const message = 'Access length udpated'
      toast.success(
        <Toast message={message} status={status} />,
        { icon: true, theme: 'dark', transition: Slide, hideProgressBar: true, autoClose: 5000, position: toast.POSITION.BOTTOM_CENTER }
      )
      let { currentPage, sort, sortColumn, rowsPerPage, searchTerm } = this.state;
      let bodyData = {
        order: sort,
        orderBy: sortColumn,
        page: currentPage,
        perPageItem: rowsPerPage,
        keyword: searchTerm,
      };
      this.apiCall({
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        customerName: "",
        courseFilterType: 0,
        adminID: this.state.adminID,
        ...bodyData,
      });
    } else {
      const status = 'error'
      const message = apiResponse.message
      toast.error(
        <Toast message={message} status={status} />,
        { icon: true, theme: 'dark', transition: Slide, hideProgressBar: true, autoClose: 5000, position: toast.POSITION.BOTTOM_CENTER }
      )
    }
  }

  render() {
    let {
      openConfirmation,
      customerInfo,
      customerList,
      CustomerHeaders,
      SymptomHeaders,
      CustomerCsvData,
      SymptomCsvData,
      isCustomverCSVLoad,
      isSymptomCSVLoad,
      startDate,
      endDate,
    } = this.state;
    // console.log(CustomerCsvData)
    const columns = [
      {
        name: "Name",
        selector: "name",
        keyName: "c.firstName",
        sortable: true,
        left: true,
        sortField: "firstName",
        width: "200px",
        allowOverflow: false,
        cell: (row) => <span>{row.firstName + " " + row.lastName}</span>,
      },
      {
        name: "Product Name",
        selector: "planName",
        keyName: "p.planID",
        sortable: true,
        left: true,
        sortField: "planName",
        width: "150px",
        allowOverflow: false,
        cell: (row) => <span>{row.planName ? row.planName : <BsDash />}</span>,
      },
      {
        name: "Teacher Name",
        selector: "teacherName",
        keyName: "cre.creatorID",
        sortable: true,
        sortField: "teacherName",
        left: true,
        width: "150px",
        allowOverflow: false,
        cell: (row) => (
          <span>{row.teacherName ? row.teacherName : <BsDash />}</span>
        ),
      },
      /* {
        name: "Coupon",
        selector: "couponCode",
        keyName: "cp.couponCode",
        sortable: true,
        left: true,
        width: "150px",
        allowOverflow: false,
      }, */
      {
        name: "Last Login Date",
        selector: "lastLoginDate",
        keyName: "c.lastLoginDate",
        sortable: true,
        left: true,
        width: "180px",
        sortField: "lastLoginDate",
        allowOverflow: false,
        cell: (row) => (
          <span>{row.lastLoginDate ? row.lastLoginDate : <BsDash />}</span>
        ),
      },
      {
        name: "First Payment Date",
        selector: "firstPaymentDate",
        keyName: "cp.createdOn",
        sortable: true,
        sortField: "firstPaymentDate",
        left: true,
        width: "180px",
        allowOverflow: false,
        cell: (row) => (
          <span>
            {row.firstPaymentDate ? row.firstPaymentDate : <BsDash />}
          </span>
        ),
      },
      {
        name: "Last Payment Date",
        selector: "lastPaymentDate",
        keyName: "cp.createdOn",
        sortable: true,
        sortField: "lastPaymentDate",
        left: true,
        width: "180px",
        allowOverflow: false,
        cell: (row) => (
          <span>{row.lastPaymentDate ? row.lastPaymentDate : <BsDash />}</span>
        ),
      },
      {
        name: "Last Watched Date",
        selector: "lastWatchedDate",
        keyName: "vWh.createdOn",
        sortable: true,
        sortField: "lastWatchedDate",
        left: true,
        width: "180px",
        allowOverflow: false,
        cell: (row) => (
          <span>{row.lastWatchedDate ? row.lastWatchedDate : <BsDash />}</span>
        ),
      },
      {
        name: "Registration Date",
        selector: "registrationDate",
        keyName: "c.createdOn",
        sortable: true,
        sortField: "registrationDate",
        left: true,
        width: "180px",
        allowOverflow: false,
        cell: (row) => (
          <span>
            {row.registrationDate ? row.registrationDate : <BsDash />}
          </span>
        ),
      },
      {
        name: "Content Pause",
        selector: "contentPause",
        keyName: "pi.isPause",
        sortable: false,
        sortField: "contentPause",
        left: true,
        omit: true,
        width: "180px",
        allowOverflow: false,
        cell: (row) => (
          <span>{row.contentPause ? row.contentPause : <BsDash />}</span>
        ),
      },
      {
        name: "Billing Pause",
        selector: "billingStatus",
        keyName: "cpm.isPause",
        sortable: false,
        left: true,
        omit: true,
        sortField: "billingStatus",
        width: "180px",
        allowOverflow: false,
        cell: (row) => (
          <span>{row.billingStatus ? row.billingStatus : <BsDash />}</span>
        ),
      },
      {
        name: "Action",
        selector: "",
        sortable: false,
        center: true,
        width: "350px",
        allowOverflow: false,
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-center">
            <span role='button' onClick={() => this.handleAccessLengthClick(row)} className='font__weight--simibold data-link'>
              Access length
            </span>
            <Link
              to={`/admin/edit-customer/personal-detail/${base64.encode(
                row.customerID
              )}`}
              className="font__weight--simibold data-link"
            >
              {/* Edit */}
              <span className='material-icons'>create</span>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={(e) => this.openPopup(e, row)}
            >
              {/* Delete */}
              <span className='material-icons' title='Delete'>
                delete
              </span>
            </Link>
          </div>
        ),
      },
      {
        name: "",
        selector: "",
        sortable: false,
        center: true,
        width: "80px",
        omit: true,
        allowOverflow: false,
        cell: (row) => (
          <Link
            to="#"
            className="text-danger"
            onClick={(e) => this.openPopup(e, row)}
          >
            {/* Delete */}
            <span className='material-icons' title='Delete'>
              delete
            </span>
          </Link>
        ),
      },
    ];
    const rendernoDataComponent = (text) =>
      text ? (
        <div className="loader py-2">{text}</div>
      ) : (
        <div className="col-12 mt-2 ">
          <FlashMessages type="warning" message="No record found" />
        </div>
      );

    // Function to handle on page changes

    return (
      <React.Fragment>
        <div id="admin-container">
          <div className="dash-wrapper">
            <main id="main">
              <div className="px-3">
                <div className="page-heading between-xs w-100">
                  <div className="heading-content d-flex-xs flex-wrap-xs middle-xs">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.6515 20.4054C20.2043 20.2902 20.5336 19.7117 20.2589 19.2183C19.6533 18.1307 18.6993 17.1749 17.4788 16.4465C15.907 15.5085 13.9812 15 12 15C10.0188 15 8.09292 15.5085 6.52112 16.4465C5.30069 17.1749 4.34666 18.1307 3.74108 19.2183C3.46638 19.7117 3.79562 20.2902 4.34843 20.4054V20.4054C9.39524 21.4572 14.6047 21.4572 19.6515 20.4054V20.4054Z"
                        fill="white"
                      />
                      <circle cx="12" cy="8" r="5" fill="white" />
                    </svg>
                    <h1 className="heading">Customers</h1>
                  </div>
                  <div className="search-filter filter-xl">
                    <div className="form-group form-creator-30 mr-10">
                      <input
                        type="text"
                        name="search"
                        className="form-control creator"
                        placeholder="Search"
                        onChange={(e) => this.filterSearchWise(e.target.value)}
                        value={this.state.searchTerm}
                      />
                    </div>
                  </div>

                  {/**Start header */}
                  {/* <div className="search-filter-css customerSerach filter-lg-css mt-3">
                  <div className="row align-items-center g-3"> */}
                  {/* <div className='col'>
                      <div className='form-group-css mr-10'>
                        <input type='text' name='search' className='form-control creator' placeholder='Search Customer' onChange={(e) => this.filterSearchWise(e.target.value)} value={this.state.customerName} />
                      </div>
                    </div>
                    <div className='col d-none'>
                      <div className='form-group-css mr-10'>
                        <Datetime
                          className='theme-datePicker'
                          ref='datetime'
                          value={this.state.startDate}
                          timeFormat={false}
                          closeOnSelect={true}
                          dateFormat='DD-MM-YYYY'
                          onChange={this.onDateChange}
                          inputProps={{placeholder: 'Start Login Date'}}
                          isClearable
                          isValidDate={(cur) => cur.isBefore(moment())}
                        />
                      </div>
                    </div>
                    <div className='col d-none'>
                      <div className='form-group-css mr-10'>
                        <Datetime className='theme-datePicker' ref='datetime' value={this.state.endDate} timeFormat={false} closeOnSelect={true} isValidDate={this.isValidDate} dateFormat='DD-MM-YYYY' onChange={(e) => this.setState({endDate: e._d})} inputProps={{placeholder: 'End Login Date'}} />
                      </div>
                    </div> */}
                  {/* <div className="col-5">
                      <div className="btn-group btn-filtergroup-css">
                        <button type='submit' className='btn btn-primary' onClick={() => this.filterDatewise()}>
                          {!endDate && !startDate ? 'fetch csv' : 'apply date'}
                        </button>
                        <CSVLink className='btn btn-primary' data={CustomerCsvData} headers={CustomerHeaders} filename={'Customer-Data.csv'} disabled={!isCustomverCSVLoad}>
                          {isCustomverCSVLoad ? 'Customer CSV' : 'customer csv'}
                        </CSVLink>

                        <CSVLink className='btn btn-primary' data={SymptomCsvData} headers={SymptomHeaders} filename={'Symptoms-Data.csv'} disabled={!isSymptomCSVLoad}>
                          {isSymptomCSVLoad ? ' Symptoms CSV' : 'symptoms csv'}
                        </CSVLink>
                      </div>
                    </div> */}
                  {/* </div>
                </div> */}
                </div>
                {/**End header */}

                <div className="table-wrapper add-category">
                  <div className=" table-responsive table-hover mt-0">
                    <DataTable
                      className="table table-responsive data-table-wrapper filter-tablecss mt-0"
                      columns={columns}
                      data={customerList.data}
                      persistTableHead={true}
                      noHeader={true}
                      responsive={true}
                      pagination={true}
                      onSort={this.handleSort}
                      sortServer={true}
                      sortIcon={<BsFillCaretDownFill />}
                      paginationServer={true}
                      paginationTotalRows={customerList.count}
                      paginationPerPage={this.state.rowsPerPage}
                      onChangeRowsPerPage={this.handleRowsPerPageChange}
                      onChangePage={this.handlePagination}
                      noDataComponent={rendernoDataComponent(
                        !this.state.isHttpRequest
                          ? customerList.length
                            ? "No record found"
                            : ""
                          : "Loading.."
                      )}
                    />
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        {openConfirmation ? (
          <ConfirmationPopup
            show={openConfirmation}
            handleSubmit={() => this.deleteCustomer(customerInfo)}
            bodyText="Do you really want to delete the customer?"
            handleClose={this.closePopup}
            {...this.props}
          />
        ) : null}

        {this.state.showAccessLengthPopUp && <AccessLengthPopUp
          data={this.state.customerAccessLengthData}
          show={this.state.showAccessLengthPopUp}
          handleClose={() => this.setState({ showAccessLengthPopUp: false })}
          handleSubmit={this.handleAccessLengthSubmit}
        />}
      </React.Fragment>
    );
  }
}
