import React from 'react'
import './feedBackCard.scss'
import { useHistory } from 'react-router-dom'
import { encode } from 'base-64'


const RenderFeedbackFail = ({ phaseGoal }) => {
    const history = useHistory()

    const handleClick = () => {
        history.push(`/student/goal/ftp-submit/${encode(phaseGoal?.id)}`)
    }
    return (
        <div className='feedback-card-container'>
            <div className="feedback-card">
                <div className="card">
                    <div className="card-body">



                        <div className="status">
                            <h3>FEEDBACK RECIEVED</h3>
                        </div>

                        <p>You would need to re-send your video to unlock more content</p>

                        <div className='btn-container'>
                            <button onClick={handleClick} className='continue-btn'>Check Your Video Submission</button>
                        </div>

                    </div>
                </div>
            </div>



        </div>
    )
}

export default function FeedbackCard({ phaseGoal }) {

    return (
        <RenderFeedbackFail phaseGoal={phaseGoal} />
    )
}
