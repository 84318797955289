import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css"
import moment from "moment"
import { date } from "yup"

export default function DatePopup(props) {
  const [startDate, setStartDate] = useState(moment().subtract(1, "week"))
  const [endDate, setEndDate] = useState(moment())

  const isValidDate = current => {
    let currentDates = moment(current._d).format()
    let after7Days = moment(startDate).add(1, "week")
    return (
      moment(currentDates) >= moment(startDate) &&
      moment(currentDates) <= moment(after7Days) &&
      moment(currentDates) < moment()
    )
  }

  const isValidStartDate = current => {
    let currentDates = moment(current._d)
    let after7Days = moment(startDate).add(1, "week")
    return moment(currentDates) >= moment(startDate) && moment(currentDates) <= moment(after7Days)
  }
  const onDateChange = e => {
    let date = e._d
    let newStartdate = moment(date)
    setStartDate(newStartdate)
    let diff = moment(endDate).diff(moment(newStartdate), "days")

    if (diff > 7) {
      let newEndDate = moment(newStartdate).add(1, "week")
      if (moment(newEndDate) > moment()) {
        setEndDate(moment())
      } else {
        setEndDate(newEndDate)
      }
    } else if (diff < 0) {
      let newEndDate = moment(newStartdate).add(1, "week")
      if (moment(newEndDate) > moment()) {
        setEndDate(moment())
      } else {
        setEndDate(newEndDate)
      }
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      centered
      dialogclassname="modal-dialog modal-confirm modalConfirmBox"
    >
      <Modal.Header className="modal-header flex-column border-0" closeButton>
        {/* <div className="icon-box">
                    <i className="material-icons">&#xE5CD;</i>
                </div> */}
        <h4 className="modal-title w-100 justify-content-center text-center">Select Customer Created Date</h4>
        {/* <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={props.handleClose}>
          &times;
        </button> */}
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="row align-items-center g-3">
          <div className="col">
            <div className="form-group-css mr-10">
              <label>Start Date</label>
              <Datetime
                className="theme-datePicker"
                value={moment(startDate).format("DD-MM-YYYY")}
                timeFormat={false}
                closeOnSelect={true}
                dateFormat="DD-MM-YYYY"
                onChange={onDateChange}
                inputProps={{ placeholder: "Start Date" }}
                isValidDate={cur => cur.isBefore(moment())}
                isClearable
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group-css mr-10">
              <label>End Date</label>
              <Datetime
                className="theme-datePicker"
                value={moment(endDate).format("DD-MM-YYYY")}
                timeFormat={false}
                closeOnSelect={true}
                isValidDate={isValidDate}
                dateFormat="DD-MM-YYYY"
                onChange={e => setEndDate(e._d)}
                inputProps={{ placeholder: "End Date" }}
                isClearable={true}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer justify-content-center ">
        <button
          type="button"
          id={"btn-theme-admin"}
          disabled={!startDate || !endDate}
          data-dismiss="modal"
          onClick={() => props.handleexportCustomerCSVData(startDate, endDate)}
        >
          Get CSV
        </button>
      </Modal.Footer>
    </Modal>
  )
}
