import React, { useState, useEffect } from 'react';
import { Button, Modal, Fade, Form } from 'react-bootstrap';
import MagicIcon from '../../../assets/images/Magic.gif';
import emailIcon from '../../../assets/images/emailIcon.png';



export default function MagicModal(props) {
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [showModal, setShowMagicModal] = useState(false);
   
    const handleOldModal = () => setShow(false);

    return (
        <React.Fragment>
            <Modal show={props.show} size="lg" onHide={props.handleShow} onShow={handleOldModal} centered className="PopupMagicModal">
                <Modal.Header closeButton>
                    {/*<Modal.Title>Check your email</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                   
                    {(props.magicStep == '1') ? (
                        <div className="PopupMagicModal-inner">
                             <div className="text-center MagicIcon"><img src={MagicIcon} alt="" /></div>
                            <h4>Password long? Hard to type?</h4>
                            <div className="PopupMagicModal-text">Get a magic link sent to your email that will sign you in instantly!</div>
                            <div className="mb-3 mt-1"><Button variant="primary" className="btn-theme" onClick={() => { props.handleNextStep(2) }}>Send me magic link</Button></div>
                        </div>
                    ) : (<div className="PopupMagicModal-inner">
                         <div className="text-center MagicIcon"><img src={emailIcon} alt="" /></div>
                        <h4>Check your email</h4>
                        <div className="PopupMagicModal-text ">We sent an email to you at  <br />It has a magic link that will sign you in.</div>
                        <div className="mb-3 mt-1"><Button variant="primary" className="">Open mail app</Button></div>
                    </div>
                    )}
                    
                    <div className="mb-4 text-center">
                        <Button variant="light" onClick={() => setOpen(!open)} aria-controls="example-fade-text" aria-expanded={open} >
                            Enter your password
                        </Button>
                    </div>
                    <div className="row justify-content-md-center">
                        <div className="col-md-7">
                            <Fade in={open}>
                                <div className="passwordForm">
                                    <Form>
                                        <Form.Group className="" controlId="">
                                            <Form.Control type="password" placeholder="Enter Password" />
                                        </Form.Group>
                                        <div>
                                        <Button variant="btn Confirm-btn">Confirm </Button>
                                        <Button variant="light Cancel-btn">Cancel</Button>
                                        </div>
                                    </Form>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}