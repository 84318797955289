import React from 'react'
import T24hrvideos from './components/History'
import './watchhistory.scss'
import History from './components/History'

export default function WatchHistory() {


    return (
        <div className='WatchHistoryContainer'>
            <div className="info">
                Find your complete watch history here
            </div>
            <History />
        </div>
    )
}
