const GradientSVG = ()=> {
    const idCSS = "progress";
    const gradientTransform = `rotate(90)`;
    return (
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id={idCSS} gradientTransform={gradientTransform}>
            <stop offset="18.01%" stopColor="#9D1D64" />
            <stop offset=" 79.48%" stopColor="#FF8D6B" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
  
  export default GradientSVG;
  