//  export const Minute = (data) => {
//    let a = 0;
//     data.map((second) => {
//         a += second.duration;
//    })
//    return Math.floor(a/60)
// }

export const monthName = (date) => {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const d = new Date(date);
  let date_month = d.getDate() + " " + monthNames[d.getMonth()]
  // console.log(d.getMonth())
  return date_month;

}

export const actionItemByIndex = (array, index) => {
  // console.log('ss', index)
  index = (index + 1) % array.length
  return array[index];
} 

export const Duration = (data) => {
  let time = data.reduce((acc, item) => {
    return acc + item.duration
  }, 0)
  return time
}

export const Year  = () => new Date().getFullYear();



export const getTimeInHourMinuteSecond = (time) => {
    let d = Number(time);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "0s";
    return (hDisplay)?hDisplay + mDisplay:hDisplay + mDisplay + sDisplay; 
}

export const Time = (time) => {
  let minuts = Math.floor(time / 60)
  let hours = Math.floor(minuts / 60)
  let mins = minuts % 60
  return hours && mins ? `${hours}h ${mins}m` : hours ? `${hours} hr` : mins > 1 ? `${mins} mins` :  `${mins} min` 
}


export const Min_Sec = (time) => {
  let minuts = Math.floor(time / 60)
  // let hours = Math.floor(minuts / 60)
  let secs = time % 60
  return minuts && secs ? `${minuts}m ${secs}s` : minuts ? minuts === 1 ?  `${minuts} min` : `${minuts} mins` : `${secs} sec` 
}




export const Hr_Min_Sec = (time) => {
  let h = Math.floor(time / 3600)
  let m = Math.floor(time % 3600 / 60)
  let s = Math.floor(time % 3600 % 60);
  if (h && m && s ) return `${h}h ${m}m ${s}s`
  if(h && m) return `${h}h ${m}m`
  if(h && s) return `${h}h ${s}s`
  if(m && s) return `${m}m ${s}s`
  if(h) return `${h} hour`
  if(m) return `${m} min`
  return `${s} sec`
}


export const formatTime = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime
}

export const dateFormat = (date) => {
  let dateByString = date.getDate()+"-"+(parseInt(date.getMonth())+1)+"-"+date.getFullYear()
  return dateByString;
}

/** Filte the array as per react select dropdown list **/
export const filterDropdown = (list,id,value) => {
  return list.map((info)=>{
	  return { label: info[value], value: info[id] }
  })
}

export const commaCat = (category) => Array.prototype.map.call(category, function(item) { return item.categoryID; }).join(",");
export const commaSym = (symptom) => Array.prototype.map.call(symptom, function(item) { return item.symptomID; }).join(",");

export const validURL = (url) =>{
  if(url.substring(0, 4) === 'http') return url
  else return 'http://'+url
  }





  export function makeTitle(slug) {
    var words = slug.split('-');
  
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
  
    return words.join(' ');
  }
	export function padStart(number, digits = 2, emptyDigit = 0) {
		let length = 0;
		let n = Math.abs(number);
		let absoluteNumber = n;
		do {
			n /= 10;
			length++;
		} while (n >= 1);
		const prefix = Array(Math.max((digits - length) + 1, 0)).join(emptyDigit);
		return number < 0 ? '' : prefix + number;
	}