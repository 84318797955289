import React from 'react'
// import './index.scss'
import styles from './header.module.scss'
import Phase1 from "assets/images/Moonphase1.svg"
import Phase2 from "assets/images/Moonphase2.svg"
import Phase3 from "assets/images/Moonphase3.svg"
import Phase4 from "assets/images/Moonphase4.svg"
import { getTranslatedName, isEmpty } from 'config/functions'


export default function PhaseHeader({ phases, activePhaseId }) {
    console.log(phases)

    const phaseContent = !isEmpty(phases) ? phases.map((phase, index) => {
        return {
            name: phase.title,
            subtitle: phase.subtitle,
            tag: `Phase ${index + 1}`,
            image: index == 0 ? Phase1 : index == 1 ? Phase2 : index == 2 ? Phase3 : Phase4,
            completed: phase.id < activePhaseId,
            active: phase.id == activePhaseId
        }
    }) : []


    return (
        <div className={styles['phases-container']}>
            {phaseContent.map((phase, index) => (
                <React.Fragment key={index}>
                    <div className={styles['phase-item']}>
                        <img
                            src={phase.image}
                            alt={`phase-${index}`}
                            className={`img-fluid mb-2'}`}
                        />
                        <p className={`${styles['phase-name']} ${phase.active ? 'text-white' : phase.completed ? 'text-white' : 'text-muted'}`}>
                            {phase.name}
                        </p>
                        <b className={`${styles['phase-tag']} ${phase.active ? 'text-white' : phase.completed ? 'text-white' : 'text-muted'}`}>
                            {phase.subtitle}
                        </b>
                        {phase.active && (
                            <p className={styles['position']}>{getTranslatedName("You're here")}</p>

                        )}
                        {phase.completed && (
                            <p className={styles['completed']}>{getTranslatedName("Completed")}</p>

                        )}
                    </div>
                    <div className={styles['timeline-line']}></div>
                </React.Fragment>
            ))}

        </div>
    )
}
