import React, { useState } from 'react'

import image1 from 'assets/images/FillipaL.png'
import './phaseCourse.scss'
import { getTranslatedName, isEmpty } from 'config/functions'
import { useHistory } from 'react-router-dom'
import base64 from 'base-64'
import { Progress } from 'reactstrap'
import ProgressBar from 'components/ProgressBar/ProgressBar'

import CourseWaitingModal from 'components/FtpReviewCard/CourseWaitingModal'
import RecommendedConfirmationModal from 'components/popup/RecommendedConfirmationModal'

const getLibraryName = (library) => {
    let libraryName = ''
    if (library.libraryType === 1) {
        libraryName = library.course?.title
    } else if (library.libraryType === 2) {
        libraryName = library.playlist?.title
    } else if (library.libraryType === 3) {
        libraryName = library.video?.title
    }
    return libraryName;
}

const getThumbnail = (library) => {
    let thumbnail = ''
    if (library.libraryType === 1) {
        thumbnail = library.course?.thumbnail
    } else if (library.libraryType === 2) {
        thumbnail = library.playlist?.thumbnail
    } else if (library.libraryType === 3) {
        thumbnail = library.video?.thumbnail
    }
    return thumbnail;

}

const getLibraryLessons = (library) => {
    let lesson = {}
    if (library.libraryType === 1) {
        let item = library.course?.courseLessons
        if (!isEmpty(item)) {
            const firstLesson = item[0]
            if ((firstLesson.customerLibraryLessonHistories?.length > 0) && (firstLesson.customerLibraryLessonHistories[0].isCompleted == 1)) {
                lesson = item[library.course?.courseLessons.length - 1]
            } else {
                lesson = item[0]
            }
        }
    } else if (library.libraryType === 2) {
        let item = library.playlist?.playlistLessons
        if (!isEmpty(item)) {
            const firstLesson = item[0]
            if ((firstLesson.customerLibraryLessonHistories?.length > 0) && (firstLesson.customerLibraryLessonHistories[0].isCompleted == 1)) {
                lesson = item[library.playlist?.playlistLessons.length - 1]
            } else {
                lesson = item[0]
            }
        }
    } else if (library.libraryType === 3) {
        lesson = {
            id: library.video?.id,
            lessonName: library.video?.title,
            day: 1
        }
    }

    return lesson;
}

const getTotalCompletedLessons = (library) => {
    let totalCompletedLessons = 0
    if (library.libraryType == 1) {
        totalCompletedLessons = library.course?.totalCompletedLesson
    } else if (library.libraryType == 2) {
        totalCompletedLessons = library.playlist?.totalCompletedLesson
    } else if (library.libraryType == 3) {
        totalCompletedLessons = library.video?.totalCompletedLesson
    }
    return totalCompletedLessons;
}

const getTotalLessons = (library) => {
    let totalLessons = 0
    if (library.libraryType === 1) {
        totalLessons = library.course?.totalLessons
    } else if (library.libraryType === 2) {
        totalLessons = library.playlist?.totalLessons
    } else if (library.libraryType === 3) {
        totalLessons = 1
    }
    return totalLessons;
}

const getTotalWatchTime = (library) => {
    let totalWatchTime = 0
    if (library.libraryType === 1) {
        totalWatchTime = library.course?.totalWatchedTime
    } else if (library.libraryType === 2) {
        totalWatchTime = library.playlist?.totalWatchedTime
    } else if (library.libraryType === 3) {
        totalWatchTime = library.video?.totalWatchedTime
    }
    return totalWatchTime;
}


const getFirstLesson = (library) => {
    let firstLesson = ''
    if (library.libraryType === 1) {
        firstLesson = library.course?.firstLesson

    } else if (library.libraryType === 2) {
        firstLesson = library.playlist?.firstLesson

    } else if (library.libraryType === 3) {
        firstLesson = library.video?.title
    }
    return firstLesson;

}

export default function PhaseLibraryCard({ phaseLibrary }) {
    const [recommomendedModalInfo, setRecommendedModalInfo] = useState()
    const history = useHistory()
    const [showUnlock, setShowUnlock] = useState(false)
    const libraryData = !isEmpty(phaseLibrary) ? phaseLibrary.map(item => {
        return {
            customerLibraryMapID: item.customerLibraryMapID,
            libraryID: item.libraryID,
            firstLesson: getFirstLesson(item),
            libraryType: item.libraryType,
            lockStatus: item.lockStatus,
            weightage: item.weightage,
            thumbnail: getThumbnail(item),
            libraryName: getLibraryName(item),
            lesson: getLibraryLessons(item),
            totalCompletedLessons: getTotalCompletedLessons(item),
            totalLessons: getTotalLessons(item),
            isCompleted: item.isCompleted,
            totalWatchedTime: getTotalWatchTime(item),
        }
    }) : []


    const handleRecommendedModal = (library) => {
        setRecommendedModalInfo(library)
    }

    const handleLibraryClick = (library) => {

        if (library?.lockStatus == 1) {
            return setShowUnlock(true)
        }

        let url = ''
        if (library.libraryType == 1) {
            url = `/student/library/${base64.encode(library.customerLibraryMapID)}`
        } else if (library.libraryType == 2) {
            url = `/student/library/${base64.encode(library.customerLibraryMapID)}`
        } else if (library.libraryType == 3) {
            url = `/student/library/${base64.encode(library.customerLibraryMapID)}`
        }
        history.push(url)
    }

    const handleLessonClick = (library) => {
        const lesson = library.lesson
        if (library.libraryType == 3) {
            return history.push(`/student/library/${base64.encode(library.customerLibraryMapID)}`)
        } else if (Object.keys(lesson).length === 0) {
            return history.push(`/student/library/${base64.encode(library.customerLibraryMapID)}`)
        }
        history.push(`/student/libary-lessons/${base64.encode(lesson.id)}/`)
    }


    const ActionButton = ({ library }) => {
        if (library.lockStatus == 1) {
            return <div className="wait-btn">
                <button onClick={() => handleLibraryClick(library)}>{getTranslatedName("Waiting for you")}</button>

            </div>
        } else if (library.lockStatus == 2) {
            return (
                <div className="recomend-btn">
                    <button onClick={() => handleRecommendedModal(library)}>
                        {getTranslatedName("Recommended for you")}
                    </button>

                </div>
            )

        } else if (library.isCompleted == 1) {
            return (
                <div className="restart-btn">

                    <button onClick={() => handleLessonClick(library)}>{getTranslatedName("Re-start")}</button>

                </div>
            )

        } else if (library.totalWatchedTime) {
            return (
                <div className="continue-btn">
                    <button onClick={() => handleLessonClick(library)}>{getTranslatedName("Continue Watching")}</button>

                </div>
            )
        } else {
            return (
                <div className="start-btn">
                    <button onClick={() => handleLessonClick(library)}>
                        {
                            library.libraryType === 1 ? getTranslatedName('Start Course') :
                                library.libraryType === 2 ? getTranslatedName('Start Playlist') :
                                    getTranslatedName('Start Video')
                        }
                    </button>

                </div>
            )

        }
    }


  

    return (
        <>
            <div className='phase-card-container mx-2'>
                {libraryData.map((library, index) => {
                    return <div className='phase-box' key={index}>
                        <h3 className='title'>{library.libraryName}</h3>
                        <div className="phase-card">
                            <div className="lesson-info">
                                {/* <h4>Lesson {library?.lesson?.day} of {library?.totalLessons}</h4> */}
                                <h4>
                                    {library?.lesson?.day ? `${getTranslatedName('Lesson')} ${library?.lesson?.day} ${getTranslatedName('of')} ${library?.totalLessons}` : `Lesson ${library?.totalLessons} of ${library?.totalLessons}`}
                                </h4>

                                <div className="lib-library-dropdown">
                                    <div className="btn-group dropleft">
                                        <span
                                            className="material-icons z icon-vertical"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            more_vert
                                        </span>
                                        <div
                                            className="dropdown-menu dropdown-option "
                                        >
                                            <span onClick={() => handleLibraryClick(library)} className="dropdown-item">
                                                {getTranslatedName("More Info")}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div >

                            <div className="title-image">
                                <div className='lesson-name'>
                                    <h2>{library?.lesson?.lessonName ? library?.lesson?.lessonName : library?.firstLesson}</h2>
                                </div>
                                {<div className="pose-container">
                                    <img src={library.thumbnail || image1} alt="pose" />
                                </div>}
                            </div>

                            <div className="library-progress">
                                <ProgressBar library={library} />
                            </div>

                            <div className='btn-container'>
                                <ActionButton library={library} />
                            </div>
                        </div >
                    </div >
                })}
                {
                    showUnlock && (
                        <CourseWaitingModal show={showUnlock} close={(v) => { setShowUnlock(v) }} />
                    )
                }
            </div >
            {!isEmpty(recommomendedModalInfo) && <RecommendedConfirmationModal
                show={!isEmpty(recommomendedModalInfo)}
                handleClose={() => setRecommendedModalInfo()}
                closeButtonText={getTranslatedName("Close")}
                submitButtonText={getTranslatedName("Read more & purchase")}
                handleSubmit={() => handleLibraryClick(recommomendedModalInfo)}
            />}
        </>
    )
}

