import React, { useEffect, useRef } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import GradientSVG from 'views/pages/DashboardNew/Gradient';
import './PhaseAccordion.scss'
import PhaseCourseCard from 'components/PhaseCourseCards';
import GoalCard from 'components/GoalCard/GoalCard';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import GreetCustomer from 'components/GreetCustomer';
export default function PhaseAccordion({ phase, handlePhaseToggle, openedPhase, nextPhase }) {

    const studentData = useSelector(state => state.register.userProfileData)
    const idCSS = "progress"
    console.log(phase)

    const getPhaseLibraryCompleted = (phase) => {
        const activePhaseId = studentData['activePhaseId']
        return phase.phaseId <= activePhaseId
    }
    let showGoal = getPhaseLibraryCompleted(phase)
    let phaseTotalLessons = 0
    let phaseCompletedLessons = 0
    const libraries = phase.library
    for (let i = 0; i < libraries.length; i++) {
        const library = libraries[i];
        if (library.libraryType == 1) {
            const totalLessons = library?.course?.totalLessons
            const completedLessons = library?.course?.totalCompletedLesson
            phaseTotalLessons += totalLessons
            phaseCompletedLessons += completedLessons
        }
        if (library.libraryType == 2) {
            const totalLessons = library?.playlist?.totalLessons
            const completedLessons = library?.playlist?.totalCompletedLesson
            phaseTotalLessons += totalLessons
            phaseCompletedLessons += completedLessons
        }
        if (library.libraryType == 3) {
            const totalLessons = library?.video?.totalLessons
            const completedLessons = library?.video?.totalCompletedLesson
            phaseTotalLessons += totalLessons
            phaseCompletedLessons += completedLessons
        }
    }

    let progress = (phaseCompletedLessons / phaseTotalLessons) * 100

    if (isNaN(progress)) {
        progress = 0
    } else if (progress > 100) {
        progress = 100
    } else if (progress < 0) {
        progress = 0
    }



    let phaseGoal = {};

    if (!isEmpty(phase?.goals)) {
        let goal = phase?.goals[0]
        phaseGoal['id'] = goal.id
        phaseGoal['goalType'] = goal.goalType
        phaseGoal['isAccepted'] = goal?.customerPhaseGoals ? goal?.customerPhaseGoals[0]?.isAccepted : null
        phaseGoal['status'] = goal?.customerPhaseGoals ? goal?.customerPhaseGoals[0]?.status : null
        phaseGoal['item'] = goal?.noOfItem
    }
    return (
        <>
            {
                ((phase.phaseId == studentData.activePhaseId)) ? <GreetCustomer student={studentData} video={false} /> : null
            }

            <div className="accordion border-0" id="phases">

                <div className="accordion-item border-0">
                    <div className="accordion-header align-items-center mb-3">

                        <div className='tag-btn'>
                            <p className={`phase-tag ${((phase.phaseId == studentData.activePhaseId)) ? 'active' : ''}`}> {phase?.subtitle || phase.title}  * </p>
                            <button onClick={() => handlePhaseToggle(phase.phaseId)} className={`accordion-button border-0 ${openedPhase.includes(phase.phaseId) && 'active'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#phase-${phase.id}`} aria-expanded={true} aria-controls={`phase-${phase.id}`}>
                                {phase.title} &nbsp; &nbsp; {
                                    openedPhase.includes(phase.phaseId) ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>
                                }
                            </button>
                        </div>


                        <div className='progress-circle'>
                            <CircularProgressbarWithChildren
                                value={progress}
                                circleRatio={1}
                                strokeWidth={8}
                                styles={{
                                    width: '32px',
                                    color: 'white',
                                    root: {
                                        transform: "rotate(0deg)"
                                    },
                                    trail: {},
                                    path: {
                                        stroke: `url(#${idCSS})`
                                    }
                                }}
                            >

                                <span className="text-white   percent">
                                    {progress.toFixed(0)}%
                                </span>

                                <GradientSVG />
                            </CircularProgressbarWithChildren>
                        </div>
                    </div>


                    <div id={`phase-${phase.phaseId}`} className={` accordion-collapse collapse ${openedPhase.includes(phase.phaseId) ? 'show' : ''}`}
                        aria-labelledby={`phase-${phase.phaseId}`} data-bs-parent='#phases'>
                        <div className="phase-accordion-body">

                            <PhaseCourseCard phaseLibrary={phase.library} />
                            {phaseGoal && phaseGoal.goalType == "1" && phaseGoal.status && <GoalCard goalType="ftp" phaseGoal={phaseGoal} status={phaseGoal.status} library={nextPhase.library} />}
                            {phaseGoal && phaseGoal.goalType == "2" && phaseGoal.status && <GoalCard goalType="liveclass" phaseGoal={phaseGoal} status={phaseGoal.status} showLiveClassButtonEnabled={showGoal} />}
                        </div>
                    </div>
                </div>
                {openedPhase.includes(phase.phaseId)
                    && (<hr className='bg-white' />)}
                {/* {(openedPhase.includes(phase.phaseId) ||
                    (phase.phaseId == studentData.activePhaseId))
                    && (<hr className='bg-white' />)} */}

            </div>
        </>

    );
}



