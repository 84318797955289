import React from 'react'

export default function ProgressBar({ library }) {
    const isCompleted = library.isCompleted

    const completedLessons = library.totalCompletedLessons
    const totalLessons = library?.totalLessons

    const progress = (completedLessons / totalLessons) * 100


    // if (library.lockStatus == 1) {
    //     return null
    // }

    // if (library.lockStatus == 2) {
    //     return null
    // }

    if (isCompleted == 1) {
        return (
            <div className="progress-bar">
                <div className={`progress completed h-100 w-${100}`} role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"
                    style={{
                        width: `${100}%`
                    }}
                ></div>
            </div>
        )
    }

    if (progress != 0) {
        return (
            <div className="progress-bar">
                <div className={`progress h-100 w-${progress}`} role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"
                    style={{
                        width: `${progress}%`
                    }}
                ></div>
            </div>
        )
    }

    return <div className="progress-bar">
        <div className={`progress h-100 w-${0}`} role="progressbar" aria-valuenow={0} aria-valuemin="0" aria-valuemax="100"
            style={{
                width: `${0}%`
            }}
        ></div>
    </div>

}
