import { getTranslatedName } from 'config/functions';
import React from 'react'
import { Button, Modal, ModalBody, Spinner } from 'reactstrap';

const CouponConfirmation = (props) => {
  return (
    <Modal
    scrollable={true}
    isOpen={props.show}
    className="  modal-dialog modal-dialog-scrollable"
    dialogclassname="showContentPause showContentPause-css"
    centered
    // backdrop={`static`}
  >
    <div className="bg-semiLocked">
      <ModalBody className="pauseContent p-0">
        <div className="d-flex flex-column px-2">
          <div className="w-100 text-center bg-start">
            <i className="fa fa-check-circle-o fa-5x text-success mt-5 pb-3" />
            <button
          type="button"
          className="close-modal pointer"
          onClick={() => props.handleClose({}, false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
          </div>

          <p className="my-4 title-modal">
            {props.text ? props.text : getTranslatedName('Are you sure ?')}
          </p>
            {/* <p className="mt-3 content-modal"> */}
        </div>
        <div className="w-100 text-center my-4 d-flex flex-column px-4">
          <Button
            color="secondary"
            className="btn-submit btn-risk border-0 mx-1 mb-2"
            onClick={() => props.handleSubmit()}
            disabled={props.isBtnLoading}
          >
            {(!props.isBtnLoading && (props.submitButtonText))}
            {props.isBtnLoading && <span>Please Wait...</span>}
          </Button>
          <Button
            color="primary"
            className="btn-goBack border-0 mx-1 mt-2"
            onClick={async () => {
              await props.handleClose();
            }}
          >
            {props.closeButtonText ? props.closeButtonText  :getTranslatedName("No")}
          </Button>{" "}
        </div>
      </ModalBody>
    </div>
  </Modal>
  )
}

export default CouponConfirmation