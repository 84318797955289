import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import WaiverHeader from "./WaiverHeader"
import axios from "axios"
import { useDispatch } from "react-redux"
import Vimeo from "@u-wave/react-vimeo"
import Footer from "views/registerStudentLayout/footer/FooterNew"
import PageLoader from "./PageLoader"

export default function WaiverStep1(props) {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState()
  useEffect(() => {
    dispatch({ type: "HANDLE_HEADER", payload: { isLinkActive: true } })
    callUserProfile()
    //  This code will be commenteed for dev/staging server
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.async = true
    script.innerHTML =
      "(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3507002,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
    document.head.appendChild(script)
    //   above hot jar script 25 may 23'
    //  This code will be commenteed for dev/staging server
  }, [])

  const callUserProfile = async () => {
    setLoading(true)
    try {
      const res1 = await axios({
        method: "get",
        url: "api/get_waiverSignDetail"
      })
      const user = await res1.data
        ; (await user.data.isWaiverSign) && props.history.push({ pathname: "/student/current-programme" })
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }
  const [swedish, setSwedish] = useState()
  useEffect(() => {
    const pl = window.localStorage.getItem("planID")
    if (pl == 4 || pl == 5) setSwedish(true)
  }, [swedish])

  return (
    <React.Fragment>
      <WaiverHeader {...props} />
      {isLoading ? <PageLoader /> : ""}
      <section className="page-inner payment-success-section mt-lg-5 waiver-1">
        <main className="main-content-css waiverStep-main-css ">
          <div className="dashboard pt-2 watch-container">
            <div className="py-lg-5 py-md-4 py-3">
              <div className="row header_video_row pt-2 px-0 px-md-3 pb-md-3 pb-0">
                <div className="col-12 col-lg-8 px-md-0 px-0">
                  <div className="embed-responsive embed-responsive-16by9 intro-video">
                    <Vimeo
                      video={
                        swedish
                          ? "https://player.vimeo.com/video/813155964"
                          : "https://player.vimeo.com/video/813152696"
                      }
                      className="embed-responsive-item "
                      autopause={false}
                      speed={true}
                      height="100%"
                    />
                  </div>
                </div>
                <div className=" mt-md-1 mt-lg-0 mt-2 ps-lg-4 ps-0 pe-0 pe-md-1 col-12 col-lg-4 h-100">
                  <div className="payment-successcss mt-3 mt-lg-0 mx-0">
                    <div className="flex-text-content d-flex flex-column flex-wrap h-100 position-relative">
                      <div className="flex-text-content-inner h-100 mb-3">
                        <div className=" text-wrap courseTitle pe-2">
                          {swedish
                            ? `Tack för att just du gått med i MoonRise! Här är dina nästa steg:`
                            : `Thanks for telling us about your health. Here are your next steps:`}
                        </div>
                        <div className="payment-text mt-md-4">
                          <ul className="list-group">
                            <li className="list-group-item">
                              {swedish ? `Titta på videon` : `Watch this introduction video`}
                            </li>

                            <li className="list-group-item">
                              {swedish
                                ? ` Skriv under hälsofriskrivning`
                                : `Sign the health disclaimer in the next step`}
                            </li>

                            {/* // This line removed  */}
                            {/* <li className='list-group-item'>{swedish ? `Ange symtompoäng` : `Enter Symptom Scores`}</li> */}
                          </ul>
                        </div>
                      </div>

                      <div className="flex-text-content-bottom">
                        <div className="d-flex-no flex-column-no align-items-end justify-content-end">
                          <Link
                            to={"/student/waiver-step-2"}
                            className="btn-theme-no btn-join-community w-100 btn btn-secondary "
                            style={{ textDecoration: "none" }}
                          >
                            <span>{swedish ? "Nästa" : "Next"}</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
      <Footer swedish={swedish} {...props} />
    </React.Fragment>
  )
}
