import { isEmpty } from 'config/functions'
import moment from 'moment'
import React, { useState } from 'react'
import { Modal, Button, ModalBody, ModalFooter, ModalHeader, Col, Label } from 'reactstrap'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
const AccessLengthPopUp = ({ show, data, handleClose, handleSubmit }) => {
    let subcriptionExpirationDate = !isEmpty(data.subcriptionExpirationDate) ? moment(data.subcriptionExpirationDate, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY') : ''
    const [startDate, setStartDate] = useState(new Date(subcriptionExpirationDate));


    let customerName = ''
    customerName = `${!isEmpty(data.firstName) ? data.firstName : ''} ${!isEmpty(data.lastName) ? data.lastName : ''}`



    return (
        <Modal className='modal-dialog-centered' isOpen={show} toggle={() => handleClose()}>
            <ModalHeader toggle={() => handleClose()}>{customerName}</ModalHeader>
            <ModalBody>
                <h4>Edit access length</h4>
                <h6 className='mt-4 mb-4'>Last payment date : {subcriptionExpirationDate}</h6>
                <Col sm={12} className='mx-0 px-0'>
                    <Label className='form-label' htmlFor='mobile_number'>
                        New access length
                    </Label>
                    <DatePicker className='form-control' selected={startDate} onChange={(date) => setStartDate(date)} dateFormat='dd/MM/yyyy' showMonthDropdown={true} showYearDropdown={true} minDate={startDate}
                    />
                </Col>
            </ModalBody>
            <ModalFooter>
                <Button outline className=' btn-theme-admin' onClick={() => handleSubmit({ date: startDate })}>
                    Submit
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default AccessLengthPopUp