import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import thumbUpIcon from '../../assets/images/thumb-up2.svg';

export default function ShowExtendConfirmation(props) {
    return (
        <Modal size="md" onHide={props.handleClose} dialogclassname="showExtendPause" centered show={props.show} backdrop={`static`} >
            <React.Fragment>
                {(props.type == 1) ? (<React.Fragment>
                    <Modal.Header>
                        <h3>Confirmation</h3>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <h4>Are you sure want to proceed </h4>
                        {(props.data) ? (<p>{`with ${props.data} ?`}</p>) : (`?`)}
                    </Modal.Body>
                    <Modal.Footer className="text-right">
                        <div className='flex'>
                            <Button variant="" onClick={props.handleClose} className="btn-Cancel  ">
                                Cancel
                            </Button>
                        </div>
                        <div className='flex'>
                            <Button variant="" onClick={props.handleNext} className="PauseAccountBtn btn-theme">
                                Proceed
                            </Button>
                        </div>
                    </Modal.Footer>
                </React.Fragment>) : (
                    <React.Fragment>
                        <Modal.Header className=' border-0'></Modal.Header>
                        <Modal.Body className='pb-0'>
                            <div className="text-center">
                                <div className="py-3">
                                    <img src={thumbUpIcon} className="img-fluid thumb-up-icon-css" alt="thumbUpIcon" />
                                </div>
                                <h4>Thanks for your feedback</h4>
                                <h6 className="my-2">We will do better next time.</h6>
                                <p className="my-2">Our team will reach out to you within 48 hours to process your account cancellation request. We hope to see you again.</p>
                            </div>

                        </Modal.Body>
                        <Modal.Footer className='justify-content-end border-0'>
                            <div className='flex'>
                                <Button variant="" onClick={props.handleSubmit} className="btn-theme">
                                    OKAY
                                </Button>
                            </div>
                        </Modal.Footer>
                    </React.Fragment>
                )}
            </React.Fragment>
        </Modal>
    )
}