import React, { useState } from 'react'
import './awesome.scss'
import { TiTick } from "react-icons/ti";
import confettibg from 'assets/images/Confetticonfetti.svg'
import CongtatsModal from './CongtatsModal';
import { getTranslatedName, isEmpty } from 'config/functions';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { encode } from 'base-64';
export default function AwesomeCofetti({ phaseGoal, library }) {
    const [show, setShow] = useState(phaseGoal?.isAccepted == 0);
    const history = useHistory()

    const getLibraryName = () => {
        if (isEmpty(library)) return
        let libraryList = [];

        for (const item of library) {
            if (item.libraryType == 1) {
                libraryList = [...libraryList, item?.course?.title]
            } else if (item.libraryType == 2) {
                libraryList = [...libraryList, item?.playlist?.title]
            } else if (item.libraryType == 3) {
                libraryList = [...libraryList, item?.video?.title]
            }
        }

        return libraryList
    }

    const handleClick = () => {
        history.push(`/student/goal/ftp-submit/${encode(phaseGoal?.id)}`)
    }

    return (
        <div className='awesome-container'>
            {phaseGoal?.status == 'completed' && <div className='d-flex flex-column align-items-center'>
                <div className="green-tick">
                    <img src={confettibg} alt="confetti" />
                    <TiTick size={'4em'} style={{ position: 'absolute' }} />
                </div>
                <Button className='btn mt-5 continue-btn start-btn button' onClick={() => handleClick()}>{getTranslatedName("Check Your Submissions")} <i className='fa fa-arrow-right ml-2'></i></Button>
            </div>}
            {
                (phaseGoal?.isAccepted == 0 && phaseGoal?.status == 'completed') && <CongtatsModal phaseGoal={phaseGoal} library={getLibraryName()} show={show}
                    close={(v) => { setShow(v) }}
                />
            }
        </div>
    )
}
