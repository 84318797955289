import { ListGroup, ListGroupItem } from "react-bootstrap";
import { List, Progress, Badge, Row, Col, Button } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import placeholderImage from "../../../assets/images/placeholder_image.png";
import userImage from "../../../assets/images/userSubmitImage.svg";
// import PdfDefault from "../../assets/images/BG_Pdf-small.png";
import PdfDefault from "../../../assets/images/bg-pdf-img-new.jpg";

import { MdAvTimer } from "react-icons/md";
import soundwave from "../../../assets/images/soundwave.gif";
import { FaCheckCircle, FaChevronDown } from "react-icons/fa";
import heartFill from "../../../assets/images/heartNew.svg";
import heartFilled from "../../../assets/images/heartFilledNew.svg";
import base64 from "base-64";
import { Slide, toast } from "react-toastify";
import Toast from "../common-components/Toast";
import {
  convertSecondsToTime,
  getTranslatedName,
  getWatchProgress,
  isEmpty,
  isValid,
} from "config/functions";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import { handleLessonFavourite } from "../DashboardNew/apiStore";
import playBtn from "../../../assets/ftp-images/play-btn.png";
import { useSelector } from "react-redux";

const UpdatedAllLessonList = ({
  lessons,
  isLocked,
  forPublic,
  ftpCards,
  type,
  getLibraryLessonData,
  watchLesson,
  algoLessonData,
  algoSearchQuery,
  loggedInCxID,
  customerLessonData,
  ...props
}) => {
  const history = useHistory()
  const Lessondata = !isEmpty(lessons) ? lessons : [];
  const [lastItem, setLastItem] = useState(false);
  const [algoCxWiseLessonData, setAlgoCxWiseLessonData] = useState([]);
  let { customerLessonID, lessonID, lessonTemplateID } = useParams()
  const FTPCard = ({ index }) => {
    let card = ftpCards.filter(ftp => ftp.cardIndex == index + 1)
    let reviewSubmitted = 0
    let customerReviewSubmitted = null
    if (!isEmpty(card) && card.length > 0) {
      reviewSubmitted = card[0].totalReviewSubmitted
      customerReviewSubmitted = card[0].customerReviewID
    }
    return (
      <Col lg={4} md={6} sm={6} className="py-3 mb-3">
        <div className="nw-lb-ftp bg-white px-3 pt-3 pb-5 box-radius h-100 d-flex align-items-center">
          <h3 className={`text-violet cstm-item-main-title`}>{getTranslatedName("Submit a video to get your technique approved")}</h3>
          <Button className={`btn-course btn-playlist btn-new`}
            onClick={() => props.handleUserUpload(index)}
          >
            {getTranslatedName(`Submit video`)}
          </Button>
        </div>
      </Col>
    )
  }

 
  const handleSearchLessonClick = async (lessonID, objectID) => {
    if (!isEmpty(lessonID)) {
      history.push(`/student/libary-lessons/${base64.encode(lessonID)}`)
    }
    else {
      history.push(`/student/libary-lessons/${base64.encode(objectID)}`)
    }
  }
  return (
    <>
      {algoLessonData == "" && (
        <Row>
          {
            Lessondata.length > 0
              ? Lessondata.map((item, index) => {
                let inprogress = getWatchProgress(item.videoDuration, item.closeVideoLengthAt)
                const lessonIndex = index + 1
                const ftpIndex = !isEmpty(ftpCards) && ftpCards.map(ftpItem => ftpItem.cardIndex).includes(index + 1)
                let thumbnail = item.lessonType == 5 ? PdfDefault : item.thumbnailUrl ? item.thumbnailUrl : placeholderImage
                return (
                  <React.Fragment key={`lesson_${index}`}>
                    <Col
                      lg={4}
                      md={6}
                      sm={6}
                      className="py-3 pointer"
                      key={`lesson_${index}`}
                      onClick={() => props.handleLessonClick(item)}
                    >
                      <div className="nw-lesson-item-style playlist-footer h-100">
                        <div className="nw-lesson-thumbnail d-flex align-items-center ">
                          {/* <img
                          src={
                            item.thumbnailUrl
                              ? item.thumbnailUrl
                              : placeholderImage
                          }
                        /> */}
                          <img src={thumbnail} />
                          <img src={playBtn} className="play-icon" />
                        </div>
                        <div className="nw-lesson-content p-3">
                          <h5 className="cstm-secondary-text fw-bold">
                            {item.lessonName ? item.lessonName : ""}
                          </h5>
                          <p className="text-gray py-2">
                            {item.descriptionTitle ? item.descriptionTitle : ""}
                          </p>
                          <div className="username duration-content">
                            <div className="time-container py-1 d-flex justify-content-between align-items-center">
                              <div>
                                <MdAvTimer
                                  className="timer mb-1"
                                  color="#9585A6"
                                  size={15}
                                />
                                <span>
                                  {convertSecondsToTime(0, item.videoDuration)}
                                </span>
                              </div>

                              {item.isCompleted === 1 ? (
                                <Badge className="p-1 fw-normal" color="success">
                                  Completed
                                </Badge>
                              ) : inprogress > 0 && inprogress < 100 ? (
                                <Badge className="p-1 fw-normal badge-inprogress">
                                  In progress
                                </Badge>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="d-flex flex-wrap align-items-center cstm-progress-bar pt-2">
                              <Progress
                                className={`course-progress playlist-progress p-0 ${item.isCompleted === 1
                                  ? "course-progress-completed col-12"
                                  : "col-12"
                                  }`}
                                value={
                                  item.isCompleted !== 1
                                    ? !isValid(item.closeVideoLengthAt) &&
                                      !isValid(item.videoDuration)
                                      ? getWatchProgress(
                                        item.videoDuration,
                                        item.closeVideoLengthAt
                                      )
                                      : 0
                                    : 100
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {!isEmpty(ftpCards) && ftpCards.map(ftpItem => ftpItem.cardIndex).includes(index + 1) && (
                      <FTPCard index={index} />
                    )}
                  </React.Fragment>
                )
              })
              : ""
          }
        </Row>
      )}
      {algoLessonData != "" && (
        <Row>
          {algoLessonData.length > 0 ? (
            algoLessonData.map((item, index) => {
              return (
                <Col
                  lg={4}
                  md={6}
                  sm={6}
                  className="py-3 pointer"
                  key={`lesson_${index}`}
                  onClick={() => handleSearchLessonClick(item.lessonID, item.objectID)}
                >
                  <div className="nw-lesson-item-style playlist-footer h-100">
                    <div className="nw-lesson-thumbnail d-flex align-items-center">
                      <img src={item.lurl ? item.lurl : placeholderImage} alt="thumbnail" />
                      <img src={playBtn} className="play-icon" alt="play" />
                    </div>
                    <div className="nw-lesson-content p-3">
                      <h5 className="cstm-secondary-text fw-bold">{item.lName ? item.lName : ""}</h5>
                      <p className="text-gray py-2">{item.sDesc ? item.sDesc : ""}</p>
                      <div className="username duration-content">
                        <div className="time-container py-1 d-flex justify-content-between align-items-center">
                          <div>
                            <MdAvTimer className="timer mb-1" color="#9585A6" size={15} />
                            <span>{convertSecondsToTime(0, item.lvDur)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              )
            })
          ) : (
            <Row className="align-items-center">
              <Col lg={12}>
                <h2 className="text-white text-wrap mb-lg-4 mb-2 cstm-lesson-library-title">
                  {`No results found. Try again with another word`}
                </h2>
              </Col>
            </Row>
          )}
        </Row>
      )}
    </>
  )
};

export default UpdatedAllLessonList;
