import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import styles from './flexi.module.scss'

export default function FlexiAccordion({
    title, description, buttonText, buttonAction, open, setopenindex, key
}) {

    const [opn, setOpn] = useState(false)

    return (
        <div className="AccordionFaq">
            <Accordion activeKey={opn ? '0' : null} style={{
                backgroundColor: 'transparent',
            }}>
                <Card className="CardFaq">
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        onClick={() => setOpn(!opn)}
                        className={opn ? "card-header-active" : ""}
                    >
                        <div className="row">
                            <div className="col">{
                                title
                            }
                            </div>
                            <div className='col-auto ps-0"'>
                                <i className={opn ? "fa fa-minus" : "fa fa-plus"}></i>
                            </div>
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="text-block">
                                {
                                    description
                                }
                            </div>
                            <div className={styles['btn-container']}>
                                <div className={styles['chat-btn']}>

                                    <button onClick={() => buttonAction()}>
                                        {
                                            buttonText
                                        }
                                    </button>

                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>

    )
}
