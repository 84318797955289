import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import './upcoming.scss'


import Card from 'react-bootstrap/Card';
import './vod.scss'
import { FaCirclePlay } from "react-icons/fa6";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import GradientSVG from 'views/pages/DashboardNew/Gradient';
import { IoIosTimer } from "react-icons/io";

import Preview from "../../../../assets/images/Load_circle.png"
import soundwave from "../../../../assets/images/soundwave.gif"
import placeholderImage from "../../../../assets/images/placeholder_image.png";

import { encode } from 'base-64';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { CiHeart } from 'react-icons/ci';
import { BsHeartFill } from 'react-icons/bs';
import { getTranslatedName } from 'config/functions';
const idCSS = "progress"

export default function UpcomingLesson({ data, upcoming }) {

    const [lessons, setLessons] = useState(data)

    useEffect(() => {
        setLessons(data)
    }, [data])


    const UpComingCard = ({ lesson }) => {
        const duration = (lesson?.videoDuration) ? lesson?.videoDuration : 0
        const durationObject = moment.duration(duration, 'seconds');
        const history = useHistory()

        const humanReadableFormat = durationObject.humanize();

        const watchtime = lesson?.closeVideoLengthAt ? lesson?.closeVideoLengthAt : 0

        let progress = (watchtime / duration) * 100

        if (isNaN(progress)) {
            progress = 0
        } else if (progress > 100) {
            progress = 100
        } else if (progress < 0) {
            progress = 0
        }

        const LibraryName = ({ library }) => {
            let libraryName = ''
            if (library?.libraryType === 1) {
                libraryName = library.course?.title
            } else if (library?.libraryType === 2) {
                libraryName = library.playlist?.title
            } else if (library?.libraryType === 3) {
                libraryName = library.video?.title
            }
            return libraryName;
        }

        const getThumnnailUrl = (lesson) => {
            if (lesson?.lessonType == 5) { // pdf
                return Preview
            } else if (lesson?.lessonType == 6) { // audio
                return soundwave
            } else {
                return lesson?.thumbnailUrl || 'https://images.ctfassets.net/jw8fay82wbzf/6XNMnzSVvxfGVD3Df7n5uC/ab278839ea12189e7966351615c73c89/Screenshot_2023-08-11_at_19.39_2__1_.png'
            }
        }

        const handleLessonClick = (lesson) => {
            if (lesson.lockStatus == 2 || lesson.lockStatus == 1) {
                history.push(`/student/library/${encode(lesson.customerLibraryMapID)}`)
                return
            }
            if (lesson.libraryType == 3) {
                history.push(`/student/watch-video/${encode(lesson.customerLibraryMapID)}`)
            } else {
                history.push(`/student/libary-lessons/${encode(lesson.id)}`)
            }
        }
        return (
            <Card className='vod-upcoming'>
                <Card.Header>
                    <div className="vod-card-header">
                        <h2 className='title'>
                            <LibraryName library={lesson} />
                        </h2>
                        <div className='progress-circle'>
                            <CircularProgressbarWithChildren
                                // value={Math.round(50)}
                                value={progress.toFixed(0)}
                                circleRatio={1}
                                strokeWidth={8}
                                styles={{
                                    width: '30px',
                                    root: {
                                        transform: "rotate(0deg)"
                                    },
                                    color: '#fff',
                                    alignSelf: 'center !important',
                                    path: {
                                        stroke: `url(#${idCSS})`
                                    }
                                }}
                            >
                                <span className={"text-white percent"}>
                                    {progress.toFixed(0)}%
                                </span>
                                <GradientSVG />
                            </CircularProgressbarWithChildren>
                        </div>
                    </div>
                </Card.Header>

                <div className="vod-card-body" role='button' onClick={() => handleLessonClick(lesson)}>

                    <div className="play-icon" onClick={() => handleLessonClick(lesson)}>
                        <FaCirclePlay />
                    </div>


                    <Card.Img variant="top" src={getThumnnailUrl(lesson) ? getThumnnailUrl(lesson) : placeholderImage} />


                </div>

                <Card.Footer>

                    <div className="vod-card-footer">

                        <div className="info">
                            <h2 className='lesson-name'>{lesson?.lessonName}</h2>
                            <p className='lesson-description'>
                                {lesson?.descriptionTitle}
                            </p>
                        </div >


                        <div className="bottom-progress-section">
                            <p className={'text-muted'}>
                                <IoIosTimer />  {humanReadableFormat}
                            </p>


                            {lesson?.isFavourite == 1 ? <div className="fav-btn">
                                < BsHeartFill />
                            </div> : <div className="fav-btn">
                                <CiHeart />
                            </div>}

                        </div >

                    </div >
                </Card.Footer >
            </Card >
        )
    }



    const LessonList = () => {
        return (

            <OwlCarousel
                className="owl-theme"
                refreshclass=''
                lazyLoad={true}
                margin={30}
                nav
                items={1}
                // slideBy={3}
                start={true}
                dots={true}


                responsive={{
                    0: {
                        items: 1,
                        nav: false,

                    },
                    600: {
                        nav: false,
                        items: 1,

                    },
                    1000: {
                        items: 3,
                    }
                }}
            >
                {
                    lessons?.map((lesson, index) => (
                        <UpComingCard key={index} lesson={lesson} upcoming={true} />
                    ))
                }



            </OwlCarousel>
        )
    }


    return (
        <div className='upcoming-lesson-container'>

            <div className="info-upcoming">
                <h3>{getTranslatedName('Upcoming Lessons')}</h3>
                <p>{getTranslatedName('Here is a preview of what is coming up for you')}</p>

            </div>

            <LessonList />

        </div>

    )
}

