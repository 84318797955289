import React, { Suspense, useEffect, useState } from "react"
import axios from "axios"
import { Route, Switch, withRouter, useLocation } from "react-router-dom"
import { API_BASE_URL } from "config/constants"
import { isEmpty, getCookie, removeCookie } from "config/functions"
import ErrorBoundary from "./ErrorBoundary"

import AutoScrollToTop from "views/common/AutoScrollToTop"
import PublicLayout from "views/publicLayout/PublicLayout"
import PublicPagesLayout from "views/publicPagesLayout"
import RegisterLayout from "views/registerStudentLayout/RegisterLayout"
import AdminLayout from "views/adminLayout/AdminLayout"
import AdminLogin from "views/pages/auth/AdminLogin"
import { Provider, useSelector } from "react-redux"
import store from "./store"
import WaiverStep1 from "views/pages/common-components/WaiverStep1"
import WaiverStep2 from "views/pages/common-components/WaiverStep2"
import PrivacyPolicy from "views/pages/common-components/Privacy-policy"
import TermsCondition from "views/pages/common-components/Terms"
import StudentLogin from "views/pages/auth/StudentLogin"
import { isStudentLoggedIn as checkStudentLoggedIn } from "config/functions"
import { Spinner } from "reactstrap"
import "./assets/css/webfonts.css"
import "./assets/css/bootstrap.min.css"
import "./assets/css/all.min.css"
import "./assets/css/style.css"
import "./assets/css/custom-styles.css"
import "./assets/css/Comment.css"
import "./assets/css/responsive.css"
// import './assets/fonts/fonts.css'
import "./assets/css/ChatComponent.css"
import "./app.css"
import "./assets/css/waiver.css"
import "./assets/css/animate.min.css"
import "./assets/css/Version3.css"
import "../src/views/pages/LibraryNew/library.css"
import "../src/views/pages/DashboardNew/PopUps/popup.css"
import { Mixpanel } from "views/common/Mixpanel"
import base64 from 'base-64'


const App = ({ history }) => {

  let userInfo = useSelector((store) => store.register.userProfileData)
  //for scroll to top
  const pathName = history.location.pathname
  let pathKey = ""

  if (!isEmpty(pathName)) {
    let tempPathArr = pathName.split("/")
    if (tempPathArr.length > 1) {
      pathKey = tempPathArr[tempPathArr.length - 1]
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathName])

  useEffect(() => {
    if (process.env.REACT_APP_SERVERNAME == "local" || process.env.REACT_APP_SERVERNAME == "DEV") {
      const script = document.createElement("script");
      script.src = "https://static.zappr.ai/static/scripts/embed.min.js";
      script.async = true;
      document.body.appendChild(script);
    }

  }, [])
  // useEffect(() => {
  //   if (process.env.REACT_APP_SERVERNAME == "DEV") {
  //     const script = document.createElement("script");
  //     script.src = "https://pgjc3do4.clarityflow.com/scripts/embed.js";
  //     script.async = true;
  //     document.body.appendChild(script);
  //   }

  // }, [])

  const pageLoader = () => (
    <div className="app-loader d-flex justify-content-center align-items-center">
      <div className="body-overlay d-flex flex-column  justify-content-center align-items-center h-100 mx-auto">
        <Spinner className="text-center  cstm-loader" size="sm" />
        <span className=" text-white  text-center mt-1">Please wait...</span>
      </div>
    </div>
  )
  axios.defaults.baseURL = API_BASE_URL
  const isStudentLoggedIn = (props, authPage = false) => {
    let authToken = getCookie("token")
    let fromPanel = getCookie("from_panel")
    const isUserActive = getCookie("isUserPlanActive")
    axios.defaults.headers.common["authorization"] = !isEmpty(authToken) ? authToken : ""
    axios.defaults.validateStatus = function (status) {
      return status >= 200 && status < 500
    }
    if (authPage) {
      axios.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8"
      if (!isEmpty(authToken)) axios.defaults.headers.common["authorization"] = authToken
      axios.defaults.validateStatus = function (status) {
        return status >= 200 && status < 500
      }

      if (!isEmpty(authToken) && isEmpty(fromPanel)) {
        return true
      } else if (!isEmpty(authToken) && !isEmpty(fromPanel) && fromPanel == "new.moonrise") {
        window.location.replace(process.env.REACT_APP_MOONRISE_BASE_URL + "/student/home")
      } else {
        let { pathname } = props.history?.location
        if (!isEmpty(pathname)) {
          pathname = base64.encode(pathname)
          localStorage.setItem("redirectPath", pathname)
        }

        props.history.push({ pathname: "/login" })
      }
    } else {

      if (!isEmpty(authToken)) {
        if (isUserActive)
          return props.history.push({ pathname: isUserActive === "no" ? "/student" : "/student/dashboard" })
        else return props.history.push({ pathname: "/student" })
      } else {
        return false
      }
    }
  }

  const isAdminLoggedIn = (props, authPage = false) => {
    let adminToken = getCookie("adminToken")
    axios.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8"
    if (!isEmpty(adminToken)) {
      axios.defaults.headers.common["authorization"] = adminToken
    }
    if (authPage) {

      if (!isEmpty(adminToken)) {
        return true
      } else {
        props.history.push({ pathname: "/admin/login" })
      }
    } else {
      if (!isEmpty(adminToken)) {
        return props.history.push({ pathname: "/admin/dashboard" })
        // return props.history.push({ pathname : '/admin/dashboard'});
      } else {
        return false
      }
    }
  }
  useEffect(() => {
    Mixpanel.track("Page View")
  })

  const isPublicPageLoggedIn = (props, authPage = false) => {
    let authToken = getCookie("token")
    let fromPanel = getCookie("from_panel")
    const isUserActive = getCookie("isUserPlanActive")
    if (checkStudentLoggedIn() === true) {
      axios.defaults.headers.common["authorization"] = !isEmpty(authToken) ? authToken : ""
      axios.defaults.validateStatus = function (status) {
        return status >= 200 && status < 500
      }
    }
    axios.defaults.headers.common["authorization"] = !isEmpty(authToken) ? authToken : ""
    axios.defaults.validateStatus = function (status) {
      return status >= 200 && status < 500
    }
    // axios.interceptors.response.use(async (response, error) => {
    //   let apiResponse = await response.data
    //   let { method, url, data } = await response.config
    //   let responseStatus = await response.data.status
    //   let authToken = response.config.headers ? response.config.headers.authorization ? response.config.headers.authorization : null : null

    //   let error1 = response.data.error
    //     let bodyData = { method, url, data, authToken, responseStatus, pageUrl: window.location.href, error: error1 }
    //   if (responseStatus == false) {
    //     let ENVIRONMENT = process.env.REACT_APP_SERVERNAME
    //     if (ENVIRONMENT !== 'local') { //
    //       axios.post('api/inform-api-fail', bodyData).then(resp => {
    //         return response
    //       }).catch(err => {
    //         return response
    //       })
    //     } else {
    //       return response
    //     }
    //   } else {
    //     return response
    //   }
    // })
    return true
  }

  // pathArr[0]-current URL; pathArr[i]-previous paths
  useEffect(() => {
    let pathhistory = localStorage.getItem("pathhistory")
    if (!isEmpty(pathhistory)) {
      pathhistory = JSON.parse(pathhistory)
      let { pathArr } = pathhistory
      if (!isEmpty(pathArr) && pathArr.length > 0) {
        if ((pathName.includes("/student/libary-lessons/") && pathArr[0].includes("/student/libary-lessons/"))) return
        if (pathArr.length == 1 || pathArr.length == 2) {
          let newPathArr = [pathName, ...pathArr]
          let pathhistory = { pathArr: newPathArr }
          localStorage.setItem("pathhistory", JSON.stringify(pathhistory))
        } else if (pathArr.length == 3) {
          pathArr.pop()
          let newPathArr = [pathName, ...pathArr]
          let pathhistory = { pathArr: newPathArr }
          localStorage.setItem("pathhistory", JSON.stringify(pathhistory))
        }
      }
    } else {
      // if (pathArr.filter(item => item.includes('/student/libary-lessons/')).length > 0) return
      let pathhistory = { pathArr: [pathName] }
      localStorage.setItem("pathhistory", JSON.stringify(pathhistory))
    }
  }, [pathName])

  useEffect(() => {
    checkUserLoginStatus()
  }, [pathName, pathKey])

  async function checkUserLoginStatus() {
    let authToken = getCookie("token")
    if (!(pathName.includes("/student") || pathName.includes("/public"))) {
      if (isEmpty(authToken)) {
        return
      }
    }
    if (isEmpty(authToken)) return
    try {
      let res = await axios.get("api/checkCustomerStatus", {
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          authorization: authToken
        }
      })

      let data = res.data.data
      if (data && data.status == 3) {
        removeCookie("token")
        removeCookie("isUserPlanActive")
        removeCookie("phoneVerificationPopup")
        removeCookie("adminID")
        window.localStorage.setItem("planID", "")
        window.location.href = "/login?logoutType=force"
      }
    } catch (error) {
      console.log(error)
    }
  }


  // Crisp Script
  useEffect(() => {
    // Execute the provided script
    window.CRISP_WEBSITE_ID = "ecd0ea46-a6e9-4a91-92d2-8dfca80c4aeb";
    window.CRISP_TOKEN_ID = userInfo?.crispSignedToken ? userInfo?.crispSignedToken : userInfo ? userInfo?.email : ""

    // Using people_ID as token
    window.CRISP_RUNTIME_CONFIG = {
      session_merge: true
    };
    (function () {
      var d = document;
      var s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);

    })();


    if (userInfo?.email) {
      // set user email
      let userEmail = userInfo?.email;
      let firstName = userInfo?.firstName || "";
      let lastName = userInfo?.lastName || "";

      let fullName = `${firstName} ${lastName}`;
      const crispSignedToken = userInfo?.crispSignedToken;
      if (window.$crisp) {
        if (crispSignedToken) {
          window.$crisp.push(["set", "user:email", [userEmail, crispSignedToken]]);
          window.$crisp.push(["set", "user:nickname", fullName]);
        } else {
          window.$crisp.push(["set", "user:email", userEmail]);
          window.$crisp.push(["set", "user:nickname", fullName]);

        }

      }
    }
  }, [userInfo]);


  return (
    <Provider store={store}>
      {/* <IntercomSettings/> */}
      <ErrorBoundary>
        <AutoScrollToTop>
          <Suspense fallback={pageLoader}>
            <Switch>
              <Route
                path="/public"
                name="PublicPagesLayout"
                render={props =>
                  isPublicPageLoggedIn(props, false) && <PublicPagesLayout {...props} pageLoader={pageLoader} />
                }
              />

              {/* ------------  Admin Section Start ---------------*/}
              <Route
                exact
                path="/admin/login"
                name="AdminLogin"
                render={props =>
                  !isAdminLoggedIn(props) && <AdminLogin {...props} pageLoader={pageLoader} title="admin Login" />
                }
              />
              <Route
                path="/admin"
                name="Admin"
                render={props => isAdminLoggedIn(props, true) && <AdminLayout {...props} pageLoader={pageLoader} />}
              />
              {/* ------------  Admin Section End ---------------*/}

              {/* ------------  Authentication Section End ---------------*/}

              <Route
                exact
                path="/privacy"
                name="PrivacyPolicy"
                render={props => <PrivacyPolicy {...props} pageLoader={pageLoader} title="Privacy Policy" />}
              />
              <Route
                exact
                path="/terms-condition"
                name="TermsCondition"
                render={props => <TermsCondition {...props} pageLoader={pageLoader} title="Terms Condition" />}
              />

              {/* --------------  RegisterStudent Section Start ----------------*/}
              <Route
                path="/student/waiver-step-1"
                name="WaiverStep1"
                render={props => isStudentLoggedIn(props, true) && <WaiverStep1 {...props} pageLoader={pageLoader} />}
              />
              <Route
                path="/student/waiver-step-2"
                name="WaiverStep1"
                render={props => isStudentLoggedIn(props, true) && <WaiverStep2 {...props} pageLoader={pageLoader} />}
              />

              <Route
                path="/student"
                name="Home"
                render={props =>
                  isStudentLoggedIn(props, true) && <RegisterLayout {...props} pageLoader={pageLoader} />
                }
              />
              {/* ----------------------  RegisterStudent Section End --------------------------*/}

              {/* ----------------------  Public Section End --------------------------*/}
              <Route
                path="/"
                name="PublicLayout"
                render={props => !isStudentLoggedIn(props) && <PublicLayout {...props} pageLoader={pageLoader} />}
              />
            </Switch>
          </Suspense>
        </AutoScrollToTop>
      </ErrorBoundary>
    </Provider>
  )
}
export default withRouter(App)
