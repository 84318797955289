import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GoTriangleDown } from "react-icons/go";
import { getCustomerPhasesDashboard } from 'views/pages/DashboardNew/apiStore'
import PhaseHeader from 'views/pages/PhasesDashoard/PhasesHeader'
import './phaseSnapshot.scss'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import arrow from 'assets/images/phaseArrow.svg'
import { getTranslatedName } from 'config/functions';
export default function PhaseSnapShot() {
    const history = useHistory()
    const studentData = useSelector(state => state.register.userProfileData)
    const [dashboardData, setDashboardData] = useState([])
    const [loading, setLoading] = useState(true)

    const getDataForCustomerDashboard = async () => {
        setLoading(true)
        const response = await getCustomerPhasesDashboard();
        if (response.status) {
            setDashboardData(response.data)
        } else {
            setDashboardData([])
        }
        setLoading(false)
    }

    // Function to get data for customer dashboard
    useEffect(() => {
        getDataForCustomerDashboard()
    }, [])



    // Calculated variables
    const phases = dashboardData.map(item => {
        return {
            id: item.phaseId,
            title: item.title,
        }
    })
    return (
        <div className='phase-snap-container'>
            <div className="tiange">
                <GoTriangleDown />
            </div>

            <div className="phase-snap-content">
                <div className="phase-snap-header">
                    <div className="info">
                        <h3>{getTranslatedName("Heal with MoonRise")}</h3>
                        <p>{getTranslatedName("Here is a snapshot of your journey")}</p>

                    </div>
                </div>
                <div className="phase-snap-body">
                    <PhaseHeader phases={phases} activePhaseId={studentData?.activePhaseId} />

                    <div className="health-plan-card">
                        <div className="info">
                            <div className="img-holder">
                                <img src="https://images.ctfassets.net/jw8fay82wbzf/6hhkZFj6Zq3ksR8OSOqJvC/66fab9f9499ebeed0646b3a0de3e75a1/Hypopressive__44_of_99_-removebg-preview_1.svg" loading='lazy' alt="yoga pose" />
                            </div>
                            <div className="text">
                                <h3>{getTranslatedName("Here is your complete health plan with us")}</h3>
                            </div>

                        </div>
                        <div className='arrow' >
                            <img src={arrow} alt="pointer" />
                        </div>

                        <div className="btn-container">
                            <button onClick={() => history.push('/student/dashboard')}>{getTranslatedName("Go to my health plan")}</button>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
}
