import React from "react"
import customerSupport from "views/pages/admin/manageCustomer/CustomerSupport"
import ManageCustomersLibrary from "views/pages/admin/manageCustomerLibrary"
import ManageCustomers from "views/pages/admin/manageCustomer/list"
import AddLibrary from "views/pages/admin/manageLibraryCustomers/LibraryAddDetails"
import AdminLibraryItemDetails from "views/pages/admin/manageLibraryCustomers/ManageLibraryItemDetail"
import LiveClassPayment from "views/pages/LiveSchedule/LiveClassPayment"
import Search from "views/pages/Search/Search"

import SupportPage from "views/pages/Support/SupportPage"
import MySchedule from "views/pages/Vod/VideoOfDay"
import WatchHistory from "views/pages/watchHistory"
// import PhasesDashboard from "views/pages/PhasesDashoard/NewDashboard"
import CustomerDashboard from "views/pages/PhasesDashoard/NewDashboard/CustomerDashboard"


// /*============================== Start Student Routes==============================*/
const Home = React.lazy(props => import("views/pages/Home"))
const StudentLogin = React.lazy(props => import("views/pages/auth/StudentLogin"))
const ForgotPassword = React.lazy(props => import("views/pages/auth/ForgotPassword"))
const ResetPassword = React.lazy(props => import("views/pages/auth/ResetPassword"))
const Chatbot = React.lazy(props => import("views/pages/chatbot/Chatbot"))
const WelcomeScreen = React.lazy(props => import("views/pages/chatbot/WelcomeScreen"))
const WelcomeEmail = React.lazy(props => import("views/pages/chatbot/WelcomeEmail"))
const PaymentConfirmation = React.lazy(props => import("views/pages/payment/PaymentConfirmation"))
const Checkout = React.lazy(props => import("views/pages/payment/Checkout"))
const PasswordCreation = React.lazy(props => import("views/pages/chatbot/PasswordCreation"))
const VerifyMagicLink = React.lazy(props => import("views/pages/auth/MagicLink"))
const VerifyAuth = React.lazy(props => import("views/pages/auth/VerifyAuth"))
const VerifyEmail = React.lazy(props => import("views/pages/auth/VerifyEmail"))
const LibraryPayment = React.lazy(props => import("views/pages/process-payment/LibraryPayment"))
const CheckoutPayment = React.lazy(props => import("views/pages/process-payment/CheckoutPayment"))
const LiveMeetings = React.lazy(() => import("./views/pages/meetings"))

// const LibraryCoursePage = React.lazy((props) => import('views/pages/CoursePag'))
// const LibraryVideoPage = React.lazy(() => import('./views/pages/MoreSection/WatchLesson'))
// const WatchLibraryVideo = React.lazy(() => import('./views/pages/LibraryNew/WatchLibraryVideo'))

//  routes for sw check out
const PaymentConfirmationLang = React.lazy(props => import("views/pages/payment/PaymentConfirmationLang"))
const CheckoutSwedish = React.lazy(props => import("views/pages/payment/CheckoutSwedish"))
const TestPage = React.lazy(props => import("views/pages/TestPage"))
const SubmitFTPFeedback = React.lazy(props => import("views/pages/public/submitFTPFeedback"))

export const publicRoute = [

  {
    path: "/",
    display: true,
    exact: true,
    name: "StudentLogin",
    component: StudentLogin,
    className: ""
  },
  {
    path: "/watch-history",
    display: true,
    exact: true,
    name: "Watch History",
    component: WatchHistory,
    className: ""
  },
  {
    path: "/login",
    display: true,
    exact: true,
    name: "StudentLogin2",
    component: StudentLogin,
    className: ""
  },
  {
    path: "/community-support",
    display: true,
    exact: true,
    name: "Community & Support",
    component: SupportPage,
    allowAccess: true
  },
  {
    path: "/join-meeting/:meetingID?",
    exact: true,
    display: true,
    name: "TestPage",
    component: TestPage,
    className: ""
  },
  {
    path: "/forgot-password",
    display: true,
    exact: true,
    name: "ForgotPassword",
    component: ForgotPassword,
    className: ""
  },
  {
    path: "/reset-password/:token",
    display: true,
    exact: true,
    name: "ResetPassword",
    component: ResetPassword,
    className: ""
  },
  {
    path: "/onboarding/:userID?",
    display: true,
    exact: true,
    name: "Chatbot",
    component: Chatbot,
    className: ""
  },
  {
    path: "/get-started/:userID?",
    display: true,
    exact: true,
    name: "WelcomeScreen",
    component: WelcomeScreen,
    className: ""
  },
  {
    path: "/payment-confirmation/:token",
    display: true,
    exact: true,
    name: "PaymentConfirmation",
    component: PaymentConfirmation,
    className: "",
    showHeader: true
  },
  {
    path: "/checkout-payment",
    display: true,
    exact: true,
    name: "CheckoutPayment",
    component: CheckoutPayment,
    className: "",
    showHeader: true
  },
  {
    path: "/payment-confirmation/:token/:lang",
    display: true,
    exact: true,
    name: "PaymentConfirmation",
    component: PaymentConfirmationLang,
    className: "",
    showHeader: true
  },
  {
    path: "/verify-email/:userID?",
    display: true,
    exact: true,
    name: "WelcomeEmail",
    component: WelcomeEmail,
    className: ""
  },
  {
    path: "/checkout/:productUID/:lang?/:coupon?",
    display: true,
    exact: true,
    name: "Checkout",
    component: Checkout,
    className: ""
    // showHeader: true,
  },
  //  {
  //    path: "/checkout/:productUID/:lang",
  //    display: true,
  //    exact: true,
  //    name: "Checkout Swedish",
  //    component: CheckoutSwedish,
  //    className: "",
  //    showHeader: true
  //  },
  {
    path: "/checkout-confirm/:productUID/:lang?/:coupon?",
    display: true,
    exact: true,
    name: "Checkout",
    component: Checkout,
    className: "",
    showHeader: false
  },
  //  {
  //    path: "/checkout-confirm/:productUID/:lang",
  //    display: true,
  //    exact: true,
  //    name: "Checkout",
  //    component: CheckoutSwedish,
  //    className: "",
  //    showHeader: true
  //  },
  {
    path: "/create-password/:userID",
    display: true,
    exact: true,
    name: "PasswordCreation",
    component: PasswordCreation,
    className: ""
  },

  {
    path: "/magic-link/:magicLink",
    display: true,
    exact: true,
    name: "VerifyMagicLink",
    component: VerifyMagicLink,
    className: ""
  },
  {
    path: "/verify/:verifyToken",
    display: true,
    exact: true,
    name: "VerifyMagicLink",
    component: VerifyAuth,
    className: ""
  },
  {
    path: "/verifyEmail/:id/:code",
    display: true,
    exact: true,
    name: "VerifyEmailLink",
    component: VerifyEmail,
    className: ""
  },
  {
    path: "/live-schedule/:meetingID",
    display: true,
    exact: true,
    name: "LiveMeetings",
    component: LiveMeetings,
    allowAccess: true
  },
  {
    path: "/submit-feedback/:id",
    display: true,
    exact: true,
    name: "SubmitFTPFeedback",
    component: SubmitFTPFeedback,
    allowAccess: true
  },

]
// /*============================== End Student Routes ==============================*/

// /*============================== Start RegisterStudent Routes==============================*/
//Version 2.0
const BillingInfo = React.lazy(props => import("views/pages/billing/BillingLayout"))
const ScienceBasedHealing = React.lazy(props => import("views/pages/ScienceBasedHealing"))
const CatchUp = React.lazy(props => import("views/pages/CatchUp"))
const Welcome = React.lazy(props => import("views/pages/Welcome"))
const NewUserSymptoms = React.lazy(props => import("views/pages/NewUserSymptoms"))
const Symptomstracker = React.lazy(props => import("views/pages/SymptomTracker"))
const Symptomstracker1 = React.lazy(props => import("views/pages/Symptomstracker1"))
const Symptomstracker2 = React.lazy(props => import("views/pages/Symptomstracker2"))
const Symptomstracker3 = React.lazy(props => import("views/pages/Symptomstracker3"))
const Symptomstracker4 = React.lazy(props => import("views/pages/Symptomstracker4"))
const CurrentProgramm = React.lazy(props => import("views/pages/CurrentProgramm"))
// const Library = React.lazy((props) => import('views/pages/Library'))
const LibraryFavourite = React.lazy(props => import("views/pages/LibraryFavourite"))

const LibraryPast = React.lazy(props => import("views/pages/LibraryPast"))
const PdfPreview = React.lazy(props => import("views/pages/PdfPreview"))
const Finishedprogramm = React.lazy(props => import("views/pages/Finishedprogramm"))
const FinishedProgrammItem = React.lazy(props => import("views/pages/FinishedProgrammItem"))
const Coursedetailsvideo = React.lazy(props => import("views/pages/Coursedetailsvideo"))
const CustomerChat = React.lazy(props => import("views/pages/CustomerChat"))
const OnboardingChat = React.lazy(props => import("views/pages/OnboardingChat"))
const SymptomChart = React.lazy(props => import("views/pages/SymptomChart"))
const Profile = React.lazy(props => import("views/pages/Profile"))
const WaiverDisclaimer = React.lazy(props => import("views/pages/common-components/WaiverDisclaimer"))
const PushNotification = React.lazy(props => import("views/pages/PushNotification"))
const ChangePassword = React.lazy(props => import("views/pages/ChangePassword"))
const Support = React.lazy(props => import("views/pages/Support"))
const ContactUs = React.lazy(props => import("views/pages/ContactUs"))
const Dashboard = React.lazy(props => import("views/pages/DashboardNew/Dashboard"))
const CoursePage = React.lazy(props => import("views/pages/CoursePage"))
const LibraryNew = React.lazy(props => import("views/pages/LibraryNew"))
const AudioLesson = React.lazy(() => import("views/pages/LibraryNew/AudioLesson"))
const MoreVideos = React.lazy(() => import("views/pages/MoreSection/MoreVideos"))
const WatchVideo = React.lazy(() => import("./views/pages/MoreSection/WatchVideo"))
const WatchLesson = React.lazy(() => import("./views/pages/MoreSection/WatchLesson"))
const UserUpload = React.lazy(() => import("./views/pages/MoreSection/UserUpload"))
const LifestyleVideos = React.lazy(() => import("./views/pages/MoreSection/LifestyleVideos"))
const ourCourses = React.lazy(() => import("./views/pages/LibraryNew/CourseNew"))
const Playlist = React.lazy(() => import("./views/pages/CoursePage/Playlist/PlaylistVideo"))
const MoreActiveLibrary = React.lazy(() => import("./views/pages/LibraryNew/AllLibraryListing/MoreLibrary"))
const WatchLibraryAssignedVideo = React.lazy(() => import("./views/pages/LibraryNew/WatchLibraryVideo"))


const UpdatedFtp = React.lazy(() => import("./views/pages/LibraryNew/new-ftp-card/FtpDetails"))
const UpdatedWatchLesson = React.lazy(() => import("./views/pages/MoreSection/UpdatedWatchLesson"))
const LibraryCoursePage = React.lazy(props => import("views/pages/CoursePage"))

const FTPSubmit = React.lazy(() => import("./views/pages/student/phaseSection/FTPSubmit"))

export const registerStudent = [

  {
    path: "/student/",
    display: true,
    exact: true,
    name: "CurrentProgramm1",
    component: CurrentProgramm,
    className: ""
  },
  {
    path: "/student/my-schedule",
    display: true,
    exact: true,
    name: "MySchedule",
    component: MySchedule,
    className: ""
  },
  {
    path: "/student/watch-history",
    display: true,
    exact: true,
    name: "Watch History",
    component: WatchHistory,
    className: ""
  },
  {
    path: "/student/search",
    display: true,
    // exact: true,
    name: "Search",
    component: Search,
  },
  {
    path: "/student/test",
    display: true,
    exact: true,
    name: "AudioLesson",
    component: AudioLesson
  },

  {
    path: "/student/community-support",
    display: true,
    exact: true,
    name: "Community & Supportt",
    component: SupportPage,
  },
  {
    path: "/student/more-videos",
    display: true,
    exact: true,
    name: "MoreVideos",
    component: MoreVideos
  },
  {
    path: "/student/lifestyle",
    display: true,
    exact: true,
    name: " LifestyleVideos",
    component: LifestyleVideos
  },
  {
    path: "/student/watchVideo",
    display: true,
    exact: true,
    name: "WatchVideo",
    component: WatchVideo
  },
  {
    path: "/student/old-libary-lessons/:customerLessonID",
    display: true,
    exact: true,
    name: "WatchLesson",
    component: WatchLesson
  },
  {
    path: "/student/libary-lessons/:customerLessonID",
    display: true,
    exact: true,
    name: "UpdatedWatchLesson",
    component: UpdatedWatchLesson
  },
  {
    path: "/student/watch-video/:videoMapID",
    display: true,
    exact: true,
    name: "WatchSingleVideo",
    component: UpdatedWatchLesson
  },
  {
    path: "/student/libary-lessons/user-uploads/:customerLibraryMapID/:customerLessonID/:ftpCardID",
    display: true,
    exact: true,
    name: "UserUpload",
    component: UserUpload
  },
  {
    path: "/student/updated-libary-lessons/user-uploads/:customerLibraryMapID/:customerLessonID/:ftpCardID",
    display: true,
    exact: true,
    name: "UpdatedUserUpload",
    component: UpdatedFtp,
    // name: "UpdatedFtp",
    component: UpdatedFtp
  },
  {
    path: "/student/libary-video/:libraryMapID",
    display: true,
    exact: true,
    name: "CoursePage",
    component: CoursePage //WatchLibraryAssignedVideo
  },
  {
    path: "/student/watchVideo",
    display: true,
    exact: true,
    name: "WatchVideo",
    component: WatchVideo
  },
  {
    path: "/student/old-dashboard",
    display: true,
    exact: true,
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/student/dashboard",
    display: true,
    exact: true,
    name: "CustomerDashboard",
    component: CustomerDashboard
  },
  {
    path: "/student/updated-ftp",
    display: true,
    exact: true,
    name: "UpdatedFtp",
    component: UpdatedFtp
  },
  {
    path: "/student/goal/ftp-submit/:goalId",
    display: true,
    exact: true,
    name: "FTPSubmit",
    component: FTPSubmit,
    className: ""
  },
  {
    path: "/student/billing/:active?",
    display: true,
    exact: true,
    name: "Welcome",
    component: BillingInfo
  },
  {
    path: "/student/science-based-healing",
    display: true,
    exact: true,
    name: "science-based-healing",
    component: ScienceBasedHealing
  },
  {
    path: "/student/catch-up",
    display: true,
    exact: true,
    name: "catch-up",
    component: CatchUp
  },
  {
    path: "/student/welcome",
    display: true,
    exact: true,
    name: "Welcome",
    component: Welcome,
    className: ""
  },
  {
    path: "/student/newuser-symptoms",
    display: true,
    exact: false,
    name: "NewUserSymptom",
    component: NewUserSymptoms,
    className: ""
  },
  {
    path: "/student/library/:libraryMapID",
    display: true,
    exact: true,
    name: "Library",
    component: LibraryCoursePage,
    className: ""
  },
  {
    path: "/student/live-schedule/:meetingID",
    display: true,
    exact: true,
    name: "LiveMeetings",
    component: LiveMeetings,
    allowAccess: true
  },
  {
    path: "/student/symptoms-tracker/:packageItemId?",
    display: true,
    exact: false,
    name: "Symptomstracker1",
    component: Symptomstracker,
    className: ""
  },
  {
    path: "/student/symptoms-tracker-step1",
    display: true,
    exact: false,
    name: "SymptomsTrackerStep1",
    component: Symptomstracker1,
    className: ""
  },
  {
    path: "/student/symptoms-tracker-step2/:trackInfoId?",
    display: true,
    exact: false,
    name: "SymptomsTrackerStep2",
    component: Symptomstracker2,
    className: ""
  },
  {
    path: "/student/symptoms-tracker-step3/:trackInfoId?",
    display: true,
    exact: false,
    name: "SymptomsTrackerStep3",
    component: Symptomstracker3,
    className: ""
  },
  {
    path: "/student/symptoms-tracker-step4/:trackInfoId?",
    display: true,
    exact: false,
    name: "SymptomsTrackerStep4",
    component: Symptomstracker4,
    className: ""
  },
  {
    path: "/student/current-programme",
    display: true,
    exact: false,
    name: "CurrentProgramm",
    component: CurrentProgramm,
    className: ""
  },
  {
    path: "/student/customerlibrary/library/favourite",
    display: true,
    exact: false,
    name: "LibraryFavourite",
    component: LibraryFavourite,
    className: ""
  },
  {
    path: "/student/pdf-preview/:id",
    display: true,
    exact: false,
    name: "PdfPreview",
    component: PdfPreview,
    className: ""
  },
  {
    path: "/student/finished-programme",
    display: true,
    exact: false,
    name: "Finishedprogramm",
    component: Finishedprogramm,
    className: ""
  },
  {
    path: "/student/finished-programme-item",
    display: true,
    exact: false,
    name: "FinishedProgrammItem",
    component: FinishedProgrammItem,
    className: ""
  },
  {
    path: "/student/course-details-video",
    display: true,
    exact: false,
    name: "Coursedetailsvideo",
    component: Coursedetailsvideo,
    className: ""
  },
  {
    path: "/student/symptom-chart",
    display: true,
    exact: false,
    name: "SymptomChart",
    component: SymptomChart,
    className: ""
  },
  {
    path: "/student/profile",
    display: true,
    exact: false,
    name: "Profile",
    component: Profile,
    className: ""
  },
  {
    path: "/student/symptom-tracker-step1/:packageItemId?",
    display: true,
    exact: false,
    name: "SymptomsTrackerStep1",
    component: Symptomstracker1,
    className: ""
  },
  {
    path: "/student/package-item-detail/:packageItemId?",
    display: true,
    exact: false,
    name: "Coursedetailsvideo",
    component: Coursedetailsvideo,
    className: ""
  },
  {
    path: "/student/course-library",
    display: true,
    exact: false,
    name: "LibraryNew",
    component: LibraryNew,
    className: ""
  },
  {
    path: "/student/chat",
    display: true,
    exact: false,
    name: "CustomerChat",
    component: CustomerChat,
    className: ""
  },
  {
    path: "/student/onboarding-chat",
    display: true,
    exact: false,
    name: "OnboardingChat",
    component: OnboardingChat,
    className: ""
  },
  {
    path: "/student/customerlibrary/library/past",
    display: true,
    exact: false,
    name: "LibraryPast",
    component: LibraryPast,
    className: ""
  },
  {
    path: "/student/waiver-disclaimer",
    display: true,
    exact: true,
    name: "WaiverDisclaimer",
    component: WaiverDisclaimer,
    className: ""
  },
  {
    path: "/student/push-notification",
    display: true,
    exact: true,
    name: "PushNotification",
    component: PushNotification,
    className: ""
  },
  {
    path: "/student/change-password",
    display: true,
    exact: true,
    name: "ChangePassword",
    component: ChangePassword,
    className: ""
  },
  {
    path: "/student/support",
    display: true,
    exact: true,
    name: "Support",
    component: Support,
    className: ""
  },
  {
    path: "/student/contact-us",
    display: true,
    exact: true,
    name: "ContactUs",
    component: ContactUs,
    className: ""
  },
  {
    path: "/student/playlistVideo",
    exact: true,
    name: "Playlist",
    component: Playlist
  },
  {
    path: "/student/more-library",
    display: true,
    exact: true,
    name: "MoreActiveLibrary",
    component: MoreActiveLibrary, //MoreLibrary
    forPublic: true
  },
  {
    path: "/student/payment-success",
    display: true,
    exact: true,
    name: "PaymentSuccess",
    component: LibraryPayment,
    className: ""
  },
  {
    path: "/student/live-class/payment-success",
    display: true,
    exact: true,
    name: "Live class Payment",
    component: LiveClassPayment,
    className: ""
  }
  /***** Package routes *******/

  /***** Package routes *******/
]
// /*============================== End RegisterStudent Routes ==============================*/
// /*============================== Start Admin Routes==============================*/

const AdminDashboard = React.lazy(props => import("./views/pages/admin/AdminDashboard/Dashboard"))
const adminPackageCategory = React.lazy(props => import("./views/pages/admin/managePackageCategory/Category"))
const SavePackageCategory = React.lazy(props => import("./views/pages/admin/managePackageCategory/SaveCategory"))

const adminSymptoms = React.lazy(props => import("./views/pages/admin/manageSymptom/Symptoms"))
const SaveSymptom = React.lazy(props => import("./views/pages/admin/manageSymptom/SaveSymptom"))
const ManagePackage = React.lazy(props => import("./views/pages/admin/managePackage/ManagePackage"))
const AdminPackageDetails = React.lazy(props => import("./views/pages/admin/managePackage/PackageDetails"))
const PackageItems = React.lazy(props => import("./views/pages/admin/managePackage/PackageItems"))
const CreatorList = React.lazy(props => import("./views/pages/admin/manageCreator/list/Creator"))
const SaveCreator = React.lazy(props =>
  import("./views/pages/admin/manageCreator/creator-details/personal/AddNewCreator")
)
const CreatorStatictics = React.lazy(props => import("./views/pages/admin/manageCreator/creator-details/statictics"))
const CreatorLessonsStatictics = React.lazy(props =>
  import("./views/pages/admin/manageCreator/creator-details/statictics/lessonsStatistics")
)
const Customer = React.lazy(props => import("./views/pages/admin/manageCustomer/list"))
const PackageCustomer = React.lazy(props => import("./views/pages/admin/packageCustomer/PackageCustomer"))
const CustomerAnalysis = React.lazy(props => import("./views/pages/admin/packageCustomer/CustomerAnalysis"))
const AnalysisPackageItems = React.lazy(props => import("./views/pages/admin/packageCustomer/AnalysisPackageItems"))
const AdminSymptomChart = React.lazy(props => import("./views/pages/admin/packageCustomer/SymptomChart"))
const SaveCustomer = React.lazy(props => import("./views/pages/admin/manageCustomer/customer-detail/PersonalDetail"))
const changePassword = React.lazy(props => import("./views/pages/admin/ChangePassword"))
const ContactUsList = React.lazy(props => import("./views/pages/admin/manageContactUs/ContactUs"))
const RefreshSitemap = React.lazy(props => import("./views/pages/admin/RefreshSitemap"))
const AdminCustomerChat = React.lazy(props => import("./views/pages/admin/adminCustomerChat/adminCustomerChat"))
//const Transactions = React.lazy((props) => import('./views/pages/admin/manageTransactions/Transactions'));

const CustomerManualOnBoarding = React.lazy(props => import("./views/pages/admin/manageCustomer/OnBoarding"))
const CustomerEditManualOnBoarding = React.lazy(props =>
  import("./views/pages/admin/manageCustomer/EditManualOnBoarding")
)
const CustomerViewManualOnBoarding = React.lazy(props =>
  import("./views/pages/admin/manageCustomer/ViewManualOnBoarding")
)

const CourseStatistic = React.lazy(props => import("./views/pages/admin/manageCourseStatistics/CourseStatistics"))
const ModuleStatistic = React.lazy(props => import("./views/pages/admin/manageCourseStatistics/ModuleStatistics"))
const StudentCourseStatistic = React.lazy(props =>
  import("./views/pages/admin/manageCourseStatistics/Student_CourseStatistics")
)
const LibraryStudentCourseStatistic = React.lazy(props =>
  import("./views/pages/admin/manageLibraryStatistics/Student_CourseStatistics")
)
const StudentModuleStatistic = React.lazy(props =>
  import("./views/pages/admin/manageCourseStatistics/Student_MoudleStatistics")
)
const LibraryStudentModuleStatistic = React.lazy(props =>
  import("./views/pages/admin/manageLibraryStatistics/Student_MoudleStatistics")
)
const WatchedModuleDetail = React.lazy(props =>
  import("./views/pages/admin/manageCourseStatistics/WatchedModuleDetail")
)
const LibraryWatchedModuleDetail = React.lazy(props =>
  import("./views/pages/admin/manageLibraryStatistics/WatchedModuleDetail")
)
const PauseMemberShip = React.lazy(props => import("./views/pages/admin/PauseMembership"))

const ManageGroup_List = React.lazy(props => import("./views/pages/admin/manageGroup/list"))
const ManageGroup_Create = React.lazy(props => import("./views/pages/admin/manageGroup/create"))

const ManageCourse_List = React.lazy(props => import("./views/pages/admin/manageCourse/list"))
const ManageCourse_Create_Details = React.lazy(props => import("./views/pages/admin/manageCourse/create/courseDetails"))
const ManageCourse_Create_Modules = React.lazy(props => import("./views/pages/admin/manageCourse/create/courseModules"))
const ManageCourse_Create_Price = React.lazy(props => import("./views/pages/admin/manageCourse/create/coursePrice"))
const ManageCourse_Create_Resources = React.lazy(props =>
  import("./views/pages/admin/manageCourse/create/courseResources")
)
const ManageCourse_Create_Reviews = React.lazy(props => import("./views/pages/admin/manageCourse/create/courseReviews"))

const ManagePlaylist_List = React.lazy(props => import("./views/pages/admin/managePlaylist/list"))
const ManagePlaylist_Create_Details = React.lazy(props =>
  import("./views/pages/admin/managePlaylist/create/playlistDetails")
)
const ManagePlaylist_Create_Modules = React.lazy(props =>
  import("./views/pages/admin/managePlaylist/create/playlistvideos")
)
const ManagePlaylistPrice_Edit = React.lazy(props => import("./views/pages/admin/managePlaylist/create/playlistPrice"))

const ManageVideos_Create_Details = React.lazy(props => import("./views/pages/admin/manageVideos/create"))
const ManageVideos_Create_Price = React.lazy(props => import("./views/pages/admin/manageVideos/price"))
const ManageReviewCreate = React.lazy(props => import("./views/pages/admin/manageReviews/create"))
const ManageVideos_List = React.lazy(props => import("./views/pages/admin/manageVideos/list/index"))
const ManageReview_List = React.lazy(props => import("./views/pages/admin/manageReviews/list/index"))
const GroupLibraryList = React.lazy(props => import("./views/pages/admin/manageLibraryLinking/list"))
const LibraryRequest = React.lazy(props => import("./views/pages/admin/manageLibraryRequest/list/index"))
const NonUserRequest = React.lazy(props => import("./views/pages/admin/nonUserLibrary/list"))

const ManageProduct = React.lazy(props => import("./views/pages/admin/ManageProductAccess/list"))
const ManageAssignProduct = React.lazy(props => import("./views/pages/admin/ManageProductAccess/assign"))
const ManageProductPhases = React.lazy(props => import("./views/pages/admin/ManageProductAccess/list/planPhases/index"))
const ViewProductPhase = React.lazy(props => import("./views/pages/admin/ManageProductAccess/list/planPhases/view"))
const ManageViewProductDetails = React.lazy(props =>
  import("./views/pages/admin/ManageProductAccess/view/ViewProductDetails")
)
// const ManageAssignDetails = React.lazy((props) => import('./views/pages/admin/ManageProductAccess/assign/AssignItems'))
const LibraryCustomers = React.lazy(props => import("./views/pages/admin/manageLibraryCustomers/index"))

// Manage Customer
const CustomerPersonalDetail = React.lazy(props =>
  import("./views/pages/admin/manageCustomer/customer-detail/PersonalDetail")
)
const CustomerLibraryDetail = React.lazy(props =>
  import("./views/pages/admin/manageCustomer/customer-detail/CustomerLibrary")
)
const CustomerPurchasedLibrary = React.lazy(props =>
  import("./views/pages/admin/manageCustomer/customer-detail/customerpurchased-library")
)
const CustomerOnboarding = React.lazy(props =>
  import("./views/pages/admin/manageCustomer/customer-detail/customerOnboarding")
)
const CustomerLibraryRequest = React.lazy(props =>
  import("./views/pages/admin/manageCustomer/customer-detail/libraryRequest")
)
const CustomerStatictics = React.lazy(props =>
  import("./views/pages/admin/manageCustomer/customer-detail/customerStatictics")
)
const CustomerLibraryStatictics = React.lazy(props =>
  import("./views/pages/admin/manageCustomer/customer-detail/customerStatictics/libraryStatistics")
)
const CustomerLibraryLessonDetail = React.lazy(props =>
  import("./views/pages/admin/manageCustomer/customer-detail/CustomerLibrary/libraryLessons")
)
const MeetingDetails = React.lazy(props => import("./views/pages/admin/manageMeetings/Meetings"))
const CreateMeeting = React.lazy(props => import("./views/pages/admin/manageMeetings/CreateMeeting"))
const AddMeetingReplay = React.lazy(props => import("./views/pages/admin/manageMeetings/AddMeetingReplay"))
const AddPartcipant = React.lazy(props => import("./views/pages/admin/manageMeetings/AddPartcipant"))

const LibraryCoupons = React.lazy(props => import("./views/pages/admin/manageCoupons/library"))
const LiveClassCoupons = React.lazy(props => import("./views/pages/admin/manageCoupons/liveClass"))
const CustomerFTPReviewList = React.lazy(props => import("./views/pages/admin/manageFTPReview/list"))
const CustomerFTPReviewInfo = React.lazy(props => import("./views/pages/admin/manageFTPReview/view"))
const AdminNotifications = React.lazy(props => import("./views/pages/admin/manageNotifications/list"))

const CustomerPhaseLibrary = React.lazy(props => import("./views/pages/admin/manageCustomer/customer-detail/CustomerLibrary/phaseCustomerLibrary/library"))
const CustomerLibraryLessons = React.lazy(props => import("./views/pages/admin/manageCustomer/customer-detail/CustomerLibrary/phaseCustomerLibrary/library/lessons"))

// const TestPage = React.lazy(props =>
//   import("./views/pages/TestPage")
// )

export const adminRoutes = [
  // {
  //   path: "/admin/join-meeting",
  //   exact: false,
  //   component: TestPage,
  //   className: ""
  // },
  {
    path: "/admin/meeting",
    exact: true,
    name: "MeetingDetails",
    component: MeetingDetails,
    className: ""
  },
  {
    path: "/admin/meeting/add-replay/:meetingID",
    exact: true,
    name: "AddMeetingReplay",
    component: AddMeetingReplay,
    className: ""
  },
  {
    path: "/admin/add-partcipant",
    exact: true,
    name: "AddParticipant",
    component: AddPartcipant,
    className: ""
  },
  {
    path: "/admin/meeting/create",
    exact: true,
    name: "CreateMeeting",
    component: CreateMeeting,
    className: ""
  },
  {
    path: "/admin/edit-meeting/:meetingID",
    exact: true,
    name: "CreateMeeting",
    component: CreateMeeting,
    className: ""
  },
  {
    path: "/admin/dashboard",
    exact: true,
    name: "AdminDashboard",
    component: AdminDashboard,
    className: ""
  },
  {
    path: "/admin/",
    exact: true,
    name: "AdminDashboard",
    component: AdminDashboard,
    className: ""
  },
  {
    path: "/admin/manage-product",
    exact: true,
    name: "ManangeProduct",
    component: ManageProduct,
    className: ""
  },
  {
    path: "/admin/manage-product/phase/:planID",
    exact: true,
    name: "ManageProductPhases",
    component: ManageProductPhases,
    className: ""
  },
  {
    path: "/admin/manage-product/phase/view/:phaseId",
    exact: true,
    name: "ViewProductPhase",
    component: ViewProductPhase,
    className: ""
  },
  {
    path: "/admin/view/:planID",
    exact: true,
    name: "ManageViewProductDetails",
    component: ManageViewProductDetails,
    className: ""
  },
  {
    path: "/admin/product/assign-list/:planID",
    exact: true,
    name: "ManageAssignProduct",
    component: ManageAssignProduct,
    className: ""
  },
  // {
  //   path: '/admin/assign-list-new',
  //   exact: true,
  //   name: 'ManageAssignDetails',
  //   component: ManageAssignDetails,
  //   className: '',
  // },
  {
    path: "/admin/symptoms",
    exact: true,
    name: "adminSymptoms",
    component: adminSymptoms,
    className: ""
  },

  {
    path: "/admin/add-symptom",
    exact: true,
    name: "SaveSymptom",
    component: SaveSymptom,
    className: ""
  },
  {
    path: "/admin/edit-symptom/:symptomID",
    exact: true,
    name: "SaveSymptom2",
    component: SaveSymptom,
    className: ""
  },
  //{ path: "/admin/transactions", exact: true, name: "Dashboard", component: Transactions, className: "" },
  {
    path: "/admin/creator",
    exact: true,
    name: "Dashboard",
    component: CreatorList,
    className: ""
  },
  {
    path: "/admin/add/creator",
    exact: true,
    name: "Dashboard",
    component: SaveCreator,
    className: ""
  },
  {
    path: "/admin/edit-creator/personal/:creatorID",
    exact: true,
    name: "Dashboard",
    component: SaveCreator,
    className: ""
  },
  {
    path: "/admin/edit-creator/statistics/:creatorID",
    exact: true,
    name: "CreatorStatictics",
    component: CreatorStatictics,
    className: ""
  },
  {
    path: "/admin/edit-creator/lesson-statictics/:creatorID/:customerLibraryID",
    exact: true,
    name: "CreatorLessonsStatictics",
    component: CreatorLessonsStatictics,
    className: ""
  },
  {
    path: "/admin/customers",
    exact: true,
    name: "Customer",
    component: ManageCustomers,
    className: ""
  },
  {
    path: "/admin/add/customer",
    exact: true,
    name: "CustomerAdd",
    component: SaveCustomer,
    className: ""
  },
  {
    path: "/admin/edit-customer/:customerID",
    exact: true,
    name: "CustomerEdit",
    component: CustomerPersonalDetail,
    className: ""
  },
  {
    path: "/admin/edit-customer/personal/:customerID",
    exact: true,
    name: "CustomerEdit",
    component: CustomerPersonalDetail,
    className: ""
  },
  {
    path: "/admin/edit-customer/library/:customerID",
    exact: true,
    name: "CustomerEdit",
    component: CustomerLibraryDetail,
    className: ""
  },
  {
    path: "/admin/edit-customer/request/:customerID",
    exact: true,
    name: "CustomerLibraryRequest",
    component: CustomerLibraryRequest,
    className: ""
  },
  {
    path: "/admin/edit-customer/purchased/:customerID",
    exact: true,
    name: "CustomerLibraryRequest",
    component: CustomerPurchasedLibrary,
    className: ""
  },
  {
    path: "/admin/edit-customer/statistics/:customerID",
    exact: true,
    name: "CustomerStatictics",
    component: CustomerStatictics,
    className: ""
  },
  {
    path: "/admin/edit-customer/lesson-statictics/:libraryID/:customerID",
    exact: true,
    name: "CustomerLibraryStatictics",
    component: CustomerLibraryStatictics,
    className: ""
  },
  {
    path: "/admin/edit-customer/onboarding/:customerID",
    exact: true,
    name: "CustomerOnboarding",
    component: CustomerOnboarding,
    className: ""
  },
  {
    path: "/admin/edit-customer/library/library-lesson/:customerID/:libraryID",
    exact: true,
    name: "CustomerEdit",
    component: CustomerLibraryLessonDetail,
    className: ""
  },
  {
    path: "/admin/coupons/library",
    exact: true,
    name: "LibraryCoupons",
    component: LibraryCoupons,
    className: ""
  },
  {
    path: "/admin/coupons/live-class",
    exact: true,
    name: "LiveClassCoupons",
    component: LiveClassCoupons,
    className: ""
  },
  {
    path: "/admin/change/password",
    exact: true,
    name: "Dashboard",
    component: changePassword,
    className: ""
  },
  {
    path: "/admin/customer-library-review",
    exact: true,
    name: "CustomerFTPReview",
    component: CustomerFTPReviewList,
    className: ""
  },
  {
    path: "/admin/customer-library-review/:id",
    exact: true,
    name: "CustomerFTPReviewInfo",
    component: CustomerFTPReviewInfo,
    className: ""
  },
  {
    path: "/admin/notifications",
    exact: true,
    name: "AdminNotifications",
    component: AdminNotifications,
    className: ""
  },
  // {
  //   path: '/admin/contactUs',
  //   exact: true,
  //   name: 'Dashboard',
  //   component: ContactUsList,
  //   className: '',
  // },
  // {
  //   path: '/admin/refreshSitemap',
  //   exact: true,
  //   name: 'refreshSitemap',
  //   component: RefreshSitemap,
  // },

  {
    path: "/admin/on-boarding",
    exact: true,
    name: "Customer Manual On-Boarding",
    component: CustomerManualOnBoarding
  },

  {
    path: "/admin/on-boarding/:userID/view",
    exact: true,
    name: "Customer On-Boarding View",
    component: CustomerViewManualOnBoarding
  },
  {
    path: "/admin/on-boarding/:userID",
    exact: true,
    name: "Customer Manual On-Boarding",
    component: CustomerEditManualOnBoarding
  },

  /***** Package routes ***/
  {
    path: "/admin/packages",
    exact: true,
    name: "ManagePackage",
    component: ManagePackage,
    className: ""
  },
  {
    path: "/admin/package-categories",
    exact: true,
    name: "adminPackageCategory",
    component: adminPackageCategory,
    className: ""
  },
  {
    path: "/admin/add-package-category",
    exact: true,
    name: "SavePackageCategory",
    component: SavePackageCategory,
    className: ""
  },
  {
    path: "/admin/edit-package-category/:categoryID",
    exact: true,
    name: "SavePackageCategory2",
    component: SavePackageCategory,
    className: ""
  },
  {
    path: "/admin/package/details/:packageID?",
    exact: true,
    name: "AdminPackageDetails",
    component: AdminPackageDetails,
    className: ""
  },
  {
    path: "/admin/package/items/:packageID?",
    exact: true,
    name: "PackageItems",
    component: PackageItems,
    className: ""
  },
  {
    path: "/admin/package-customer",
    exact: true,
    name: "PackageCustomer",
    component: PackageCustomer,
    className: ""
  },
  {
    path: "/admin/customer-package/details/:customerPackageID?",
    exact: true,
    name: "AdminPackageDetails",
    component: AdminPackageDetails,
    className: ""
  },
  {
    path: "/admin/customer-package/items/:customerPackageID?",
    exact: true,
    name: "PackageItems",
    component: PackageItems,
    className: ""
  },
  {
    path: "/admin/copy-package/details/:copyPackageID?",
    exact: true,
    name: "AdminPackageDetails",
    component: AdminPackageDetails,
    className: ""
  },
  // {
  //   path: '/admin/customer-analysis/:searchData?',
  //   exact: true,
  //   name: 'CustomerAnalysis',
  //   component: CustomerAnalysis,
  //   className: '',
  // },
  // {
  //   path: '/admin/customer-analysis/items/:packageID/:customerID/:searchData?',
  //   exact: true,
  //   name: 'AnalysisPackageItems',
  //   component: AnalysisPackageItems,
  //   className: '',
  // },
  // {
  //   path: '/admin/customer-analysis/symptom-tracker/:customerID?',
  //   exact: true,
  //   name: 'AdminSymptomChart',
  //   component: AdminSymptomChart,
  //   className: '',
  // },
  {
    path: "/admin/customer-chat",
    exact: true,
    name: "AdminCustomerChat",
    component: AdminCustomerChat,
    className: ""
  },
  {
    path: "/admin/courseStatistics",
    exact: true,
    name: "courseStatistics",
    component: CourseStatistic,
    className: ""
  },
  {
    path: "/admin/library/courseStatistics",
    exact: true,
    name: "courseStatistics",
    component: CourseStatistic,
    className: ""
  },
  {
    path: "/admin/moduleStatistics/:packageID",
    exact: true,
    name: "moduleStatistics",
    component: ModuleStatistic,
    className: ""
  },
  {
    path: "/admin/customer-courseAnalytics",
    exact: true,
    name: "courseStatistics",
    component: StudentCourseStatistic,
    className: ""
  },
  {
    path: "/admin/library/customer-courseAnalytics",
    exact: true,
    name: "courseStatistics",
    component: LibraryStudentCourseStatistic,
    className: ""
  },
  {
    path: "/admin/customer-moduleAnalytics/:packageID/:customerID",
    exact: true,
    name: "moduleStatistics",
    component: StudentModuleStatistic,
    className: ""
  },
  {
    path: "/admin/library/customer-moduleAnalytics/:packageID/:customerID",
    exact: true,
    name: "moduleStatistics",
    component: LibraryStudentModuleStatistic,
    className: ""
  },
  {
    path: "/admin/customer-moduleWatchLog/:itemID/:customerID",
    exact: true,
    name: "moduleStatistics",
    component: WatchedModuleDetail,
    className: ""
  },
  {
    path: "/admin/library/customer-moduleWatchLog/:itemID/:customerID",
    exact: true,
    name: "moduleStatistics",
    component: LibraryWatchedModuleDetail,
    className: ""
  },
  /***** Package routes ***/

  {
    path: "/admin/pause-membership",
    exact: true,
    name: "Pause Membership",
    component: PauseMemberShip,
    className: ""
  },
  {
    path: "/admin/customer-support",
    exact: true,
    name: "Customer Support Messages",
    component: customerSupport
  },
  {
    path: "/admin/groups",
    exact: true,
    name: "ManageGroupsList",
    component: ManageGroup_List,
    className: ""
  },
  {
    path: "/admin/add-group",
    exact: true,
    name: "ManageGroups_Create",
    component: ManageGroup_Create,
    className: ""
  },
  {
    path: "/admin/edit-group/:groupId",
    exact: true,
    name: "ManageGroups_Edit",
    component: ManageGroup_Create,
    className: ""
  },
  {
    path: "/admin/courses",
    exact: true,
    name: "ManageCoursesList",
    component: ManageCourse_List,
    className: ""
  },
  {
    path: "/admin/library-customers",
    exact: true,
    name: "Manage Library Customers",
    component: LibraryCustomers,
    className: ""
  },
  {
    path: "/admin/library-request",
    exact: true,
    name: "Manage Library Request",
    component: LibraryRequest,
    className: ""
  },
  {
    path: "/admin/manage-customer-library",
    exact: true,
    name: "Manage Library Customers",
    component: ManageCustomersLibrary,
    className: ""
  },
  {
    path: "/admin/library/add",
    exact: true,
    name: "add Library",
    component: AddLibrary,
    className: ""
  },
  {
    path: "/admin/edit-assigned-library/playlist/details/:customerPlaylistID",
    exact: true,
    name: "Edit Assigned playlist",
    component: ManagePlaylist_Create_Details,
    className: ""
  },
  {
    path: "/admin/edit-assigned-library/details/:customerCourseID",
    exact: true,
    name: "Edit Assigned Course",
    component: ManageCourse_Create_Details,
    className: ""
  },
  {
    path: "/admin/edit-assigned-library/modules/:customerCourseID",
    exact: true,
    name: "Edit Assigned Course",
    component: ManageCourse_Create_Modules,
    className: ""
  },

  {
    path: "/admin/add-course",
    exact: true,
    name: "ManageCourse_Create",
    component: ManageCourse_Create_Details,
    className: ""
  },
  {
    path: "/admin/edit-course/details/:courseID/",
    exact: true,
    name: "ManageCourse_Edit",
    component: ManageCourse_Create_Details,
    className: ""
  },
  {
    path: "/admin/edit-course/modules/:courseID/",
    exact: true,
    name: "ManageCourse_Edit",
    component: ManageCourse_Create_Modules,
    className: ""
  },
  {
    path: "/admin/edit-course/price/:courseID/",
    exact: true,
    name: "ManageCourse_Edit",
    component: ManageCourse_Create_Price,
    className: ""
  },
  {
    path: "/admin/edit-course/details/:customerCourseID/",
    exact: true,
    name: "ManageCourse_Edit",
    component: ManageCourse_Create_Details,
    className: ""
  },
  {
    path: "/admin/edit-course/modules/:customerCourseID/",
    exact: true,
    name: "ManageCourse_Edit",
    component: ManageCourse_Create_Modules,
    className: ""
  },
  {
    path: "/admin/edit-course/resources/:courseID",
    exact: true,
    name: "ManageCourse_Edit",
    component: ManageCourse_Create_Resources,
    className: ""
  },
  {
    path: "/admin/edit-course/reviews/:courseID",
    exact: true,
    name: "ManageCourse_Edit",
    component: ManageCourse_Create_Reviews,
    className: ""
  },
  {
    path: "/admin/playlist",
    exact: true,
    name: "ManageCoursesList",
    component: ManagePlaylist_List,
    className: ""
  },
  {
    path: "/admin/add-playlist",
    exact: true,
    name: "ManagePlaylist_Create",
    component: ManagePlaylist_Create_Details,
    className: ""
  },
  {
    path: "/admin/edit-playlist/details/:playlistID",
    exact: true,
    name: "ManagePlaylist_Edit",
    component: ManagePlaylist_Create_Details,
    className: ""
  },
  {
    path: "/admin/edit-playlist/modules/:playlistID",
    exact: true,
    name: "ManagePlaylist_Edit",
    component: ManagePlaylist_Create_Modules,
    className: ""
  },
  {
    path: "/admin/edit-playlist/price/:playlistID",
    exact: true,
    name: "ManagePlaylistPrice_Edit",
    component: ManagePlaylistPrice_Edit,
    className: ""
  },
  {
    path: "/admin/edit-customer-playlist/modules/:customerPlaylistID",
    exact: true,
    name: "ManagePlaylist_Edit",
    component: ManagePlaylist_Create_Modules,
    className: ""
  },
  {
    path: "/admin/videos",
    exact: true,
    name: "ManageCoursesList",
    component: ManageVideos_List,
    className: ""
  },
  {
    path: "/admin/add-video",
    exact: true,
    name: "ManageCoursesCreate",
    component: ManageVideos_Create_Details,
    className: ""
  },
  {
    path: "/admin/edit-video/:videoID",
    exact: true,
    name: "ManageCoursesEdit",
    component: ManageVideos_Create_Details,
    className: ""
  },
  {
    path: "/admin/edit-video/price/:videoID",
    exact: true,
    name: "ManageCoursesEdit",
    component: ManageVideos_Create_Price,
    className: ""
  },
  {
    path: "/admin/edit-customer-video/:customerVideoID",
    exact: true,
    name: "ManageCoursesEdit",
    component: ManageVideos_Create_Details,
    className: ""
  },
  {
    path: "/admin/non-user-request",
    exact: true,
    name: "NonUserRequest",
    component: NonUserRequest,
    className: ""
  },
  {
    path: "/admin/library-plan",
    exact: true,
    name: "GroupLibraryList",
    component: GroupLibraryList,
    className: ""
  },
  {
    path: "/admin/reviews",
    exact: true,
    name: "ManageReviewList",
    component: ManageReview_List,
    className: ""
  },
  {
    path: "/admin/add-review",
    exact: true,
    name: "ManageReviewCreate",
    component: ManageReviewCreate,
    className: ""
  },
  {
    path: "/admin/edit-customer/phase/library/:customerID/:phaseID",
    exact: true,
    name: "CustomerPhaseLibrary",
    component: CustomerPhaseLibrary,
    className: ""
  },
  {
    path: "/admin/edit-customer/phase/library/lesson/:customerID/:phaseID/:libraryID/:libraryType",
    exact: true,
    name: "CustomerLibraryLessons",
    component: CustomerLibraryLessons,
    className: ""
  },
]

export const creatorRoutes = [
  {
    path: "/admin/dashboard",
    exact: true,
    name: "AdminDashboard",
    component: AdminDashboard,
    className: ""
  },
  {
    path: "/admin/customer-chat",
    exact: true,
    name: "AdminCustomerChat",
    component: AdminCustomerChat,
    className: ""
  },
  {
    path: "/admin/on-boarding",
    exact: true,
    name: "Customer Manual On-Boarding",
    component: CustomerManualOnBoarding
  },
  {
    path: "/admin/on-boarding/:userID/view",
    exact: true,
    name: "Customer On-Boarding View",
    component: CustomerViewManualOnBoarding
  },
  {
    path: "/admin/on-boarding/:userID",
    exact: true,
    name: "Customer Manual On-Boarding",
    component: CustomerEditManualOnBoarding
  },
  {
    path: "/admin/package-customer",
    exact: true,
    name: "PackageCustomer",
    component: PackageCustomer,
    className: ""
  },
  {
    path: "/admin/customer",
    exact: true,
    name: "Customer",
    component: Customer,
    className: ""
  },
  {
    path: "/admin/customer-analysis/:searchData?",
    exact: true,
    name: "CustomerAnalysis",
    component: CustomerAnalysis,
    className: ""
  },
  {
    path: "/admin/customer-support",
    exact: true,
    name: "Customer Support Messages",
    component: customerSupport
  },
  {
    path: "/admin/customer-courseAnalytics",
    exact: true,
    name: "courseStatistics",
    component: StudentCourseStatistic,
    className: ""
  },
  {
    path: "/admin/courseStatistics",
    exact: true,
    name: "courseStatistics",
    component: CourseStatistic,
    className: ""
  },
  {
    path: "/admin/pause-membership",
    exact: true,
    name: "Pause Membership",
    component: PauseMemberShip,
    className: ""
  },
  {
    path: "/admin/edit-customer/:customerID",
    exact: true,
    name: "CustomerEdit",
    component: SaveCustomer,
    className: ""
  },
  {
    path: "/admin/customer-package/details/:customerPackageID?",
    exact: true,
    name: "AdminPackageDetails",
    component: AdminPackageDetails,
    className: ""
  },
  {
    path: "/admin/customer-package/items/:customerPackageID?",
    exact: true,
    name: "PackageItems",
    component: PackageItems,
    className: ""
  },
  {
    path: "/admin/change/password",
    exact: true,
    name: "Dashboard",
    component: changePassword,
    className: ""
  }
]
// /*============================== End Admin Routes ==============================*/

// /*============================== Public Pages Routes Start ==============================*/

const LibraryVideoPage = React.lazy(() => import("./views/pages/MoreSection/WatchLesson"))
const MoreList = React.lazy(() => import("./views/pages/MoreList"))
const PublicLibrary = React.lazy(props => import("./views/pages/renderPublicLibrary"))
const WatchLibraryVideo = React.lazy(() => import("./views/pages/LibraryNew/WatchLibraryVideo"))
const NewPublilcLibrary = React.lazy(() => import("./views/pages/LibraryNew"))
// const MoreLibrary = React.lazy(() => import('./views/pages/MoreSection/MoreLibrary/MoreLibrary'))
const MoreLibrary = React.lazy(() => import("./views/pages/LibraryNew/AllLibraryListing/MoreLibrary"))
const publicLibraryDetail = React.lazy(() => import("./views/pages/CoursePage/PublicLibraryDetail"))
const PublicLibraryLesson = React.lazy(() => import("./views/pages/CoursePage/PublicLibraryDetail/publicLibraryLesson"))
// const PublicLibraryMoreVideos = React.lazy(() => import('views/pages/LibraryNew/PublicMoreVideos'))
const MoreLibaryVideos = React.lazy(() => import("./views/pages/LibraryNew/AllLibraryListing/MoreVideos"))
const WatchPublicLesson = React.lazy(() => import("./views/pages/MoreSection/WatchPublicLesson"))
const BuyCourse = React.lazy(() => import("./views/pages/CoursePage/BuyCourse/BuyCourse"))

const LiveClasses = React.lazy(() => import("./views/pages/LiveSchedule"))
const BuyLiveClasses = React.lazy(() => import("./views/pages/LiveSchedule/BuyLiveClass"))
const ClassDescription = React.lazy(() => import("./views/pages/LiveSchedule/ClassDescription/index.js"))
export const publicLibraryRoute = [
  // {
  //   path: '/public/library/watchsinglevideo',
  //   display: true,
  //   exact: false,
  //   name: 'LibraryCoursePage',
  //   component: WatchLibraryVideo,
  //   className: '',
  //   forPublic: true,
  // },
  {
    path: "/public/search",
    display: true,
    exact: true,
    name: "Search",
    component: Search,
    forPublic: true
  },
  {
    path: "/public/live-schedule",
    display: true,
    exact: true,
    name: "LiveClasses",
    component: LiveClasses, //List Schedule
    className: "",
    forPublic: true
  },
  {
    path: "/public/buy/live-class",
    display: true,
    exact: true,
    name: "Buy Live Classes",
    component: BuyLiveClasses, //List Schedule
    className: "",
    forPublic: true
  },
  {
    // path: "/public/live-schedule/description",
    path: "/public/live-schedule/description/:meetingID",
    display: true,
    exact: true,
    name: "ClassDescription",
    component: ClassDescription, //List Schedule
    className: "",
    forPublic: true
  },
  {
    path: "/public/library/:libraryType/:slug",
    display: true,
    exact: true,
    name: "LibraryCoursePage",
    component: LibraryCoursePage, //LibraryCoursePage
    className: "",
    forPublic: true
  },
  {
    path: "/public/video/:videoSlug",
    display: true,
    exact: true,
    name: "WatchLesson",
    component: CoursePage, // WatchLesson
    forPublic: true
  },
  {
    path: "/public/lesson/:lessonTemplateID",
    display: true,
    exact: true,
    name: "WatchPublicLesson",
    component: WatchPublicLesson, //WatchPublicLesson
    forPublic: true
  },
  {
    path: "/public/videos/:videoType/:slug",
    display: true,
    exact: true,
    name: "LibraryVideoPage",
    component: LibraryVideoPage, //LibraryVideoPage
    forPublic: true
  },
  {
    path: "/public/more-videos",
    display: true,
    exact: true,
    name: "MoreLibaryVideos",
    component: MoreLibaryVideos, //publicLibraryDetail
    forPublic: true
  },
  {
    path: "/public/more-coaches",
    display: true,
    exact: true,
    name: "MoreList",
    component: MoreList, //Coaches more section
    forPublic: true
  },
  {
    path: "/public/course-library",
    display: true,
    exact: true,
    name: "LibraryVideoPage",
    component: NewPublilcLibrary, //LibraryVideoPage
    forPublic: true
  },
  {
    path: "/public/watch-videos/:videoID",
    display: true,
    exact: true,
    name: "LibraryVideoPage",
    component: CoursePage, //WatchLibraryVideo, //LibraryVideoPage
    forPublic: true
  },
  {
    path: "/public/:libraryType",
    display: true,
    exact: true,
    name: "MoreLibrary",
    component: MoreLibrary, //MoreLibrary
    forPublic: true
  },
  {
    path: "/public/:libraryType/:libraryID",
    display: true,
    exact: true,
    name: "CoursePage",
    component: CoursePage, //publicLibraryDetail
    forPublic: true
  },
  {
    path: "/public/lesson/:libraryID/:lessonID",
    display: true,
    exact: true,
    name: "WatchLesson",
    component: WatchLesson, //PublicLibraryLesson
    forPublic: true
  },
  {
    path: "public/course/buy",
    display: true,
    exact: true,
    name: "BuyCourse",
    component: BuyCourse, //BuyCourse
    forPublic: true
  }
]
// /*============================== Public Pages Routes End ==============================*/
