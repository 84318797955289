import React from 'react'
import { Card } from 'react-bootstrap'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Badge, Button, CardBody } from 'reactstrap'

const SkeletomDashboardLoader = ({skeletonType, skeletonBtn, itemCount}) => {
    const repeatedItems = [];
    for (let i = 0; i < itemCount; i++) {
      repeatedItems.push(
        <div className={`item ${skeletonType.includes("liveClass") || skeletonType.includes("exploreCourse") ? "course-viewers col-xl-3" : "col-xl-4"} col-md-6 col-sm-6 col-12 px-lg-3 px-sm-1 px-0 pb-3 course-active`} key={i}>
          <Card className={`${skeletonType.includes("liveClass") || skeletonType.includes("exploreCourse") || skeletonType.includes("moreCourse") ? `m-0` : ''} course-footer dashboard-content border-0 card-active`}>
            <CardBody className="p-0">
                <Skeleton containerClassName="avatar-skeleton" highlightColor="#ffffff" className='card-course-bg mb-md-1 mb-2' style={{"bordeRadius":"10px 10px 0 0"}} />
                <div className="details-container  px-3 py-2">
                <div className="media flex-wrap align-items-center ">
                    <div className="media-body title-container">
                    <div className=" my-auto ">
                        <p className={`text-footer-contact  text-main-light text-truncate`}><Skeleton containerClassName="avatar-skeleton" highlightColor="#ffffff" width={200} /></p>
                    </div>
                    {skeletonType.includes("myCourse") ? "" : (<span className={`username text-white- text-main-light user-sm text-center  me-50`}><Skeleton containerClassName="avatar-skeleton" highlightColor="#ffffff" width={150} /></span>)}
                    </div>
                </div>
                {
                    skeletonType.includes("moreCourse") ? "" : (
                        <>
                        <div className={`course-name  text-capitalize ${skeletonType.includes("liveClass") ? `` : 'mt-xl-3 mt-2'}`}>
                            {skeletonType.includes("myCourse") ? "" : (<p className="text-duration text-uppercase font-semiBold "><Skeleton containerClassName="avatar-skeleton" highlightColor="#ffffff" width="60" /> </p>)}
                            <p className={`next-lesson-name  text-truncate font-semiBold text-main-light`}><Skeleton containerClassName="avatar-skeleton" highlightColor="#ffffff" width="100" /></p>
                        </div>
                        {
                        skeletonBtn == "btn" ? "" :(<Skeleton containerClassName="avatar-skeleton" highlightColor="#ffffff" className={`btn-new w-100  mt-xl-3 mt-2 `} style={{"borderRadius" : " 30px"}}/>)
                        }
                        {
                        skeletonBtn == "btn" ? (<p className={`text-footer-contact text-main-light text-truncate text-end mt-2`}><Skeleton containerClassName="avatar-skeleton" highlightColor="#ffffff" width={120} /></p>) :""
                        }
                        </>
                    )
                }
                </div>
            </CardBody>
          </Card>
        </div>
      );
    }
    return (
        <div className={`${skeletonType == "dashboard" ? "container-courses" : ''} ${skeletonType.includes("myCourse") ? `${skeletonType.includes(`moreCourse`) ? `mt-0` : `pt-md-56 pt-32 mt-2`} ` : ''} ${skeletonType.includes("liveClass") && "mb-5"} row`}>
            {repeatedItems}
        </div>
        
    )
}

export default SkeletomDashboardLoader