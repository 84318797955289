import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { publicRoute } from 'routes';
import NoMatchPage from 'views/pages/NoMatchPage';
import Header from "./header/Header";
import Footer from "../registerStudentLayout/footer/FooterNew";
import Session from 'react-session-api'
import CheckoutHeader from 'views/pages/common-components/CheckouFlowHeader';
import uuid from 'react-uuid';


export default function PublicLayout(props) {
let currentPage = window.location.pathname;
	Session.set('sessionID', uuid())
	let planID = localStorage.getItem('planID')
	return (
		<React.Fragment>
			{/* <CheckoutHeader/> */}
			<div className={`${currentPage.includes("checkout/hypopressives-course") || currentPage.includes("checkout/pelvic-dysfunction/")  ? "hypos-checkout" : 'page-inner'}`} >
				{/*<Redirect to="/login" />*/}
				{<Suspense fallback={props.pageLoader()}>
					<Switch>
						{
							publicRoute.map((route, idx) => {
								return (typeof route.component !== "undefined" ? (
									<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (<React.Fragment>
										{route.showHeader === true && !currentPage.includes("/checkout/hypopressives-course") && <CheckoutHeader/>}
										<route.component {...props} />
									</React.Fragment>)} />
								) : (null))
							})
						}
						<Route component={NoMatchPage} />
					</Switch>
				</Suspense>}
			</div>
			{(currentPage.includes("/checkout/hypopressives-course")|| currentPage.includes("checkout/pelvic-dysfunction/")) ? ""  :<Footer {...props} />}
		</React.Fragment>
	)
}