import axios from "axios";

const authRequest = axios.create({
    baseURL: process.env.REACT_APP_API_APP_MOONRISE_BASE_URL,
    timeout: 60000,
});

class API {
    async registerUserInAppMoonrise(userData, password) {
        const response = await authRequest({
            url: `/api/register`,
            method: "post",
            data: { email: userData.email, firstname: userData.firstName, lastName: userData.lastName, password: password, planType: 2, stripeSubscriptionId: "" },
        })
        return response.data
    };

    async updateUserInAppMoonrise(email, password) {
        const response = await authRequest({
            url: `/api/updateUser`,
            method: "post",
            data: { email: email, password: password },
        })
        return response.data
    };

    async logInAppMoonrise(email, password) {
        const response = await authRequest({
            url: `/api/login`,
            method: "post",
            data: { email: email, password: password },
        })
        return response.data
    };

}

export default API;
