export const getUserProfile = () => ({
  type: 'USER_PROFILE',
})

export const getGroupwiseCourses = () => ({
  type: 'GROUPWISE_COURSES_LOGGEDIN',
})

export const getUserJournal = () => ({
  type: 'GET_USER_JOURNAL',
})

export const saveCourse = (payload) => ({
  type: 'SAVE_MY_COURSE',
  payload,
})

export const getCategorywiseCourses = (payload) => ({
  type: 'CATEGORYWISE_COURSES',
  categoryID: payload,
})

export const getSymptomwiseCourses = (payload) => ({
  type: 'SYMPTOM_WISE_COURSES',
  symptomID: payload,
})

export const getCourseDetail = (payload) => ({
  type: 'COURSE_DETAIL',
  courseID: payload,
})

export const addAction = (payload) => ({
  type: 'ADD_ACTION',
  courseID: payload,
})

export const getAllCreators = () => ({
  type: 'GET_ALL_CREATORS',
})

export const userProfile = () => ({
  type: 'USER_PROFILE_DATA',
})

export const saveWatchHistory = (payload) => ({
  type: 'SAVE_WATCH_HISTORY',
  watchInfo: payload,
})

export const subscriptionPlan = (payload) => ({
  type: 'SUBSCRIPTION_PLAN',
  planID: payload,
})

export const addModuleAction = (payload) => ({
  type: 'ADDMODULE_ACTION',
  payload,
})
export const updateContentPauseStatus = (payload) => ({
  type: 'CONTENT_P_STATUS',
  payload,
})
