import React from 'react'
import Iconflower from '../Iconflower.png'
import { CiHeart } from "react-icons/ci";
import { FaChevronCircleRight } from 'react-icons/fa';
import './fav.scss'
import { useHistory } from 'react-router-dom/';
import { checkSwedish, getTranslatedName } from 'config/functions';
export default function FavInfo() {
    const history = useHistory()

    const handleHistoryBtnClick = () => {
        history.push('/student/watch-history')
    }

    const handleFavBtnClick = () => {
        history.push('/student/customerlibrary/library/favourite')
    }

    return (
        <>
            <div className='fav-container'>
                <div className='fav-box'>

                    <div className="flower-icon">
                        <img src={Iconflower} alt="flower icon" />
                    </div>

                    <div className="info text-center">
                        {getTranslatedName("All your favourite videos, lessons and more are arranged here")}
                    </div>

                    <div className="btn-container">
                        <div className="abs-btn" role='button' >
                            <button onClick={handleFavBtnClick}>
                                <CiHeart /> {checkSwedish() ? 'Favoriter' : ("Go to your Favourites")}
                            </button>
                        </div>
                    </div>
                </div>
                <p role='button' onClick={handleHistoryBtnClick} className='abs-a'>
                    {getTranslatedName('Your past lessons')} <FaChevronCircleRight />
                </p>
            </div>
        </>
    )
}
