/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getCookie, isEmpty, encodeId, decodeId } from "../../config/functions"
import {
  getFeaturedCourses,
  getTestimonial,
  getPlanList,
  getCategoriesSymptoms,
  symptomTrackerInfo,
  getAllCreators,
  addMoodScoresAction
} from "../publicLayout/action"
import HeaderMeta from "../common/HeaderMeta"
import PageLoader from "../common/Loader"
import MultiRangeSliderApp from "../common/MultiRangeSlider/MultiRangeSliderApp"
import { RANGE_SLIDER_MIN, RANGE_SLIDER_MAX } from "../../config/constants"
import axios from "axios"
import FlashMessages from "views/common/FlashMessages"
import moment from "moment"
import { Form, Modal } from "react-bootstrap"
import { useFormik } from "formik"
import * as Yup from "yup"

export default function Symptomstracker1(props) {
  const { swedish } = props
  const params = useParams()
  const dispatch = useDispatch()
  const [dataLoaded, setDataLoaded] = useState("")
  const [btnDisable, setbtnDisable] = useState(true)
  const [flashMessage, setFlashMessage] = useState("")
  const [messageType, setMessageType] = useState("error")
  const [overAllMoodVal, setOverAllMoodVal] = useState(0)
  const userProfileData = useSelector(state => state.register.userProfileData)

  const featuredCourses = useSelector(state => state.non_register.feturedCourses)
  const testimonials = useSelector(state => state.non_register.testmonials)
  const creators = useSelector(state => state.non_register.getAllCreators)
  const planList = useSelector(state => state.non_register.planList)
  const categoriesSymptoms = useSelector(state => state.non_register.categoriesSymptoms)
  const featuredCoursesData = featuredCourses.data
  const featuredCoursesLoading = featuredCourses.loading
  const testimonialData = testimonials.data
  const testimonialLoading = testimonials.loading
  const creatorsData = creators.data
  const creatorsLoading = creators.loading
  const categoriesSymptomsData = categoriesSymptoms.data
  const categoriesSymptomsLoading = categoriesSymptoms.loading
  const symptomTrackerInfoData = useSelector(state => state.non_register.symptomtrackerinfo)
  const symptomTrackData = symptomTrackerInfoData.data
  const symptomTrackLoading = symptomTrackerInfoData.loading
  const pathname = props.pathname
  const [needToUpdateTracker, setNeedToUpdateTracker] = useState(false)
  const [showTrackerMessageBox, setShowTrackerMessageBox] = useState(false)
  const [notify, setNotify] = useState({
    type: "",
    message: ""
  })
  const symptomAndScores = useSelector(state => state.register.symptomAndScores)

  const [countryID, setCountryID] = useState("")

  // const [labels, setLabels] = useState([])

  const sw_labels = [
    "Extremt dåligt",
    "Betydligt värre",
    "Mycket värre",
    "Något värre",
    "Lite värre",
    "Ingen förändring",
    "Lite bättre",
    "Något bättre",
    "Mycket bättre",
    "Betydligt bättre",
    "Extremt bra"
  ]

  const labels = [
    "Extremely bad",
    "Significantly worse",
    "A lot worse",
    "Somewhat worse",
    "A little worse",
    "No change",
    "A little better",
    "Somewhat better",
    "A lot better",
    "Significantly better",
    "Extremely good"
  ]

  useEffect(() => {
    if (userProfileData) {
      setCountryID(userProfileData.countryID)
    }
  }, [userProfileData, swedish])

  useEffect(() => {
    /* if (pathname !== pathname.toLowerCase()) {
            props.history.replace(pathname.toLocaleLowerCase())
        } */
  }, [pathname])

  useEffect(() => {
    setDataLoaded(false)
    dispatch({
      type: "HANDLE_HEADER",
      payload: {
        activeNav: "",
        showJoinBtn: true,
        isSubscriptionPage: false,
        isLinkActive: false
      }
    })
    isEmpty(featuredCoursesData) && dispatch(getFeaturedCourses())
    isEmpty(testimonialData) && dispatch(getTestimonial())
    isEmpty(planList) && dispatch(getPlanList())
    isEmpty(creatorsData) && dispatch(getAllCreators())
    isEmpty(categoriesSymptomsData) && dispatch(getCategoriesSymptoms())
    isEmpty(symptomTrackData) && dispatch(symptomTrackerInfo())
  }, [])

  useEffect(() => {
    if (
      !featuredCoursesLoading &&
      !testimonialLoading &&
      !creatorsLoading &&
      !categoriesSymptomsLoading &&
      !symptomTrackLoading
    ) {
      setDataLoaded(true)
    }
  }, [featuredCoursesLoading, testimonialLoading, creatorsLoading, categoriesSymptomsLoading, symptomTrackLoading])

  const handleRangeDataCallback = rangeData => {
    setOverAllMoodVal(rangeData)
    setFlashMessage("")
    setbtnDisable(false)
  }
  let authToken = getCookie("token")

  const handleSubmit = async event => {
    let packItemId = props.packageItemId ? decodeId(props.packageItemId) : "0"
    if (overAllMoodVal) {
      console.log(overAllMoodVal, "moood value")
      let formData = new FormData()
      formData.append("packageItemID", packItemId)
      formData.append("question1Score", overAllMoodVal)
      let symptomTrackId = symptomTrackData ? symptomTrackData.customerSymptomTrackerInfoID : "0"
      formData.append("symptomTrackId", symptomTrackId)
      let tracktype = props.packageItemId ? "2" : "1"
      formData.append("trackerType", tracktype)
      /// store data in redux
      dispatch(addMoodScoresAction({ mood: `${overAllMoodVal} (${labels[overAllMoodVal]})` }))
      await axios
        .post("api/addSymptonmTrackStep1", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "access-control-allow-origin": "*",
            authorization: authToken
          }
        })
        .then(result => {
          if (result.data.status) {
            console.log(result.data)
            let newTrackId = encodeId(result.data.trackID)
            if (props.packageItemId) {
              props.handleNewTrackId(newTrackId)
              props.handleTracker(3)
              // props.history.push(
              //   "/student/symptoms-tracker-step3/" + newTrackId
              // );
            } else {
              props.handleNewTrackId(newTrackId)
              props.handleTracker(2)
              // props.history.push(
              //   "/student/symptoms-tracker-step2/" + newTrackId
              // );
            }
          } else {
            setFlashMessage(result.data.message)
            setbtnDisable(false)
          }
        })
        .catch(error => {
          console.log(error)
          setFlashMessage(error.message)
          setbtnDisable(false)
        })
    }
  }

  const handleFlashUnmount = () => {
    setFlashMessage("")
  }

  const formik = useFormik({
    initialValues: {
      message: ""
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .min(10, "Minimum 10 characters are required!")
        .required("Message is required!")
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleMessageSubmit(values)
    }
  })

  const handleMessageSubmit = values => {
    let token = getCookie("token")
    let message = values.message
    // console.log(message)
    axios
      .post("api/sendTrackerUpdateMailToAdmin", {
        message,
        headers: {
          "content-type": "application/json",
          "access-control-allow-origin": "*",
          authorization: token
        }
      })
      .then(result => {
        if (result.data.status === false) {
          setNotify({ type: "danger", message: result.data.message })
        } else {
          setNotify({ type: "success", message: result.data.message })
          formik.resetForm()
          let userProfile = userProfileData
          userProfile.isTrackerMessageSubmitted = 1
          dispatch({ type: "GET_USER_PROFILE_DATA", payload: userProfile })
          setShowTrackerMessageBox(false)
        }
        setTimeout(() => {
          setNotify({
            type: "",
            message: ""
          })
        }, 3000)
      })
  }

  return (
    <Modal
      show={props.showTracker == 1}
      backdrop="static"
      keyboard={false}
      size="xl"
      fullscreen="true"
      dialogclassname="Add-symptommodal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Modal.Header className="border-0" closeButton onHide={() => props.history.goBack()}></Modal.Header>
        <div className="container-lg symptoms-tracker-questions symptoms-tracker-css1">
          <div className="Content-view symptoms-tracker-modal">
            <div className="question-step active">
              <div className="text-center step-number invisible1 invisible-mobile1">
                <span className="badge bg-light">1/4</span>
              </div>
              <div className="d-flex align-items-center justify-content-center step-bar">
                <div className="step-btn active"></div>
                <div className="step-btn "></div>
                <div className="step-btn "></div>
                <div className="step-btn "></div>
              </div>
              <h2 className="h2 text-center">
                {swedish ? "Hur är ditt allmänna humör och välmående?" : "How is your overall mood & wellbeing?"}
              </h2>
              {/*<div className="row text-center justify-content-md-center extremelyBad">
                <div className="col-mobile-css col-6 col-sm-3">
                  <span className="badge bg-danger">{RANGE_SLIDER_MIN}</span>{" "}
                  Extremely bad
                </div>
                <div className="col-mobile-css col-6 col-sm-3">
                  Extremely good{" "}
                  <span className="badge bg-success">{RANGE_SLIDER_MAX}</span>
                </div>
              </div>*/}
              <>
                {flashMessage ? (
                  <div className="row text-center justify-content-md-center mt-3">
                    <FlashMessages type={messageType} message={flashMessage} unmountMe={handleFlashUnmount} />
                  </div>
                ) : (
                  ""
                )}
              </>
              <div className="symptoms-tracker-body">
                <div className="card mt-4 shadow-smnoo no-border">
                  <div className="card-body mt-md-0 mt-4 p-0">
                    <div className="MultiRangeSliderApp">
                      <div className="RangeSliderbg">
                        {(swedish && (
                          <div className="row no-gutters">
                            <div className="col-3 colorbg darkred dataTextCSS" dataTextCSS="Extremt dåligt"></div>

                            <div className="col-3 colorbg lightred dataTextCSS" dataTextCSS="Okej"></div>

                            <div className="col-3 colorbg lightblue dataTextCSS" dataTextCSS="Bra"></div>

                            <div className="col-3 colorbg darkgreen dataTextCSS" dataTextCSS="Extremt Bra"></div>
                          </div>
                        )) || (
                          <div className="row no-gutters">
                            <div className="col-3 colorbg darkred dataTextCSS" dataTextCSS="Extremely bad"></div>

                            <div className="col-3 colorbg lightred dataTextCSS" dataTextCSS="Okay"></div>

                            <div className="col-3 colorbg lightblue dataTextCSS" dataTextCSS="Good"></div>

                            <div className="col-3 colorbg darkgreen dataTextCSS" dataTextCSS="Extremely good"></div>
                          </div>
                        )}
                      </div>
                      {/* <div className='d-flex w-100'> */}

                      <MultiRangeSliderApp
                        rangeData={handleRangeDataCallback}
                        symptomID=""
                        labels={swedish ? sw_labels : labels}

                        // labels={swedish || countryID == '199' ? sw_labels : labels}
                      />

                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center symptoms-tracker-bottom">
                <button
                  className=" btn-lg btn-whiteTheme btn-stepcss"
                  disabled={btnDisable ? true : false}
                  onClick={handleSubmit}
                >
                  {swedish ? "Nästa fråga" : "Next Question"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
