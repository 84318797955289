import React from "react"
import { Button, Col, Row } from "reactstrap"
import defaultImg from "../../../../assets/images/placeholder_image.png"
import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import GradientSVG from "../Gradient"
import completed from "../../../../assets/images/Completed.svg"
import { getTranslatedName, isEmpty, isValid } from "config/functions"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import base64 from "base-64"

const UpdatedLibraryCards = ({ libraryData, isLibraryGroup, algolibraryData, dashboardLockedAlgoData }) => {


  const idCSS = "progress"
  const history = useHistory()
 

  const handleLibraryRoutes = library => {

    if (library.libraryType == 3 || library.lType == "video") {
      history.push(
        `/student/libary-video/${base64.encode(library.libraryMapID)}`
      )
    } else {
      history.push(`/student/library/${base64.encode(library.libraryMapID)}`)
    }

  }
  const handleLibraryPush = library => {
    if (library.lockStatus === 1 || library.isCompleted == 1) {
      history.push(`/student/library/${base64.encode(library.libraryMapID)}`)
    } else {
      history.push(
        `/student/libary-lessons/${base64.encode(library.libraryLessonID ? library.libraryLessonID : library.lessID)}`
      )
    }
  }
  const handleLibraryDetails = (libraryMapID, libraryID, libraryType) => {

    let url = ""
    if (libraryType == "course") {
      url = `/public/course/${base64.encode(libraryID)}`
    } else if (libraryType == "playlist") {

      url = `/public/playlist/${base64.encode(libraryID)}`

    } else if (libraryType == "video") {

      url = `/public/watch-videos/${base64.encode(libraryID)}`

    }
    history.push(url)

  }
  const handleLibraryPushFtp = library => {
    // return
    if (library.lockStatus === 1) {
      history.push(`/student/library/${base64.encode(library.libraryMapID)}`)
    } else {
      history.push(
        `/student/libary-lessons/${base64.encode(library.libraryLessonID ? library.libraryLessonID : library.lessID)}`
      )
    }
  }
  return (
    <>
      {libraryData && (
        <Row>
          {libraryData.length > 0
            ? libraryData.map((item, index) => {
              const libraryType = item.libraryType ? item.libraryType : 1
              const totalWatchedTime = !isValid(item.totalWatchTime) ? item.totalWatchTime : 0
              const totalDuration = !isValid(item.totalDuration) ? item.totalDuration : 0
              let totalProgress =
                !isValid(item.totalWatchTime) && totalDuration != 0 ? (totalWatchedTime * 100) / totalDuration : 0

              console.log("totalProgress", totalProgress)

              if (!totalProgress) {
                totalProgress = 0
              } else if (totalProgress > 100) {
                totalProgress = 100
              } else if (totalProgress < 0) {
                totalProgress = 0
              } else {
                totalProgress = totalProgress
              }


              const isFtp = item.FtpCardID != null ? true : false
              return (
                <React.Fragment>
                  <Col lg={4} sm={6} className={`py-3`} index={`l_${index}`}>
                    {isLibraryGroup ? (
                      <h5 className="mb-3 text-white text-truncate cstm-item-title">
                        {item.libraryTitle ? item.libraryTitle : ""}
                      </h5>
                    ) : (
                      ""
                    )}

                    <div
                      className={`nw-library-item position-relative nw-ftp-outer ${isLibraryGroup ? "nw-library-ds-item mb-3 mb-sm-0" : ""
                        }`}
                    >
                      <div className="nw-library-thumbnail">
                        <div className="library-thumbnail-img d-flex align-items-center">
                          <img
                            src={item.lessonThumbnailUrl ? item.lessonThumbnailUrl : defaultImg}
                            className="w-100 h-100"
                            alt="thumbnail"
                          />
                        </div>
                        <div className="nw-library-dropdown">
                          <div className="btn-group dropleft w-100 h-100  d-flex align-items-center justify-content-center pointer">
                            <span
                              className="material-icons icon-vertical"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              more_vert
                            </span>
                            <div
                              className="dropdown-menu dropdown-option border-0 dropdown-menu-item-css px-1"
                              onClick={() => handleLibraryRoutes(item)}
                            >
                              <span className="dropdown-item">
                                {item.libraryType === 1
                                  ? getTranslatedName("Course description")
                                  : item.libraryType === 2
                                    ? getTranslatedName("Playlist description")
                                    : item.libraryType == 3
                                      ? getTranslatedName("Video description")
                                      : getTranslatedName("Library Description")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nw-library-content p-3">
                        <div className="d-flex justify-content-between">
                          <div className="nw-lb-content">
                            <p className="text-duration text-uppercase font-semiBold ">
                              Lesson {item.libraryLessonDay ? item.libraryLessonDay : 0} of{" "}
                              {item.totalLessons ? item.totalLessons : 0}
                            </p>
                            <p
                              className={`text-footer-contact ${libraryType == 2 ? "text-cstm-orange" : "text-main-light"
                                }`}
                            >
                              {item.lessonName ? item.lessonName : ""}
                            </p>
                          </div>

                          <div className="progress-container-program">
                            <span className="progress-container-program">
                              {item.isCompleted == 1 ? (
                                <img src={completed} alt="complete" />
                              ) : (
                                <>
                                  <CircularProgressbarWithChildren
                                    value={Math.round(totalProgress)}
                                    circleRatio={1}
                                    strokeWidth={6}
                                    styles={{
                                      root: {
                                        transform: "rotate(0deg)"
                                      },
                                      trail: {},
                                      path: {
                                        stroke: `url(#${idCSS})`
                                      }
                                    }}
                                  >
                                    <div className="">
                                      <span className="text-copyright text-cstm-purple">
                                        {Math.round(totalProgress)}%
                                      </span>
                                    </div>
                                    <GradientSVG />
                                  </CircularProgressbarWithChildren>
                                  <span className="gradient-svg"></span>
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                        <Button
                          className={`btn-course ${libraryType == 2 ? "btn-playlist" : ""} btn-new w-100 mt-3 mb-2`}
                          onClick={() => {
                            handleLibraryPush(item)
                          }}
                        >
                          {item.isCompleted == 1
                            ? "Restart the "
                            : item.totalWatchTime != null
                              ? "Continue this "
                              : "Start the "}
                          {libraryType == 1
                            ? "Course"
                            : libraryType == 2
                              ? "Playlist"
                              : libraryType == 3
                                ? "Video"
                                : ""}
                        </Button>
                      </div>
                    </div>
                  </Col>
                  {/* {isFtp && (
                      <Col lg={4} sm={6} className={`py-3 mb-3`} index={`ftp_${index}`}>
                        {isLibraryGroup ? (
                          <h5 className="mb-3 text-white text-truncate invisible">
                            {item.libraryTitle ? item.libraryTitle : ""}
                          </h5>
                        ) : (
                          ""
                        )}
                        <div
                          className={`nw-lb-ftp bg-white px-3 pt-3 pb-5 box-radius d-flex align-items-center ${
                            isLibraryGroup ? "nw-library-ds-item" : "h-100"
                          }`}
                        >
                          <h3 className={`text-violet cstm-item-main-title`}>
                            {getTranslatedName("Submit a video to get your technique approved")}
                          </h3>
                          <Button
                            className={`btn-course btn-playlist btn-new`}
                            // onClick={history.push(
                            //   `/student/libary-lessons/user-uploads/${base64.encode(item.customerLibraryMapID)}/${base64.encode(item.libraryLessonID)}/${base64.encode(item.FtpCardID)}`
                            // )}
                            onClick={() => handleLibraryPushFtp(item)}
                          >
                            {getTranslatedName(`Submit video`)}
                          </Button>
                        </div>
                      </Col>
                    )} */}
                </React.Fragment>
              )
            })
            : null}
        </Row>
      )}
      {algolibraryData != "" ? (
        <Row>
          {algolibraryData?.length > 0 ? (
            algolibraryData?.map((item, index) => {
              const teacherFirstName = item.tFN ? item.tFN : ""
              const teacherLastName = item.tLN ? item.tLN : ""
              const teacherFullName = `${teacherFirstName} ${teacherLastName} `
              return (
                <Col lg={4} sm={6} className={`py-3`} key={`crs_${index}`}>
                  <h5 className="mb-3 text-white text-truncate text-start cstm-item-title">
                    {item.dtitle ? item.dtitle : ""}
                  </h5>
                  <div className="nw-library-item position-relative nw-ftp-outer nw-library-ds-item">
                    <div className="nw-library-thumbnail">
                      <div className="library-thumbnail-img d-flex align-items-center">
                        <img src={item.tLUrl ? item.tLUrl : defaultImg} className="w-100 h-100" alt="thumbnail" />
                      </div>
                      <div className="nw-library-dropdown">
                        <div className="btn-group dropleft w-100 h-100  d-flex align-items-center justify-content-center pointer">
                          <span
                            className="material-icons icon-vertical"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            more_vert
                          </span>
                          <div
                            className="dropdown-menu dropdown-option border-0 dropdown-menu-item-css px-1"
                            onClick={() => handleLibraryDetails(item.lessID, item.libId, item.lType)}
                          >
                            <span className="dropdown-item">
                              {item.lType == "course"
                                ? getTranslatedName("Course description")
                                : item.lType == "playlist"
                                  ? getTranslatedName("Playlist description")
                                  : item.lType == "video"
                                    ? getTranslatedName("Video description")
                                    : getTranslatedName("Library Description")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="nw-library-content p-3">
                      <div className="d-flex justify-content-between">
                        <div>
                          {/* <p className="text-duration text-uppercase font-semiBold ">Lesson {item.libraryLessonDay ? item.libraryLessonDay : 0} of {item.totalLessons ? item.totalLessons : 0}</p> */}
                          <p
                            className={`text-footer-contact ${item.lType == "playlist" ? "text-cstm-orange" : "text-main-light"
                              }`}
                          >
                            {item.lName ? item.lName : ""}
                          </p>
                        </div>
                      </div>
                      <div className="video-size h-100 d-flex flex-column mt-2">
                        <span className="video-caption  author mt-1">{teacherFullName}</span>
                      </div>
                      <Button
                        className={`btn-course ${item.lType == "course" ? "btn-playlist" : ""} btn-new w-100 mt-3 mb-2`}
                        onClick={() => {
                          handleLibraryDetails(item.lessID, item.libId, item.lType)
                        }}
                      >
                        {/* Start the{" "} */}
                        Go to&nbsp;
                        {item.lType == "course"
                          ? "Course"
                          : item.lType == "playlist"
                            ? "Playlist"
                            : item.lType == "video"
                              ? "Video"
                              : ""}
                      </Button>
                    </div>
                  </div>
                </Col>
              )
            })
          ) : (
            <Row className="align-items-center">
              {algolibraryData?.length > 0 ? (
                <Col lg={12}>
                  <h2 className="text-white text-wrap mb-lg-4 mb-2 cstm-lesson-library-title">
                    {`No results found. Try again with another word`}
                  </h2>
                </Col>
              ) : (
                ""
              )}
            </Row>
          )}
        </Row >
      ) : (
        <Row className="align-items-center">
          <Col lg={12}>
            <h2 className="text-white text-wrap mb-lg-4 mb-2 cstm-lesson-library-title">
              {`No results found. Try again with another word`}
            </h2>
          </Col>
        </Row>
      )}
    </>
  )
}

export default UpdatedLibraryCards
