/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Tab, Nav } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import slugify from "react-slugify"
import { getCookie, isEmpty, removeCookie, encodeId, decodeId } from "../../config/functions"
import {
  getFeaturedCourses,
  getTestimonial,
  getPlanList,
  getCategoriesSymptoms,
  checkSymptomTrackId,
  addConfidenceScoresAction
} from "../publicLayout/action"
import { getAllCreators } from "../publicLayout/action"
import HeaderMeta from "../common/HeaderMeta"
import PageLoader from "../common/Loader"
import MultiRangeSliderApp from "../common/MultiRangeSlider/MultiRangeSliderApp"
import { RANGE_SLIDER_MIN, RANGE_SLIDER_MAX } from "../../config/constants"
import FlashMessages from "views/common/FlashMessages"
import axios from "axios"
import { encode, decode } from "base-64"
import Modal from "react-bootstrap/Modal"
import { useHistory } from "react-router-dom"

export default function Symptomstracker4(props) {
  const { swedish } = props
  const dispatch = useDispatch()
  const params = useParams()
  let trackId = props.newTrackId ? params.trackInfoId : "0"
  const [trackerInfoId, setTrackerInfoId] = useState(trackId)
  const [flashMessage, setFlashMessage] = useState("")
  const [messageType, setMessageType] = useState("error")
  const [dataLoaded, setDataLoaded] = useState("")
  const [btnDisable, setbtnDisable] = useState(true)
  const [confidenceLevel, setConfidenceLevel] = useState(0)
  const userProfileData = useSelector(state => state.register.userProfileData)
  const featuredCourses = useSelector(state => state.non_register.feturedCourses)
  const testimonials = useSelector(state => state.non_register.testmonials)
  const creators = useSelector(state => state.non_register.getAllCreators)
  const planList = useSelector(state => state.non_register.planList)
  const categoriesSymptoms = useSelector(state => state.non_register.categoriesSymptoms)
  const activeUser = useSelector(state => state.register.userProfileData.isActiveSubscrion)
  const featuredCoursesData = featuredCourses.data
  const featuredCoursesLoading = featuredCourses.loading
  const testimonialData = testimonials.data
  const testimonialLoading = testimonials.loading
  const creatorsData = creators.data
  const creatorsLoading = creators.loading
  const categoriesSymptomsData = categoriesSymptoms.data
  const categoriesSymptomsLoading = categoriesSymptoms.loading
  const [activePlaneTab, setActivePlaneTab] = useState("quarterly")
  const pathname = props.pathname
  const history = useHistory()

  const logout = () => {
    removeCookie("token")
    removeCookie("isUserPlanActive")
    dispatch({ type: "GET_PLAN_LIST", payload: [] })
    dispatch({ type: "GET_USER_PROFILE_DATA", payload: [] })
    props.history.push("/login")
  }

  const symptomTrackData = useSelector(state => state.non_register.symptomtrackdata)
  const trackData = symptomTrackData.data
  const trackLoading = symptomTrackData.loading
  let labels = [];
  if (swedish) {
    labels = [
      "Extremt tveksam",
      "Väldigt tveksam",
      "Ganska tveksam",
      "Någorlunda tveksam",
      " Lite tveksam",
      "Varken säker eller tveksam",
      "Lite säker",
      "Någorlunda säker",
      "Ganska säker ",
      "Väldigt säker ",
      "Extremt säker"
    ]
  }
  else {
    labels = [
      "Extremely Doubtful",
      "Very Doubtful",
      "Quite Doubtful",
      "Somewhat Doubtful",
      "Slightly Doubtful",
      "Neither confident nor doubtful",
      "Slightly Confident",
      "Somewhat confident",
      "Quite Confident",
      "Very Confident ",
      "Extremely Confident"
    ]

  }


  useEffect(() => {
    /* if (pathname !== pathname.toLowerCase()) {
            props.history.replace(pathname.toLocaleLowerCase())
        } */
  }, [pathname])

  let authToken = getCookie("token")

  useEffect(() => {
    setDataLoaded(false)
    dispatch({
      type: "HANDLE_HEADER",
      payload: {
        activeNav: "",
        showJoinBtn: true,
        isSubscriptionPage: false,
        isLinkActive: false
      }
    })
    isEmpty(featuredCoursesData) && dispatch(getFeaturedCourses())
    isEmpty(testimonialData) && dispatch(getTestimonial())
    isEmpty(planList) && dispatch(getPlanList())
    isEmpty(creatorsData) && dispatch(getAllCreators())
    isEmpty(categoriesSymptomsData) && dispatch(getCategoriesSymptoms())
    isEmpty(trackData) && dispatch(checkSymptomTrackId(trackerInfoId))
  }, [])

  useEffect(() => {
    if (
      !featuredCoursesLoading &&
      !testimonialLoading &&
      !creatorsLoading &&
      !categoriesSymptomsLoading &&
      !trackLoading
    ) {
      setDataLoaded(true)
      // if(!trackData){
      // 	logout();
      // }
    }
  }, [
    featuredCoursesLoading,
    testimonialLoading,
    creatorsLoading,
    categoriesSymptomsLoading,
    trackLoading,
    confidenceLevel
  ])

  const handleRangeDataCallback = rangeData => {
    setConfidenceLevel(rangeData)
    setbtnDisable(false)
  }
  const handleSubmit = async e => {
    if (confidenceLevel || confidenceLevel === 0) {
      let question3Score = confidenceLevel
      let formData = new FormData()
      formData.append("customerSymptomTrackerInfoID", props.newTrackId)
      formData.append("question3Score", question3Score)
      dispatch(addConfidenceScoresAction({ confidence: `${question3Score} (${labels[question3Score]})` }))
      await axios
        .post("api/addSymptonmTrackStep4", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "access-control-allow-origin": "*",
            authorization: authToken
          }
        })
        .then(result => {
          if (result.data.status) {
            props.handleTracker(0)
            props.sendmailfunction(question3Score)
            history.push("/student/finished-programme")
          } else {
            setFlashMessage(result.data.message)
          }
        })
        .catch(error => {
          setFlashMessage(error.message)
        })
    }
  }
  const handleFlashUnmount = () => {
    setFlashMessage("")
  }
  return (
    <Modal
      show={props.showTracker === 4}
      backdrop="static"
      keyboard={false}
      size="xl"
      fullscreen="true"
      dialogclassname="Add-symptommodal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="border-0" closeButton onHide={() => props.history.goBack()}></Modal.Header>
      <Modal.Body>
        <React.Fragment>

          <div className="container-lg symptoms-tracker-questions">
            <div className="Content-view symptoms-tracker-modal">
              <div className="question-step active">
                <div className="text-center step-number ">
                  <span className="badge bg-light">4/4</span>
                </div>
                <div className="d-flex align-items-center justify-content-center step-bar">
                  <div className="step-btn active"></div>
                  <div className="step-btn active"></div>
                  <div className="step-btn active"></div>
                  <div className="step-btn active-fill"></div>
                </div>
                <h2 className="h2 text-center">
                  {swedish
                    ? "Hur säker är du på att du kan bli symtomfri i framtiden?"
                    : "How confident do you feel you will be symptom-free in the future?"}
                </h2>
                {/*<div className="row text-center  justify-content-md-center extremelyBad">
                  <div className="col-mobile-css col-6 col-sm-3">
                    <span className="badge bg-danger">{RANGE_SLIDER_MIN}</span>{' '}
                    Not confident at all
                  </div>
                  <div className="col-mobile-css col-6 col-sm-3">
                    Very confident{' '}
                    <span className="badge bg-success">{RANGE_SLIDER_MAX}</span>
                  </div>
                </div>*/}
                <div className="row text-center justify-content-md-center mt-3">
                  {flashMessage ? (
                    <FlashMessages type={messageType} message={flashMessage} unmountMe={handleFlashUnmount} />
                  ) : (
                    ""
                  )}
                </div>
                <div className="symptoms-tracker-body">
                  <div className="card mt-4 shadow-smno tracker4 no-border">
                    <div className="card-body tracker4">
                      <div className="MultiRangeSliderApp ">
                        <div className="RangeSliderbg">
                          <div className="row no-gutters">
                            <div
                              className="col-3 colorbg darkred dataTextCSS"
                              dataTextCSS={swedish ? "Inte säker" : "Not Confident"}
                            ></div>

                            <div
                              className="col-3 colorbg lightred dataTextCSS"
                              dataTextCSS={swedish ? "Lite säker" : "Slightly Confident"}
                            ></div>

                            <div
                              className="col-3 colorbg lightblue dataTextCSS"
                              dataTextCSS={swedish ? "Ganska säker" : "Quite Confident"}
                            ></div>

                            <div
                              className="col-3 colorbg darkgreen dataTextCSS"
                              dataTextCSS={swedish ? "Väldigt säker" : "Very Confident"}
                            ></div>
                          </div>
                        </div>
                        <MultiRangeSliderApp
                          rangeData={handleRangeDataCallback}
                          symptomID=""
                          score={trackData ? trackData.question3Score : 0}
                          labels={labels}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center symptoms-tracker-bottom">
                  <button
                    className=" btn-lg btn-whiteTheme btn-stepcss"
                    disabled={btnDisable ? true : false}
                    onClick={handleSubmit}
                  >
                    {swedish ? "Skicka in" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal>
  )
}
