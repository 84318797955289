export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:3000'
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3500'
export const SERVER_NAME = process.env.REACT_APP_SERVERNAME || 'DEV'
export const DYTE_ORGANIZATION_ID = process.env.REACT_APP_DYTE_ORGANIZATION_ID || '5e9f58b4-a9b3-4334-ad43-4adfcaa1e9bb'
export const DYTE_API_KEY = process.env.REACT_APP_DYTE_API_KEY || 'fc1281e0c155cb73ee84'
export const DYTE_ACCESS_TOKEN=process.env.REACT_APP_DYTE_ACCESS_TOKEN || 'fc1281e0c155cb73ee84'
export const timeZone = process.env.REACT_APP_TIME_ZONE || 'UTC'
export const PUBLIC_URL = process.env.PUBLIC_URL || ''
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || ''
export const WRITE_WITH_US_EMAIL = 'support@moonrise.health'
export const RANGE_SLIDER_MIN = 0
export const RANGE_SLIDER_MAX = 10
export const SYMPTOM_TRACK_AFTER_DAY = 20
export const DYTE_ORGID = DYTE_ORGANIZATION_ID
export const DYTEAPIKEY = DYTE_API_KEY

// export const SYMPTOM_TRACK_AFTER_DAY = 6;

export const PAYPAL_CLIENTID = process.env.REACT_APP_PAYPAL_CLIENTID || 'AXkXl0sXoJkPVSXFA6GptTRxj0Z_oPvrTIQKXHkRsWiJCAwuP33eblJYN4I5-X1BXemWLLYGGMsNuZf2'

export const REACT_APP_MIX_PANEL_KEY = process.env.REACT_APP_MIX_PANEL_KEY || ''



export const dashboadOptions = (plan) => {
    const arr = [{ title: 'PFD', id: 1 }, { title: 'DR', id: 2 }, { title: 'HE', id: 3 }, { title: 'HS', id: 4 }]

    const planIDs = plan.map(item => item.planID)
    const newArr = arr.filter(item => planIDs.includes(item.id))
    if (newArr.length > 0) {
        let tempArr = newArr.map(item => item.title)
        return `${tempArr.join(' & ')}`
    } else {
        return 'N/A'
    }
}
