import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import HeaderMeta from "views/common/HeaderMeta"
import { useSelector } from "react-redux"
import { getCustomerPhasesDashboard } from "../../DashboardNew/apiStore"


import PhaseHeader from "../PhasesHeader"
import PhaseContainer from "../PhasesContainer"
import Loader from "views/common/Loader"
import GreetCustomer from "components/GreetCustomer"


const PhasesDashboard = () => {
    const studentData = useSelector(state => state.register.userProfileData)
    const [dashboardData, setDashboardData] = useState([])
    const [loading, setLoading] = useState(true)


    const getDataForCustomerDashboard = async () => {
        setLoading(true)
        const response = await getCustomerPhasesDashboard();
        if (response.status) {
            setDashboardData(response.data)
        } else {
            setDashboardData([])
        }
        setLoading(false)
    }


    // Function to get data for customer dashboard
    useEffect(() => {
        getDataForCustomerDashboard()
    }, [])



    // Calculated variables
    const phases = dashboardData.map(item => {
        return {
            id: item.phaseId,
            title: item.title,
            subtitle: item.subtitle
        }
    })

    const MyHealthPlanHeading = () => {
        const styles = {
            fontSize: '2rem',
            fontWeight: 600,
            fontFamily: '"Caveat", cursive',
            // textAlign: 'center',
            // paddingTop: '1rem',
            color: '#E56A6A',
            margin: "10px"
        };

        return (
            <h3 style={styles}>My health plan:</h3>
        );
    };

    return (
        <React.Fragment>
            {
                loading ? <Loader /> : null
            }
            {!loading && <div className="dashboard cstm-dashboard">
                <Container fluid>
                    <HeaderMeta title="New Dashboard" />
                </Container>
                <PhaseHeader phases={phases} activePhaseId={studentData?.activePhaseId} />
                <hr className="d-md-none bg-white mb-2" />
                <div className="phases-dashboard-container" style={{ overflowX: 'hidden' }}>
                    <div className="phases-content-div mx-2 mt-3">
                        <PhaseContainer dashboardData={dashboardData} />
                    </div>
                </div >
            </div>}
        </React.Fragment>
    )
}

export default PhasesDashboard
