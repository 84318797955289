import React, { Component, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { getCookie, isEmpty } from "config/functions"
import { httpGetRequest, httpPostRequest } from "config/services"
import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css"
import DataTable from "react-data-table-component"
import moment from "moment"
import { CSVLink, CSVDownload } from "react-csv"
import customerSvg from "admin-assets/images/menu-icon/customer.png"
import { date } from "yup"
import base64 from "base-64"
import ConfirmationPopup from "views/pages/common-components/ConfirmationPopup"
import DeletePopUp from "./DeletePopUp"
import DatePopup from "./DatePopup"
import FlashMessages from "views/common/FlashMessages"
import { data } from "jquery"
import { BsFillCaretDownFill, BsEye } from "react-icons/bs"
// import { updateCustomerAccessLengthDate } from "../manageCustomer/apiStore";
import Toast from "views/pages/common-components/Toast"
import { Slide, toast } from "react-toastify"
import { AiOutlinePlusCircle, AiOutlineDelete } from "react-icons/ai"
import { Alert, Badge, Button } from "reactstrap"
import { getCustomerCSVData, getCustomerListing, getSymptomsCSVData, handleDeleteCustomer } from "../apiStore"
import ReactCountryFlag from "react-country-flag"
import adminDefault from "../../../../../admin-assets/images/default-icon.png"
import PageLoader from "views/pages/common-components/PageLoader"
import Loader from "views/common/Loader"
const Customer = () => {
  // State Vars
  const [customerList, setCustomerList] = useState({ data: [], total: 0 })
  const [searchTerm, setSearchTerm] = useState("")
  const [sort, setSort] = useState("DESC")
  const [sortColumn, setSortColumn] = useState("createdOn")
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [flashMessages, setFlashMessage] = useState("")
  const [customerInfo, setCustomerInfo] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDateModal, setShowDateModal] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)

  // routes vars
  const history = useHistory()

  // Function to get data on mount
  useEffect(() => {
    let bodyData = {
      order: sort,
      orderBy: sortColumn,
      page: currentPage,
      perPageItem: rowsPerPage,
      keyword: searchTerm
    }
    getCustomerList(bodyData)
  }, [rowsPerPage, currentPage, searchTerm])

  // function to handlesorting
  const handleSort = async (_sort, _sortdirection) => {
    setSortColumn(_sort.sortField)
    setSort(_sortdirection)
    let bodyData = {
      order: _sortdirection,
      orderBy: _sort.sortField,
      page: currentPage,
      perPageItem: rowsPerPage,
      keyword: searchTerm
    }
    await getCustomerList(bodyData)
  }

  // function to handle rows per page changes
  const handleRowsPerPageChange = val => {
    setRowsPerPage(val)
  }

  // function to handle pagination
  const handlePagination = val => {
    setCurrentPage(val)
  }

  // function to handle searching
  const filterSearchWise = val => {
    setSearchTerm(val)
  }

  // Function to get customer list
  const getCustomerList = async data => {
    if (customerList.data.length <= 0) {
      setLoading(true)
    }
    const apiRes = await getCustomerListing(data)
    if (apiRes.status === false) {
      setFlashMessage(apiRes.message)
    } else {
      setCustomerList(apiRes.data)
    }
    setLoading(false)
  }

  // function to handle cusotmer edit click
  const handleCustomerEdit = row => {
    history.push(`/admin/edit-customer/personal/${base64.encode(row.customerID)}`)
  }

  // function to toggle customer delted modal
  const handleCustomerDelete = data => {
    const { show, row } = data
    setShowDeleteModal(show)
    setCustomerInfo(row)
  }

  // function to toggle  date modal
  const openCustomerDate = () => {
    setShowDateModal(true)
  }

  // functiont to handle cusotmer delted
  const handleDelete = async () => {
    let bodyData = { customerID: customerInfo.customerID }
    const apiRes = await handleDeleteCustomer(bodyData)
    if (apiRes.status === true) {
      handleCustomerDelete({ show: false, row: {} })
      const status = "success"
      const message = "Customer deleted successfully"
      toast.success(<Toast message={message} status={status} />, {
        icon: true,
        theme: "dark",
        transition: Slide,
        hideProgressBar: true,
        autoClose: 5000,
        position: toast.POSITION.BOTTOM_CENTER
      })
      let bodyData = {
        order: sort,
        orderBy: sortColumn,
        page: currentPage,
        perPageItem: rowsPerPage,
        keyword: searchTerm
      }
      getCustomerList(bodyData)
    } else {
      const status = "error"
      const message = apiRes.message
      toast.error(<Toast message={message} status={status} />, {
        icon: true,
        theme: "dark",
        transition: Slide,
        hideProgressBar: true,
        autoClose: 5000,
        position: toast.POSITION.BOTTOM_CENTER
      })
    }
  }

  // Table Columns
  const columns = [
    {
      name: "Customer",
      selector: "name",
      keyName: "c.firstName",
      sortable: true,
      left: true,
      sortField: "firstName",
      minWidth: "310px",
      allowOverflow: false,
      cell: row => (
        <span className="d-flex align-items-center  email-container">
          <div>
            <img
              className="profile-img mr-10"
              src={isEmpty(row.imageURL) ? adminDefault : row.imageURL}
              alt={row.name}
            />
          </div>
          <div className="text-truncate">
            <p className="dt_provider-name">{row.name}</p>
            <span className="dt_provider-email text-nowrap- text-truncate" title={row.email}>
              {" (" + row.email + ") "}
            </span>
          </div>
        </span>
      )
    },
    {
      name: "Product Name",
      selector: "planName",
      keyName: "planName",
      // width:'270px',
      sortable: true,
      left: true,
      sortField: "planName",
      allowOverflow: false,
      cell: row => <span>{`${row.planName}, Single Courses(${row.totalSubPlans})`}</span>
    },
    {
      name: "Onboarding Status",
      selector: "onBoardingStatus",
      keyName: "onBoardingStatus",
      sortable: true,
      left: true,
      // width:"150px",
      sortField: "onBoardingStatus",
      allowOverflow: false,
      cell: row => (
        <span>{row.onBoardingStatus === 1 ? "Completed" : row.onBoardingStatus === 2 ? "Stuck" : "N/A"}</span>
      )
    },
    {
      name: "Country",
      selector: "countryName",
      keyName: "countryName",
      sortable: true,
      left: true,
      // width:"150px",
      sortField: "countryName",
      allowOverflow: false,
      cell: row => (
        <span className="d-flex align-items-center">
          {!isEmpty(row.countryName) ? (
            <React.Fragment>
              <span className="me-2">
                <ReactCountryFlag sizes={20} style={{ fontSize: "1.5rem" }} countryCode={row.countryCode} />
              </span>
              <span>{row.countryName}</span>
            </React.Fragment>
          ) : (
            <span>N/A</span>
          )}
        </span>
      )
    },
    {
      name: "Action",
      sortable: false,
      left: true,
      minWidth: "250px",
      allowOverflow: false,
      cell: row => (
        <span className="d-flex align-items-center">
          <span onClick={() => handleCustomerEdit(row)} className="m-0 p-1 px-3 border-0 pointer">
            {" "}
            <BsEye size={20} color="#100224" />
          </span>
          <span
            className="text-danger me-3"
            role="button"
            onClick={e => handleCustomerDelete({ show: true, row: row })}
            title="Delete"
          >
            <span className="material-icons">delete</span>
            {/* <AiOutlineDelete size={16}/> */}
          </span>
        </span>
      )
    }
  ]
  const [isLoading, setLoading] = useState(false)
  // function to export customer csv
  const exportCustomerCSVData = async (startDate, endDate) => {
    setShowDateModal(false)
    setPageLoading(!pageLoading)
    const apiRes = await getCustomerCSVData(moment(startDate), moment(endDate))
    if (apiRes.status === false) {
      setFlashMessage(apiRes.message)
      setPageLoading(false)
    } else {
      const link = document.createElement("a")
      const filename = "CustomerList.csv"
      link.setAttribute("href", encodeURI(apiRes.data.records))
      link.setAttribute("download", filename)
      setPageLoading(false)
      link.click()
    }
  }
  // function to export customer csv
  const exportSymptomsCSVData = async () => {
    setPageLoading(!pageLoading)

    const apiRes = await getSymptomsCSVData()
    if (apiRes.status === false) {
      setFlashMessage(apiRes.message)
      setPageLoading(false)
    } else {
      const link = document.createElement("a")
      const filename = "CustomerSymptoms.csv"
      link.setAttribute("href", encodeURI(apiRes.data.records))
      link.setAttribute("download", filename)
      setPageLoading(false)
      link.click()
    }
  }

  return (
    <React.Fragment>
      {pageLoading && <PageLoader />}
      <div id="admin-container">
        <div className="dash-wrapper">
          <main id="main">
            <div className="px-3">
              <div className="page-heading between-xs w-100">
                <div className="heading-content d-flex-xs flex-wrap-xs middle-xs">
                  <img className="icon-header" src={customerSvg} alt="" aria-hidden="true" />
                  <h1 className="heading">Manage Customers</h1>
                </div>
                <div className="row ">
                  <div className="col-12 col-md-12 col-lg-12 pl-lg-5">
                    <div className="search-filter filter-xl setmin-width">
                      <div className="form-group form-creator-30 mr-10">
                        <input
                          type="text"
                          name="search"
                          className="form-control creator"
                          placeholder="Search"
                          onChange={e => filterSearchWise(e.target.value)}
                          value={searchTerm}
                        />
                      </div>
                      <div className="form-group  mr-10">
                        <Button
                          onClick={openCustomerDate}
                          // onClick={exportCustomerCSVData}
                          className="btn btn-primary w-auto me-1"
                        >
                          Customer CSV
                        </Button>
                        <Button onClick={exportSymptomsCSVData} className="btn btn-primary w-auto">
                          Symptoms CSV
                        </Button>
                      </div>
                      <AiOutlinePlusCircle
                        role="button"
                        onClick={() => history.push(`/admin/add/customer`)}
                        color="white"
                        size={30}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/**End header */}
              {!isEmpty(flashMessages) && (
                <Alert color="danger" className="mt-3" toggle={() => setFlashMessage("")}>
                  {flashMessages}
                </Alert>
              )}

              <div className="table-wrapper add-category">
                <div className=" table-responsive table-hover mt-0">
                  <DataTable
                    className="table table-responsive data-table-wrapper filter-tablecss mt-0"
                    columns={columns}
                    data={customerList.data}
                    persistTableHead={true}
                    noHeader={true}
                    responsive={true}
                    pagination={true}
                    onSort={handleSort}
                    sortServer={true}
                    sortIcon={<BsFillCaretDownFill />}
                    progressPending={isLoading}
                    progressComponent={<span className="text-center text-cstm">Loading....</span>}
                    paginationServer={true}
                    paginationTotalRows={customerList.total}
                    paginationPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handlePagination}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
        {showDateModal ? (
          <DatePopup
            show={showDateModal}
            handleClose={() => setShowDateModal(false)}
            handleexportCustomerCSVData={exportCustomerCSVData}
          />
        ) : null}
      </div>
      {showDeleteModal ? (
        <DeletePopUp
          show={showDeleteModal}
          handleSubmit={handleDelete}
          handleClose={() => handleCustomerDelete({ show: false, row: {} })}
        />
      ) : null}
    </React.Fragment>
  )
}

export default Customer
