import axios from "axios"
import { API_BASE_URL } from "config/constants"

axios.defaults.baseURL = API_BASE_URL

export const getDataForCustomerDashboard = (customerID) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-data-for-customer-dashboard', { customerID });
        resolve(response.data)
    })
}
// for new dashboard library data
export const getDataForCustomerDashboardUpdated = (customerID) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-data-for-customer-dashboard-updated', { customerID });
        resolve(response.data)
    })
}
// For sending onboarding mail to support team
export const getOnboardingCustomerData = (customerID) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/api/get-data-for-customer-onboarding', { customerID });
        console.log("resp", response)
        resolve(response.data)
    })
}

export const getCustomerAssignedCourse = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-customer-assigned-dashboard', data);
        resolve(response.data)
    })
}

export const getCustomerActivePrograms = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-customer-active-programs', data);
        resolve(response.data)
    })
}

export const submitCustomerLibraryReview = (body) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/submit-library-review', body);
        resolve(response.data)
    })
}

export const getCustomerLibraryLessonDetail = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-customer-library-lesson-data', data);
        resolve(response.data)
    })
}

export const submitCustomerLessonFeedback = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/submit-customer-library-lesson-feedback', data);
        resolve(response.data)
    })
}

export const handleLessonFavourite = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/submit-customer-library-lesson-favourite', data);
        resolve(response.data)
    })
}

export const updateCustomerWatchHistory = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/update-customer-watch-history', data);
        resolve(response.data)
    })
}

export const updateLessonFavouriteStatus = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/update-lesson-favourite-status', data);
        resolve(response.data)
    })
}

export const handleCustomerRequestChanges = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/change-course-request', data);
        resolve(response.data)
    })
}

export const getLibraryVideoDetail = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-library-video-detail-for-customer', data);
        resolve(response.data)
    })
}

export const getLibraryVideoDetailForNonUser = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-library-video-detail-for-non-user', data);
        resolve(response.data)
    })
}

export const getPublicLibraryVideoDetail = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-public-video-detail-for-customer', data);
        resolve(response.data)
    })
}


export const handleRemoveCustomerLibrary = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/remove_customer_library', data);
        resolve(response.data)
    })
}

export const handleTrackerBannerClose = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/close-tracker', data);
        resolve(response.data)
    })
}

export const getCustomerLibraryVideoDetail = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-customer-library-video-for-customer', data);
        resolve(response.data)
    })
}

export const getCustomerLibraryFTPReviewDetail = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-customer-library-ftp', data);
        resolve(response.data)
    })
}
export const sendDataToAlgolia = (customerID) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/sendNewUserDataToDashboard', { customerID })
        resolve(response.data)
    })
}

export const getCustomerPhasesDashboard = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.get('/customer/get-phases-dashboard', data);
        resolve(response.data)
    })
}

export const getCusomerLessonDetail = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/get-customer-lesson', data);
        resolve(response.data)
    })
}


// phases
export const getCustomerVideoDetail = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/library/video', data);
        resolve(response.data)
    })
}

export const submitVideoFeedback = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/library/video/feedback', data);
        resolve(response.data)
    })
}

export const handleVideoFavourite = (data) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/library/video/favourite', data);
        resolve(response.data)
    })
}