import axios from "axios"
import { API_BASE_URL } from "config/constants"

import { getCookie } from "config/functions"
import moment from "moment"

axios.defaults.baseURL = API_BASE_URL

const token = getCookie("token")

export const updateCustomerAccessLengthDate = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/update-customer-access-length", bodyData)
    resolve(response.data)
  })
}

export const getCustomerListing = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer-list", bodyData)
    resolve(response.data)
  })
}

export const getLibraryStaticsByCustomerID = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer-statictics", bodyData)
    resolve(response.data)
  })
}

export const getLibraryLessonStaticsByCustomerID = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer-lesson-statictics", bodyData)
    resolve(response.data)
  })
}

export const getCustomerLibraryRequestData = data => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/get-library-request-data", data)
    resolve(response.data)
  })
}

export const handleCustomerLibraryApprove = data => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/handle-customer-library-approve", data)
    resolve(response.data)
  })
}

export const handleCustomerUnlockLibraryApprove = data => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/handle-customer-unlock-library-approve", data)
    resolve(response.data)
  })
}

export const getOnBoardingDataByCustomerID = data => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/get-customer-onboarding", data)
    resolve(response.data)
  })
}

export const getCustomerGraphData = data => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/getCustomerSymptomGraph", data)
    resolve(response.data)
  })
}

export const getCustomerCSVData = (startDate, endDate) => {
  let start = moment(startDate).format("YYYY-MM-DD")
  let end = moment(endDate).format("YYYY-MM-DD")
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/get-customer-csv", {
      startDate: start,
      endDate: end
    })
    resolve(response.data)
  })
}

export const getSymptomsCSVData = data => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.get("/admin/get-symptoms-csv", data)
    resolve(response.data)
  })
}

export const handleDeleteCustomer = data => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/deleteCustomer", data)
    resolve(response.data)
  })
}

export const handleDeleteCustomerLibrary = data => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/library/delete_customer_library", data)
    resolve(response.data)
  })
}

export const getLibraryStatsDetailByCustomerID = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer-stats", bodyData)
    resolve(response.data)
  })
}

export const getCustomerLibraryLessonsStatsDetailByCustomerID = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer-library-stats", bodyData)
    resolve(response.data)
  })
}

// After Phases
export const getCustomerDetails = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer/info", bodyData)
    resolve(response.data)
  })
}

export const getCustomerPhases = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer/phases", bodyData)
    resolve(response.data)
  })
}

export const getCustomerPhaseLibrary = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer/phase/library", bodyData)
    resolve(response.data)
  })
}

export const deleteCustomerPhaseLibrary = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer/phase/library/delete", bodyData)
    resolve(response.data)
  })
}

export const getCustomerLibraryLessons = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer/phase/library/lessons", bodyData)
    resolve(response.data)
  })
}

export const getAssignLibraryList = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer/library-list", bodyData)
    resolve(response.data)
  })
}

export const handleAssingPhaseLibrary = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer/phase/library/assign", bodyData)
    resolve(response.data)
  })
}

export const updateCustomerLibrary = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer/phase/library/update", bodyData)
    resolve(response.data)
  })
}

export const updatePhaseCustomerLibrary = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer/phase/library/library-update", bodyData)
    resolve(response.data)
  })
}

export const markPhaseAsCompleted = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer/phase/mark-completed", bodyData)
    resolve(response.data)
  })
}

export const handleCustomerDashboardViewChange = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/admin/customer/assign-phases", bodyData)
    resolve(response.data)
  })
}