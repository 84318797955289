import axios from "axios";
import { getCookie } from "config/functions";
import React, { Component } from "react";
import { Tab, Nav, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import Timer from "./Timer";
import InputMask from "react-input-mask";
export default class PhoneVerificationPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allCountries: [],
      phone_code: this.props.customerData.phone_code || "+1",
      selectedPhoneCode: "+1 (United States)", // this is for the select dropdown
      phone: this.props.customerData.phone,
      verificationCode: "",
      sentCode: false,
      notificationMessage: {
        sendCode: "",
        verifyCode: "",
      },
      verifyPhoneMessage: {
        message: "",
      },
      showSendCodeButton: true,
    };
  }

  componentDidMount() {
    this.getAllCountries();
    if (
      this.props.customerData.phone && // if the customer has a phone number
      this.props.customerData.phone_code && // if the customer has a phone number code
      this.props.customerData.isPhoneVerified === 0
    ) {
      this.setState({
        sentCode: true,
        verifyPhoneMessage: {
          message: `${this.props.customerData.phone_code} ${this.props.customerData.phone} .`,
        },
      });
    }
  }

  getAllCountries = () => {
    axios.get("/api/getAllCountry").then((res) => {
      if (res.data.status) {
        let newArray = [];
        res.data.data.map(async (item) => {
          await newArray.push({
            label: `${item.phone_code}`,
            value: item.phone_code,
          });
        });
        this.setState({
          allCountries: newArray,
        });
      }
    });
  };

  sendVerificationCode = (e) => {
    e.preventDefault();
    const token = getCookie("token");
    let phone = this.state.phone.replace(/[^0-9]+/g, "")
    if(phone.length < 8){
      return
    }
    this.setState({
      phone: phone,
    })
    axios
      .post("/api/sendPhoneVerificationCode", {
        phone_code: this.state.phone_code,
        phone: phone,
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          authorization: token,
        },
      })
      .then((res) => {
        if (res.data.status === false) {
          this.setState({
            notificationMessage: {
              sendCode: res.data.message,
            },
          });
          setTimeout(() => {
            this.setState({
              notificationMessage: {
                sendCode: "",
              },
            });
          }, 3000);
        } else {
          this.setState({
            sentCode: true,
            showSendCodeButton: false,
            verifyPhoneMessage: {
              message: `${this.state.phone_code} ${this.state.phone} .`,
            },
          });
          setTimeout(() => {
            this.setState({
              showSendCodeButton: true,
            });
          }, 60000);
        }
      });
  };

  verifyCode = (e) => {
    e.preventDefault();
    const token = getCookie("token");
    let code = this.state.verificationCode.replace(/[^0-9]+/g, "");
    if(code.length < 4){
      return
    }
    this.setState({
      verificationCode: code,
    })
    axios
      .post("/api/verifyVerificationCode", {
        verificationCode: code,
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          authorization: token,
        },
      })
      .then((res) => {
        if (res.data.status === false) {
          this.setState({
            notificationMessage: {
              ...this.state.notificationMessage,
              verifyCode: res.data.message,
            },
          });
          setTimeout(() => {
            this.setState({
              notificationMessage: {
                ...this.state.notificationMessage,
                verifyCode: "",
              },
            });
          }, 3000);
        } else {
          this.props.handlePhoneVerificationModal();
        }
      });
  };

  sendVerificationCodeToVerifyPhone = (e) => {
    this.setState({
      showSendCodeButton: false,
    });
    let token = getCookie("token");
    axios
      .post("/api/sendVerificationCodeToVerifyPhone", {
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          authorization: token,
        },
      })
      .then((res) => {
        if (res.data.status === false) {
          this.setState({
            notificationMessage: {
              ...this.state.notificationMessage,
              verifyCode: res.data.message,
            },
          });
          setTimeout(() => {
            this.setState({
              notificationMessage: {
                ...this.state.notificationMessage,
                verifyCode: "",
              },
            });
          }, 3000);
        } else {
          this.setState({
            sentCode: true,
          });
        }
        setTimeout(() => {
          this.setState({
            showSendCodeButton: true,
          });
        }, 60000);
      });
  };

  render() {
    return (
      <Modal
        show={this.props.showPhoneVerificationModal}
        backdrop="static"
        onHide={() => this.props.handlePhoneVerificationModal()}
        keyboard={false}
        // size="xl"
        fullscreen="true"
        dialogclassname="Add-symptommodal phoneVerificationModal modal-md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="phone-verification-bg">
          <div className="phoneVerification">
            <h5 className="text-center phone_title">
              {this.state.sentCode
                ? "Please Verify Your Phone"
                : "Please Add Your Phone"}
            </h5>
            <div className="text-modal text-center text-dark">
              <p>
                We can text you when your program is updated. We will never spam
                you. Please leave your number below.
              </p>
            </div>
            <div className="phone_verification">
              {!this.state.sentCode ? (
                <form onSubmit={this.sendVerificationCode}>
                  {this.state.notificationMessage.sendCode && (
                    <p className="text-danger alert-danger mt-4 mb-3 p-2 rounded d-flex align-items-center justify-content-between text-capitalize">
                      {this.state.notificationMessage.sendCode}
                    </p>
                  )}
                  <div className="row g5">
                    <div className="col-md-3 col-3">
                      <div className="form-group country-select">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={{
                            label: "+1",
                            value: "+1",
                          }}
                          value={this.state.allCountries.find(
                            (item) => item.value === this.state.phone_code 
                          )}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          menuPosition={"static"}
                          onChange={(selected) =>
                            this.setState({
                              phone_code: selected.value,
                            })
                          }
                          isSearchable={true}
                          name="color"
                          options={this.state.allCountries}
                          WebkitOverflowScrolling="touch"
                          placeholder="Country"
                        />
                      </div>
                    </div>
                    <div className="col-md-9 col-9">
                      <div className="form-group border-none">
                        <InputMask
                          mask="999-999-9999"
                          maskChar={null}
                          formatChars= {{
                            '9': '[0-9]',
                          }}
                          value={this.state.phone}
                          onChange={(e) => this.setState({ phone: e.target.value })}
                        >
                          {(inputProps) => (
                            <input
                              {...inputProps}
                              type="text"
                              className="form-control"
                              placeholder="Phone Number"
                            />
                          )}
                        </InputMask>
                      </div>
                    </div>
                    <div className="col-md-12 ">
                      <button
                        type="submit"
                        className="btn-whiteTheme btn-send-code float-md-right"
                      >
                        Send Code
                      </button>
                    </div>
                    <div className="skip-button d-block col-md-12 mt-5">
                      <span
                        role="button"
                        onClick={() =>
                          this.props.handlePhoneVerificationModal()
                        }
                        className="text-center text-underline d-block"
                      >
                        I will update later
                      </span>
                    </div>
                  </div>
                </form>
              ) : (
                <form onSubmit={this.verifyCode}>
                  {this.state.verifyPhoneMessage.message && (
                    <div className="row mb-3 mt-3">
                      <div className="text-dark col">
                        {this.state.verifyPhoneMessage.message}
                      </div>
                      {this.state.showSendCodeButton ? (
                        <>
                          <div className="col-auto d-flex justify-content-md-end  justify-content-lg-end justify-content-sm-start align-items-center justify-content-xl-end">
                            <span
                              className="mr-3"
                              onClick={() => this.setState({ sentCode: false })}
                              role="button"
                            >
                              Edit{" "}
                            </span>
                            <span
                              onClick={() =>
                                this.sendVerificationCodeToVerifyPhone()
                              }
                              className=""
                              role="button"
                            >
                              Send Code
                            </span>
                          </div>
                        </>
                      ) : (
                        <small className=" col-md-4 text-underline">
                          Resend After <Timer count={60} /> Seconds
                        </small>
                      )}
                    </div>
                  )}
                  {this.state.notificationMessage.verifyCode && (
                    <p className="text-danger alert-danger mt-4 mb-3 p-2 rounded d-flex align-items-center justify-content-between text-capitalize">
                      {this.state.notificationMessage.verifyCode}
                    </p>
                  )}
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group">
                      <InputMask
                          mask="9-9-9-9"
                          maskChar={null}
                          formatChars= {{
                            '9': '[0-9]',
                          }}
                          value={this.state.verificationCode}
                          onChange={(e) => this.setState({ verificationCode: e.target.value })}
                        >
                          {(inputProps) => (
                            <input
                              {...inputProps}
                              type="text"
                              className="form-control"
                              placeholder="Verification Code"
                            />
                          )}
                        </InputMask>
                        <div className="input-group-append"><button
                        type="submit"
                        className="btn-whiteTheme"
                        style={{borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px"}}
                      >
                        Verify
                      </button></div>
                      </div>
                    </div>
                    <div className="skip-button d-block col-md-12 mt-5">
                      <span
                        role="button"
                        onClick={() =>
                          this.props.handlePhoneVerificationModal()
                        }
                        className="text-center text-underline d-block"
                      >
                        I will Verify later
                      </span>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
