import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

// import axios from 'axios';
export default class ShowMessagePopup extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      title: props.title,
      info: props.questionInfo,
      show: props.show,
    }
    this.state = this.initialState
    // console.log({ propss: props })
  }
  render() {
    let title = this.state.info ? ((this.state.info.firstName ? this.state.info.firstName : '') + " " + (this.state.info.lastName ? this.state.info.lastName : '')) : 'Message info'
    let content = this.state.info ? this.state.info.message : 'Are You Sure Want to delete ?'
    let subContent = this.state.subContent ? this.state.subContent : ''
    let show = this.state.show ? this.state.show : false
    return (
      <Modal className='provider_module CustomerSuportModal' show={show} size='md' onHide={this.props.handleClose} aria-labelledby='contained-modal-title-vcenter' backdrop='static' centered scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title className='text-muted'>
            <h5>Customer : {title}</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className='text-justify text-dark'>{content}</p>
          {subContent !== '' ? <p className='mt-2'>{subContent}</p> : null}
        </Modal.Body>
        {/* <Modal.Footer className='d-flex justify-content-around'>
          <p className='  lead'>delete this message .? </p>
          <Button variant={this.props.buttonClass ?? 'danger'} onClick={this.props.handleSubmit}>
            {this.props.buttonText ?? 'Yes'}
          </Button>
          <Button variant='secondary' onClick={this.props.handleClose}>
            No
          </Button>
        </Modal.Footer> */}
      </Modal>
    )
  }
}
