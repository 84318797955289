import React from 'react'
import { Spinner } from 'reactstrap' 
const PageLoader = () => {

  
  return (
    <div className='app-loader d-flex justify-content-center align-items-center'>
      <div className='body-overlay d-flex flex-column  justify-content-center align-items-center h-100 mx-auto' style={{backgroundColor:"rgba(0,0,0,0.75)"}}>
        <Spinner  className='  text-center  cstm-loader' size='sm'  />
        <span className='text-white  text-center mt-1'>Please wait...</span>
    </div>
    </div>
  )
}

export default PageLoader