import { put, takeLatest, fork, takeEvery, } from "redux-saga/effects";
import axios from "axios";





function* Login() {
    yield takeLatest("ADMIN_LOGIN", adminLogin)
}

function* adminLogin(payload) {

    const { email, password } = payload
    try {
        let res = yield axios.post('admin/adminLogin', { email, password });
        let data = yield res.data.data
        yield put({ type: 'ADMIN-LOGIN', payload: data === undefined ? res.data : data })
    } catch (err) {
        console.log(err)
    }
}


function* getAllCategory() {
    yield takeEvery("GET_ALL_CATEGORY", getCategory)
}

function* getCategory() {
    try {
        let res = yield axios.get('admin/getAllCategory');
        let data = yield res.data.data
        yield put({ type: 'GET_CATEGORIES', payload: data })
    } catch (err) {
        alert(err)
    }
}

function* getAllSymptom() {
    yield takeEvery("GET_ALl_SYMPTOM", getSymptom)
}

function* getSymptom() {
    try {
        let res = yield axios.get('admin/getAllSymptom');
        let data = yield res.data.data
        yield put({ type: 'GET_SYMPTOMS', payload: data })
    } catch (err) {
        alert(err)
    }
}

function* addCategories() {
    yield takeLatest("ADD_CATEGORY", addCategory)
}
function* addCategory(payload) {
    try {
        let { category, status } = payload;
        let res = yield axios.post('admin/addCategory', { categoryName: category, status: status });
        let data = yield res.data
        yield put({ type: 'ADD_CATEGORIES', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* addSymptoms() {
    yield takeLatest("ADD_SYMPTOM", addSymptom)
}
function* addSymptom(payload) {
    try {
        let { symptom, status } = payload;
        let res = yield axios.post('admin/addSymptom', { symptomName: symptom, status: status });
        let data = yield res.data
        yield put({ type: 'ADD_SYMPTOMS', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* getAllCreators() {
    yield takeLatest("GET_ADMIN_CREATORS", getAllCreator)
}
function* getAllCreator() {
    try {
        let res = yield axios.get('admin/getAllCreator');
        let data = yield res.data.data
        yield put({ type: 'GET_ADMIN_CREATOR', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* addNewCourse() {
    yield takeEvery("ADD_COURSE", addCourseDetail)
}
function* addCourseDetail(payload) {
    try {
        let {
            creatorID,
            title,
            brief,
            detail,
            categoryID,
            symptomID,
            status,
            isFeatured
        } = payload;
        let couDetail = {
            creatorID: creatorID,
            title: title,
            brief: brief,
            detail: detail,
            categoryID: categoryID,
            symptomID: symptomID,
            status: parseInt(status),
            isFeatured: parseInt(isFeatured)
        }
        let res = yield axios.post('admin/addCourse', couDetail);
        let data = yield res.data
        yield put({ type: 'ADD_COURSE_DETAILS', payload: data })
    } catch (err) {
        console.log('AAAA', err)
        throw (err)
    }
}

function* getAllCourses() {
    yield takeLatest("GET_COURSES", getCourse)
}
function* getCourse() {
    try {
        let res = yield axios.get('admin/getAllCourse');
        let data = yield res.data.data;
		if( res.data.status )
        yield put({ type: 'GET_COURSE_DETAIL', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* getAllModules() {
    yield takeLatest("GET_MODULE", getModule)
}
function* getModule() {
    try {
        let res = yield axios.get('admin/getAllModules');
        let data = yield res.data.data
        yield put({ type: 'GET_MODULE_DETAIL', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* getAllResources() {
    yield takeLatest("GET_RESOURCE", getResource)
}
function* getResource() {
    try {
        let res = yield axios.get('admin/getAllResources');
        let data = yield res.data.data
        yield put({ type: 'GET_RESOURCE_DETAIL', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* getDashboardData() {
    yield takeLatest("GET_DASHBOARD", getDashboard)
}
function* getDashboard() {
    try {
        let res = yield axios.get('admin/dashboard');
        let data = yield res.data.data
        yield put({ type: 'GET_DASHBOARD_RESOURCE', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* editCategory() {
    yield takeLatest("EDIT_CATEGORY", editCat)
}
function* editCat(payload) {
    try {
        let { categoryID, categoryName, status } = payload;

        let res = yield axios.post('admin/updateCategory', { categoryID, categoryName, status });
        console.log('responce', res.data)
        let data = yield res.data
        yield put({ type: 'EDIT_CAT', payload: data })
    } catch (err) {
        throw (err)
    }
}


function* editSymptom() {
    yield takeLatest("EDIT_SYMPTOM", editSym)
}
function* editSym(payload) {
    try {
        let { symptomID, symptomName, status } = payload;

        let res = yield axios.post('admin/updateSymptom', { symptomID, symptomName, status });
        let data = yield res.data
        yield put({ type: 'EDIT_SYM', payload: data })
    } catch (err) {
        throw (err)
    }
}


function* getModuleByCourseID() {
    yield takeLatest("GET_MODULE_CID", getModByCourseId)
}
function* getModByCourseId(payload) {
    try {
        let { courseID } = payload;

        let res = yield axios.post('admin/getAllModules', { courseID });
        let data = yield res.data.data
        yield put({ type: 'GET_MODULES', payload: data })
    } catch (err) {
        throw (err)
    }
}


function* getResourceByCourseID() {
    yield takeLatest("GET_RESOURCE_CID", getResByCourseID)
}
function* getResByCourseID(payload) {
    try {
        let { courseID } = payload;

        let res = yield axios.post('admin/getAllResources', { courseID });
        let data = yield res.data.data
        yield put({ type: 'GET_RESOURCES', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* addModuleByCourseId() {
    yield takeLatest("ADD_MODULE_BY_COURSE_ID", addModule)
}
function* addModule(payload) {
    try {
        let {
            title,
            courseID,
            categoryID,
            symptomID,
            videoURL,
            duration,
            isFree
        } = payload;

        let res = yield axios.post('admin/addModules', {
            title,
            courseID,
            categoryID,
            symptomID,
            videoURL,
            duration,
            isFree
        });
        let data = yield res.data.data
        yield put({ type: 'ADD_MODULES', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* getReviewByCourseID() {
    yield takeLatest("GET_REVIEW_CID", getRevByCourseID)
}
function* getRevByCourseID(payload) {
    try {
        let { courseID } = payload;

        let res = yield axios.post('admin/reviewListByCourse', { courseID });
        let data = yield res.data.data
        yield put({ type: 'GET_REVIEWS', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* addResourceByCourseID() {
    yield takeLatest("ADD_RESOURCE_BY_ID", addResource)
}
function* addResource(payload) {
    try {
        let {
            courseID,
            resourceType,
            Text,
            LinkUrl,
            isPublished
        } = payload;

        let res = yield axios.post('admin/addCourseResources', {
            courseID,
            resourceType,
            Text,
            LinkUrl,
            isPublished
        });

        let data = yield res.data.data
        yield put({ type: 'ADD_RESOURCE', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* addReviewByCourseID() {
    yield takeLatest("ADD_REVIEW_BY_ID", addReview)
}
function* addReview(payload) {
    try {
        let {
            isRegisteredUser,
            name,
            rating,
            message,
            courseID,
        } = payload;

        let res = yield axios.post('admin/addReview', {
            isRegisteredUser,
            name,
            rating,
            message,
            courseID
        });

        let data = yield res.data
        yield put({ type: 'ADD_REVIEW', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* addActionByCourseID() {
    yield takeLatest("ADD_ACTION_BY_ID", addAction)
}
function* addAction(payload) {
    try {
        let {
            courseID,
            courseVideoID,
            text,
            status
        } = payload;

        let res = yield axios.post('admin/addAction', {
            courseID,
            courseVideoID,
            text,
            status
        });

        let data = yield res.data
        yield put({ type: 'ADD_ACTION_BY_COURSE_ID', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* getActionByCourseID() {
    yield takeLatest("GET_ACTION_BY_ID", getAction)
}
function* getAction(payload) {
    try {
        let {
            courseVideoID,
            courseID
        } = payload;

        let res = yield axios.post('admin/getActionListByCourse', {
            courseVideoID,
            courseID
        });

        let data = yield res.data.data
        yield put({ type: 'GET_ACTION', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* getCourseByID() {
    yield takeLatest("GET_COURSE_BY_ID", getCourseId)
}
function* getCourseId(payload) {
    try {
        let {
            courseID
        } = payload;

        let res = yield axios.post('admin/get_course_detail', {
            courseID
        });

        let data = yield res.data.data
        yield put({ type: 'GET_COURSE_BY', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* getManageGroups() {
    yield takeLatest("GET_GROUP", getManageGroup)
}
function* getManageGroup() {
    try {
        let res = yield axios.get('admin/getGroupList');

        let data = yield res.data.data
        yield put({ type: 'GET_MANAGE_GROUP', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* addManageGroup() {
    yield takeLatest("ADD_GROUP", addGroup)
}
function* addGroup(payload) {
    try {
        let { GroupName, status } = payload;
        let res = yield axios.post('admin/addGroup', { GroupName: GroupName, status: status });
        let data = yield res.data
        yield put({ type: 'ADD_GROUPS', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* editManageGroup() {
    yield takeLatest("EDIT_GROUP", editGroup)
}
function* editGroup(payload) {
    try {
        let { browseGroupID, GroupName, status } = payload;

        let res = yield axios.post('admin/updateGroup', { browseGroupID, GroupName, status });

        console.log('responce', res.data)
        let data = yield res.data
        yield put({ type: 'EDIT_GRO', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* getActiveGroup() {
    yield takeLatest("GET_ACTIVE_GROUP", activeGroup)
}
function* activeGroup() {
    try {
        let res = yield axios.get('admin/getActiveGroupList');

        let data = yield res.data.data
        yield put({ type: 'GET_GROUP', payload: data })
    } catch (err) {
        throw (err)
    }
}

function* updateCourse() {
    yield takeEvery("UPDATE_COURSE", courseUpdate)
}
function* courseUpdate(payload) {
    try {
        let {
            courseID,
            creatorID,
            title,
            brief,
            detail,
            categoryID,
            symptomID,
            status,
            isFeatured
        } = payload;
        let couDetail = {
            courseID: courseID,
            creatorID: creatorID,
            title: title,
            brief: brief,
            detail: detail,
            categoryID: categoryID,
            symptomID: symptomID,
            status: parseInt(status),
            isFeatured: parseInt(isFeatured)
        }
        let res = yield axios.post('admin/updateCourse', couDetail);
        let data = yield res.data
        yield put({ type: 'UPDATE_COURSE_DETAILS', payload: data })
    } catch (err) {
        console.log('AAAA', err)
        throw (err)
    }
}

function* changePassword() {
    yield takeEvery("CHANGE_PASSWORD", passChange)
}

function* passChange(payload) {
    try {
        let { oldPassword, newPassword } = payload;
        let res = yield axios.post('admin/changePassword', { oldPassword, newPassword });
        console.log('response', res.data)
        let data = yield res.data
        yield put({ type: 'CHANGE_PASS', payload: data })
    } catch (err) {
        throw (err)
    }
}

export default [
    fork(Login),
    fork(getAllCategory),
    fork(getAllSymptom),
    fork(addCategories),
    fork(addSymptoms),
    fork(getAllCreators),
    fork(addNewCourse),
    fork(getAllCourses),
    fork(getAllModules),
    fork(getAllResources),
    fork(getDashboardData),
    fork(editCategory),
    fork(editSymptom),
    fork(getModuleByCourseID),
    fork(getResourceByCourseID),
    fork(addModuleByCourseId),
    fork(getReviewByCourseID),
    fork(addResourceByCourseID),
    fork(addReviewByCourseID),
    fork(addActionByCourseID),
    fork(getActionByCourseID),
    fork(getCourseByID),
    fork(getManageGroups),
    fork(addManageGroup),
    fork(editManageGroup),
    fork(getActiveGroup),
    fork(updateCourse),
    fork(changePassword)

]



