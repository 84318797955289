import React from "react";
import ReactDOM from "react-dom";
import "bootstrap";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { PUBLIC_URL } from "config/constants";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
ReactDOM.render(
  <Provider store={store}>
    <Router basename={PUBLIC_URL}>
      <App />
      <ToastContainer toastStyle={{ backgroundColor: "#413D70" }} newestOnTop />
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register();
