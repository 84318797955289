import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {isEmpty} from 'config/functions'

export default class LibraryHeader extends Component {
  constructor(props) {
    super(props)
    const {tabSequence, packageID, customerPackageID} = this.props
    this.initialState = {
      tabSequence: !isEmpty(tabSequence) ? tabSequence : '1',
      packageID: !isEmpty(packageID) ? packageID : '',
      customerPackageID: !isEmpty(customerPackageID) ? customerPackageID : '',
      navLinkArray: [],
    }
    this.state = this.initialState
    let navLinks = []
    if (packageID) {
      navLinks = [
        {linkUrl: `/admin/package/details/${packageID}`, className: 'nav-item nav-link', linkText: '1. Details'},
        {linkUrl: `/admin/package/items/${packageID}`, className: 'nav-item nav-link', linkText: '2. Package Items'},
      ]
    } else if (customerPackageID) {
      navLinks = [
        {linkUrl: `/admin/customer-package/details/${customerPackageID}`, className: 'nav-item nav-link', linkText: '1. Details'},
        {linkUrl: `/admin/customer-package/items/${customerPackageID}`, className: 'nav-item nav-link', linkText: '2. Package Items'},
      ]
    }
    this.state.navLinkArray = navLinks
  }

  render() {
    let {tabSequence, navLinkArray, providerSlug, packageID, customerPackageID} = this.state
    let backPageAction = customerPackageID ? '/admin/package-customer' : '/admin/packages'
    let backPageUrl = navLinkArray.hasOwnProperty(tabSequence - 2) ? navLinkArray[tabSequence - 2].linkUrl : backPageAction
    return (
      <header className='adminHeader'>
        <div className='adminlink'>
          <div className='back-icon'>
            <Link to={backPageUrl}>
              <span className='goback'></span>
            </Link>
          </div>
          <ul className='list-unstyled'>
            {this.state.navLinkArray.length > 0
              ? this.state.navLinkArray.map(
                  function(data, i) {
                    return <NavLinkItem key={i} Values={data} currentTab={i + 1} props={this.props} tabSequence={this.props.tabSequence} />
                  },
                  {props: this.props}
                )
              : ''}
          </ul>
        </div>
      </header>
    )
  }
}

class NavLinkItem extends Component {
  render() {
    let pathname = this.props.props.location.pathname
    let listInfo = this.props.Values
    let tabSequence = this.props.tabSequence
    let currentTab = this.props.currentTab
    let className = currentTab == tabSequence ? listInfo.className + ' active' : listInfo.className
    return typeof listInfo.linkText !== 'undefined' ? (
      <li className={currentTab == tabSequence ? 'activeli' : ''}>
        <Link to={tabSequence > currentTab ? listInfo.linkUrl : pathname} className={className}>
          {listInfo.linkText}
        </Link>
      </li>
    ) : null
  }
}
