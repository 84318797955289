import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import HeaderMeta from "views/common/HeaderMeta"
import UpdatedLibraryCards from "../../DashboardNew/Library/UpdatedLibraryCards"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import UpdatedWaitingForYou from "../../DashboardNew/UpdatedWaitingForYou"
import { getTranslatedName, isEmpty, isValid } from "config/functions"
import { getDataForCustomerDashboardUpdated, sendDataToAlgolia } from "../../DashboardNew/apiStore"
import SkeletomDashboardLoader from "views/common/SkeletomDashboardLoader"
import SymptomBanner from "../../DashboardNew/banners/SymptomBanner"

const UpdatedDashboard = () => {
  const history = useHistory()
  // ** For store
  const studentData = useSelector(state => state.register.userProfileData)
  const symptomTrackerInfo = studentData.symptomTrackerInfo
  const { question3Score, question1Score } = symptomTrackerInfo
  const firstName = studentData.firstName ? studentData.firstName : ""
  const lastName = studentData.lastName ? studentData.lastName : ""
  const userName = firstName + " " + lastName
  const customerID = studentData.customerID
  // const algoliasearch = require("algoliasearch/lite")

  // const client = algoliasearch("U5JCU1I4XJ", "5e56d03b3903388442c594da73fa7af1")
  // const indexForDashboard = client.initIndex("dashboard_library")
  const dispatch = useDispatch()
  let planID = window.localStorage.getItem("planID")
  // ** For all states
  const [openCourse, setOpenCourse] = useState("")
  const [openLesson, setopenLesson] = useState("")
  const [dashboardData, setDashboardData] = useState([])
  const [loader, setLoader] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [results, setResults] = useState([])
  const [lockedStautusArray, setLockedStatusArray] = useState()
  const courseToggle = id => {
    if (openCourse === id) {
      setOpenCourse()
    } else {
      setOpenCourse(id)
    }
  }
  const lessonToggle = id => {
    if (openLesson === id) {
      setopenLesson()
    } else {
      setopenLesson(id)
    }
  }
  // Function for dashboard library list
  async function getDashboardLibraryData() {
    setLoader(true)
    const libraryData = await getDataForCustomerDashboardUpdated(customerID)
    if (libraryData.status) {
      let cdLibraryData = libraryData.data ? libraryData.data : null
      cdLibraryData = cdLibraryData.customerLibraryData ? cdLibraryData.customerLibraryData : []
      setDashboardData(cdLibraryData)
      setLoader(false)
    } else {
      setLoader(false)
    }
  }
  useEffect(() => {
    let pathHistory = localStorage.getItem("pathhistory")
    // console.log("pathHistory", pathHistory)
    if (!isEmpty(pathHistory)) {
      pathHistory = JSON.parse(pathHistory)
      let { pathArr } = pathHistory
      // console.log("pathArr", pathArr[0], pathArr[1])
      if (pathArr[1] == '/student/waiver-step-2' && pathArr[0] == '/student/dashboard') {
        // console.log("customerID", customerID)
        let updateResponse = sendDataToAlgolia(customerID)
        // console.log("pathArr", pathArr)
        if (updateResponse) {
          console.log("Updated successfully", updateResponse)
        }
        else {
          console.log("Something went wrong while updating", updateResponse)
        }
      }
    }

  }, [])
  // Which library are locked
  const lockeddashboardData = dashboardData.length > 0 ? dashboardData.filter(item => item.lockStatus === 1) : []
  // Which library are unlocked
  let unlockeddashboardData = dashboardData.length > 0 ? dashboardData.filter(item => item.lockStatus === 0) : []

  const allParentLibrary = unlockeddashboardData.filter(item => !item.parentLibraryMapID)
  let allalgoParentLibrary = results.filter(item => !item.includesParentLib)

  for (const currentParent of allParentLibrary) {
    currentParent.libraryData = [
      { ...currentParent },
      ...unlockeddashboardData.filter(item => item.parentLibraryMapID === currentParent.libraryMapID)
    ]
  }


  useEffect(() => {
    dispatch({ type: "HANDLE_HEADER", payload: { isSubscriptionPage: false } })
    getDashboardLibraryData()
  }, [planID])

  

  return (
    <div className="dashboard cstm-dashboard pt-md-80 pt-sm-40 pb-md-64 pb-48">
      <Container fluid>
        <HeaderMeta title="Dashboard" />
        <Row className="align-items-center justify-content-center">
         

        </Row>
        {(isValid(question1Score) || isValid(question3Score)) && studentData.isTrackerClosed !== 1 && (
          <Col xs={12} className="SymptomBanner-msg pt-32">
            <SymptomBanner />
          </Col>
        )}
      </Container>
      <div className="pt-32">
        {searchQuery == "" && (
          <Container fluid>
            {!loader ? (
              <>
                {allParentLibrary.length > 0
                  ? allParentLibrary.map((item, index) => {
                    const libraryData = item.libraryData ? item.libraryData : []
                    const teacherFirstName = item.teacherFirstName ? item.teacherFirstName : ""
                    const teacherLastName = item.teacherLastName ? item.teacherLastName : ""
                    const teacherFullName = `${teacherFirstName} ${teacherLastName} `
                    let isLibraryGroup = item.libraryData.length > 1 ? true : false
                    return (
                      <div className="row mb-4" key={`lb_${index}`}>
                        <Col lg={12}>
                          <h3 className="text-white mb-2 cstm-item-main-title">
                            {item.libraryTitle ? item.libraryTitle : ""}
                          </h3>
                          <UpdatedLibraryCards libraryData={libraryData} isLibraryGroup={isLibraryGroup} />
                        </Col>
                      </div>
                    )
                  })
                  : null}
                {lockeddashboardData.length > 0 && (
                  <>
                   
                    <Row>
                      <Col lg={12} className="mb-3">
                        <h2 className="topic-title">{getTranslatedName("Waiting for you")}</h2>
                        <p className="text-favourite">
                          {getTranslatedName("Complete your active programs to start the below")}
                        </p>
                      </Col>
                    </Row>
                    <UpdatedWaitingForYou dashboardData={lockeddashboardData} />
                  </>
                )}
              </>
            ) : (
              <div className="px-lg-0 px-md-2 px-3 course-section  pt-md-40 pt-32 col-12">
                <SkeletomDashboardLoader skeletonType={"dashboard"} itemCount={3} />
              </div>
            )}
          </Container>
        )}

      </div>
    </div>
  )
}

export default UpdatedDashboard
