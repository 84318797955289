import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Logo from 'assets/images/MOONRISE_White.svg';
import { useDispatch } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { IoChevronBackOutline } from "react-icons/io5"


const CheckoutHeader = () => {
    const currentpage = window.location.pathname;
    return (
        <React.Fragment>
            {false
                ? "" : 
                <Nav className="navbar navbar-expand-lg navbar-light fixed-top Checkout-header p-1">
                    <Nav.Link to='#' className="navbar-brand-checkout  p-0 ">
                        <img src={Logo} alt="Logo" width={180} className="d-inline align-items-center " />
                    </Nav.Link>

                </Nav>
                }
        </React.Fragment>
    )
}
// currentpage.includes("/get-started") || currentpage.includes("/on-boarding") || currentpage.includes("create-password") || currentpage.includes("/login") ||
// currentpage.includes("forgot-password") || currentpage.includes("reset-password") || currentpage.includes("verifyEmail")

export default CheckoutHeader;
