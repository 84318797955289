/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react"
import { Link, useParams } from "react-router-dom"
import { Tab, Nav } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Modal } from "react-bootstrap"
import slugify from "react-slugify"

import { getCookie, isEmpty, removeCookie, encodeId, decodeId } from "../../config/functions"
import {
  getFeaturedCourses,
  getTestimonial,
  getPlanList,
  getCategoriesSymptoms,
  getAllCreators,
  getSelectedSymptomList,
  checkSymptomTrackId,
  addsymptomAndScoresAction
} from "../publicLayout/action"
import HeaderMeta from "../common/HeaderMeta"
import PageLoader from "../common/Loader"
import MultiRangeSliderApp from "../common/MultiRangeSlider/MultiRangeSliderApp"
import { RANGE_SLIDER_MIN, RANGE_SLIDER_MAX } from "../../config/constants"
import FlashMessages from "views/common/FlashMessages"
import axios from "axios"
import { encode } from "base-64"

export default function Symptomstracker3(props) {
  const { swedish } = props
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)
  const scrollToRef = myRef => window.scrollTo(0, myRef.current.offsetTop)

  const dispatch = useDispatch()
  const params = useParams()
  let trackerId = props.newTrackId ? props.newTrackId : "0"
  //console.log("trackerId " + props.newTrackId);
  let [trackerInfoId, setTrackerInfoId] = useState(trackerId)
  const [dataLoaded, setDataLoaded] = useState("")
  const [severeSymptom, setSevereSymptom] = useState([])
  const [selectedRangeData, setSelectedRangeData] = useState(0)
  const [flashMessage, setFlashMessage] = useState("")
  const [messageType, setMessageType] = useState("error")
  const selectedSymptoms = useSelector(state => state.non_register.selectedsymptoms)
  const selectedSymptomsData = selectedSymptoms.data
  const selectedSymptomsLoading = selectedSymptoms.loading
  const userProfileData = useSelector(state => state.register.userProfileData)
  const featuredCourses = useSelector(state => state.non_register.feturedCourses)
  const testimonials = useSelector(state => state.non_register.testmonials)
  const creators = useSelector(state => state.non_register.getAllCreators)
  const planList = useSelector(state => state.non_register.planList)
  const categoriesSymptoms = useSelector(state => state.non_register.categoriesSymptoms)
  const activeUser = useSelector(state => state.register.userProfileData.isActiveSubscrion)
  const featuredCoursesData = featuredCourses.data
  const featuredCoursesLoading = featuredCourses.loading
  const testimonialData = testimonials.data
  const testimonialLoading = testimonials.loading
  const creatorsData = creators.data
  const creatorsLoading = creators.loading
  const categoriesSymptomsData = categoriesSymptoms.data
  const categoriesSymptomsLoading = categoriesSymptoms.loading
  const [activePlaneTab, setActivePlaneTab] = useState("quarterly")
  const pathname = props.pathname

  const logout = () => {
    removeCookie("token")
    removeCookie("isUserPlanActive")
    dispatch({ type: "GET_PLAN_LIST", payload: [] })
    dispatch({ type: "GET_USER_PROFILE_DATA", payload: [] })
    props.history.push("/login")
  }
  const symptomTrackData = useSelector(state => state.non_register.symptomtrackdata)
  const trackData = symptomTrackData.data
  const trackLoading = symptomTrackData.loading
  // console.log(
  //   selectedSymptomsData,
  //   //  symptomTrackData,
  //   severeSymptom
  //   // selectedRangeData
  // )

  useEffect(() => {
    /* if (pathname !== pathname.toLowerCase()) {
            props.history.replace(pathname.toLocaleLowerCase())
        } */
  }, [pathname])

  let authToken = getCookie("token")

  useEffect(() => {
    setDataLoaded(false)
    dispatch({
      type: "HANDLE_HEADER",
      payload: {
        activeNav: "",
        showJoinBtn: true,
        isSubscriptionPage: false,
        isLinkActive: false
      }
    })
    isEmpty(featuredCoursesData) && dispatch(getFeaturedCourses())
    isEmpty(testimonialData) && dispatch(getTestimonial())
    isEmpty(planList) && dispatch(getPlanList())
    isEmpty(creatorsData) && dispatch(getAllCreators())
    isEmpty(categoriesSymptomsData) && dispatch(getCategoriesSymptoms())
    // isEmpty(selectedSymptomsData) && dispatch(getSelectedSymptomList())
    dispatch(getSelectedSymptomList())
    isEmpty(trackData) && dispatch(checkSymptomTrackId(trackerInfoId))
  }, [])

  useEffect(() => {
    if (
      !featuredCoursesLoading &&
      !testimonialLoading &&
      !creatorsLoading &&
      !categoriesSymptomsLoading &&
      !selectedSymptomsLoading &&
      !trackLoading
    ) {
      setDataLoaded(true)
      //   if (!trackData) {
      //     logout();
      //   }
    }
  }, [
    featuredCoursesLoading,
    testimonialLoading,
    creatorsLoading,
    categoriesSymptomsLoading,
    selectedSymptomsLoading,
    trackLoading
  ])

  const handleRangeDataCallback = rangeData => {
    setSelectedRangeData(rangeData)
  }
  const handleRangeDataSymptomId = symptomIds => {
    setFlashMessage("")
    let newSelectedSymptomRangeData = {
      symptomId: symptomIds,
      rangedata: selectedRangeData
    }
    setSevereSymptom(selectedSymptomRangeData => {
      let fIndex = selectedSymptomRangeData.map(el => el.symptomId).indexOf(symptomIds)
      if (fIndex > -1) {
        selectedSymptomRangeData[fIndex] = newSelectedSymptomRangeData
        return [...selectedSymptomRangeData]
      } else {
        return [...selectedSymptomRangeData, newSelectedSymptomRangeData]
      }
    })
  }
  let sympScores
  if (selectedSymptomsData && severeSymptom) {
    // console.log(severeSymptom, '130')
    sympScores = selectedSymptomsData.map((sym, ind) => {
      //  selectedSymptomsData==>> : {symptomID: 6, name: 'Pregnancy'}
      //  severeSymptom ==>> : {symptomId: 26, rangedata: 5}
      const symptom = sym.name
      const scorearr = severeSymptom.filter(symp => symp.symptomId == sym.symptomID)
      // console.log(scorearr, '138')
      const sympScore = {
        symptom: symptom,
        score: scorearr.length ? scorearr[0]["rangedata"] : ""
      }
      return sympScore
    })
    // console.log(sympScores, 'syms')
    // setSymptomScores(sympScores)
    // dispatch(addsymptomAndScoresAction(sympScores))
  }
  const handleSubmit = async e => {
    e.preventDefault()
    if (severeSymptom.length == selectedSymptomsData.length) {
      let severeSymptomScore = JSON.stringify(severeSymptom)
      let formData = new FormData()
      formData.append("customerSymptomTrackerInfoID", props.newTrackId)
      formData.append("packageItemID", "0")
      formData.append("severeSymptom", severeSymptomScore)
      // console.log(sympScores, '156')

      // dispatch(addsymptomAndScoresAction(sympScores))
      await axios
        .post("api/addSymptonmTrackStep3", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "access-control-allow-origin": "*",
            authorization: authToken
          }
        })
        .then(result => {
          if (result.data.status) {
            props.handleTracker(4)
          } else {
            setFlashMessage(result.data.message)
          }
        })
        .catch(error => {
          setFlashMessage(error.message)
        })
    } else {
      if(swedish){
        setFlashMessage("Vänligen välj alla symptomstatus!")

      }
      else{

        setFlashMessage("Please choose all symptom status!")
      }

      var myDiv = document.getElementById("ScrolldModal")

      myDiv.scrollIntoView({ behavior: "smooth" })
    }
  }
  const handleFlashUnmount = () => {
    setFlashMessage("")
  }
  return (
    <Modal
      show={props.showTracker === 3}
      backdrop="static"
      keyboard={false}
      size="xl"
      fullscreen="true"
      dialogclassname="Add-symptommodal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header className="border-0" closeButton onHide={() => props.history.goBack()}></Modal.Header>
      <Modal.Body>
        <React.Fragment>
          <div className="container-lg symptoms-tracker-questions">
            <div className="Content-view symptoms-tracker-modal">
              <div className="question-step active">
                <div className="text-center step-number ">
                  <span className="badge bg-light">3/4</span>
                </div>
                <div className="d-flex align-items-center justify-content-center step-bar">
                  <div className="step-btn active"></div>
                  <div className="step-btn active"></div>
                  <div className="step-btn active-fill"></div>
                  <div className="step-btn "></div>
                </div>
                <h2 className="h2 text-center mb-3">
                  {swedish ? "Hur allvarliga är dina symptom?" : "How severe are these symptoms?"}
                </h2>
                {/*<div className="row text-center  justify-content-md-center extremelyBad">
                  <div className="col-mobile-css col-6 col-sm-3">
                    <span className="badge bg-success">{RANGE_SLIDER_MIN}</span>{" "}
                    Not Severe at all
                  </div>
                  <div className="col-mobile-css col-6 col-sm-3">
                    Very Severe{" "}
                    <span className="badge bg-danger">{RANGE_SLIDER_MAX}</span>
                  </div>
                </div>*/}
                <>
                  {flashMessage ? (
                    <div className="row text-center justify-content-md-center my-3">
                      <div ref={myRef} id="ScrolldModal" className="messageTop">
                        <FlashMessages type={messageType} message={flashMessage} unmountMe={handleFlashUnmount} />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>

                <div className="symptoms-tracker-body">
                  {selectedSymptomsData
                    ? selectedSymptomsData.map((symptomData, index) => {
                        return (
                          <div className="range-slider-repeat" key={index}>
                            <h5 className="range-title-css">
                              {swedish && symptomData.swedish ? symptomData.swedish : symptomData.name}
                            </h5>
                            <div className="card shadow-smno border-0 mt-2 mb-2">
                              <div className="card-body tracker3">
                                <div className="MultiRangeSliderApp">
                                  <div className="RangeSliderbg step3">
                                    {(swedish && (
                                      <div className="row no-gutters">
                                        <div
                                          className="col-3 colorbg darkgreen111 dataTextCSS lightgary"
                                          dataTextCSS={"Inte allvarliga"}
                                        ></div>

                                        <div
                                          className="col-3 colorbg lightblue111 dataTextCSS darkgary"
                                          dataTextCSS={"Ganska allvarliga"}
                                        ></div>

                                        <div
                                          className="col-3 colorbg lightred dataTextCSS"
                                          dataTextCSS={"Allvarliga"}
                                        ></div>

                                        <div
                                          className="col-3 colorbg darkred dataTextCSS"
                                          dataTextCSS={"Väldigt allvarliga"}
                                        ></div>
                                      </div>
                                    )) || (
                                      <div className="row no-gutters">
                                        <div
                                          className="col-3 colorbg darkgreen111 dataTextCSS lightgary"
                                          dataTextCSS={"Not severe"}
                                        ></div>

                                        <div
                                          className="col-3 colorbg lightblue111 dataTextCSS darkgary"
                                          dataTextCSS={"Quite severe"}
                                        ></div>

                                        <div
                                          className="col-3 colorbg lightred dataTextCSS"
                                          dataTextCSS={"Severe"}
                                        ></div>

                                        <div
                                          className="col-3 colorbg darkred dataTextCSS"
                                          dataTextCSS={"Really severe"}
                                        ></div>
                                      </div>
                                    )}
                                  </div>

                                  <MultiRangeSliderApp
                                    swedish={swedish}
                                    rangeData={handleRangeDataCallback}
                                    symptomID={e => handleRangeDataSymptomId(symptomData.symptomID)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    : ""}
                </div>
                <div className="text-center symptoms-tracker-bottom">
                  <button className="btn-lg btn-whiteTheme btn-stepcss" onClick={e => handleSubmit(e)}>
                    {swedish ? "Nästa fråga" : "Next Question"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal>
  )
}
