import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { adminRoutes, creatorRoutes } from 'routes';
import NoMatchPage from 'views/pages/NoMatchPage';
import ScrollToTopBtn from "views/common/ScrollToTopBtn";
import SideBar from "./sidebar/SideBar";
import '../../admin-assets/admin_css/style.css';
import { getCookie } from '../../config/functions';

export default class AdminLayout extends Component {

	render() {
		let role = getCookie('adminRole');
		return (
			<React.Fragment>
				<div >
					<SideBar {...this.props} />
					<div>
						<Suspense fallback={this.props.pageLoader()}>
							<Switch>
								{(role == '1') && adminRoutes.map((route, idx) => {
									return (typeof (route.component !== "undefined") ? (
										<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (<route.component {...this.props} {...props} />)} />
									) : (null))
								})
								}

								{(role == '2') && creatorRoutes.map((route, idx) => {
									return (typeof (route.component !== "undefined") ? (
										<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (<route.component {...this.props} {...props} />)} />
									) : (null))
								})
								}

								<Route component={NoMatchPage} />
							</Switch>
						</Suspense>
					</div>
				</div>
				{/* <ScrollToTopBtn /> */}
			</React.Fragment>
		);
	}
}