import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './vod.scss'
import { FaCirclePlay } from "react-icons/fa6";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import GradientSVG from 'views/pages/DashboardNew/Gradient';
import { IoIosTimer } from "react-icons/io";
import heartFill from "../../../../assets/images/heartNew.svg"
import heartFilled from "../../../../assets/images/heartFilledNew.svg"
import Preview from "../../../../assets/images/Load_circle.png"
import soundwave from "../../../../assets/images/soundwave.gif"
import Locked from "../../../../assets/images/LockedItem.svg";
import placeholderImage from "../../../../assets/images/placeholder_image.png";

import { encode } from 'base-64';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { CiHeart } from 'react-icons/ci';
import { BsClipboardHeartFill, BsHeartFill } from 'react-icons/bs';
const idCSS = "progress"


const VodCardItem = ({ lesson }) => {


    const duration = (lesson?.videoDuration)
    const durationObject = moment.duration(duration, 'seconds');
    const history = useHistory()

    const humanReadableFormat = durationObject.humanize();

    const watchtime = lesson?.closeVideoLengthAt ? lesson?.closeVideoLengthAt : 0

    let progress = (watchtime / duration) * 100

    if (isNaN(progress)) {
        progress = 0
    } else if (progress > 100) {
        progress = 100
    } else if (progress < 0) {
        progress = 0
    }

    const LibraryName = ({ library }) => {
        let libraryName = ''
        if (library?.libraryType === 1) {
            libraryName = library.course?.title
        } else if (library?.libraryType === 2) {
            libraryName = library.playlist?.title
        } else if (library?.libraryType === 3) {
            libraryName = library.video?.title
        }
        return libraryName;
    }

    const getThumnnailUrl = (lesson) => {
        if (lesson?.lessonType == 5) { // pdf
            return Preview
        } else if (lesson?.lessonType == 6) { // audio
            return soundwave
        } else {
            return lesson?.thumbnailUrl
        }
    }

    const handleLessonClick = (lesson) => {
        if (lesson.lockStatus == 2 || lesson.lockStatus == 1) {
            history.push(`/student/library/${encode(lesson.customerLibraryMapID)}`)
            return
        }
        if (lesson.libraryType == 3) {
            history.push(`/student/watch-video/${encode(lesson.customerLibraryMapID)}`)
        } else {
            history.push(`/student/libary-lessons/${encode(lesson.id)}`)
        }
    }


    return (
        <Card className='vod-video-container'>
            <Card.Header>
                <div className="vod-card-header">
                    <h2 className='title'><LibraryName library={lesson} /></h2>
                    <div className='progress-circle'>
                        <CircularProgressbarWithChildren
                            // value={Math.round(50)}
                            value={progress.toFixed(0)}
                            circleRatio={1}
                            strokeWidth={8}
                            styles={{
                                width: '30px',
                                root: {
                                    transform: "rotate(0deg)"
                                },
                                color: '#fff',
                                alignSelf: 'center !important',
                                path: {
                                    stroke: `url(#${idCSS})`
                                }
                            }}
                        >
                            <span className={"text-white percent"}>
                                {progress.toFixed(0)}%
                            </span>
                            <GradientSVG />
                        </CircularProgressbarWithChildren>
                    </div>
                </div>
            </Card.Header>

            <div className="vod-card-body" role='button' onClick={() => handleLessonClick(lesson)}>
                <div className="play-icon" >
                    <FaCirclePlay />
                </div>

                <Card.Img variant="top" src={getThumnnailUrl(lesson) ? getThumnnailUrl(lesson) : placeholderImage} loading='lazy' />

            </div>

            <Card.Footer >
                <div className="vod-card-footer">

                    <div className="info">
                        <h2 className='lesson-name'>{lesson.libraryType == 3 ? <LibraryName library={lesson} />  :lesson?.lessonName}</h2>
                        <p className='lesson-description'>
                            {lesson.libraryType == 3 ?  lesson.shortDescription    : lesson?.descriptionTitle}
                        </p>
                    </div >


                    <div className="bottom-progress-section">

                        <p className={'text-muted'}>
                            <IoIosTimer />  {humanReadableFormat}
                        </p>

                        {lesson?.isFavourite == 1 ? <div className="fav-btn">
                            < BsHeartFill />
                        </div> : <div className="fav-btn">
                            <CiHeart />
                        </div>}

                    </div>

                    <div className="library-progress">
                        <div className="progress-bar">
                            <div className={`progress h-100 w-${0}`} role="progressbar" aria-valuenow={0} aria-valuemin="0" aria-valuemax="100"
                                style={{
                                    width: `${0}%`
                                }}
                            ></div>
                        </div>
                    </div>

                </div>
            </Card.Footer>
        </Card>
    )
}

export default function VodCard({ lesson, upcoming, data }) {
    return (
        <div className='vod-card' >

            <VodCardItem lesson={lesson} />

        </div>
    )
}
