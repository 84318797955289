import React, { useState } from 'react'
// import styles from './supportpg.module.scss'
import fillipaLpose from 'assets/images/fillipaLpose.png'
import FlexiAccordion from './components/FlexiAccordion'
import styles from './supportpg.module.scss'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { httpPostRequest } from 'config/services'
import { FiChevronRight } from 'react-icons/fi'
import { checkSwedish, getTranslatedName } from 'config/functions'

const data_en = [
    { title: "Book 1-1 with Abby", description: "2x30 minute sessions for 95 GBP. For women wanting to go deeper with Hypopressives", buttonText: "Find a time with Abby", buttonAction: function () { return window.open('https://meet.brevo.com/abby-lord/abby-paid-coaching') }, open: false },
    // {
    //     "title": "Book 1-1 with Trista Zinn",
    //     "description": "2x30 minute sessions for 95 GBP.<br>For women wanting to improve their pelvic health and core strength",
    //     "buttonText": "Find a time with Trista",
    //     "buttonAction": function () { return console.log('trista'); },

    //     "open": false
    // },
    {
        "title": "Book 1-1 with Fillipa Odevall",
        "description": "2x30 minute sessions for 95 GBP. For women wanting to improve their posture and reduce back pain",
        "buttonText": "Find a time with Fillipa",
        "buttonAction": function () { return window.open('https://meet.brevo.com/filippa/1-1-paid') },

        "open": false
    }
]

const data_sw = [
    { title: "Boka 1-1 med Abby", description: "2x30 minuters sessioner för 95 GBP. För kvinnor som vill gå djupare med Hypopressives", buttonText: "Hitta en tid med Abby", buttonAction: function () { return window.open('https://meet.brevo.com/abby-lord/abby-paid-coaching') }, open: false },
    // {
    //     "title": "Boka 1-1 med Trista Zinn",
    //     "description": "2x30 minuters sessioner för 95 GBP.<br>För kvinnor som vill förbättra sin bäckens hälsa och kärn styrka",
    //     "buttonText": "Hitta en tid med Trista",
    //     "buttonAction":
    //         function () {
    //             return window.open('https://meet.brevo.com/abby-lord/abby-paid-coaching')
    //         },
    //     "open": false
    // },
    {
        "title": "Boka 1-1 med Fillipa Odevall",
        "description": "2x30 minuters sessioner för 95 GBP.För kvinnor som vill förbättra sin hållning och minska ryggsmärta",
        "buttonText": "Hitta en tid med Fillipa",
        "buttonAction":
            function () {
                return window.open('https://meet.brevo.com/filippa/1-1-paid')
            },
        "open": false
    }
]

const data = checkSwedish() ? data_sw : data_en

export default function SupportPage() {

    const [openi, setopeni] = useState(0)
    const activeUser = useSelector(state => state.register.userProfileData)

    function setOpen(index) {
        setopeni(index)
    }

    const saveUserToken = async (token, customerID) => {
        const bodyData = {
            token, customerID
        }

        httpPostRequest("/customer/save-pensil-token", bodyData, (err, res) => {
            if (err) {
                console.log("ERROR while login to pensil", err)
            } else {
                console.log("SUCCESS while login to pensil", res)
                window.open(`https://moonrisecircle.pensil.in?token=${token}`, '_blank')
                window.location.reload()
            }
        })

    }

    const handleCommunityClick = async () => {
        if (activeUser.pensilUserToken) {
            window.open(`https://moonrisecircle.pensil.in?token=${activeUser.pensilUserToken}`, '_blank')
            return;
        }
        // setActiveNav('community')
        let data = {
            "email": activeUser.email,
            "referenceIdInSource": JSON.stringify(activeUser.customerID),
            "createUser": true,
            "name": activeUser.firstName + " " + activeUser.lastName,
            "picture": 'https://womencycle-media.s3.amazonaws.com/iconMoonrise.jpg',
            "description": "Moonrise User"
        }
        let headerOptions = {
            headers: {
                'communityid': '64cb8065ae9f2535392b3555',
                'clientsecret': '64cb8065ae9f2535392b3555.c4aec5a509a1d420c12489f74799ce67',
                'clientid': '64cb8065ae9f2535392b3555.9e5494108d10bbd5f9e7ae52239546c4',
                'Content-Type': 'application/json',
                'access-control-allow-origin': '*',
            }
        }
        let response = await axios.post('https://api.pensil.in/api/3pc/generate-user-token', data, headerOptions)
        try {
            if (response.status == "200") {
                let userToken = response.data.user.token
                saveUserToken(userToken, activeUser.customerID)
            }
        }
        catch (err) {
            console.log("ERROR while login to pensil", response)
        }
    }

    const handleHelpDeskClick = () => {
        const url = checkSwedish() ? 'https://moonrise.crisp.help/sv/' : 'https://moonrise.crisp.help/en/';
        window.open(url, '_blank')
    }

    return (
        <div className={styles['support-container']}>
            <div className={styles['support-hero']}>
                <div className={styles['info']}>
                    <h3 className='mx-2'>{getTranslatedName('Regain your health with support from our trainers')}</h3>
                    <h6>{getTranslatedName('Here are three ways to feel supported')}</h6>
                </div>

                <div className={styles['community-banner']}>
                    <h4>{getTranslatedName('Ask in the community')}</h4>
                    <img src={'https://images.ctfassets.net/jw8fay82wbzf/3sbejr6SWJqWatc1vWw4Cj/cbf9c9d94fc29a350fc32663e85074cb/Group_Zoom_Call_1.png'} loading='lazy' alt="coaches" />
                </div>
                <div className={styles['btn-container']}>
                    <div className={styles['community-btn']}>
                        <button onClick={handleCommunityClick}>{getTranslatedName('Go to our community')}</button>
                    </div>
                </div>
            </div>

            <div className={styles['live-help']}>
                <h3>{getTranslatedName('Live Coaching Calls Tailored to You.')}</h3>
                <p>{getTranslatedName('Enjoy powerful coaching delivered to you')}</p>

                <img src={fillipaLpose} alt="l-pose" />

                <div className={styles['flexi-accordion-container']}>
                    {data.map((d, i) => (
                        <FlexiAccordion
                            key={i}
                            title={d.title}
                            description={d.description}
                            buttonText={d.buttonText}
                            buttonAction={d.buttonAction}
                            open={0}
                            setopenindex={setOpen}
                        />
                    ))}
                </div>
            </div>

            <div className={styles['help-desk']}>
                <h3>{getTranslatedName('Still need more help?')}</h3>
                <h6>{getTranslatedName('Chat with our world class support team')}</h6>

                <img src={'https://images.ctfassets.net/jw8fay82wbzf/4d0xFUlbBcdIznkaiB7ymg/102bda20b4fafc710ff01a87f6720191/Practiotioners__1_.png'} alt="help=desk" loading='lazy' className='mx-auto' />
                <div className={`${styles['btn-container']} my-3`}>
                    <button onClick={() => window?.$crisp.push(['do', 'chat:open'])} className={`${styles['chat-us-btn']} px-5 mb-0`}>{getTranslatedName('Chat with us')}</button>

                </div>


                <div className={`${styles['btn-container']} mb-3`}>
                    <div className={styles['help-btn']} onClick={handleHelpDeskClick}>
                        <button>{getTranslatedName('Read our helpdesk')} <FiChevronRight /></button>
                    </div>
                </div>

            </div>
        </div>
    )
}
