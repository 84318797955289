import React, { useEffect, useState } from "react"
import { useHistory, useLocation, Link } from "react-router-dom"
import base64 from "base-64"
import {
  handleAssignSubscriptionToCustomer,
  handleLibraryBundleAssignToCustomer,
  handleCustomerSubscriptionAssignLibrary
} from "../CoursePage/apiStore"
import { useSelector } from "react-redux"
import { isEmpty, isValid } from "config/functions"
import { httpPostRequest } from "config/services"
import { Button } from 'react-bootstrap'
import Loader from "views/common/Loader"
import { getMeetingDetailsByID } from "./apiStore"

const LiveClassPayment = () => {
  // Routes vars
  const history = useHistory()
  const { search } = useLocation()
  const [loading, setLoading] = useState(false)
  const query = new URLSearchParams(search)
  const [error, setError] = useState("")
  const [redirect, setRredirect] = useState(false)
  const [meetingUrl, setmeetingUrl] = useState("")
  const [meetingDetails, setmeetingDetails] = useState([])

  const session_id = query.get("session_id")
  const customerID = query.get("customerID")
  const livePlanID = query.get("planID")
  const priceID = query.get("priceID")
  const meetingID = query.get("meetingID")
  const amount = query.get("amount")
  const status = query.get("status")
  const couponId = query.get("couponId")

  const studentData = useSelector(state => state.register.userProfileData)

  useEffect(() => {
    if (meetingID) {
      let url = `/public/live-schedule/description/${meetingID}`
      setmeetingUrl(url)
    }
    if (meetingID && livePlanID) {
      handlePaymentSession()
    }
    if (redirect) {
      // return window.location.href = meetingUrl
      return history.push(meetingUrl)
    }

  }, [redirect, meetingUrl, meetingDetails])



  // FUNCTION TO HANDLE PAYMENT SESSION
  async function handlePaymentSession() {
    // Subscription payment OR Library payment
    if (status == "success" && customerID) {
      try {
        const bodyData = {
          id: base64.decode(meetingID),
          customerID: JSON.parse(customerID)
        }

        if (couponId) {
          bodyData.couponId = couponId
        }

        const apiRes = await getMeetingDetailsByID(bodyData)
        if (apiRes.status) {
          const meetingDetails = await apiRes.data
          // console.log({ meetingDetails: meetingDetails })
          const plan = {
            customerID: customerID,
            livePlanID: livePlanID,
            priceID: priceID,
            sessionID: session_id,
            amount: amount,
            meetingDetails: meetingDetails,
            planID: studentData.subscriptionDetails.planID
          }
          handleCustomerLiveClassPayment(plan)
        }

      } catch (error) {
        console.log(error)
      }


    } else if (status == "cancel") {
      setRredirect(true)
    } else {
      setRredirect(true)
    }
  }


  const handleCustomerLiveClassPayment = async plan => {
    setLoading(true)
    let planData = {
      ...plan,
    }

    if (couponId) {
      planData.couponId = couponId
    }
    httpPostRequest("/api/handle-customer-live-class-registration", planData, async (err, res) => {
      if (err) {
        setLoading(false)
        setError(err)
        setRredirect(true)
      } else {
        // redirect t to live class page..
        setRredirect(true)
        setLoading(false)
      }
    })
  }

  // return <div>{(!isEmpty(meetingUrl) && status) && <PaymentChild url={meetingUrl} status={status}></PaymentChild>}</div>
  return (
    <div>
      {loading && <Loader />}
    </div>
  )
}


export default LiveClassPayment
