import React, { useState } from 'react'
import Plate from 'assets/images/PlateProgress.svg'
import styles from './ftp.module.scss'
import AwesomeCofetti from './AwesomeCofetti'
import { useHistory } from 'react-router-dom'
import { encode } from 'base-64'
import FeedbackModal from './FeedbackModal'
import { getTranslatedName } from 'config/functions'
import AwesomeWorkModal from './AwesomeWorkModal'

const renderAwesome = (phaseGoal, library) => (
    <AwesomeCofetti phaseGoal={phaseGoal} library={library} />
)

export default function FtpReviewCard({ phaseGoal, library }) {
    const submitted = true
    const history = useHistory()
    const [showInfoModal, setShowInfoModal] = useState(false)


    const handleOpenCrisp = async () => {
        setShowInfoModal(false)
        window.$crisp.push(['do', 'chat:open'])
    }



    const handleCheckSubmission = () => {
        setShowInfoModal(false)
        history.push(`/student/goal/ftp-submit/${encode(phaseGoal?.id)}`)
    }

    return (
        <>
            <div className={styles['review-card-container']}>
                <div className={styles["review-card"]}>
                    <div className={styles["card"]}>
                        <div className={styles["card-body"]}>

                            <div className={styles['status']}>
                                <img src={Plate} alt="plate" />
                                <p>{getTranslatedName("WAITING FOR APPROVAL")}</p>
                            </div>

                            <div className="lesson-number">
                                <h3>{getTranslatedName("We are reviewing your technique")}</h3>

                            </div>

                            <h4 className='mb-3'>{getTranslatedName("24 hour turn around during weekdays")}</h4>

                            <div className={styles['btn-container']}>
                                <button onClick={() => setShowInfoModal(true)} className={styles['continue-btn']}>{getTranslatedName("What does this mean?")}</button>
                            </div>

                        </div>
                    </div>
                </div>



            </div>
            {
                showInfoModal && (
                    <FeedbackModal show={showInfoModal} handleClose={() => setShowInfoModal(false)} handleCheckSubmission={handleCheckSubmission} handleOpenCrisp={handleOpenCrisp} />
                )
            }
            {renderAwesome(phaseGoal, library)}
        </>
    )
}
