import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from '../registerStudentLayout/action';
import moment from 'moment';
import { isEmpty, isEmptyObj } from 'config/functions';
import { httpGetRequest, httpPostRequest } from 'config/services';
import { Button, Form } from 'react-bootstrap';
import FlashMessages from '../../views/common/FlashMessages';
import Footer from "../registerStudentLayout/footer/FooterNew"
import ShowExtendConfirmation from "./ShowExtendConfirmation";

export default function MemberShipPaused(props) {
    const userProfileData = useSelector((state) => state.register.userProfileData);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(userProfile())
    }, []);
    const [isShowExtended, setIsShowExtended] = useState(false);
    const [isShowConfirmationStatus, setIsShowConfirmationStatus] = useState(false);
    const [thanksStatus, setThanksStatus] = useState(false);


    const [error, setError] = useState("");
    const extendedData = [
        { label: 'Extend Pause for 2 more weeks', value: 14 },
        { label: 'Extend Pause for 4 more weeks', value: 28 }
    ];
    // const [extendValue, setExtendValue] = useState(
    //     new Array(extendedData.length).fill(false)
    // );
    const [extendValue, setExtendValue] = useState({});
    const handleChecked = (e, position) => {
        setExtendValue(extendedData[position]);
        if (e.target.checked) {
            setIsShowConfirmationStatus(true)
        }
    };
    const handleResume = () => {
        try {
            httpGetRequest('api/resumeMemberShip', {}, (error, result) => {
                if (!isEmpty(error)) {
                    setError(error.message);
                } else {
                    if (result.status) {
                        //props.history.push('/student/current-programme');
                        dispatch(userProfile())
                    } else {
                        setError(result.message);
                    }
                }
            });
        }
        catch (err) {
            console.log("err", err);
            setError("Network Error!");
        }
    }

    const extendPauseMemberShip = () => {
        try {
            httpPostRequest('api/subscriptionExtend', { noOfExtendDay: extendValue.value }, (error, result) => {
                if (!isEmpty(error)) {
                    setError(error.message);
                } else {
                    if (result.status) {
                        setIsShowConfirmationStatus(false);
                        setThanksStatus(true);
                    } else {
                        setError(result.message);
                    }
                }
            });
        }
        catch (err) {
            console.log("err", err);
            setError("Network Error!");
        }
    }

    // console.log({
    //     userProfileData: userProfileData
    // })
    return (
        <div className="page-inner">
            <div className="container-lg">
                <div className="row">
                    {error && (<div className="col-12 my-3">
                        <FlashMessages message={error} unmountMe={() => { setError('') }} />
                    </div>)}
                    {(!isEmptyObj(userProfileData) && !isEmpty(userProfileData.firstName) && !isEmpty(userProfileData.pauseStartDate) && !isEmpty(userProfileData.pauseEndDate)) && (<div className="col-12 member-ship-wrapper my-3 my-md-5 py-md-0">
                        <h2 className='d-flex align-items-center flex-wrap member-ship-h2'>
                            <span className="membership_paused_icon mr-2 d-flex align-items-center flex-wrap">
                                <span className="material-icons">pause</span>
                            </span>Billing Paused</h2>
                        <p className="my-2 text-dark">Dear {userProfileData.firstName}, you have paused your billing from {moment.utc(userProfileData.pauseStartDate).local().format('DD/MM/YYYY')} to {moment.utc(userProfileData.pauseEndDate).local().format('DD/MM/YYYY')}</p>

                        <p className='text-dark'>Please resume billing to access your account again. On resuming billing your normal billing cycles will be resumed.</p>

                        <div className='flex-fill'>
                            <button type="button" className="my-3 btn-theme btn-lg" onClick={() => { handleResume() }}>
                                Resume Billing
                            </button>
                        </div>
                        {(!userProfileData.isExtend) && (<div className='flex-fill'>
                            <Button type="button" variant="default" className="pl-0 mb-3" onClick={() => { setIsShowExtended(!isShowExtended) }}>
                                Extend Pause
                            </Button>
                        </div>)}

                        {(isShowExtended) && (<div className="mb-2">
                            {extendedData.map((item, index) => (
                             
                                <Form.Group className="mb-2" controlId={item} key={`extendedData_${index}`}>
                                    <Form.Check
                                        type="radio"
                                        label={item.label}
                                        value={item.value}
                                        checked={(extendValue.value == item.value)}
                                        onChange={(e) => handleChecked(e, index)}
                                    />
                                </Form.Group>
                            ))}
                        </div>)}
                        <span className="">Note: Failure to resume billing within 4 weeks will lead to account cancellatIon and you might loose your account progress and data.</span>
                    </div>)}
                </div>
            </div>
            {(isShowConfirmationStatus) && (
                <ShowExtendConfirmation
                    show={isShowConfirmationStatus}
                    handleClose={() => { setIsShowConfirmationStatus(false) }}
                    handleNext={() => { extendPauseMemberShip() }}
                    type={1}
                    data={extendValue.label}
                />
            )}
            {(thanksStatus) && (
                <ShowExtendConfirmation
                    show={thanksStatus}
                    handleClose={() => { setThanksStatus(false); setIsShowExtended(false); setExtendValue({}); }}
                    handleSubmit={() => { dispatch(userProfile()); setThanksStatus(false); setIsShowExtended(false); setExtendValue({}); }}
                    type={2}
                    data={extendValue.label}
                />
            )}
        </div>
    )
}