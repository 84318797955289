import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FaFacebookSquare, FaInstagram, FaYoutube } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import Support from "../../pages/DashboardNew/PopUps/Support";
import moment from "moment";
import chatIcon from "../../../assets/images/icons/chat.svg"
import flag1 from "../../../assets/images/UnitedStates.svg"
import flag2 from "../../../assets/images/Canada.svg"
import flag3 from "../../../assets/images/Sweden.svg"
import flag4 from "../../../assets/images/UnitedKingdom.svg"

import OutofOrder from "views/pages/DashboardNew/PopUps/OutofOrder";
import { isStudentLoggedIn, isEmpty, getTranslatedName } from "config/functions";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import LoomIntegration from "views/common/Loom";

export default function Footer(props) {
  const [support, setSupport] = useState(false);
  const userProfileData = useSelector((state) => state.register.userProfileData)
  const userPlan = userProfileData?.subscriptionDetails;
  const [swedish, setSwedish] = useState()
  const history = useHistory()

  useEffect(() => {
    if (userPlan && userPlan.planID === 4) {
      setSwedish(true)
    }
    if (history.location.pathname.includes('/sw')) {
      setSwedish(true)
    }
  }, [userPlan])
  let planID = !isEmpty(props.userInfo) ? !isEmpty(props.userInfo.subscriptionDetails) ? props.userInfo.subscriptionDetails.planID : 1 : 1
  // console.log({planID})

  if (!isEmpty(localStorage.getItem('planID'))) {
    planID = localStorage.getItem('planID')
    planID = Number(planID)
  } else {
    planID = !isEmpty(props.userInfo) ? !isEmpty(props.userInfo.subscriptionDetails) ? props.userInfo.subscriptionDetails.planID : 1 : 1
  }
  const isLinkActive = useSelector(
    (state) => state.register.header.isLinkActive
  );
  let currentPageName = window.location.pathname;
  let showFooter = true;
  if (currentPageName == "/student/chat") {
    showFooter = false;
  }
  const handleContact = () => {
    setSupport(true);
  };
  const handleClose = () => {
    setSupport(false);
  };

  const handleContactUsclick = () => {
    if (isStudentLoggedIn() === true) {
      handleContact();
    } else {
      window.open("https://moonrise.health/contact-us", "_blank");
    }
  };
  return (
    <>
      {showFooter && (
        <footer id="footer-sec" className="footer-style ">
          <div className={` ${isLinkActive && "not-active"}`}>
            <div className="row m-0 ">
              <div className="col-12 p-0">
                <div className=" text-footer row justify-content-center ">
                  <div className="ipad-col text-footer col-md-6 col-12 d-flex  justify-content-center justify-content-md-start pb-md-4 pb-2 ">
                    <div className="footer-heading"> {swedish || planID === 4 || planID === 5 ?
                      <div className="text-md-left text-center">MoonRise: Digital<br />egenvård för kvinnor</div> :
                      <div className="text-md-left text-center">MoonRise: Digital<br />Self-Healthcare for Women</div>}
                    </div>
                  </div>

                  <div className="ipad-col d-flex flex-column col-md-6 col-12 align-items-md-end justify-content-center justify-content-md-start">
                    <div className="d-flex flex-column justify-content-center align-items-center align-items-md-start ps-1">
                      <div className="text-md-left text-center">
                        <a
                          href="https://www.facebook.com/WeAreMoonRise"
                          target="_blank"
                          className="px-1 links"
                          rel="noreferrer"
                        >
                          <FaFacebookSquare color="#b45381" />
                        </a>
                        <a
                          href="https://www.instagram.com/moonrise_hq/"
                          target="_blank"
                          className="px-1 links"
                          rel="noreferrer"
                        >
                          <FaInstagram color="#b45381" />
                        </a>
                        <a
                          href="https://www.youtube.com/@moonrisefilippa_en"
                          target="_blank"
                          className="px-1 links"
                          rel="noreferrer"
                        >
                          <IoLogoYoutube color="#b45381" />
                        </a>
                      </div>
                      <div>
                        <Button className="chat-button mx-md-1" outline onClick={() => window.$crisp.push(['do', 'chat:open'])}><img src={chatIcon} alt="chat-icon" className="me-1" />{getTranslatedName('Chat with us')}</Button>
                      </div>
                      {/* <p className="text-footer-contact">{swedish || planID === 4 || planID == 5 ? "Vi älskar att prata ring oss" : "We love talking, call us!"}</p>
                      {planID === 4 || swedish || planID == 5 ?
                        <> <span className=" ms-0"><img src={flag3} height={18} width={25} /> <span className="contact-link my-25"> +46 853 523 523</span></span></>
                        :
                        <><div className="d-flex flex-column align-items-start">
                          <span><img src={flag1} height={18} width={25} /> <span className="contact-link mt-25"> +1 707 851 6229</span></span>
                          <span className=" ms-0"><img src={flag2} height={18} width={25} /> <span className="contact-link my-25"> +1 807 808 3549</span></span>
                        </div>
                          <div className="d-flex flex-column align-items-start ms-2 ms-md-0">
                            <span><img src={flag4} height={18} width={25} /> <span className="contact-link my-25"> +44 160 427 9509</span></span>


                          </div>
                          </>} */}
                    </div>

                  </div>

                </div>

              </div>




              <hr className="hr-border d-inline my-1 px-md-2" />

              <div className=" col-12 px-0 pt-2">
                <div className="copyright-text text-center w-100">
                  Copyright © {moment().year()} WomenCycles AB. All Rights
                  Reserved.
                </div>
              </div>
            </div>
          </div>
          {support && <Support isOpen={support} handleClose={handleClose} />}
          {/* {/ {support && <OutofOrder isOpen={support} handleClose={handleClose}/>} /} */}

          {/* <div className="position-fixed bottom-0 left-0 loom-z-index ">
            <LoomIntegration />

          </div> */}
        </footer>
      )}
    </>
  );
}

