import { put, takeLatest, fork, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { getCookie } from 'config/functions'
function* getUserProfileWatcher() {
  yield takeEvery('USER_PROFILE', getUserProfile)
}
function* getUserProfile() {
  try {
    let res = yield axios.get('api/get_userProfile')
    let data = yield res.data.data
    // console.log(data)
    yield put({ type: 'GET_USER_PROFILE', payload: data })
    // console.log(data)
    // await setFeatured_courses_data([...featured_courses_data, ...data])
    // await console.log(data)
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}
function* getUserJournal() {
  // console.log('watcher')
  yield takeLatest('GET_USER_JOURNAL', getCreatorProfileById)
}
function* getCreatorProfileById() {
  try {
    let res = yield axios.get('api/get_user_journal')
    let data = yield res.data.data
    yield put({ type: 'GET_USER_ACTION', payload: data })
    // console.log(data)
    // await setFeatured_courses_data([...featured_courses_data, ...data])
    // await console.log(data)
  } catch (err) {
    // throw(err)
    alert(err)
  }
}
function* saveCourseWatcher() {
  yield takeLatest('SAVE_MY_COURSE', saveCourse)
}
function* saveCourse(payload) {
  const courseID = payload.payload[0]
  const isUnsave = payload.payload[1]
  // console.log(courseID, isUnsave)
  // const {courseID} = payload
  try {
    // console.log(courseID)
    let res = yield axios.post('api/post_save_Course', { courseID, isUnsave })
    let data = yield res.data
    // console.log(data)
    yield put({ type: 'SAVED_COURSE', payload: data })
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}
function* categoryWatcher() {
  yield takeEvery('CATEGORYWISE_COURSES', caregorywiseCourses)
}
function* caregorywiseCourses(payload) {
  const { categoryID } = payload
  try {
    // console.log(courseID)
    let res = yield axios.post('api/getcourses_byCategoryID_loggedUser', { categoryID })
    let data = yield res.data.data
    // yield console.log(data)
    yield put({ type: 'GET_CATOGERYWISE_COURSES', payload: data })
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}
function* symptomWatcher() {
  yield takeLatest('SYMPTOM_WISE_COURSES', symptomwiseCourses)
}
function* symptomwiseCourses(payload) {
  const { symptomID } = payload
  try {
    // console.log(courseID)
    let res = yield axios.post('api/getcourses_bySymptomID_loggedUser', { symptomID })
    let data = yield res.data.data
    // yield console.log(data)
    yield put({ type: 'GET_SYMPTOMWISE_COURSES', payload: data })
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}
// COURSE_DETAIL
function* courseDetailWatcher() {
  yield takeLatest('COURSE_DETAIL', courseDetail)
}
function* courseDetail(payload) {
  const { courseID } = payload
  try {
    // console.log(courseID)
    let res = yield axios.post('api/get_course_detail', { courseID })
    let data = yield res.data.data
    yield put({ type: 'GET_COURSE_DETAIL', payload: { loading: false, data: data } })
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}
function* groupWiseCoursesWatcher() {
  yield takeEvery('GROUPWISE_COURSES_LOGGEDIN', getGroupWiseCourses)
}
function* getGroupWiseCourses() {
  try {
    let res = yield axios.get('api/get_groupwise_courses_loggedInUser')
    let data = yield res.data.data
    // console.log(data)
    yield put({ type: 'GET_GROUPWISE_COURSES_LOGGEDIN', payload: data })
    // console.log(data)
    // await setFeatured_courses_data([...featured_courses_data, ...data])
    // await console.log(data)
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}
function* addActionWatcher() {
  yield takeLatest('ADD_ACTION', addAction)
}
function* addAction(payload) {
  const { courseID } = payload
  // const isUnsave = payload.payload[1]
  try {
    // console.log(courseID)
    let res = yield axios.post('api/add_actionitems', { courseID })
    let data = yield res.data
    // yield console.log(data)
    yield put({ type: 'ADDED_ACTION', payload: data })
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}
function* userProfileWatcher() {
  yield takeEvery('USER_PROFILE_DATA', userProfile)
}
function* userProfile() {
  let authToken = getCookie("token");
  try {
    let res = yield axios.get('api/getCustomerProfile', {
      headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
        authorization: authToken,
      }
    })

    let data = yield res.data.data
    // console.log(data)
    yield put({ type: 'GET_USER_PROFILE_DATA', payload: data })
    // console.log(data)
    // await setFeatured_courses_data([...featured_courses_data, ...data])
    // await console.log(data)
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}

function* suscriptionPlanWatcher() {
  yield takeEvery('SUBSCRIPTION_PLAN', subscriptionPlan)
}
function* subscriptionPlan(payload) {
  const { planID } = payload
  try {
    // console.log(planID)
    let res = yield axios.post('api/getPlanDetails', { planID })
    let result = yield res.data
    if (result.status) {
      // yield console.log(data)
      yield put({ type: 'GET_PLAN', payload: result.data })
    } else {
      alert('something is wrong')
    }
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}

export default [fork(getUserProfileWatcher), fork(saveCourseWatcher), fork(categoryWatcher), fork(symptomWatcher), fork(getUserJournal), fork(courseDetailWatcher), fork(groupWiseCoursesWatcher), fork(addActionWatcher), fork(userProfileWatcher), fork(suscriptionPlanWatcher)]
