import { all } from "redux-saga/effects";
import non_register from "./views/publicLayout/saga"
import register from './views/registerStudentLayout/saga'
import admin from './views/adminLayout/saga'
// import LoginSagas from "./auth/sagas";
// import BlogSagas from "./blog/sagas";
// import TeamSagas from "./profile/sagas";
// import TournamentSagas from "./tournaments/sagas";
// import CommonSagas from "common/sagas";

const sagasToListenTo = [
  ...non_register,
  ...register,
  ...admin,
//   ...authSaga
  // ...LoginSagas,
  // ...BlogSagas,
  // ...TeamSagas,
  // ...TournamentSagas,
  // ...CommonSagas,
];

export default function* mainSaga() {
  yield all(sagasToListenTo);
}
