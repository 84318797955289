import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Card } from 'react-bootstrap';
import { isEmpty, isEmptyObj } from 'config/functions';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import cardLogo from '../../../../assets/images/Cards.png';
import lockIcon from '../../../../assets/images/lockIcon.svg';
import PayPal from '../../../../assets/images/PayPal.png';
import FlashMessages from '../../../../views/common/FlashMessages';
import { httpPostRequest } from "config/services";

export default function StripeForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const [stripeStatus, setStripeStatus] = useState({ complete: false, cardError: '' });
    const [disable, setDisable] = useState(true);
    const [error, setError] = useState("");
    const [countryValue, setcountryValue] = useState("");
    const { cardInfo, country } = props;
    const CardElementOptions = {
        style: {
            base: {

            },
            invalid: {

            },
            complete: {

            }
        },
        hidePostalCode: true,
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!stripe || !elements) {
                return;
            }
            await setDisable(true);
            const card = elements.getElement(CardElement);
            const result = await stripe.createToken(card,{ address_country : countryValue });
            if (!result.error) {
                stripeTokenHandler(result.token);
            }else{
                setError(result.error.message);
                setDisable(false);
            }
        } catch (err) {
            setError("Network Error!");
            setDisable(false);
            //console.log("err", err);
        }
    };

    const stripeTokenHandler = async (token) => {
        const paymentData = { token: token.id };
        let sendData = {};
        if(props.apiName == 'updatestripecard'){
            if (props.STCID) {
                sendData.stripeCustomerID = props.STCID;
            }
            if (paymentData && paymentData.token) {
                sendData.token = paymentData.token;
            }
            if (cardInfo && cardInfo.id) {
                sendData.oldCardID = cardInfo.id;
            }
        }else{
            if (cardInfo && cardInfo.email) {
                sendData.email = cardInfo.email;
            }
            if (paymentData && paymentData.token) {
                sendData.token = paymentData.token;
            }
        }
        try {
            httpPostRequest(`api/${props.apiName}`, sendData, (error, result) => {
                if (!isEmpty(error)) {
                    setDisable(false);
                    setError(error);
                } else {
                    if (result.status) {
                        props.handleClose();
                        props.setPaymentMethodStatus({ show: true, type: 'success', msg: result.message })
                        props.setIsLoading(true);
                        props.getBillingInfo();
                    } else {
                        setError(result.message);
                    }
                    setDisable(false);
                }
            });
        } catch (err) {
            setDisable(false);
            setError("Network Error!");
        }
    }

    const handleFlashUnmount = () => {
        setError("");
    };
    return (
        <Form onSubmit={handleSubmit}>
            <Card className="PaymentMethodCard edit-card-details">
                <Card.Body>
                    {error && (
                        <FlashMessages
                            message={error}
                            unmountMe={handleFlashUnmount}
                        />
                    )}
                    <div className="row align-items-center">
                        <div className="col">
                            <label className="form-check-label radio-container credit-card-radio pl-0">
                                {/* <input type="radio" className="form-check-input" name="" value="" /> <span className='radio-checkmark'></span> */}
                                <h6 className='text-left'>Credit Card</h6>
                            </label>
                        </div >
                        <div className="col-auto">
                            <img src={cardLogo} className="cardLogoIcon" alt="" />
                        </div>
                    </div>
                    <div className="inputcard mb-1">
                        <CardElement
                            onChange={(data) => {setStripeStatus({ ...stripeStatus, complete: data["complete"], cardError: data['error'] }); setDisable(!(!isEmpty(countryValue) ) && data["complete"]) }}
                            options={CardElementOptions}
                        />
                    </div>
                    {!isEmpty(stripeStatus.cardError) ? <span className="errormsg text-danger">{stripeStatus.cardError.message}</span> : ''}

                    <div className='row mt-2'>
                        <div className='col-12'>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Country</Form.Label>
                                <select
                                    name="country"
                                    
                                    className="form-control"
                                    onChange={ (e) => { setcountryValue(e.target.value); setDisable(!(stripeStatus && stripeStatus.complete && !isEmpty(e.target.value))) }}
                                >
                                    <option label="Type country"></option>
                                    {country.map((value, key) => (
                                        <option key={key} value={value.country_code}  >
                                        {value.country_name}
                                        </option>
                                    ))}
                                </select>
                            </Form.Group>
                        </div>

                    </div>
                    <div className='d-flex align-items-center payment-title'>
                        <div className='pr-2'>
                            <img src={lockIcon} alt="Lock Icon" />
                        </div>
                        <div>
                            We protect your payment information using encryption to provide bank-level security.
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {/* <Card className="PaymentMethodCard mt-3">
                <Card.Body>
                    <div className="row align-items-center">
                        <div className="col">
                            <label className="form-check-label radio-container credit-card-radio">
                                <input type="radio" className="form-check-input" name="" value="" />
                                <span className='radio-checkmark'></span>
                                <h6 className='text-left'>Credit Card</h6>
                                <p>You will be redirected to the PayPal website after submitting your order</p>
                            </label >
                        </div >
                        <div className="col-auto">
                            <img src={PayPal} className="cardLogoIcon" alt="" />
                        </div>
                    </div >
                </Card.Body>
            </Card> */}
            <div className='mt-3 mb-3 text-right'>
                <Button type="submit" variant="success" className={!disable ? " btn-whiteTheme stripe-btn" : " btn-whiteTheme stripe-btn btndeactive"} disabled={disable}> UPDATE</Button>
            </div>
        </Form >
    )
}