import React, { useState, useEffect } from "react"
import { Button, Alert } from "react-bootstrap"
import { Link } from "react-router-dom"
import { saveCookie, encodeId, isEmpty, getCookie, removeCookie } from "config/functions"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import { useFormik } from "formik"
import * as Yup from "yup"
import Moonrise from "../../../assets/images/MOONRISE_White.svg"
import { getIpInfo } from "views/publicLayout/action"
import HeaderMeta from "views/common/HeaderMeta"
import FlashMessages from "views/common/FlashMessages"
import Header from "views/publicLayout/header/Header"
import { Mixpanel } from "../../common/Mixpanel"
import moment from "moment"
import { SYMPTOM_TRACK_AFTER_DAY } from "../../../config/constants"
import { httpGetRequest } from "../../../config/services"
import { isMobile, browserName } from "react-device-detect"
import ExpiredPopup from "./popup/ShowSubscriptionExpirePopup"
import MagicModal from "./MagicModal"
import UpdateFormPopup from "../billing/popup/UpdateFormPopup"
import API from "../../../api/api"
import PasswordPrompt from "./popup/PasswordPrompt"
import { useLocation } from "react-router-dom"
import { put } from "redux-saga/effects"
import base64 from "base-64"
const WAPI = new API()
export default function StudentLogin(props) {
  const [show, setShow] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showExpired, setShowExpired] = useState(false)
  const [showUpdateFormPopup, setShowUpdateFormPopup] = useState(false)
  const [countries, setCountries] = useState([])
  const [tempData, setTempData] = useState({})
  const [paymentMethodStatus, setPaymentMethodStatus] = useState({
    show: false,
    type: "danger",
    msg: ""
  })


  // Routes vars
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const logoutType = query.get("logoutType");

  let customErrorMessage = ''
  if (!isEmpty(logoutType) && logoutType == 'force') {
    customErrorMessage = 'You have been logged out due to an issue with your account. Contact our support team for further clarifications'
  }

  const handleShow = () => setShow(!show)
  const [steps, setSteps] = useState("1")

  const dispatch = useDispatch()
  const [ErrorMessage, setErrorMessage] = useState(!isEmpty(customErrorMessage) ? customErrorMessage : "")
  const [customerId, setCustomerId] = useState("")
  const pathname = props.location.pathname;
  const pathHistory = localStorage.getItem('pathhistory')

  const ipInfoData = useSelector(state => state.non_register.getIpInfo)
  useEffect(() => {
    if (pathname !== pathname.toLowerCase()) {
      props.history.replace(pathname.toLocaleLowerCase())
    }
  }, [pathname])



  useEffect(() => {
    dispatch({
      type: "HANDLE_HEADER",
      payload: { activeNav: "", showJoinBtn: true, isSubscriptionPage: false }
    })
    isEmpty(ipInfoData) && dispatch(getIpInfo())
  }, [])

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is a required Field"),
      password: Yup.string()
        .min(6, "Minimum 6 characters")
        .required("Password is a required Field")
    }),
    onSubmit: (values, { resetForm, setSubmitting }) => {
      let deviceName = isMobile ? "Mobile" : "Desktop"
      let watchedDevice = deviceName + "/" + browserName
      // values.countryData = ipInfoData.countryID
      values.watchDevice = watchedDevice
      handleSubmit(values)
    }
  })

  //If user expired register in app.moonrise.health and make user login there
  const registerExpiredUser = async (user, password) => {
    let response = await WAPI.registerUserInAppMoonrise(user.data, password)
    if (response.status) {
      loginExpiredUser(user.data.email, password)
    } else if (!response.status && response.message == "Email Address Already Exists!") {
      let response = await WAPI.updateUserInAppMoonrise(user.data.email, password)
      if (response.status) {
        loginExpiredUser(user.data.email, password)
      } else {
        setErrorMessage(response.message)
      }
    } else {
      setErrorMessage(response.message)
    }
  }

  const getUserRedirectUrl = (studentData) => {
    try {
      const userAgent = navigator.userAgent;
      if (studentData?.showPhases != 1){
        return "/student/dashboard"
      } else if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(userAgent)) {
        return "/student/my-schedule";
      } else if (/Macintosh|Windows|Linux|Ubuntu/.test(userAgent)) {
        return "/student/dashboard";
      } else {
        return "/student/dashboard";
      }
    } catch (error) {
      return "/student/dashboard";
    }

  }

  //After registration make user login in app.moonrise.health
  const loginExpiredUser = async (email, password) => {
    let response = await WAPI.logInAppMoonrise(email, password)
    //process.exit(true);
    if (response.status) {
      await saveCookie("token", response.data.token)
      await saveCookie("from_panel", "new.moonrise")
      window.location.replace(process.env.REACT_APP_MOONRISE_BASE_URL + "/student/home")
    } else {
      setErrorMessage(response.message)
    }
  }

  const handleSubmit = async values => {
    axios({
      method: "post",
      url: "api/login",
      data: values
    })
      .then(async result => {
        let resultObj = result.data

        if (!resultObj.status) {
          if (resultObj.planExpired) {
            registerExpiredUser(resultObj, values.password)
          } else if (resultObj.isExpired) {
            setShowExpired(true)
            if (resultObj.data) {
              setTempData(resultObj.data)
            }
          } else {
            setErrorMessage(resultObj.message)
          }
        } else {
          let user = resultObj.data

          let userEmail = user?.profile?.email;
          let firstName = user?.profile?.firstName || "";
          let lastName = user?.profile?.lastName || "";

          const fullName = `${firstName} ${lastName}`;
          const crispSignedToken = user?.profile?.crispSignedToken;
          if (window.$crisp) {
            if (crispSignedToken) {
              window.$crisp.push(["set", "user:email", [userEmail, crispSignedToken]]);
              window.$crisp.push(["set", "user:nickname", fullName]);
            } else {
              window.$crisp.push(["set", "user:email", userEmail]);
              window.$crisp.push(["set", "user:nickname", fullName]);
            }

          }

          await saveCookie("token", user.token)
          await Mixpanel.identify(values.email)

          if (user.profile.isActiveSubscrion) {
            if (!user.profile.isWaiverSign) {
              let planID = result.data.data
                ? result.data.data.profile.subscriptionDetails
                  ? result.data.data.profile.subscriptionDetails.planID
                  : ""
                : ""

              if (planID) {
                window.localStorage.setItem("planID", planID)
              }

              props.history.push({ pathname: "/student/waiver-step-1" })
            } else {
              let trackerInfo1 = result.data.data.trackInfo1
              let trackInfo2 = result.data.data.trackInfo2
              let customerHasPackage = result.data.data.customerHasPackage
              let packageItemId = encodeId("1")
              let planID = result.data.data
                ? result.data.data.profile.subscriptionDetails
                  ? result.data.data.profile.subscriptionDetails.planID
                  : ""
                : ""
              console.log({ planID })

              if (planID) {
                window.localStorage.setItem("planID", planID)
              }

              if (customerHasPackage) {
                if (trackerInfo1) {
                  if (trackerInfo1.question3Score) {
                    let lastUpdatedDate = trackInfo2 ? trackInfo2.createdOn : trackerInfo1.createdOn
                    let utcMoment = moment.utc()
                    let lastUpdateDate = moment(lastUpdatedDate).format("MM/DD/YYYY")
                    let currentDate = utcMoment.format("MM/DD/YYYY")
                    let dateDiff = moment(currentDate).diff(moment(lastUpdateDate), "days")
                    if (dateDiff > SYMPTOM_TRACK_AFTER_DAY + 1) {
                      let lastPath = localStorage.getItem("redirectPath")
                      if (lastPath) {
                        lastPath = base64.decode(lastPath)
                        props.history.push(lastPath)
                        localStorage.removeItem("redirectPath")
                      } else {
                        props.history.push(getUserRedirectUrl(user.profile))
                        localStorage.setItem("Current Tab", "dashboard")
                      }
                    } else {
                      let lastPath = localStorage.getItem("redirectPath")
                      if (lastPath) {
                        lastPath = base64.decode(lastPath)
                        props.history.push(lastPath)
                        localStorage.removeItem("redirectPath")
                      } else {
                        props.history.push({
                          pathname: getUserRedirectUrl(user.profile)
                        })
                      }
                      localStorage.setItem("Current Tab", "dashboard")

                    }
                  }
                } else {
                  let lastPath = localStorage.getItem("redirectPath")
                  if (lastPath) {
                    lastPath = base64.decode(lastPath)
                    props.history.push(lastPath)
                    localStorage.removeItem("redirectPath")
                  } else {
                    props.history.push({ pathname: getUserRedirectUrl(user.profile) })
                    localStorage.setItem("Current Tab", "dashboard")
                  }
                }
              } else {
                if (pathHistory.includes("/student/live-schedule") || pathHistory.includes("/public/live-schedule")) {
                  let lastPath = localStorage.getItem("redirectPath")
                  if (lastPath) {
                    lastPath = base64.decode(lastPath)
                    props.history.push(lastPath)
                    localStorage.removeItem("redirectPath")
                  } else {

                    props.history.push({ pathname: "/public/live-schedule" })
                    localStorage.setItem("Current Tab", "Live")
                  }
                }
                else {
                  let lastPath = localStorage.getItem("redirectPath")
                  if (lastPath) {
                    lastPath = base64.decode(lastPath)
                    props.history.push(lastPath)
                    localStorage.removeItem("redirectPath")
                  } else {

                    props.history.push({ pathname: getUserRedirectUrl(user.profile) })
                    localStorage.setItem("Current Tab", "dashboard")
                  }
                }

              }
            }
          } else {
            let lastPath = localStorage.getItem("redirectPath")
            if (lastPath) {
              props.history.push(lastPath)
              localStorage.removeItem("redirectPath")
            } else {

              await props.history.push({
                pathname: getUserRedirectUrl(user.profile)
              })
            }
          }
        }
      })
      .catch(error => {
        console.log(error)
        setErrorMessage("Internal server error please try after some time")
      })
  }

  const handleFlashUnmount = () => {
    setErrorMessage("")
    formik.resetForm()
    props.history.push({
      pathname: "/login"
    })
    // setloading(false)
  }

  const handleNextStep = step => {
    setSteps(step)
  }

  const handleExpiredClosed = () => {
    setShowExpired(false)
  }

  const showUpdateMethodForm = () => {
    try {
      httpGetRequest("api/getAllCountry", {}, (error, result) => {
        if (!isEmpty(error)) {
          //setIsLoading(false);
        } else {
          if (result.status) {
            let response = result.data
            setCountries(response)
            setShowUpdateFormPopup(true)
          }
        }
      })
    } catch (err) {
      console.log("err", err)
    }
  }

  return (
    <React.Fragment>
      {/* <PasswordPrompt /> */}
      <div className="artist-section student-login  customer-wrapper-css pt-32 pb-32">
        <Header {...props} />
        <div className="customer-logincss ">
          <HeaderMeta title="Student Login" />
          {/* <section id='' className=''> */}
          <div className="container pb-md-5 pb-2">
            <div className="login-container d-flex flex-wrap justify-content-center align-items-center row">
              <div className="col-12">
                <div className="row justify-content-md-center">
                  <div className="col-12">
                    <div className="page-Logo mt-0 ">
                      {" "}
                      <img src={Moonrise} alt="Moonrise" className="logo" />
                    </div>
                  </div>
                  <div className="col-12 mb-5-no col-md-7 col-lg-8 p-0">
                    <div className="card card-style">
                      <div className="card-body">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <div className="contant-wraper">
                                <h2 className="mb-3 block-title">Welcome back</h2>
                                <div className="form-grp">
                                  {ErrorMessage && (
                                    <FlashMessages
                                      type={"error"}
                                      message={ErrorMessage}
                                      unmountMe={handleFlashUnmount}
                                    />
                                  )}
                                </div>

                                {paymentMethodStatus && paymentMethodStatus.show && (
                                  <div className="billingAlertDanger">
                                    <Alert
                                      variant={paymentMethodStatus.type}
                                      show={!showAlert}
                                      onClose={() => setShowAlert(!showAlert)}
                                      dismissible
                                    >
                                      <div className="d-flex ">
                                        <div className="">
                                          {paymentMethodStatus.type == "danger" ? (
                                            <span className="material-icons ">error_outline</span>
                                          ) : (
                                            <span className="material-icons">check_circle</span>
                                          )}
                                        </div>
                                        <div>
                                          <p className="mx-2">{paymentMethodStatus.msg}</p>
                                        </div>
                                      </div>
                                    </Alert>
                                  </div>
                                )}

                                <div className="form-box">
                                  <div className="form-group">
                                    <label className="label">Email</label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Email"
                                      name="email"
                                      value={formik.values.email}
                                      onChange={formik.handleChange}
                                      autoFocus
                                    />
                                    {formik.errors.email && formik.touched.email && (
                                      <p style={{ color: "red" }}>{formik.errors.email}</p>
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <label className="label">Password</label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder="Password"
                                      name="password"
                                      value={formik.values.password}
                                      onChange={formik.handleChange}
                                    />
                                    {formik.errors.password && formik.touched.password && (
                                      <p style={{ color: "red" }}>{formik.errors.password}</p>
                                    )}
                                  </div>
                                  <div className="row justify-content-md-center align-items-center ">
                                    <div className="col-lg-7 col-sm-12 col-md-5">
                                      {/* <Form.Group controlId="formBasicCheckbox">
                                                                            <Form.Check type="checkbox" label="Remember me" />
                                                                        </Form.Group> */}
                                      <div className="mb-2 forget-link">
                                        <Link
                                          to="/forgot-password"
                                          className="forget-link"
                                          onClick={() =>
                                            dispatch({
                                              type: "SET_EMAIL",
                                              payload: formik.values.email
                                            })
                                          }
                                        >
                                          Forgot Password
                                        </Link>
                                      </div>
                                    </div>
                                    <div className=" col-sm-12 col-lg-5 col-md-7 btn-SignIn">
                                      <Button variant="" type="submit" className="btn-whiteTheme btn-lg">
                                        Sign In
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="form-group login-group"></div>
                                  <div className="row">
                                    {/*<div className='col-6' ><Link to="/membership" className="forget-link float-right">Sign up</Link></div>*/}

                                    <Button variant="primary" className="d-none" onClick={handleShow}>
                                      Popup
                                    </Button>

                                    <MagicModal
                                      show={show}
                                      magicStep={steps}
                                      handleNextStep={handleNextStep}
                                      handleShow={handleShow}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>

                        {/* <button type="button" onClick={()=> setShowExpired(true) } className="PauseAccountBtn">
                                                Error button
                                            </button> */}
                        <ExpiredPopup
                          show={showExpired}
                          data={tempData}
                          handleShowUpdateForm={() => showUpdateMethodForm()}
                          handleClose={handleExpiredClosed}
                          {...props}
                        />
                        {showUpdateFormPopup && (
                          <UpdateFormPopup
                            show={showUpdateFormPopup}
                            handleClose={() => {
                              setShowUpdateFormPopup(false)
                            }}
                            country={countries}
                            cardInfo={tempData}
                            auth={false}
                            setPaymentMethodStatus={setPaymentMethodStatus}
                            {...props}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </section> */}
          </div>
          {/*<Footer {...props} />*/}
        </div>
      </div>
    </React.Fragment>
  )
}
