/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Tab, Nav, Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import slugify from "react-slugify"
import { getCookie, isEmpty, removeCookie, encodeId, decodeId } from "../../config/functions"
import {
  getFeaturedCourses,
  getTestimonial,
  getPlanList,
  getCategoriesSymptoms,
  getAllCreators,
  getSymptomList,
  checkSymptomTrackId,
  getSelectedSymptomList
} from "../publicLayout/action"
import HeaderMeta from "../common/HeaderMeta"
import PageLoader from "../common/Loader"
import Select from "react-select"
import FlashMessages from "views/common/FlashMessages"
import axios from "axios"
import { decode, encode } from "base-64"

export default function Symptomstracker2(props) {
  const { swedish } = props
  const params = useParams()
  let trackerId = props.newTrackId ? props.newTrackId : "0"
  const [trackerInfoId, setTrackerInfoId] = useState(trackerId)
  const [selectedOption, setSelectedOption] = useState([])
  const [selectedSymptoms, setSelectedSymptoms] = useState([])
  const [flashMessage, setFlashMessage] = useState("")
  const [messageType, setMessageType] = useState("error")
  const customStyles = {
    control: (base, state) => ({
      ...base
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    multiValueLabel: base => ({
      ...base,
      // kill the white space on first and last option
      padding: "5px 8px",
      backgroundColor: "#7F9689",
      color: "#fff",
      fontSize: "15px"
    })
  }

  const dispatch = useDispatch()
  const [dataLoaded, setDataLoaded] = useState("")
  const [showAddSymptomError, setshowAddSymptomError] = useState("")
  const [showChoosedSymptom, setshowChoosedSymptom] = useState(false)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const userProfileData = useSelector(state => state.register.userProfileData)
  const featuredCourses = useSelector(state => state.non_register.feturedCourses)
  const testimonials = useSelector(state => state.non_register.testmonials)
  const creators = useSelector(state => state.non_register.getAllCreators)
  const planList = useSelector(state => state.non_register.planList)
  const categoriesSymptoms = useSelector(state => state.non_register.categoriesSymptoms)
  const activeUser = useSelector(state => state.register.userProfileData.isActiveSubscrion)
  const featuredCoursesData = featuredCourses.data
  const featuredCoursesLoading = featuredCourses.loading
  const testimonialData = testimonials.data
  const testimonialLoading = testimonials.loading
  const creatorsData = creators.data
  const creatorsLoading = creators.loading
  const categoriesSymptomsData = categoriesSymptoms.data
  const categoriesSymptomsLoading = categoriesSymptoms.loading

  const logout = () => {
    removeCookie("token")
    removeCookie("isUserPlanActive")
    dispatch({ type: "GET_PLAN_LIST", payload: [] })
    dispatch({ type: "GET_USER_PROFILE_DATA", payload: [] })
    props.history.push("/login")
  }
  const pathname = props.pathname
  const symptomTrackData = useSelector(state => state.non_register.symptomtrackdata)
  const trackData = symptomTrackData.data
  const trackLoading = symptomTrackData.loading

  const selectedSymptomsApiData = useSelector(state => state.non_register.selectedsymptoms)
  const selectedSymptomsData = selectedSymptomsApiData.data
  const selectedSymptomsDataLoading = selectedSymptomsApiData.loading

  const symptomLists = useSelector(state => state.non_register.symptoms)
  const symptomListDatas = symptomLists.data
  const symptomListLoading = symptomLists.loading
  let allSymptomsOptions = []
  let fiveShowSymptomsOption = []
  let [fiveSymptomsOption, setFiveSymptomsOption] = useState([])
  let [allSymptomsOption, setAllSymptomsOption] = useState([])

  useEffect(() => {
    /* if (pathname !== pathname.toLowerCase()) {
            props.history.replace(pathname.toLocaleLowerCase())
        } */
  }, [pathname])

  let authToken = getCookie("token")
  useEffect(() => {
    setDataLoaded(false)
    dispatch({
      type: "HANDLE_HEADER",
      payload: {
        activeNav: "",
        showJoinBtn: true,
        isSubscriptionPage: false,
        isLinkActive: false
      }
    })
    isEmpty(featuredCoursesData) && dispatch(getFeaturedCourses())
    isEmpty(testimonialData) && dispatch(getTestimonial())
    isEmpty(planList) && dispatch(getPlanList())
    isEmpty(creatorsData) && dispatch(getAllCreators())
    isEmpty(categoriesSymptomsData) && dispatch(getCategoriesSymptoms())
    isEmpty(symptomListDatas) && dispatch(getSymptomList())
    isEmpty(trackData) && dispatch(checkSymptomTrackId(trackerInfoId))

    isEmpty(selectedSymptomsData) && dispatch(getSelectedSymptomList())
  }, [])

  useEffect(() => {
    if (
      !featuredCoursesLoading &&
      !testimonialLoading &&
      !creatorsLoading &&
      !categoriesSymptomsLoading &&
      !symptomListLoading &&
      !trackLoading &&
      !selectedSymptomsDataLoading
    ) {
      setDataLoaded(true)
      if (!isEmpty(selectedSymptomsData)) {
        // props.handleTracker(3);
      }
    }
  }, [
    featuredCoursesLoading,
    testimonialLoading,
    creatorsLoading,
    categoriesSymptomsLoading,
    symptomListLoading,
    trackLoading,
    selectedSymptomsDataLoading
  ])

  console.log({
    selectedOption: selectedOption,
    five: fiveSymptomsOption,
    seletedApi: selectedSymptomsApiData
  })

  useEffect(() => {
    if (symptomListDatas) {
      if (selectedSymptomsApiData.data && selectedSymptomsApiData.data.length) {
        console.log(selectedSymptomsApiData.data)
        // get id and name
        const saved = []
        const savedSymptoms = selectedSymptomsApiData.data.map(s => {
          return {
            id: s.symptomID,
            name: s.name
          }
        })
        saved.push(savedSymptoms)
        // console.log(savedSymptoms, "svd")
        let newSympArray = []
        const newSymps = savedSymptoms.map(sym => {
          console.log({
            sym: sym
          })
          let newSelectedSymptom = {
            value: sym.id,
            label: sym.name,
            isSelected: true
          }
          console.log(newSelectedSymptom, "nss")
          newSympArray.push(newSelectedSymptom)
          return newSympArray
        })
        // console.log(newSympArray, "nsymps")
        setSelectedOption(prev_selected => [...prev_selected, ...newSympArray])
        setFiveSymptomsOption(prev => [...prev, ...newSympArray])
      }

      for (let i = 0; i < symptomListDatas.length; i++) {
        let symptomData = symptomListDatas[i]
        let newJson = {
          value: symptomData.ID,
          label: swedish && symptomData.swedish ? symptomData.swedish : symptomData.name,
          isSelected: false
        }
        if (i < 5 && !selectedSymptomsApiData.data) {
          fiveShowSymptomsOption.push(newJson)
          setFiveSymptomsOption(fiveShowSymptomsOption)
        }
        allSymptomsOptions.push(newJson)
      }

      // }
    }
    setAllSymptomsOption(
      allSymptomsOptions.filter(
        sym => sym.label !== "I don't have any symptom" && sym.label !== "I don't have any symptoms"
      )
    )
    // console.log(allSymptomsOptions)
  }, [symptomListDatas, selectedSymptomsApiData])

  const handlesymptomId = (event, symptomId, symptomName) => {
    event.preventDefault()
    setFlashMessage("")
    setshowAddSymptomError("")
    let newSelectedSymptom = {
      value: symptomId,
      label: symptomName,
      isSelected: true
    }
    setFiveSymptomsOption(selectedFiveOption => {
      let fIndex = selectedFiveOption.map(el => el.value).indexOf(symptomId)
      if (fIndex > -1) {
        if (selectedFiveOption[fIndex].isSelected) {
          selectedFiveOption[fIndex] = {
            value: selectedFiveOption[fIndex].value,
            label: selectedFiveOption[fIndex].label,
            isSelected: false
          }
        } else {
          selectedFiveOption[fIndex] = {
            value: selectedFiveOption[fIndex].value,
            label: selectedFiveOption[fIndex].label,
            isSelected: true
          }
        }
        return [...selectedFiveOption]
      } else {
        return [...selectedFiveOption, newSelectedSymptom]
      }
    })
    setSelectedOption(selectedOption => {
      let fIndex = selectedOption.map(el => el.value).indexOf(symptomId)
      if (fIndex > -1) {
        selectedOption.splice(fIndex, 1)
        return [...selectedOption]
      } else {
        return [...selectedOption, newSelectedSymptom]
      }
    })
  }

  const handleSymptomIdFromPopup = (event, symptomId, symptomName) => {
    event.preventDefault()
    setFlashMessage("")
    setshowAddSymptomError("")
    let newSelectedSymptom = {
      value: symptomId,
      label: symptomName,
      isSelected: true
    }
    setSelectedOption(selectedOption => {
      let fIndex = selectedOption.map(el => el.value).indexOf(symptomId)
      if (fIndex > -1) {
        selectedOption.splice(fIndex, 1)
        return [...selectedOption]
      } else {
        return [...selectedOption, newSelectedSymptom]
      }
    })
  }
  const handleAddSymptoms = () => {
    if (selectedOption.length < 1) {
      if (swedish) {
        setshowAddSymptomError("Välj vilket symptom som helst.")
      } else {
        setshowAddSymptomError("Please choose atleast one symptom.")
      }
    } else {
      handleAddMore(false)
      // setshowChoosedSymptom(true);
      setShow(false)
    }

    // handleAddMore(false)
    // setShow(false)
  }

  const handleAddMore = status => {
    setShow(status)
    let allDatas = allSymptomsOption
    allDatas.map((symptomsopt, index) => {
      let symptomIdFromAll = symptomsopt.value
      let fIndex = selectedOption.map(el => el.value).indexOf(symptomIdFromAll)
      if (fIndex > -1) {
        allDatas[index] = {
          value: symptomsopt.value,
          label: symptomsopt.label,
          isSelected: true
        }
      } else {
        allDatas[index] = {
          value: symptomsopt.value,
          label: symptomsopt.label,
          isSelected: false
        }
      }
      if (allDatas) {
        let newFiveShowSymptomsOption = []
        let allSortedDatas = allDatas.sort(function(x, y) {
          return x.isSelected === y.isSelected ? 0 : x.isSelected ? -1 : 1
        })
        for (let i = 0; i < allSortedDatas.length; i++) {
          let symptomData = allSortedDatas[i]
          let newJson = {
            value: symptomData.value,
            label: symptomData.label,
            isSelected: symptomData.isSelected
          }

          if (selectedOption.length) {
            if (i < selectedOption.length) {
              newFiveShowSymptomsOption.push(newJson)
            }
          } else {
            if (i < 5) {
              newFiveShowSymptomsOption.push(newJson)
            }
          }
        }
        setFiveSymptomsOption(newFiveShowSymptomsOption)
        allDatas.sort(function(a, b) {
          var nameA = a.label.toLowerCase(),
            nameB = b.label.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      }
    })
  }
  const handleSubmit = async event => {
    if (selectedOption.length > 0) {
      let symptoms = JSON.stringify(selectedOption)
      let formData = new FormData()
      formData.append("customerSymptomTrackerInfoID", props.newTrackId)
      formData.append("packageItemID", "0")
      formData.append("symptoms", symptoms)
      await axios
        .post("api/addSymptonmTrackStep2", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "access-control-allow-origin": "*",
            authorization: authToken
          }
        })
        .then(result => {
          if (result.data.status) {
            props.handleTracker(3)
          } else {
            this.setState({
              messageType: "error",
              flashMessage: result.data.message
            })
          }
        })
        .catch(error => {
          this.setState({
            messageType: "error",
            flashMessage: error.message
          })
        })
    } else {
      if (swedish) {
        setFlashMessage("Välj symptom!")
      } else {
        setFlashMessage("Please choose atleast one symptom!")
      }
      setshowChoosedSymptom(false)
    }
  }
  const handleFlashUnmount = () => {
    setFlashMessage("")
    setshowAddSymptomError("")
  }
  return (
    <Modal
      show={props.showTracker === 2}
      backdrop="static"
      keyboard={false}
      size="xl"
      fullscreen="true"
      dialogclassname="Add-symptommodal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="border-0" closeButton onHide={() => props.history.goBack()}></Modal.Header>
      <Modal.Body>
        <React.Fragment>
          <div className="container-lg symptoms-tracker-questions">
            <div className="Content-view symptoms-tracker-modal">
              <div className="question-step active">
                <div className="text-center step-number">
                  <span className="badge bg-light">2/4</span>
                </div>
                <div className="d-flex align-items-center justify-content-center step-bar">
                  <div className="step-btn active"></div>
                  <div className="step-btn active-ii"></div>
                  <div className="step-btn "></div>
                  <div className="step-btn "></div>
                </div>
                <h2 className="h2 text-center">
                  {swedish ? "Vilka symptom upplever du idag?" : "What symptoms are you experiencing currently?"}
                </h2>
                <div className="row text-center justify-content-md-center extremelyBad">
                  <div className="col-12">{swedish ? "Du kan välja flera symtom" : "Select all that apply"}</div>
                </div>

                <div className="symptoms-tracker-body">
                  <div className="symptoms-btn-body text-center">
                    <>
                      {flashMessage ? (
                        <div className="row text-center justify-content-md-center">
                          <FlashMessages type={messageType} message={flashMessage} unmountMe={handleFlashUnmount} />
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                    {fiveSymptomsOption.map((symptomsData, index) => {
                      return (
                        <Link
                          to="#"
                          key={index}
                          className={
                            symptomsData.isSelected
                              ? "btn btn-light rounded-pill"
                              : "btn btn-light rounded-pill selected"
                          }
                          onClick={e => handlesymptomId(e, symptomsData.value, symptomsData.label)}
                        >
                          {symptomsData.label}
                        </Link>
                      )
                    })}
                  </div>
                </div>
                <div className="text-center symptoms-tracker-bottom">
                  <div className="mb-4">
                    <p className="text-small text-dark">
                      {swedish ? "Ska symtomen vara på svenska?" : "Don't see your symptoms?"}
                      <Link
                        to={"#"}
                        className="text-uppercase addMore underline ps-1"
                        onClick={() => handleAddMore(true)}
                      >
                        {swedish ? "Lägg till fler symtom " : "Add more"}
                      </Link>
                    </p>
                  </div>
                  <Link
                    to="#"
                    className=" btn-lg btn-whiteTheme btn-stepcss text-decoration-none"
                    onClick={e => handleSubmit(e)}
                  >
                    {swedish ? "Nästa fråga" : "Next Question"}
                  </Link>
                </div>
              </div>
            </div>

            {/* The Modal  */}

            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              size="lg"
              dialogclassname="Add-symptommodal"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton={false} className="flex-wrap border-0 pb-1 text-center">
                <h4 className="h2 pt-2 col-12 px-0 mb-2 text-user-submit">
                  {swedish ? "Välj dina symtom " : "Add your symptom"}
                </h4>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div>
                    <div className="symptoms-btn-body modal-symptoms-list">
                      {/* <h4 className="mb-2">Symptoms list</h4> */}
                      <div className="mt-3">
                        {showAddSymptomError ? (
                          <FlashMessages
                            type={messageType}
                            message={showAddSymptomError}
                            unmountMe={handleFlashUnmount}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      {allSymptomsOption.map((symptomData, index) => {
                        return (
                          <Link
                            to="#"
                            key={index}
                            className={
                              selectedOption != null &&
                              selectedOption.map(el => el.value).indexOf(symptomData.value) > -1
                                ? "btn btn-light rounded-pill"
                                : "btn btn-light rounded-pill selected"
                            }
                            onClick={e => handleSymptomIdFromPopup(e, symptomData.value, symptomData.label)}
                          >
                            {symptomData.label}
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                  {/* <div className="mb-3">
                    <div className="selectup-to">Up to 5 symptoms</div>
                  </div> */}
                </form>
              </Modal.Body>
              <Modal.Footer className="border-0 text-center w-100">
                <button type="button" onClick={handleAddSymptoms} className=" btn-lg btn-whiteTheme Add-symptoms">
                  {swedish ? "Välj dina symtom " : "Add your symptoms"}
                </button>
              </Modal.Footer>
            </Modal>
            {/* Modal end */}
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal>
  )
}
