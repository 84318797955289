import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'config/functions'
import { httpPostRequest } from 'config/services'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import DataTable from 'react-data-table-component'
import adminDefault from 'admin-assets/images/default-icon.png'
import moment from 'moment'
import { date } from 'yup'
import base64 from 'base-64'
import ConfirmationPopup from 'views/pages/common-components/ConfirmationPopup'
import ShowMessagePopup from 'views/common/ShowMessagePopup'

export default class ManageSupport extends Component {
  constructor(props) {
    super(props)

    this.initialState = {
      customerSupportMessages: [],
      customerName: '',
      firstTimeDataLoaded: false,

      currentPage: 0,
      questionInfo: {},
      openMessageConfirmation: false,
    }
    this.state = this.initialState
  }

  componentDidMount() {
    this.apiCall({
      customerName: '',
      columnName: 'cq.createdOn',
      filterType: 'desc',
    })
  }

  apiCall(payload) {
    // console.log({ payload: payload })
    try {
      httpPostRequest('admin/getCustomerSupportMessages', payload, (error, result) => {
        let stateObj = {}
        if (!isEmpty(error)) {
          stateObj = {
            messageType: 'error',
            flashMessage: error.message,
            btnDisabled: false,
          }
        } else {
          if (result.status) {
            this.setState({ customerSupportMessages: [] })
            this.setState({
              customerSupportMessages: !isEmpty(result.data) ? result.data : [],
            })
          } else {
            stateObj = {
              messageType: 'error',
              flashMessage: result.message,
              btnDisabled: false,
            }
          }
        }
      })
    } catch (err) {
      console.log('err', err)
      this.props.history.push('/admin/support')
    }
  }

  // not sure what thhis does
  isValidDate = (current) => {
    return current.isAfter(this.state.startDate)
  }

  // this either
  handlePageClick = (page) => {
    this.setState({ currentPage: page.selected })
  }
  filterSearchWise(customerName) {
    this.setState({ customerName: customerName })
    this.apiCall({
      customerName: customerName,
    })
  }

  SortColumn(column, sortDirection, event) {
    // console.log(column, sortDirection, event)
    if (!isEmpty(column.keyName) && !isEmpty(sortDirection)) {
      this.apiCall({
        customerName: this.state.customerName,
        columnName: column.keyName,
        filterType: sortDirection,
      })
    }
  }

  openPopup = (e, questionInfo) => {
    e.preventDefault()
    this.setState({
      openConfirmation: true,
      questionInfo: questionInfo,
    })
  }

  closePopup = () => {
    this.setState({
      openConfirmation: false,
      questionInfo: {},
    })
  }
  viewMessagePopup = (e, questionInfo) => {
    e.preventDefault()
    this.setState({
      openMessageConfirmation: true,
      questionInfo: questionInfo,
    })
  }
  closeMessagePopup = (e, questionInfo) => {
    this.setState({
      openMessageConfirmation: false,
      questionInfo: {},
    })
  }

  deleteCustomerMessage = (questionInfo) => {
    // console.log(questionInfo)
    try {
      httpPostRequest(
        'admin/deleteSupportMessage',
        {
          questionId: questionInfo.qid,
        },
        (error, result) => {
          let stateObj = {}
          if (!isEmpty(error)) {
            stateObj = {
              messageType: 'error',
              flashMessage: error.message,
              btnDisabled: false,
            }
          } else {
            if (result.status) {
              this.setState({
                openConfirmation: false,
                questionInfo: {},
                openMessageConfirmation: false,
              })
              this.apiCall({
                customerName: this.state.customerName,
                columnName: 'cq.createdOn',
                filterType: 'desc',
              })
            } else {
              stateObj = {
                messageType: 'error',
                flashMessage: result.message,
                btnDisabled: false,
              }
            }
          }
          this.setState(stateObj)
        }
      )
    } catch (err) {
      console.log('err', err)
      this.props.history.push('/admin/support')
    }
  }

  render() {
    let { openConfirmation, openMessageConfirmation, questionInfo, customerSupportMessages } = this.state
    // console.log({ openMessageConfirmation: openMessageConfirmation })

    const columns = [
      {
        name: 'Customer',
        selector: 'name',
        keyName: 'c.name',
        sortable: true,
        left: true,
        width: 'auto',
        allowOverflow: false,
        // cell: (row) => <span>{`${row.firstName}   ${row.lastName}`} </span>,
        cell: (row) => (
          <>
            <div>
              <img className='profile-img mr-10' src={isEmpty(row.imgUrl) ? adminDefault : row.imgUrl} alt={row.firstName + ' ' + row.lastName} />
            </div>
            <div className='text-truncate'>
              <span className='dt_provider-name'>{row.firstName + ' ' + row.lastName}</span>
              <p className='dt_provider-email text-nowrap '>{' (' + row.email + ') '}</p>
            </div>
          </>
        ),
      },
      // {
      //   name: 'Email',
      //   selector: 'email',
      //   keyName: 'c.email',
      //   sortable: true,
      //   left: true,
      //   width: 'auto',
      //   allowOverflow: false,
      //   cell: (row) => <span>{row.email}</span>,
      // },
      {
        name: 'Phone',
        selector: 'phone',
        keyName: 'c.phone',
        sortable: true,
        left: true,
        width: '150px',
        allowOverflow: false,
        cell: (row) => <span>{(row.phoneCode ? row.phoneCode + ' ' : '') + (row.phone ? row.phone : '')}</span>,
      },

      {
        name: 'created on',
        selector: 'createdOn',
        keyName: 'cq.createdOn',
        sortable: true,
        left: true,
        width: '200px',
        allowOverflow: false,
      },

      {
        name: 'Customer Message',
        selector: 'question',
        keyName: 'cq.message',
        sortable: true,
        left: true,
        width: '300px',
        allowOverflow: false,
        cell: (row) => <div className="text-truncate d-inline-block">{row.message}</div>,
      },
      // {
      //   name: 'updated on',
      //   selector: 'updatedOn',      //   keyName: 'cq.updatedOn',
      //   sortable: true,
      //   left: true,
      //   width: 'auto',
      //   allowOverflow: false,
      // },
      // {
      //   name: 'status',
      //   selector: 'status',
      //   keyName: 'cq.status',
      //   sortable: true,
      //   left: true,
      //   width: '80px',
      //   allowOverflow: false,
      // },

      {
        name: '',
        selector: '',
        sortable: false,
        center: true,
        width: '80px',
        allowOverflow: false,
        cell: (row) => (
          <Link
            // to={`/admin/support/${base64.encode(row.qid)}`}
            // to={`/admin/support/${row.qid}`}
            // className="font__weight--simibold data-link"
            to='#'
            onClick={(e) => this.viewMessagePopup(e, row)}
          >
            View
          </Link>
        ),
      },
      //   {
      //     name: '',
      //     selector: '',
      //     sortable: false,
      //     center: true,
      //     width: '80px',
      //     allowOverflow: false,
      //     cell: (row) => (
      //       <Link to='#' className='text-danger' onClick={(e) => this.openPopup(e, row)}>
      //         Delete
      //       </Link>
      //     ),
      //   },
    ]

    return (
      <React.Fragment>
        <div id='admin-container'>
          <div className='dash-wrapper'>
            <main id='main'>
              <div className='px-3'>
                <div className='page-heading between-xs'>
                  <div className='heading-content d-flex-xs flex-wrap-xs middle-xs'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.6515 20.4054C20.2043 20.2902 20.5336 19.7117 20.2589 19.2183C19.6533 18.1307 18.6993 17.1749 17.4788 16.4465C15.907 15.5085 13.9812 15 12 15C10.0188 15 8.09292 15.5085 6.52112 16.4465C5.30069 17.1749 4.34666 18.1307 3.74108 19.2183C3.46638 19.7117 3.79562 20.2902 4.34843 20.4054V20.4054C9.39524 21.4572 14.6047 21.4572 19.6515 20.4054V20.4054Z" fill="white" />
                      <circle cx="12" cy="8" r="5" fill="white" />
                    </svg>
                    <h1 className='heading'>Customer Support</h1>
                  </div>

                  <div className='search-filter customerSerach filter-lg'>
                    <div className='form-group form-creator-30 mr-10'>
                      <input type='text' name='search' className='form-control creator' placeholder='Search Customer' onChange={(e) => this.filterSearchWise(e.target.value)} value={this.state.customerName} />
                    </div>

                    <div className='form-group form-btn-126'>
                      <button type='submit' className='btn btn-primary' onClick={() => this.filterSearchWise()}>
                        Search
                      </button>
                    </div>
                    {/*
									<div className="form-group form-btn-126 d-none">
										<Link to="/admin/add/customer" className="btn btn-primary">Add Customer</Link>
									</div>
									*/}
                  </div>
                </div>
                <div className='table-wrapper  add-category'>
                  <div className=' table-responsive table-hover mt-0' >
                    <DataTable
                      className='table table-responsive data-table-wrapper filter-tablecss mt-0'
                      columns={columns}
                      data={customerSupportMessages}
                      persistTableHead={true}
                      noHeader={true}
                      responsive={true}
                      keyField='customerID'
                      pagination={true}
                      paginationServer={false}
                      paginationTotalRows={customerSupportMessages.length}
                      paginationPerPage={10}
                      noDataComponent='No record found'
                      sortServer={true}
                      onSort={(column, sortDirection, event) => this.SortColumn(column, sortDirection, event)}
                    />
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        {openConfirmation ? <ConfirmationPopup show={openConfirmation} handleSubmit={() => this.deleteCustomerMessage(questionInfo)} bodyText='Do you really want to delete the support message?' handleClose={this.closePopup} {...this.props} /> : null}
        {openMessageConfirmation ? <ShowMessagePopup show={openMessageConfirmation} handleClose={this.closeMessagePopup} handleSubmit={() => this.deleteCustomerMessage(questionInfo)} questionInfo={this.state.questionInfo} /> : null}
      </React.Fragment>
    )
  }
}
