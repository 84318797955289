import { getTranslatedName } from 'config/functions';
import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';

const CourseWaitingModal = (props) => {
    return (
        <Modal
            scrollable={true}
            isOpen={props.show}
            className="  modal-dialog modal-dialog-scrollable"
            dialogclassname="showContentPause showContentPause-css"
            centered
            size='md'
            backdrop={`static`}
        >
            <div className="bg-semiLocked">
                <ModalBody className="pauseContent p-0">
                    <div className="d-flex flex-column px-2">
                        <div className="w-100 text-center bg-start">
                            <i className="fa fa-check-circle-o fa-5x text-success mt-5 pb-3" />
                            <button
                                type="button"
                                className="close-modal pointer"
                                onClick={() => props.close(false)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <p className="my-4 title-modal">
                            {getTranslatedName("Unlock Your Next Level")}:
                        </p>

                        <ul className="list-unstyled text-center">
                            <li className="my-2">
                                {getTranslatedName("You are almost there!")}
                            </li>

                            <li className="my-2">
                                {getTranslatedName("Complete your current set of videos to unlock this content.")}
                            </li>

                            <li className='my-2'>
                                {getTranslatedName("we're eagerly waiting for your video upload to provide feedback,  or for you to finish your current programs.")}
                            </li>
                            <li className='my-2'>
                                {getTranslatedName("Your  next level awaits!")}
                            </li>
                        </ul>
                    </div>

                </ModalBody>
            </div>
        </Modal>
    )
}

export default CourseWaitingModal