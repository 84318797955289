const initialState = {
    adminProfile: [],
    category: [],
    symptom: [],
    addCategory: [],
    addSymptom: [],
    creators: [],
    addCourse: [],
    courseDetail: [],
    getModule: [],
    getResource: [],
    getDashboardData: [],
    editCategory: [],
    editSymptom: [],
    // header: { activeNav: 'Dashboard', headerDark: false },
    getModules: [],
    getResources: [],
    addModules: [],
    getReviews: [],
    addResources: [],
    addReviews: [],
    addActions: [],
    getActions: [],
    getCourseById: [],
    getManageGroup: [],
    addGroup: [],
    editGroup: [],
    getGroup: [],
    updateCourseD: [],
    changePass: []
}

export default (state = initialState, { type, payload }) => {

    switch (type) {
        case 'HANDLE_HEADER':
            return { ...state, header: { ...state.header, ...payload } }

        case 'ADMIN-LOGIN':
            return { ...state, adminProfile: payload }

        case 'GET_CATEGORIES':
            return { ...state, category: payload }

        case 'GET_SYMPTOMS':
            return { ...state, symptom: payload }
        case 'ADD_CATEGORIES':
            return { ...state, addCategory: payload }

        case 'ADD_SYMPTOMS':
            return { ...state, addSymptom: payload }

        case 'GET_ADMIN_CREATOR':
            return { ...state, creators: payload }

        case 'ADD_COURSE_DETAILS':
            return { ...state, addCourse: payload }

        case 'GET_COURSE_DETAIL':
            return { ...state, courseDetail: payload }

        case 'GET_MODULE_DETAIL':
            return { ...state, getModule: payload }

        case 'GET_RESOURCE_DETAIL':
            return { ...state, getResource: payload }

        case 'GET_DASHBOARD_RESOURCE':
            return { ...state, getDashboardData: payload }

        case 'EDIT_CAT':
            return { ...state, editCategory: payload }

        case 'EDIT_SYM':
            return { ...state, editSymptom: payload }

        case 'GET_MODULES':
            return { ...state, getModules: payload }

        case 'GET_RESOURCES':
            return { ...state, getResources: payload }

        case 'ADD_MODULES':
            return { ...state, addModules: payload }

        case 'GET_REVIEWS':
            return { ...state, getReviews: payload }

        case 'ADD_RESOURCE':
            return { ...state, addResources: payload }

        case 'ADD_REVIEW':
            return { ...state, addReviews: payload }

        case 'ADD_ACTION_BY_COURSE_ID':
            return { ...state, addActions: payload }

        case 'GET_ACTION':
            return { ...state, getActions: payload }

        case 'GET_COURSE_BY':
            return { ...state, getCourseById: payload }

        case 'GET_MANAGE_GROUP':
            return { ...state, getManageGroup: payload }

        case 'ADD_GROUPS':
            return { ...state, addGroup: payload }

        case 'EDIT_GRO':
            return { ...state, editGroup: payload }

        case 'CHANGE_PASS':
            return { ...state, changePass: payload }

        case 'GET_GROUP':
            return { ...state, getGroup: payload }

        case 'UPDATE_COURSE_DETAILS':
            return { ...state, updateCourseD: payload }

        default:
            return state
    }
}
