import React, { useEffect, useState } from 'react'
import PhaseAccordion from 'components/PhaseAccordion'
import { isEmpty } from 'config/functions'
import { useSelector } from 'react-redux'

export default function PhaseContainer({ dashboardData }) {

    const studentData = useSelector(state => state.register.userProfileData)
    const phaseData = dashboardData.map((item, index) => {
        return {
            id: (index + 1),
            ...item
        }
    })


    const [openedPhase, setOpenedPhase] = useState([studentData?.activePhaseId]);


    useEffect(() => {
        setOpenedPhase([studentData?.activePhaseId])
    }, [dashboardData, studentData?.activePhaseId])

    const handlePhaseToggle = (id) => {
        if (openedPhase.includes(id)) {
            const newArr = openedPhase.filter(item => item != id);
            setOpenedPhase(newArr)
        } else {
            setOpenedPhase([...openedPhase, id])
        }
    }

    return (
        <div className='phase-container'>
            {
                phaseData.map((phase, index) => {
                    return (
                        <PhaseAccordion openedPhase={openedPhase} handlePhaseToggle={handlePhaseToggle} key={phase.id} phase={phase}
                            nextPhase={phaseData[index + 1]}
                        />
                    )
                })
            }

        </div>
    )
}
