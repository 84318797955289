import React, { useState, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import axios from "axios"
import { useFormik } from "formik"
import * as Yup from "yup"
import { getCookie, isEmpty, removeCookie, encodeId, decodeId } from "../../config/functions"
import { useSelector, useDispatch } from "react-redux"
import Symptomstracker1 from "./Symptomstracker1"
import Symptomstracker2 from "./Symptomstracker2"
import Symptomstracker3 from "./Symptomstracker3"
import Symptomstracker4 from "./Symptomstracker4"
import { decode, encode } from "base-64"
import { useParams } from "react-router-dom"
import moment from "moment"
import { httpPostRequest } from "config/services"
import HeaderMeta from "../common/HeaderMeta"

import { SYMPTOM_TRACK_AFTER_DAY } from "config/constants"
import { getUserProfile } from "views/registerStudentLayout/action"
const SymptomTracker = props => {
  const dispatch = useDispatch()
  const pathname = props.location.pathname
  const params = useParams()
  let packageItemId = params.packageItemId
  const userProfileData = useSelector(state => state.register.userProfileData)
  const [showTrackerMessageBox, setShowTrackerMessageBox] = useState(false)
  const [showTracker, setShowTracker] = useState(0)
  const [showStartBox, setShowStartBox] = useState(false)
  // console.log(showTrackerMessageBox, showTracker, showStartBox)
  const [newTrackId, setNewTrackId] = useState("")
  const [notify, setNotify] = useState({
    message: "",
    type: ""
  })

  const [lastTrackerDayDifference, setLastTrackerDayDifference] = useState(0)
  const userSymptomData = useSelector(state => state.non_register.symptomAndScores)

  let authToken = getCookie("token")
  // console.log(userSymptomData)
  // console.log(userProfileData)

  const userPlan = userProfileData?.subscriptionDetails
  const [swedish, setSwedish] = useState()
  useEffect(() => {
    const sw = window.localStorage.getItem("swedish")
    const planID = window.localStorage.getItem("planID")
    if (userProfileData.countryID == "199" && userPlan.planID == 4) setSwedish(sw)
    if (userPlan.planID == 4) setSwedish(true)
    if (planID == 4) setSwedish(true)
  }, [swedish])

  const formik = useFormik({
    initialValues: {
      message: ""
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .min(10, "Minimum 10 characters are required!")
        .required("Message is required!")
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleMessageSubmit(values)
    }
  })

  const handleMessageSubmit = values => {
    let token = getCookie("token")
    let message = values.message
    // console.log(message)
    axios
      .post("api/sendTrackerUpdateMailToAdmin", {
        message,
        headers: {
          "content-type": "application/json",
          "access-control-allow-origin": "*",
          authorization: token
        }
      })
      .then(result => {
        if (result.data.status === false) {
          setNotify({ type: "danger", message: result.data.message })
        } else {
          setNotify({ type: "success", message: result.data.message })
          formik.resetForm()
          let userProfile = userProfileData
          userProfile.isTrackerMessageSubmitted = 1
          dispatch({ type: "GET_USER_PROFILE_DATA", payload: userProfile })
          setShowTrackerMessageBox(false)
          setShowStartBox(false)

          setShowTracker(0)
          console.log("feed back submitted")
          props.history.push("/student/dashboard/")
        }

        setTimeout(() => {
          setNotify({
            type: "",
            message: ""
          })
        }, 3000)
      })
  }

  const handleTracker = tracker => {
    setShowStartBox(false)
    setShowTracker(tracker)
  }

  const handleNewTrackId = id => {
    let newTrackid = Number(decode(id))
    setNewTrackId(Number(decode(id)))
  }
  // console.log(showTracker, showStartBox)

  useEffect(() => {
    dispatch(getUserProfile())
    // console.log(userProfileData.symptomTrackerInfo)
    //if (userProfileData) {
    //if (userProfileData && !userProfileData.isTrackerMessageSubmitted) {
    if (packageItemId) {
      setShowStartBox(false)
      if (showTracker) {
        setShowStartBox(false)
        setShowTracker(showTracker)
      } else setShowTracker(1)
    } else {
      let symptomTrackerInfo = userProfileData.symptomTrackerInfo
      if (symptomTrackerInfo !== null) {
        // console.log('Symtom tracker->>>>>>', symptomTrackerInfo)
        // console.log(symptomTrackerInfo)
        setLastTrackerDayDifference(moment().diff(moment(symptomTrackerInfo.createdOn), "days"))
        if (moment().diff(moment(symptomTrackerInfo.createdOn), "days") >= SYMPTOM_TRACK_AFTER_DAY + 1) {
          if (userProfileData.isTrackerMessageSubmitted === 0 && !isEmpty(symptomTrackerInfo)) {
            if (showTracker == 0) {
              setShowStartBox(true)
            }
            setShowTracker(0)
          } else {
            setShowStartBox(false)
            setShowTracker(1)
          }
        } else {
          setShowStartBox(false)
          setShowTracker(1)
        }
      } else {
        setShowStartBox(false)
        setShowTracker(1)
      }
    }
    //}
  }, [userProfileData, userSymptomData])
  // console.log(userProfileData)

  const sendmailfunction = async q3 => {
    // console.log('send mail symptoms', q3)
    if (userSymptomData && userSymptomData.mood && userSymptomData.symptomScores) {
      const userData = {}
      userData.userName = userProfileData.firstName + " " + userProfileData.lastName
      userData.email = userProfileData.email
      userData.scoredDate = moment().toDate()
      userData.mood = userSymptomData.mood
      userData.symptomScores = userSymptomData.symptomScores
      userData.confidence = userSymptomData.confidence ? userSymptomData.confidence : q3

      // console.log(userData)

      await httpPostRequest("/api/sendScoresMailtoAdmin", {
        userData,
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          authorization: authToken
        }
      })
        .then(res => {
          if (res.data.status === false) {
            // do something
            console.log("false status")
          } else {
            console.log(res.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  return (
    <React.Fragment>
      <HeaderMeta
        title="Natural Self-healthcare therapies for women"
        description="Natural Self-healthcare therapies for women"
      />
      {showStartBox ? (
        <Modal
          show={showStartBox}
          backdrop="static"
          keyboard={false}
          size="md"
          fullscreen="true"
          dialogclassname="symptom-start-modal"
          //className="rounded"
          aria-labelledby="contained-modal-title-vcenter"
          contentClassName="box-shadow-none border-0"
          centered
        >
          <Modal.Body className="rounded card box-shadow-none border-0">
            {lastTrackerDayDifference === SYMPTOM_TRACK_AFTER_DAY + 1 && (
              <span
                onClick={() => props.history.push("/student/dashboard/")}
                role="button"
                className="float-right font-weight-bold ml-auto"
              >
                &times;
              </span>
            )}
            <div className="start-box p-4 text-center rounded">
              <h4 className="font-weight-bold mt-3">How are you feeling ? </h4>
              <p className="my-3 text-dark">
                Your input helps us to design better programs for you to get better.
                <br />
                <p className="text-dark"> Takes less than a minute.</p>
              </p>
              <button
                onClick={() => {
                  setShowStartBox(false)
                  setShowTracker(1)
                }}
                className="btn d-block mx-auto btn-main px-5 my-4 py-3 text-uppercase"
              >
                Start
              </button>
              <span
                onClick={() => {
                  setShowStartBox(false)
                  setShowTrackerMessageBox(true)
                }}
                role="button"
                className="text-main text-uppercase"
              >
                I don't want to fill this
              </span>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}

      {showTrackerMessageBox && (
        <Modal
          show={showTrackerMessageBox}
          backdrop="static"
          onHide={() => setShowTrackerMessageBox(false)}
          keyboard={false}
          size="md"
          fullscreen="true"
          dialogclassname="symptom-message-modal"
          aria-labelledby="contained-modal-title-vcenter"
          contentClassName="box-shadow-none border-0"
          centered
        >
          <Modal.Body>
            <div className="phoneVerification">
              <h5 className="text-center phone_title">What's stopping you from inputting the symptom scores?</h5>
            </div>
            {notify.message && (
              <p
                className={`alert-${notify.type} text-${notify.type} mb-4 p-2 rounded d-flex align-items-center justify-content-between`}
              >
                {notify.message}{" "}
                <span role="button" onClick={() => setNotify({ type: "", message: "" })}>
                  &times;
                </span>
              </p>
            )}
            <form onSubmit={formik.handleSubmit} className="mt-3">
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Message"
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                />
                {formik.errors.message && formik.touched.message ? (
                  <small className="text-danger">{formik.errors.message}</small>
                ) : (
                  ""
                )}
              </div>
              <button style={{ backgroundColor: "#515795" }} className="btn text-white float-right" type="submit">
                Submit
              </button>
            </form>
          </Modal.Body>
        </Modal>
      )}

      {showTracker === 1 && (
        <Symptomstracker1
          swedish={swedish}
          pathname={pathname}
          showTracker={showTracker}
          handleTracker={handleTracker}
          newTrackId={newTrackId}
          handleNewTrackId={handleNewTrackId}
          history={props.history}
          packageItemId={packageItemId}
        />
      )}

      {showTracker === 2 && (
        <Symptomstracker2
          swedish={swedish}
          pathname={pathname}
          showTracker={showTracker}
          handleTracker={handleTracker}
          newTrackId={newTrackId}
          handleNewTrackId={handleNewTrackId}
          history={props.history}
        />
      )}

      {showTracker === 3 && (
        <Symptomstracker3
          swedish={swedish}
          pathname={pathname}
          showTracker={showTracker}
          handleTracker={handleTracker}
          newTrackId={newTrackId}
          handleNewTrackId={handleNewTrackId}
          history={props.history}
        />
      )}

      {showTracker === 4 && (
        <Symptomstracker4
          swedish={swedish}
          pathname={pathname}
          showTracker={showTracker}
          handleTracker={handleTracker}
          newTrackId={newTrackId}
          handleNewTrackId={handleNewTrackId}
          sendmailfunction={sendmailfunction}
          history={props.history}
        />
      )}
    </React.Fragment>
  )
}

export default React.memo(SymptomTracker)
