import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Logo from 'assets/images/MOONRISE_White.svg';
import { useDispatch } from 'react-redux';
import { Nav } from 'react-bootstrap';

export default function WaiverHeader(props) {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: 'HANDLE_HEADER', payload: { isSubscriptionPage: true, isLinkActive: true } })


        // const script = document.createElement('script');
        // script.type = 'text/javascript';
        // script.async = true;
        // script.innerHTML = "(function(w,n) {if (typeof(w[n]) == 'undefined'){ob=n+'Obj';w[ob]=[];w[n]=function(){w[ob].push(arguments);};d=document.createElement('script');d.type = 'text/javascript';d.async=1;d.src='https://s3.amazonaws.com/provely-public/w/provely-2.0.js';x=document.getElementsByTagName('script')[0];x.parentNode.insertBefore(d,x);}})(window, 'provelys', '');provelys('config', 'baseUrl', 'app.provely.io');provelys('config', 'https', 1);provelys('data', 'campaignId', '26254');provelys('config', 'widget', 1);";

        // document.body.appendChild(script);

        // const script1 = document.createElement('script');
        // script1.type = 'text/javascript';
        // script1.async = true;
        // script1.innerHTML = "(function(w,n) {if (typeof(w[n]) == 'undefined'){ob=n+'Obj';w[ob]=[];w[n]=function(){w[ob].push(arguments);};d=document.createElement('script');d.type = 'text/javascript';d.async=1;d.src='https://s3.amazonaws.com/provely-public/w/provely-2.0.js';x=document.getElementsByTagName('script')[0];x.parentNode.insertBefore(d,x);}})(window, 'provelys', '');provelys('config', 'baseUrl', 'app.provely.io');provelys('config', 'https', 1);provelys('data', 'campaignId', '26254');provelys('config', 'track', 1);"
        // document.body.appendChild(script1);

        // return () => {
        //     // document.body.removeChild(script);
        //     document.body.removeChild(script1);
        // }
    }, [])

    const pathname = props.location.pathname;

    useEffect(() => {
        if (pathname !== pathname.toLowerCase()) {
            props.history.replace(pathname.toLocaleLowerCase())
        }
    }, [pathname])


    return (
        <React.Fragment>
            <Nav className="navbar navbar-expand-lg navbar-light fixed-top Checkout-header">
                {/* <div className="container"> */}
                <Nav.Link to='#' className="navbar-brand lg-brand">
                    {/* <img alt="MoonRise" height={60} width={160} src={Logo}  /> */}
                    <img src={Logo} alt="Logo" height={40} width={200} className="d-inline align-items-center mx-2" />
                </Nav.Link>
                <Nav.Link to='#' className="navbar-brand sm-brand d-md-none">
                    <img alt="MoonRise" height={40} width={160} src={Logo} className="sm-logo" />
                </Nav.Link>
                {/* <nav className="navbar navbar-expand-lg bg-white navbar-light fixed-top Checkout-header">
                <div className="container">
                    <Link to='#' className="navbar-brand ">
                        <img alt="Women's health issues learning platform: MOONRISE" height="80" width="80" src={Logo} className="lg-logo" />
                    </Link>
                    <Link to='#' className="navbar-brand sm-brand">
                        <img alt="Women's health issues learning platform: MOONRISE" height="80" width="80" src={Logo} className="sm-logo" />
                    </Link> */}

                {/* </div> */}
            </Nav>
        </React.Fragment>
    )
}


