import React, { useState, useEffect, useRef } from "react"
import { Link, useHistory, useLocation, useParams } from "react-router-dom"
import {
  getCookie,
  isEmpty,
  removeCookie,
  saveCookieFor24Hours,
  isStudentLoggedIn,
  getTranslatedName,
  checkSwedish
} from "../../../config/functions"
import Moonrise from "assets/images/MOONRISE_White.svg"
// import Moonrise from "assets/images/Moonrise.png"
import profileNew from "assets/images/MoonRiseWhite.png"
import { useDispatch, useSelector } from "react-redux"
import { Dropdown, Navbar, Nav, Modal, Button, NavLink, NavbarBrand } from "react-bootstrap"
import { IoChevronBackOutline } from "react-icons/io5"
import logOut from "assets/images/logout.png"
//import { IntercomProvider } from 'react-use-intercom';
import "../../../../node_modules/react-smartbanner/dist/main.css"
import PhoneVerificationPopUp from "views/pages/common-components/PhoneVerificationPopUp"
import Symptomtracker from "../../pages/SymptomTracker"
import moment from "moment"
import { FaRegHeart, FaChevronDown } from "react-icons/fa"
import { SYMPTOM_TRACK_AFTER_DAY } from "../../../config/constants"
import { userProfile } from "../../../views/registerStudentLayout/action"
import newBadge from "assets/images/newBadge.svg"
import { BiSearch } from "react-icons/bi"
import { RxCross1 } from "react-icons/rx"
import { httpPostRequest } from "config/services"
import axios from "axios"
import MoonriseLogo from "assets/images/iconMoonrise.jpg"
import { isMobile, browserName } from "react-device-detect"

export default function Header(props) {
  // const INTERCOM_APP_ID = "ft0i0gsz";
  const dispatch = useDispatch()
  const history = useHistory()
  // const [showDemoPop,setShowDemoPop] = useState(false)
  let currentPage = window.location.pathname
  const [showmobilenav, setShow] = useState(false)
  const activeUser = useSelector(state => state.register.userProfileData)
  const [isNavDisabled, setIsNavDisabled] = useState(false)
  const [showTrackPopup, setShowTrackPopup] = useState(false)
  const { headerDark, showJoinBtn } = useSelector(state => state.register.header)
  // const [isActive, setActive] = useState("");
  const [isActiveNav, setActiveNav] = useState()

  // trackking symptomQuestionstate
  const [modal, showModal] = useState(false)
  const symptomTrackerInfo = activeUser?.symptomTrackerInfo ? activeUser?.symptomTrackerInfo : {}
  const { question3Score, question1Score } = symptomTrackerInfo
  const cPMStatus = activeUser?.cPMStatus
  const handleClose = () => {
    showModal(false)
  }
  const headerRef = useRef(null)

  function handleContinue() {
    // popup for adding symptom score
    history.push("/student/newuser-symptoms")
    showModal(false)
  }
  let pathhistory = localStorage.getItem("pathhistory")
  const handleBack = () => {
    // history.goBack();
    let pathhistory = localStorage.getItem("pathhistory")
    if (!isEmpty(pathhistory)) {
      pathhistory = JSON.parse(pathhistory)
      let { pathArr } = pathhistory
      if (!isEmpty(pathArr) && pathArr.length > 0) {
        let lastPath = pathArr[1]
        if (lastPath.includes("/student/libary-video/") || lastPath.includes("/student/library")) {
          if (pathArr[2]) {
            history.push(pathArr[2])
          } else {
            history.push("/public/course-library")
          }
          return
        }
        if (!isEmpty(lastPath) &&
          (
            lastPath.includes("/public/course") ||
            lastPath.includes("/public/playlist") ||
            lastPath.includes("/student/payment-success"))
        ) {
          // history.push("/public/course-library")
          history.push("/student/dashboard")
        }
        else if (pathArr[0] == pathArr[1]) {
          if (pathArr[0].includes("/public/live-schedule/description")) {
            history.push("/public/live-schedule")
          }
          else
            history.push("/public/course-library")
        }
        else if (pathArr[0].includes("/public/live-schedule/description")) {
          console.log("I am here")
          history.push('/public/live-schedule')
        }
        // else if ((lastPath.includes("/student/payment-success") || pathArr[0].includes("/student/libary-lessons"))) {
        else if ((lastPath.includes("/student/payment-success"))) {
          history.push("/public/course-library")
        }
        else if (pathArr[0].includes("/public/course/") || pathArr[0].includes("/public/playlist")) {
          history.push("/public/course-library")
        }
        else {
          history.goBack()
        }
      } else {
        history.goBack()
      }
    } else {
      history.goBack()
    }
  }

  const [showPhoneVerificationModal, setShowPhoneVerificationModal] = useState(false)

  const logout = e => {
    // e.preventDefault();
    dispatch({ type: "GET_PLAN_LIST", payload: [] })
    dispatch({ type: "GET_USER_PROFILE_DATA", payload: [] })
    removeCookie("token")
    removeCookie("isUserPlanActive")
    removeCookie("phoneVerificationPopup")
    removeCookie("adminID")
    window.localStorage.setItem("planID", "")
    props.history.push("/login")
    window.$crisp.push(["do", "session:reset"])

  }


  useEffect(() => {
    if (!isEmpty(activeUser?.phone) && activeUser?.isPhoneVerified === 1) {
      setShowPhoneVerificationModal(false)
    } else {
      if (getCookie("phoneVerificationPopup")) {
        setShowPhoneVerificationModal(false)
      } else {
        setShowPhoneVerificationModal(true)
      }
    }
  }, [activeUser])

  useEffect(() => {
    if (activeUser?.isTrackerMessageSubmitted === 1) {
      setShowTrackPopup(false)
    }
  }, [activeUser?.isTrackerMessageSubmitted])

  useEffect(() => {
    let currentPageName = window.location.pathname
    if (
      activeUser?.customerHasPackage &&
      !activeUser?.isTrackerMessageSubmitted &&
      currentPageName != "/student/symptoms-tracker"
    ) {
      if (activeUser?.symptomTrackerInfo) {
        let symptomTracker = activeUser?.symptomTrackerInfo
        let lastUpdatedDate = symptomTracker && symptomTracker.createdOn
        let utcMoment = moment.utc()
        let lastUpdateDate = moment(lastUpdatedDate).format("MM/DD/YYYY")
        let currentDate = utcMoment.format("MM/DD/YYYY")
        let dateDiff = moment(currentDate).diff(moment(lastUpdateDate), "days")
        if (dateDiff < SYMPTOM_TRACK_AFTER_DAY + 1 && question1Score) {
          setShowTrackPopup(false)
        }
        if (dateDiff > SYMPTOM_TRACK_AFTER_DAY + 1) {
          // setShowTrackPopup(true);
          setShowPhoneVerificationModal(false)
        }
      } else {
        // setShowTrackPopup(true);
        setShowPhoneVerificationModal(false)
      }
    } else {
      if (currentPageName == "/student/symptoms-tracker") {
        setShowPhoneVerificationModal(false)
      }
    }
    // if (activeUser?.isTrackerMessageSubmitted === 0) {
    //   setIsNavDisabled(true)
    // } else {
    //   setIsNavDisabled(false)
    // }
    if (!question1Score) {
      setIsNavDisabled(true)
    } else {
      setIsNavDisabled(false)
    }
  }, [activeUser?.isTrackerMessageSubmitted, symptomTrackerInfo, showTrackPopup, question1Score])


  useEffect(() => {
    // wisepop conditions for firstTime login device specific  popup
    window.wisepops("pageview");
    if (checkSwedish()) {
      if (isMobile) {
        openPops('se-mob')
      } else {
        openPops('se-desk')
      }
    } else {
      if (isMobile) {
        openPops('en-mob')
      } else {
        openPops('en-desk')
      }
    }

    if (question1Score) dispatch(userProfile())
  }, [])

  function openPops(popup) {
    console.log('open pops')
    window.wisepops("pageview");
    // if (window.localStorage.getItem('firstLogin') == '1' || !window.localStorage.getItem('firstLogin')) {
    window.wisepops("event", popup)
    // window.wisepops("event", popup)
    // window.wisepops("event", popup)
    // window.wisepops("event", popup)

    // Dispatch the Wisepops custom event twice
    setTimeout(() => {
      window.wisepops("event", popup)
      window.localStorage.setItem('firstLogin', '0')
    }, 2000)

    // Update 'firstLogin' to '0' after showing the demo or performing necessary actions
    // }
  }

  let appMetaOBJ = { ios: "apple-itunes-app", android: "google-play-app" }
  let storeTextObj = { ios: "On the App Store", android: "In Google Play" }
  let priceTextObj = { ios: "FREE", android: "FREE" }

  let userName = `${!isEmpty(activeUser?.firstName) ? activeUser?.firstName : ""} ${!isEmpty(activeUser?.lastName) ? activeUser?.lastName : ""
    }`
  let userEmail = `${!isEmpty(activeUser?.email) ? activeUser?.email : ""}`

  // handleDocumentClick(e) {
  //   const container = this._element;
  //   if (e.target !== container && !container.contains(e.target)) {
  //     this.toggle();
  //   }
  // }
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (headerRef?.current && showmobilenav == true && !headerRef?.current?.contains(e.target)) {
        // alert("hi")
        setShow(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  })
  const activeNav = window.localStorage.getItem("Current Tab")
  useEffect(() => {
    // {
    if (currentPage.includes("public/course-library#add-library")) {
      setActiveNav("library")
      setKey("")
      setShowInput(false)

    }

    if (currentPage.includes("/student/dashboard")) {
      setActiveNav('dashboard')
      setKey("")
      setShowInput(false)
      window.localStorage.setItem("Current Tab", "dashboard")
    }

    if (currentPage.includes("/student/community-support")) {
      setActiveNav('Support')
      setKey("")
      setShowInput(false)
      window.localStorage.setItem("Current Tab", "Support")
    }
    if (currentPage.includes("/student/dashboard")) {
      setActiveNav('phases')
      setKey("")
      setShowInput(false)
      window.localStorage.setItem("Current Tab", "phases")
    }

    if (currentPage.includes("public/course-library")) {
      window.localStorage.setItem("Current Tab", "library")
      setActiveNav('library')
      setShowInput(false)
      setKey("")
    }
    if (currentPage.includes("public/search") || currentPage.includes("student/search")) {
      window.localStorage.setItem("Current Tab", "search")
      setActiveNav('search')
    }
    if (currentPage.includes("student/community-support") || currentPage.includes("student/community")) {
      window.localStorage.setItem("Current Tab", "Support")
      setActiveNav('Support')
    }
    if (currentPage.includes('/public/live-schedule') || currentPage.includes('student/live-schedule')) {
      window.localStorage.setItem("Current Tab", "Live")
      setActiveNav('Live')
      setShowInput(false)
      setKey("")
    }

    // (function (w, i, s, e) {
    //   window[w] =
    //     window[w] ||
    //     function () {
    //       (window[w].q = window[w].q || []).push(arguments)
    //     }
    //   window[w].l = Date.now()
    //   s = document.createElement("script")
    //   e = document.getElementsByTagName("script")[0]
    //   s.defer = 1
    //   s.src = i
    //   e.parentNode.insertBefore(s, e)
    // })("wisepops", "https://wisepops.net/loader.js?v=2&h=Cg7G2gtQGZ")
    if (currentPage) {
      if (window.wisp) {
        window.wisp.update()
      }
      window.wisepops("pageview")
    }
  }, [currentPage])
  useEffect(() => {
    // (function (w, i, s, e) {
    //   window[w] =
    //     window[w] ||
    //     function () {
    //       (window[w].q = window[w].q || []).push(arguments)
    //     }
    //   window[w].l = Date.now()
    //   s = document.createElement("script")
    //   e = document.getElementsByTagName("script")[0]
    //   s.defer = 1
    //   s.src = i
    //   e.parentNode.insertBefore(s, e)
    // })("wisepops", "https://wisepops.net/loader.js?v=2&h=Cg7G2gtQGZ")
    if (currentPage) {
      if (window.wisp) {
        window.wisp.update()
      }
      window.wisepops("pageview")
    }
  }, [currentPage])
  const [key, setKey] = useState("")
  // const params = useParams()
  const { search } = useParams()

  const handleSearchKey = (e) => {
    e.preventDefault()
    let word = ""
    word = e.target.value
    word = word.replace(/^\s+/, "")
    setKey(word)

  }

  const handleSearch = () => {
    // if (key != "") {
    if (currentPage.includes("/student")) {
      history.push({
        pathname: `/student/search/`, search: `${key}`
      })
    }
    else {
      history.push({
        pathname: `/public/search/`, search: `${key}`
      })
    }

  }
  const [showInput, setShowInput] = useState(false)
  const keynew = useLocation()

  const handleSearchInput = () => {
    setShowInput(true)
  }
  const handleSearchClose = () => {
    pathhistory = JSON.parse(pathhistory)
    let { pathArr } = pathhistory
    if (showInput) {
      setShowInput(false)
    }
    // history.goBack()
    if (pathArr[1].includes("/student/dashboard") || pathArr[1].includes("/student/live-schedule")
      || pathArr[1].includes("/public/live-schedule") || pathArr[1].includes("public/course-library")) {
      history.push(pathArr[1])
    }
    else {
      history.push("/student/dashboard")
    }
    setKey("")

  }
  useEffect(() => {
    if (currentPage.includes("/student/search") || (currentPage.includes("/public/search"))) {
      setShowInput(true)
      if (keynew != "" && keynew.search != "") {
        let newKey = keynew.search.slice(1)
        setKey(newKey)
      }
    }
  }, [])

  const saveUserToken = async (token, customerID) => {
    const bodyData = {
      token, customerID
    }

    httpPostRequest("/customer/save-pensil-token", bodyData, (err, res) => {
      if (err) {
        console.log("ERROR while login to pensil", err)
      } else {
        console.log("SUCCESS while login to pensil", res)
        window.open(`https://moonrisecircle.pensil.in?token=${token}`, '_blank')
      }
    })

  }

  const handleCommunity = async () => {
    return history.push('/student/community-support')

    if (activeUser.pensilUserToken) {
      window.open(`https://moonrisecircle.pensil.in?token=${activeUser.pensilUserToken}`, '_blank')
      return;
    }
    // setActiveNav('community')
    let data = {
      "email": activeUser.email,
      "referenceIdInSource": JSON.stringify(activeUser.customerID),
      "createUser": true,
      "name": activeUser.firstName + " " + activeUser.lastName,
      "picture": 'https://womencycle-media.s3.amazonaws.com/iconMoonrise.jpg',
      "description": "Moonrise User"
    }
    let headerOptions = {
      headers: {
        'communityid': '64cb8065ae9f2535392b3555',
        'clientsecret': '64cb8065ae9f2535392b3555.c4aec5a509a1d420c12489f74799ce67',
        'clientid': '64cb8065ae9f2535392b3555.9e5494108d10bbd5f9e7ae52239546c4',
        'Content-Type': 'application/json',
        'access-control-allow-origin': '*',
      }
    }
    let response = await axios.post('https://api.pensil.in/api/3pc/generate-user-token', data, headerOptions)
    try {
      if (response.status == "200") {
        let userToken = response.data.user.token
        saveUserToken(userToken, activeUser.customerID)
      }
    }
    catch (err) {
      console.log("ERROR while login to pensil", response)
    }
  }
  return (
    <React.Fragment>
      <Modal
        show={modal}
        onHide={() => showModal(false)}
        style={{
          maxWidth: "90%",
          margin: 20
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>hi there.! </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <main>
            <div
              style={{
                textAlign: "center",
                fontSize: 24
              }}
            >
              you need to provide your
              <br />
              <span
                style={{
                  color: "#575195",
                  // color: "green",
                  fontWeight: "bold"
                }}
              >
                "Mood and Symptom Scores"
              </span>
              <br />
              in order to continue.
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={handleContinue}>
            lets continue
          </Button>
        </Modal.Footer>
      </Modal>

      <div className={`navbarheader nav-style align-items-center`}>
        <Navbar
          collapseOnSelect
          expand="md"
          expanded={showmobilenav}
          fixed="top"
          variant="light"
          className={` ${headerDark ? "registered bg-dark" : " noyet "}`}
        >
          {(currentPage.includes("/student/more-videos") ||
            currentPage.includes("/student/watchVideo") ||
            currentPage.includes("student/library") ||
            currentPage.includes("/playlistVideo") ||
            currentPage.includes("/lifestyle") ||
            currentPage.includes("/libary-lessons") ||
            currentPage.includes("/public/playlist") ||
            currentPage.includes("/public/more-videos") ||
            currentPage.includes("/public/course/") ||
            currentPage.includes("/student/libary-video/") ||
            currentPage.includes("/public/watch-videos") ||
            currentPage.includes("public/live-schedule/description")) &&
            !showInput ? (
            <div className="d-flex flex-row align-items-center">
              <NavLink onClick={handleBack} className="back-btn pointer d-flex align-items-center d-md-none ">
                <IoChevronBackOutline color="#EED3DD" className="me-md-2 me-25" size={23} />
                {getTranslatedName("Back")}
              </NavLink>
            </div>
          ) : (
            showInput ? "" :
              <>
                {isStudentLoggedIn() === true && (
                  <Navbar.Toggle onClick={() => setShow(!showmobilenav)} className={"show"} />
                )}
              </>

          )}
          {<div className={`${showInput ? "d-none d-md-inline" : ""} header`}>
            <NavbarBrand className={`${isStudentLoggedIn() ? '' : 'non-user-header'}`}>
              <Link to={!question1Score ? "/student/dashboard" : "/student/dashboard"}>
                <img src={Moonrise} className="logo-sm" alt="Logo" height={30} width={160} />
              </Link>
            </NavbarBrand>
          </div>}
          {false ? (
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className={showmobilenav ? "showmobilemenu" : "noshowmobilemenu"}
            >
              <Navbar.Toggle
                className="navclose"
                aria-controls="responsive-navbar-nav"
                onClick={() => setShow(!showmobilenav)}
              />
            </Navbar.Collapse>
          ) : (
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className={showmobilenav ? "showmobilemenu" : "noshowmobilemenu "}
            >
              {!showmobilenav ? (
                <div className={`${showInput ? "d-none" : ""} navbar-nav`}>
                  {(isStudentLoggedIn() === true && activeUser.showPhases != 1) && <Nav.Item
                    onClick={() => localStorage.setItem("Current Tab", "dashboard")}
                    className="p-0 nav-content"
                  >
                    {false ? null : (
                      <Link
                        to="/student/dashboard"
                        className={`${(activeNav === "dashboard" || isActiveNav === "dashboard") &&
                          !currentPage.includes("/student/billing") &&
                          !currentPage.includes("/student/profile") &&
                          !currentPage.includes("/student/change-password") &&
                          !currentPage.includes("/student/customerlibrary/library/favourite")
                          ?
                          "nav-item-active-css nav-link"
                          : "nav-item"
                          }`}
                      >
                        {getTranslatedName("Dashboard")}
                      </Link>
                    )}
                  </Nav.Item>}

                  {(isStudentLoggedIn() === true && activeUser.showPhases == 1) && <Nav.Item
                    onClick={() => localStorage.setItem("Current Tab", "dashboard")}
                    className="p-0 nav-content"
                  >
                    {false ? null : (
                      <Link
                        to="/student/dashboard"
                        className={`${(activeNav === "phases" || isActiveNav === "phases") &&
                          !currentPage.includes("/student/billing") &&
                          !currentPage.includes("/student/profile") &&
                          !currentPage.includes("/student/change-password") &&
                          !currentPage.includes("/student/customerlibrary/library/favourite")
                          ?
                          "nav-item-active-css nav-link"
                          : "nav-item"
                          }`}
                      >
                        {getTranslatedName("My Program")}
                      </Link>
                    )}

                  </Nav.Item>}
                  {(isStudentLoggedIn() === true && activeUser.showPhases == 1) && <Nav.Item
                    onClick={() => {

                      setActiveNav("schedule")
                      setShow(false)
                      localStorage.setItem("Current Tab", "schedule")
                    }}
                    className="p-0 nav-content"
                  >
                    {(
                      <Link
                        to="/student/my-schedule"
                        className={`${(activeNav === "schedule" || isActiveNav === "schedule") &&
                          !currentPage.includes("/student/billing") &&
                          !currentPage.includes("/student/profile") &&
                          !currentPage.includes("/student/change-password") &&
                          !currentPage.includes("/student/customerlibrary/library/favourite")
                          ?
                          "nav-item-active-css nav-link"
                          : "nav-item"
                          }`}
                      >
                        {getTranslatedName("My Schedule")}
                      </Link>
                    )}

                  </Nav.Item>}

                  {(isStudentLoggedIn()) && <Nav.Item
                    onClick={() => localStorage.setItem("Current Tab", "library")}
                    className="p-0 nav-content"
                  >
                    <Link
                      to="/public/course-library"
                      className={`${(activeNav === "library" || isActiveNav === "library") &&
                        !currentPage.includes("/student/billing") &&
                        !currentPage.includes("/student/profile") &&
                        !currentPage.includes("/student/change-password") &&
                        !currentPage.includes("/student/customerlibrary/library/favourite")
                        ? "nav-item-active-css nav-link"
                        : "nav-item"
                        } `}
                    >
                      {getTranslatedName("More Courses")}
                    </Link>
                    {/* )} */}
                  </Nav.Item>}

                  {isStudentLoggedIn() && <Nav.Item onClick={() => localStorage.setItem("Current Tab", "Live")} className="p-0 nav-content">
                    <Link
                      to="/public/live-schedule"
                      className={`${(activeNav === "Live" || isActiveNav === "Live") &&
                        !currentPage.includes("/student/billing") &&
                        !currentPage.includes("/student/profile") &&
                        !currentPage.includes("/student/change-password") &&
                        !currentPage.includes("/student/customerlibrary/library/favourite")
                        ? "nav-item-active-css nav-link"
                        : "nav-item p-0"
                        } `}
                    >
                      {getTranslatedName('Live')}
                    </Link>
                  </Nav.Item>}


                  {/* PENSIL */}
                  {isStudentLoggedIn() === true &&
                    <Nav.Item
                      className="p-0 nav-content"

                      onClick={() => {
                        localStorage.setItem("Current Tab", "Support")
                        setActiveNav("Support")
                      }}
                    >
                      <Link
                        to="/student/community-support"
                        className={`${(activeNav === "Support" || isActiveNav === "Support") &&
                          !currentPage.includes("/student/billing") &&
                          !currentPage.includes("/student/profile") &&
                          !currentPage.includes("/student/change-password") &&
                          !currentPage.includes("/student/customerlibrary/library/favourite")
                          ? "nav-item-active-css nav-link"
                          : "nav-item"
                          } `}
                      >
                        {getTranslatedName("Support")}
                      </Link>
                    </Nav.Item>}
                </div>
              )
                :
                // isStudentLoggedIn() ? 
                (
                  <>
                    <Navbar.Toggle
                      className="navclose  me-2 mb-2"
                      aria-controls="responsive-navbar-nav"
                      onClick={() => setShow(!showmobilenav)}
                    />

                    <div ref={headerRef} className="mt-3 pt-4 mx-2 d-flex flex-column text-left collapse-nav ">


                      {(isStudentLoggedIn() == true && activeUser.showPhases != 1) && <Nav.Item
                        onClick={() => {
                          setActiveNav("dashboard")
                          setShow(false)
                        }}
                        className={`${isActiveNav === "dashboard" &&
                          !currentPage.includes("/public/course-library") &&
                          !currentPage.includes("student/symptom-chart") &&
                          !currentPage.includes("student/profile") &&
                          !currentPage.includes("/student/push-notification") &&
                          !currentPage.includes("student/change-password") &&
                          !currentPage.includes("student/support") &&
                          !currentPage.includes("/public/live-schedule")
                          ? "nav-item-active"
                          : "nav-link"
                          } `}
                      >
                        <Link className="nav-link p-0" to="/student/dashboard">
                          Dashboard
                        </Link>
                      </Nav.Item>}

                      {(isStudentLoggedIn() == true && activeUser.showPhases == 1) && <Nav.Item
                        onClick={() => {
                          setActiveNav("phases")
                          setShow(false)
                        }}
                        className={`${isActiveNav === "phases"
                          ? "nav-item-active"
                          : "nav-link"
                          } `}
                      >
                        <Link className="nav-link p-0" to="/student/dashboard">
                          {getTranslatedName("My Program")}
                        </Link>
                      </Nav.Item>}
                      {(isStudentLoggedIn() == true && activeUser.showPhases == 1) && <Nav.Item
                        onClick={() => {
                          setActiveNav("schedule")
                          setShow(false)
                        }}
                        className={`${isActiveNav === "schedule"
                          ? "nav-item-active"
                          : "nav-link"
                          } `}
                      >
                        <Link className="nav-link p-0" to="/student/my-schedule">
                          {getTranslatedName("My Schedule")}
                        </Link>
                      </Nav.Item>}


                      {(isStudentLoggedIn()) && <Nav.Item
                        onClick={() => {
                          setActiveNav("library")
                          setShow(false)
                        }}
                        className={`${isActiveNav === "library" || currentPage.includes("/public/course-library")
                          ? "nav-item-active"
                          : "nav-link"
                          } `}
                      >
                        <Link to="/public/course-library" className="nav-link p-0">
                          {getTranslatedName("More Courses")}
                        </Link>
                      </Nav.Item>}
                      {isStudentLoggedIn() && <Nav.Item onClick={() => {
                        setActiveNav("Live")
                        setShow(false)
                      }} className={`${(isActiveNav === "Live") &&
                        !currentPage.includes("/student/dashboard") &&
                        !currentPage.includes("/public/course-library") &&
                        !currentPage.includes("/student/billing") &&
                        !currentPage.includes("/student/profile") &&
                        !currentPage.includes("/student/change-password") &&
                        !currentPage.includes("/student/customerlibrary/library/favourite")
                        ? "nav-item-active "
                        : "nav-item"
                        } `}>
                        <Link
                          to="/public/live-schedule"
                          className="nav-link p-0"
                        >
                          Live
                        </Link>
                      </Nav.Item>}


                      {/* {(isStudentLoggedIn() && activeUser.showPhases == 1) && <Nav.Item
                        onClick={() => {
                          setActiveNav("library")
                          setShow(false)
                        }}
                        className={`${isActiveNav === "library" || currentPage.includes("/public/course-library")
                          ? "nav-item-active"
                          : "nav-link"
                          } `}
                      >
                        <Link to="/public/course-library" className="nav-link p-0">
                          {getTranslatedName("More Courses")}
                        </Link>
                      </Nav.Item>} */}

                      {isStudentLoggedIn() === true &&
                        <Nav.Item
                          className={`${isActiveNav === "Support" ? "nav-item-active" : "nav-link"}`}
                          onClick={() => {
                            localStorage.setItem("Current Tab", "Support")
                            setActiveNav("Support")
                          }}
                        >
                          <Link
                            to="/student/community-support"
                            className="nav-link p-0"
                          >
                            {getTranslatedName("Support")}
                          </Link>
                        </Nav.Item>}

                    </div>
                  </>
                )
              }
            </Navbar.Collapse>

          )}
          {isStudentLoggedIn() &&
            <>
              <div className={`${showInput ? "d-flex " : "d-xl-flex d-none"} align-items-center  input-group flex-nowrap cstm-global-search m-auto- ms-auto-`}>
                <div className="input-group-prepend">
                  <span className="input-group-text px-1 border-0 bg-transparent">
                    <BiSearch color="#EED3DD" size={18} className="search-icon ms-2" />
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control border-0 bg-transparent text-white"
                  placeholder={getTranslatedName("Search")}
                  // onClick={handleSearch}
                  onChange={(e) => handleSearchKey(e)}
                  onKeyUp={handleSearch}
                  value={search ? search : key}
                // value={key ? key : search}
                // onChange={e => handleSearchMultiple(e)}
                />
              </div>
              {((currentPage.includes("/public/search") || (currentPage.includes("/student/search"))) || showInput) &&
                <span className={`${showInput ? "d-xl-flex d-md-none d-flex " : ""} pe-lg-2 pe-0 border-0 bg-transparent`} >
                  <RxCross1 color="#E3DEE7" size={18} className="search-icon ms-2 pointer" role="button" onClick={() => handleSearchClose()} />
                </span>}

            </>}
          {isStudentLoggedIn() === true ? (
            <>
              <div className={`${showInput ? "d-md-flex d-none" : "d-flex"} profile-wraper dropdown  flex-row align-items-center`}>
                {false ? null : (
                  <div className="d-flex pointer icon-header-nav me-md-2 me-1">
                    {showInput ?
                      <RxCross1 color="#EED3DD" size={22} className={` search-icon ms-lg-2 ms-0 pointer`} role="button" onClick={() => handleSearchClose()} /> :
                      <FaRegHeart
                        color="#EED3DD"
                        size={22}
                        className="d-xl-inline  d-none"
                        onClick={() => history.push("/student/customerlibrary/library/favourite")}
                      />}
                    <BiSearch color="#EED3DD" size={22} className={`${showInput ? "d-none" : "d-xl-none d-inline"} search-icon ms-2- "`} role="button" onClick={() => handleSearchInput()} />
                    <span className="ms-2">
                      <a
                        tabIndex="0"
                        role="button"
                        aria-label="Notifications"
                        href="#"
                        className="wisp">
                        <svg fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          stroke="#EED3DD"
                          xmlns="<http://www.w3.org/2000/svg>">
                          <path d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9">
                          </path>
                        </svg>
                      </a>

                    </span>

                  </div>
                )}
                <hr />
                <Dropdown className="Useraction ">
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <img src={profileNew} className="profile-image me-1" alt="user" />
                    <span>
                      <FaChevronDown color=" #EED3DD" size={12} />
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu alignRight>
                    {false ? null : (
                      <>
                        <Dropdown.Item onClick={() => history.push("/student/profile")}>
                          <div className="dropdown-item" style={{ cursor: "pointer" }}>
                            {getTranslatedName("Profile")}
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => history.push("/student/customerlibrary/library/favourite")}>
                          <div className="dropdown-item" style={{ cursor: "pointer" }}>
                            {getTranslatedName("Favourites")}
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => history.push("/student/billing")}>
                          <div className="dropdown-item" style={{ cursor: "pointer" }}>
                            {getTranslatedName("Billing")}
                          </div>
                        </Dropdown.Item>

                      </>
                    )}
                    <Dropdown.Item onClick={() => logout()}>
                      <div className="dropdown-item" style={{ cursor: "pointer" }}>
                        {getTranslatedName("Sign out")}
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>
            </>
          ) : (
            ""
          )}
        </Navbar>


        {showTrackPopup && cPMStatus !== 1 && <Symptomtracker {...props} />}
      </div >

    </React.Fragment >
  )
}
