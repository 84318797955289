import React from 'react';
// import { isEmpty } from 'config/functions';
import { Helmet } from "react-helmet";
import { BASE_URL } from '../../config/constants';
import { isEmpty } from 'config/functions';



export default function HeaderMeta(props) {
    let title = props.title ? props.title + '\xa0 |\xa0 MoonRise' : "Women's Health Issues Portal. Online Learning Platform | MOONRISE"
    let description = props.description ? props.description : "A women's health portal where you'll find a range of specialised courses and articles. Check out our online learning platform. Remeber, be the women you are!"
    return (
        <div className="application">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:type" content="article" />
                <meta name="keywords" content="Health Issues" />
                <meta name="author" content={BASE_URL} />
                {!isEmpty(props.href) ? <link rel="canonical" href={BASE_URL + "" + props.href} /> : ""}
            </Helmet>
        </div>
    );

}


// import React, { Component } from 'react';
// import { isEmpty } from 'config/functions';
// import { Helmet } from "react-helmet";

// export default class HeaderMeta extends Component {
//     constructor(props) {
//         super(props);
//         this.initialState = {
//             title: (!isEmpty(props.title) ? props.title : "")
//         }
//         this.state = this.initialState;
//     }

//     render() {
//         return (
//             <div className="application">
//                 <Helmet>
//                     <meta charSet="utf-8" />
//                     <title>{this.state.title} |    Women Cycle</title>
//                 </Helmet>
//             </div>
//         );
//     }
// }
