import React from 'react';
import { useSelector } from 'react-redux';
import UpdatedDashboard from './UpdatedDashboard';
import PhasesDashboard from '../NewDashboard';

const CustomerDashboard = () => {

  const customerData = useSelector(state => state.register.userProfileData)
  const showPhases = customerData.showPhases == 1 ? true : false

  return (
    showPhases ? <PhasesDashboard /> : <UpdatedDashboard />
  )
}

export default CustomerDashboard