import { getTranslatedName, isEmpty } from "config/functions"
import { Card } from "react-bootstrap"
import { MdAvTimer } from "react-icons/md"
import StarRatings from "react-star-ratings"
import { Badge } from "reactstrap"
import placeholderImage from "../../../assets/images/placeholder_image.png"
import moreIcon from "../../../assets/images/Arrow.png"
import { useHistory } from "react-router-dom"
import base64 from "base-64"

const MoreCourses = ({ data }) => {
    const history = useHistory()
    const handleLibraryClick = item => {
        let url = ""
        if (item.libType == "course") {

            url = `/public/course/${base64.encode(item.libId)}`
        } else if (item.libType == "playlist") {

            url = `/public/playlist/${base64.encode(item.libId)}`

        } else if (item.libType == "video") {

            url = `/public/watch-videos/${base64.encode(item.libId)}`

        }
        history.push(url)
    }
    const getRating = rating => {
        if (rating !== null) {
            return `${Number(rating).toFixed(1)}`
        }
        return `0.0`
    }
    return (
        <>
            <div className="">
                <div className="nw-course-section h-100 row ">
                    {data.map((item, index) => (
                        <div className="nw-course-outer h-100 col-xl-4 col-sm-6 col-12  py-3 h-100" key={index}>
                            <div
                                className="nw-course-item playlist-footer nw-library-item"
                                onClick={() => handleLibraryClick(item)}
                            >
                                <div className="overlay-thumbnail">
                                    <Badge
                                        className={`${item.libType == "course"
                                            ? "course-tag"
                                            : item.libType == "playlist"
                                                ? "playlist-tag"
                                                : "video-tag"
                                            } status-tag type-library text-center pb-3`}
                                    >
                                        {item.libType == "course"
                                            ? getTranslatedName("Course")
                                            : item.libType == "playlist"
                                                ? getTranslatedName("Playlist")
                                                : getTranslatedName("Video")}
                                    </Badge>
                                </div>
                                <div className="nw-courseThumb">
                                    <img src={item.thumbnailUrl ? item.thumbnailUrl : placeholderImage} alt="thumbnail" />
                                </div>
                                <div
                                    className={`${item.libType == "course"
                                        ? "theme-course"
                                        : item.libType == "playlist"
                                            ? "theme-playlist"
                                            : "theme-video"
                                        } nw-course-content`}
                                >
                                    <h5
                                        className={`fw-bold mb-2 ${item.libType == "course"
                                            ? "text-violet"
                                            : item.libType == "playlist"
                                                ? "text-cstm-orange"
                                                : item.libType == "video"
                                                    ? "text-main"
                                                    : "text-main"
                                            }`}
                                    >
                                        {item.title ? item.title : ""}
                                    </h5>
                                    <div className="nw-course-rating d-flex align-items-center">
                                        <StarRatings
                                            rating={item.rating}
                                            starRatedColor="#ffd700"
                                            numberOfStars={5}
                                            className="rating-star "
                                            starDimension="20px"
                                        />
                                        <span className="mx-1 mt-1 rating"> {getRating(item.rating)}</span>
                                    </div>

                                    <div className="nw-course-bottom-section d-flex align-items-center mt-2">
                                        <div className="d-flex align-items-center">
                                            <MdAvTimer color="#76164B" className="me-1" size={18} />
                                            <span>15 min a day</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
export default MoreCourses