import {put, takeLatest, fork, takeEvery} from 'redux-saga/effects'
import axios from 'axios'
function* getFeatureCourse() {
  yield takeEvery('FEATURED_COURSES', featuredCourses)
}
function* featuredCourses() {
  try {
    let res = yield axios.get('api/get_featured_courses')
    let data = yield res.data.data
    yield put({type: 'GET_FEATURED_COURSES', payload: data})
    // console.log(data)
    // await setFeatured_courses_data([...featured_courses_data, ...data])
    // await console.log(data)
  } catch (err) {
    // alert(err)
    // throw (err)
    alert(err)
  }
}

function* getTestimonial() {
  // console.log('watcher')
  yield takeEvery('TESTIMONIAL', tesimonial)
}
function* tesimonial() {
  try {
    let res = yield axios.get('api/get_testimonials')
    let data = yield res.data
    // console.log(data)
    if (data.status) {
      yield put({type: 'GET_TESTIMONIALS', payload: data.data})
    } else {
      yield put({type: 'GET_TESTIMONIALS', payload: []})
    }

    // console.log(data)
    // await setFeatured_courses_data([...featured_courses_data, ...data])
    // await console.log(data)
  } catch (err) {
    // throw (err)
    alert(err)
  }
}

function* getGroupWiseCourse() {
  // console.log('watcher')
  yield takeEvery('GROUPWISE_COURSES', groupwiseCourses)
}
function* groupwiseCourses() {
  try {
    let res = yield axios.get('api/get_groupwise_courses')
    let data = yield res.data.data
    yield put({type: 'GET_GROUPWISE_COURSES', payload: data})
    //   yield console.log(data)
    // await setFeatured_courses_data([...featured_courses_data, ...data])
    // await console.log(data)
  } catch (err) {
    // throw (err)
    alert(err)
  }
}

function* signUp() {
  // console.log('watcher')
  yield takeLatest('SIGNUP', userSignUp)
}
function* userSignUp(payload) {
  const {userInfo} = payload
  // console.log(userInfo)
  try {
    let res = yield axios.post('api/register', userInfo)
    let data = yield res.data
    // console.log(data)
    yield put({type: 'SIGNUP_SUCCESSFULLY', payload: data})
    //   yield console.log(data)
    // await setFeatured_courses_data([...featured_courses_data, ...data])
    // await console.log(data)
  } catch (err) {
    yield put({type: 'SIGNUP_FAILED', payload: err})
    alert(err)
  }
}

function* getAllCreator() {
  // console.log('watcher')
  yield takeEvery('GET_ALL_CREATORS', getAllCreators)
}
function* getAllCreators() {
  try {
    let res = yield axios.get('api/get_all_creators')
    let data = yield res.data.data
    yield put({type: 'GET_ALL_CREATOR', payload: data})
    // console.log(data)
    // await setFeatured_courses_data([...featured_courses_data, ...data])
    // await console.log(data)
  } catch (err) {
    // throw (err)
    alert(err)
  }
}

function* getCreatorProfile() {
  // console.log('watcher')
  yield takeLatest('GET_CREATOR_PROFILE_BY_ID', getCreatorProfileById)
}
function* getCreatorProfileById(creatorID) {
  try {
    let id = creatorID.payload
    let res = yield axios.post('api/get_creator', {creatorID: id})
    let data = yield res.data.data
    yield put({type: 'GET_CREATOR_PROFILE_BY', payload: data})
    // console.log(data)
    // await setFeatured_courses_data([...featured_courses_data, ...data])
    // await console.log(data)
  } catch (err) {
    // throw (err)
    alert(err)
  }
}

function* categoryWatcher() {
  yield takeEvery('CATEGORYWISE_COURSES_PUBLIC', caregorywiseCourses)
}

function* caregorywiseCourses(payload) {
  const {categoryID} = payload
  try {
    // console.log(courseID)
    let res = yield axios.post('api/getcourses_byCategoryID', {categoryID})
    let data = yield res.data.data
    // yield console.log(data)
    yield put({type: 'GET_CATOGERYWISE_COURSES_PUBLIC', payload: data})
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}

function* symptomWatcher() {
  yield takeLatest('SYMPTOM_WISE_COURSES_PUBLIC', symptomwiseCourses)
}
function* symptomwiseCourses(payload) {
  const {symptomID} = payload
  try {
    // console.log(courseID)
    let res = yield axios.post('api/getcourses_bySymptomID', {symptomID})
    let data = yield res.data.data
    // yield console.log(data)
    yield put({type: 'GET_SYMPTOMWISE_COURSES_PUBLIC', payload: data})
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}

function* planListWatcher() {
  yield takeLatest('PLAN_LIST', planList)
}

function* planList() {
  // const {symptomID} = payload
  try {
    // console.log(courseID)
    let res = yield axios.get('api/getCustomerAvailablePlanList')
    let data = yield res.data.data.planList

    // yield console.log(data)
    yield put({type: 'GET_PLAN_LIST', payload: data})
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}

function* reviewListWatcher() {
  yield takeLatest('REVIEW_LIST', planList)
}

function* reviewList(payload) {
  const {formdata} = payload
  try {
    // console.log(courseID)
    let res = yield axios.post('api/get_course_review', {formdata})
    let data = yield res.data.data

    // yield console.log(data)
    yield put({type: 'GET_REVIEW_LIST', payload: data})
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}

function* categorySymptomsWatcher() {
  yield takeLatest('CATEGORY_SYMPTOMS', categorySymptoms)
}
function* categorySymptoms() {
  // const { symptomID } = payload
  try {
    // console.log(courseID)
    let res = yield axios.post('api/getCategoryAndSymptomList', {})
    let data = yield res.data.data
    // yield console.log(data)
    yield put({type: 'GET_CATEGORIES_SYMPTOMS', payload: data})
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}

function* upcomingCoursesWatcher() {
  yield takeLatest('UPCOMING_COURSES', upcomingCourses)
}
function* upcomingCourses() {
  // const { symptomID } = payload
  try {
    // console.log(courseID)
    let res = yield axios.post('api/getUpcomingCourseList', {})
    let data = yield res.data.data
    // yield console.log(data)
    yield put({type: 'GET_UPCOMING_COURSES', payload: data})
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}

function* upcomingCoursesByCreatorWatcher() {
  yield takeLatest('UPCOMING_COURSES_BY_CREATOR', upcomingCoursesByCreator)
}
function* upcomingCoursesByCreator(payload) {
  const {creatorID} = payload
  try {
    // console.log(courseID)
    let res = yield axios.post('api/getUpcomingCourseList', {creatorID})
    let data = yield res.data.data
    // yield console.log(data)
    yield put({type: 'GET_UPCOMING_COURSES_BY_CREATOR', payload: data})
  } catch (err) {
    alert(err)
    // console.log(err)
  }
}

function* symptomsWatcher() {
  yield takeLatest('SYMPTOMS', symptoms)
}
function* symptoms() {
  try {
    let res = yield axios.get('api/getSymptomList', {})
    let data = yield res.data.data
    yield put({type: 'GET_SYMPTOMS', payload: data})
  } catch (err) {
    console.log(err)
  }
}

function* selectedSymptomsWatcher() {
  yield takeLatest('SELECTED_SYMPTOMS', selected_symptoms)
}
function* selected_symptoms(payload) {
  try {
    let res = yield axios.post('api/getSelectedSymptoms', {})
    let data = yield res.data.data
    yield put({type: 'GET_SELECTED_SYMPTOMS', payload: data})
  } catch (err) {
    console.log(err)
  }
}

function* checkSymptomTrackIdWatcher() {
  yield takeLatest('CHECK_SYMPTOM_TRACK_ID', checkSymptomTrackId)
}
function* checkSymptomTrackId(payload) {
  const {trackerInfoId, customerId} = payload
  try {
    let res = yield axios.post('api/getSymptomTrackDataById', {trackerInfoId, customerId})
    let data = yield res.data.data
    yield put({type: 'GET_SYMPTOM_TRACK_DATA_BY_ID', payload: data})
  } catch (err) {
    console.log(err)
  }
}

function* symptomTrackerInfoWatcher() {
  yield takeLatest('SYMPTOM_TRACKER_INFO', symptomTrackerInfo)
}
function* symptomTrackerInfo(payload) {
  try {
    let res = yield axios.post('api/getSymptomTrackerInfo', {})
    let data = yield res.data.data
    yield put({type: 'GET_SYMPTOM_TRACKER_INFO', payload: data})
  } catch (err) {
    console.log(err)
  }
}

function* getIpInfoWatcher() {
  yield takeLatest('GET_IP_INFO', getIpInfo)
}
function* getIpInfo(payload) {
  try {
    let res = yield axios.post('admin/getIPData', {})
    let data = yield res.data.data
    yield put({type: 'GET_IP_INFORMATION', payload: data})
  } catch (err) {
    console.log(err)
  }
}

export default [
  fork(getFeatureCourse),
  fork(getTestimonial),
  fork(getGroupWiseCourse),
  fork(signUp),
  fork(getAllCreator),
  fork(getCreatorProfile),
  fork(categoryWatcher),
  fork(symptomWatcher),
  fork(planListWatcher),
  fork(categorySymptomsWatcher),
  fork(upcomingCoursesWatcher),
  fork(upcomingCoursesByCreatorWatcher),
  fork(symptomsWatcher),
  fork(selectedSymptomsWatcher),
  fork(checkSymptomTrackIdWatcher),
  fork(symptomTrackerInfoWatcher),
  fork(getIpInfoWatcher),
]
