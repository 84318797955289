import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Badge, Button, Card } from "reactstrap"
import thumbnail from "../../../assets/images/Video_cover_19.png"
import heartFill from "../../../assets/images/heartNew.svg"
import showMore from "../../../assets/images/showMore.png"
import alert from "../../../assets/images/Danger.svg"
import { MdAvTimer } from "react-icons/md"
import Slider from "react-slick"
import heartFilled from "../../../assets/images/heartFilledNew.svg"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { getTranslatedName, isEmpty, isValid } from "config/functions"
import { BsStarFill } from "react-icons/bs"
import soundwave from "../../../assets/images/soundwave.gif"
import base64 from "base-64"
import { handleLessonFavourite } from "./apiStore"
import PdfDefault from "../../../assets/images/BG_Pdf.jpeg"
import PageLoader from "../common-components/PageLoader"

const UpdatedWaitingForYou = ({ dashboardData, handleMore, dashboardLockedAlgoData, ...props }) => {
  // routes vars
  const history = useHistory()
  // function to get library average rating
  const getAverageRating = reviews => {
    if (!isEmpty(reviews)) {
      let ratingArr = reviews.map(item => item.rating)
      let totalRating = ratingArr.reduce((a, b) => a + b)
      let avgRating = totalRating / reviews.length
      return avgRating
    }
    return 0
  }

  // AppItems
  let Appitems = !isEmpty(dashboardData)
    ? dashboardData.map(item => {
        return {
          id: item.customerLibraryID,
          thumbnail:
            item.lessonType === 5
              ? `${PdfDefault}`
                ? item.lessonType === 6
                  ? soundwave
                  : item.lessonThumbnailUrl
                : item.lessonThumbnailUrl
              : item.lessonThumbnailUrl,
          title: item.libraryTitle,
          libraryType: item.libraryType,
          isFavourite: 1,
          libraryLessons: item.lessonName,
          rating: item.rating,
          libraryMapID: item.libraryMapID,
          teacher: item.teacherFirstName + " " + item.teacherLastName
        }
      })
    : []

  // Appitems = Appitems.reverse()
  let newArray = Appitems

  //   Slider Settings

  const settings = {
    className: "center",
    centerMode: false,
    infinite: false,
    centerPadding: "20px",
    speed: 500,
    row: 1,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 5.5,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4.25,
          slidesToScroll: 4,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4.15,
          slidesToScroll: 3,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.75,
          slidesToScroll: 3,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2.95,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2.25,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1.75,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.65,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1.45,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1
        }
      }
    ]
  }

  //   Favourite

  const [isFav, setFav] = useState(false)
  const handleClick = async (lessonID, favStatus) => {
    return
    let bodyData = { lessonId: lessonID, favStatus: favStatus === 1 ? 0 : 1 }
    const response = await handleLessonFavourite(bodyData)
    if (response.status === true) {
      props.getDashboardData()
    }
  }
  const handleFavourite = () => {
    setFav(!isFav)
  }

  // function to get library average rating
  const getRating = rating => {
    if (rating !== null) {
      return `${Number(rating).toFixed(1)}`
    }
    return `0.0`
  }

  // Function to handle library click
  const handleLibraryClick = item => {
    let url = ""
    if (item.libraryType == 3 || item.lType == "video") {
      // url = `/student/libary-lessons/${base64.encode(item.libraryLessons[0].Id)}`
      url = `/student/libary-video/${base64.encode(item.libraryMapID ? item.libraryMapID : item.objectID)}`
    } else {
      url = `/student/library/${base64.encode(item.libraryMapID ? item.libraryMapID : item.objectID)}`
    }
    history.push(url)
  }

  return (
    <React.Fragment>
      {props.isLoading ? <PageLoader /> : ""}
      {newArray?.length > 0 ? (
        <div className="playlist-lessons">
          <div className="course-viewers">
            <Slider {...settings}>
              {newArray.map((item, index) => (
                <div className="video-item-css" key={index}>
                  <div className="video-wrapper w-100">
                    {false && (
                      <Button className="heart btn-fav" onClick={() => handleClick(item.id, item.isFavourite)}>
                        {item.isFavourite !== 1 ? (
                          <img src={heartFill} className="heart-icon ms-25" alt="" />
                        ) : (
                          <img src={heartFilled} className="heart-icon ms-25" alt="" />
                        )}
                      </Button>
                    )}
                    <span className="">
                      {item.libraryType && (
                        <Badge className="status-tag type-library text-center pb-3">
                          {" "}
                          {item.libraryType === 1
                            ? getTranslatedName("Course")
                            : item.libraryType === 2
                            ? getTranslatedName("Playlist")
                            : item.libraryType === 3
                            ? getTranslatedName("Video")
                            : ""}
                        </Badge>
                      )}
                    </span>
                    <div className="video-size pointer">
                      <span
                        onClick={() => handleLibraryClick(item)}
                        to={`/student/library/${base64.encode(item.libraryMapID)}`}
                        className="no-drag"
                      >
                        <div className="video-wrapper pdf-overlay border-0 course-image w-100">
                          <div
                            className="hasImage video-cover-bg-css "
                            style={{ backgroundImage: `url(${item.thumbnail ? item.thumbnail : thumbnail})` }}
                          >
                            <div className="pdf-overlay"></div>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="video-caption-css video-size h-100 d-flex align-items-center justify-content-start  px-2 py-2">
                    <span className=" my-1 progress-container me-2">
                      {" "}
                      <img src={alert} alt="alert" />
                    </span>
                    <span className="video-caption d-flex  align-items-center text-complete-program ">
                      Complete your current active program
                    </span>
                  </div>

                  <div className="video-size h-100 d-flex flex-column mt-2">
                    <span className="video-caption figure-caption text-truncate">{item.title}</span>
                    <div className="d-flex align-items-center video-caption mt-1">
                      {item.teacher ? <span className="author  me-2">{item.teacher}</span> : ""}
                      <span className="rating rating-video d-flex align-items-baseline">
                        <BsStarFill color="#FDC83C" className="star-css mx-1" />
                        <span>{getRating(item.rating)}</span>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              {dashboardData?.total > 4 ? (
                <Card onClick={handleMore} className="show-more-card text-center">
                  <div>
                    <img src={showMore} alt="more" />
                    <p className=" mt-2 show-more-card-text">Show more</p>
                  </div>
                </Card>
              ) : (
                ""
              )}
            </Slider>
          </div>
        </div>
      ) : (
        <div className="playlist-lessons">
          <div className="course-viewers">
            <Slider {...settings}>
              {dashboardLockedAlgoData?.map((item, index) => (
                <div className="video-item-css" key={index}>
                  <div className="video-wrapper w-100">
                    {/* {false && (
                      <Button className="heart btn-fav"
                      //  onClick={() => handleClick(item.id, item.isFavourite)}
                       >
                        {item.isFavourite !== 1 ? (
                          <img src={heartFill} className="heart-icon ms-25" alt="" />
                        ) : (
                          <img src={heartFilled} className="heart-icon ms-25" alt="" />
                        )}
                      </Button>
                    )} */}
                    <span className="">
                      {item.lType && (
                        <Badge className="status-tag type-library text-center pb-3">
                          {" "}
                          {item.lType == "course"
                            ? getTranslatedName("Course")
                            : item.lType == "playlist"
                            ? getTranslatedName("Playlist")
                            : item.lType == "video"
                            ? getTranslatedName("Video")
                            : ""}
                        </Badge>
                      )}
                    </span>
                    <div className="video-size pointer">
                      <span
                        onClick={() => handleLibraryClick(item)}
                        to={`/student/library/${base64.encode(item.objectID)}`}
                        className="no-drag"
                      >
                        <div className="video-wrapper pdf-overlay border-0 course-image w-100">
                          <div
                            className="hasImage video-cover-bg-css "
                            style={{ backgroundImage: `url(${item.tLUrl ? item.tLUrl : thumbnail})` }}
                          >
                            <div className="pdf-overlay"></div>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="video-caption-css video-size h-100 d-flex align-items-center justify-content-start  px-2 py-2">
                    <span className=" my-1 progress-container me-2">
                      {" "}
                      <img src={alert} alt="alert" />
                    </span>
                    <span className="video-caption d-flex  align-items-center text-complete-program ">
                      Complete your current active program
                    </span>
                  </div>

                  <div className="video-size h-100 d-flex flex-column mt-2">
                    <span className="video-caption figure-caption text-truncate">{item.dtitle}</span>
                    <div className="d-flex align-items-center video-caption mt-1">
                      {item.tFN ? (
                        <span className="author  me-2">
                          {item.tFN} {item.tLN}
                        </span>
                      ) : (
                        ""
                      )}
                      {/* <span className="rating rating-video d-flex align-items-baseline">
                        <BsStarFill color="#FDC83C" className="star-css mx-1" />
                        <span>{getRating(item.rating)}</span>
                      </span> */}
                    </div>
                  </div>
                </div>
              ))}
              {dashboardLockedAlgoData?.total > 4 ? (
                <Card onClick={handleMore} className="show-more-card text-center">
                  <div>
                    <img src={showMore} alt="more" />
                    <p className=" mt-2 show-more-card-text">Show more</p>
                  </div>
                </Card>
              ) : (
                ""
              )}
            </Slider>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default UpdatedWaitingForYou
