import mixpanel from 'mixpanel-browser';
import { REACT_APP_MIX_PANEL_KEY } from '../../config/constants';
// console.log(`REACT_APP_MIX_PANEL_KEY ${REACT_APP_MIX_PANEL_KEY}`);
mixpanel.init(REACT_APP_MIX_PANEL_KEY);

/* let env_check = process.env.NODE_ENV === 'production'; */
let env_check = true;

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;