import cookie from 'react-cookies';
import base64 from 'base-64';
import moment from 'moment';
import { rewind } from 'react-helmet';
import LanguageData from '../views/common/translator/sw.json'

var hasOwnProperty = Object.prototype.hasOwnProperty;

export function isEmpty(obj) {
	if (obj === "") return true;
	if (obj === 0) return true;
	if (obj === "0") return true;
	if (obj == null) return true;
	if (obj === false) return true;
	if (obj.length > 0) return false;
	if (obj.length === 0) return true;
	if (obj == "undefined") return true;
	if (obj == "null") return true;
	if (typeof (obj) == "undefined") return true;
	if (typeof obj !== "object") return true;
	for (var key in obj) {
		if (hasOwnProperty.call(obj, key)) return false;
	}
	return false
}

export function strip_html_tags(str) {
	if ((str === null) || (str === ''))
		return false;
	else
		str = str.toString();
	return str.replace(/<[^>]*>/g, '');
}

export const wordLimiter = (str, no_words) => {
	str = !isEmpty(str) ? str : "";
	let string = str.split(" ").splice(0, (no_words) ? no_words : str.split(' ').length).join(" ");
	return string;
}
export const characterLimiter = (str, no_words) => {
	str = !isEmpty(str) ? str : "";
	let string = str.substring(0, no_words)
	return (str.length > string.length) ? string : string;
}
export const isMore = (str, no_words) => {
	str = !isEmpty(str) ? str : "";
	let string = str.split(" ").splice(0, (no_words) ? no_words : str.split(' ').length).join(" ");
	return (str.length > string.length) ? true : false;
}
export const isMoreForChar = (str, no_words) => {
	str = !isEmpty(str) ? str : "";
	let string = str.substring(0, no_words)
	return (str.length > string.length) ? true : false;
}



export function isValid(obj) {
	if (obj === "") return true
	if (obj === 0) return true
	if (obj === "0") return true
	if (obj === null) return true
	if (obj === false) return true
	if (typeof obj === "undefined") return true
	if (obj.length > 0) return false
	if (obj.length === 0) return true
	//if (typeof obj !== "object") return true 
	if (typeof obj === 'object') {
		for (const key in obj) {
			if (hasOwnProperty.call(obj, key)) return false
		}
	}
	return false
}

export function isEmptyObj(obj) {
	for (var prop in obj) {
		if (obj.hasOwnProperty(prop)) {
			return false;
		}
	}
	return true;
}

export function saveCookie(key, value) {
	const expiryDate = new Date();
	expiryDate.setTime(expiryDate.getTime() + (24 * 60 * 60 * 1000));
	value = base64.encode(value);
	cookie.save(
		key,
		value,
		{
			path: '/',
			httpOnly: false,
			SameSite: 'none',
			secure: false,
			expires: expiryDate
		}
	)
}

export function removeCookie(key) {
	//key 		= base64.encode(key);
	cookie.remove(
		key,
		{
			path: '/',
			httpOnly: false,
			SameSite: 'none',
			secure: false,
		}
	)
}

export function getCookie(key) {
	//key 			= base64.encode(key);
	let value = cookie.load(key);
	if (!isEmpty(value)) {
		value = base64.decode(value);
	}
	return value;
}

export function formatDate(date) {
	var day = date.getDate();
	var month = date.getMonth() + 1;
	var year = date.getFullYear();

	month = (month.toString().length === "1" ? "0" + month : month)
	day = (day.toString().length === "1" ? "0" + day : day)

	return year + '-' + month + '-' + day;
}


export function toggleNav(e) {
	var menu = document.querySelector("#admin-container");
	var mainMenu = document.querySelectorAll('main')
	if (menu.classList.contains("sidebar-hide")) {
		menu.classList.remove("sidebar-hide");
	} else {
		menu.classList.add("sidebar-hide");
	}
	for (let i = 0; i < mainMenu.length; i++) {
		const element = mainMenu[i];
		element.classList.toggle('sidebar-toggle')
	}
}

export function sortTable(columnNumber, tableId) {
	var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
	table = document.getElementById(tableId);
	switching = true;
	// Set the sorting direction to ascending:
	dir = "asc";
	/* Make a loop that will continue until
	no switching has been done: */
	while (switching) {
		// Start by saying: no switching is done:
		switching = false;
		rows = table.rows;
		/* Loop through all table rows (except the
		first, which contains table headers): */
		for (i = 1; i < (rows.length - 1); i++) {
			// Start by saying there should be no switching:
			shouldSwitch = false;
			/* Get the two elements you want to compare,
			one from current row and one from the next: */
			x = rows[i].getElementsByTagName("TD")[columnNumber];
			y = rows[i + 1].getElementsByTagName("TD")[columnNumber];
			/* Check if the two rows should switch place,
			based on the direction, asc or desc: */
			if (dir == "asc") {
				if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
					// If so, mark as a switch and break the loop:
					shouldSwitch = true;
					break;
				}
			} else if (dir == "desc") {
				if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
					// If so, mark as a switch and break the loop:
					shouldSwitch = true;
					break;
				}
			}
		}
		if (shouldSwitch) {
			/* If a switch has been marked, make the switch
			and mark that a switch has been done: */
			rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
			switching = true;
			// Each time a switch is done, increase this count by 1:
			switchcount++;
		} else {
			/* If no switching has been done AND the direction is "asc",
			set the direction to "desc" and run the while loop again. */
			if (switchcount == 0 && dir == "asc") {
				dir = "desc";
				switching = true;
			}
		}
	}
}

export function getTimeInMinuteAndSecond(second) {
	let returnInfo = { minutes: 0, seconds: 0 };
	if (!isEmpty(second)) {
		returnInfo.minutes = Math.floor(parseInt(second) / 60);
		returnInfo.seconds = Math.floor(second - returnInfo.minutes * 60);
	}
	return returnInfo;
}

export function validateEmail(email) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function shortName(fullName) {
	var split_names = fullName.trim().split(" ");
	if (split_names.length > 1) {
		var shortName = "";
		//	for(var i=0;i<split_names.length;i++)
		for (var i = 0; i < 2; i++) {
			shortName = shortName + "" + split_names[i].charAt(0);
		}
		return shortName.trim();
	}
	else { return split_names[0].charAt(0) };
}

export function avgRating(reviewArr) {
	var review = 0;
	for (var i = 0; i < reviewArr.length; i++) {
		review = review + parseFloat(reviewArr[i].star);
	}
	return review / reviewArr.length;
}


export const getTimeAgo = (date, dateTimeInSecond = false) => {
	if (dateTimeInSecond) {
		date = moment(moment().subtract(dateTimeInSecond, 'seconds'));
	}
	var msPerMinute = 60 * 1000;
	var msPerHour = msPerMinute * 60;
	var msPerDay = msPerHour * 24;
	var msPerMonth = msPerDay * 30;
	var msPerYear = msPerDay * 365;

	var difference = moment()._d - moment(date)._d;
	if (difference < msPerMinute) {
		return Math.round(difference / 1000) + 's ago';
	}
	else if (difference < msPerHour) {
		return Math.round(difference / msPerMinute) + 'm ago';
	}
	else if (difference < msPerDay) {
		return Math.round(difference / msPerHour) + 'h ago';
	}
	else if (difference < msPerMonth) {
		return Math.round(difference / msPerDay) + ' days ago';
	}
	else if (difference < msPerYear) {
		return Math.round(difference / msPerMonth) + ' months ago';
	}
	else {
		return Math.round(difference / msPerYear) + ' years ago';
	}
}


export const count_duplicate = (array) => {
	let reviewInfo = {};
	let counts = {};
	let reviewArr = [];
	let total = 0;
	for (let i = 0; i < array.length; i++) {
		if (counts[array[i].star]) {
			counts[array[i].star] += 1
		} else {
			counts[array[i].star] = 1
		}
	}
	for (let prop in counts) {
		if (counts[prop] >= 0) {
			//console.log(prop + " counted: " + counts[prop] + " times.")
			total = total + prop * counts[prop];
		}
	}
	//console.log(counts)
	for (var j = 1; j < 6; j++) {
		var counter = 0;
		for (let prop in counts) {
			counter++;
			if (j == prop) {
				let obj = {};
				obj.review = counts[prop];
				obj.star = parseInt(prop);
				//	console.log(counts[prop])
				obj.percent = (counts[prop] * 100) / array.length;
				reviewArr.push(obj);
				counter = 0;
			}
			else {
				if (counter == Object.keys(counts).length) {
					let obj = {};
					obj.review = 0;
					obj.star = j;
					obj.percent = 0;
					reviewArr.push(obj);
				}
			}
		}
	}
	//console.log(reviewArr)
	reviewInfo.counts = counts;
	reviewInfo.reviewArr = reviewArr.reverse();
	reviewInfo.totalAvg = (total / array.length).toFixed(1);
	reviewInfo.len = array.length;
	return (reviewInfo)
}

export const dynamicsort = (property, order, type) => {
	var sort_order = 1;
	if (order === "desc") {
		sort_order = -1;
	}
	return function (a, b) {
		if (type == 'string') {
			if (sort_order == -1) {
				return b[property].localeCompare(a[property]);
			} else {
				return a[property].localeCompare(b[property]);
			}
		}
		else if (type == 'number') {
			// a should come before b in the sorted order
			if (a[property] < b[property]) {
				return -1 * sort_order;
				// a should come after b in the sorted order
			} else if (a[property] > b[property]) {
				return 1 * sort_order;
				// a and b are the same
			} else {
				return 0 * sort_order;
			}
		}
	}
}


export const convertInLowerCase = (str) => {
	return str.toLowerCase();
}

export const convertDateSelectedTimeZoneWise = (value, dateFormat = false) => {
	if (!dateFormat) {
		dateFormat = 'MM-DD-YYYY HH:mm:ss';
	}
	return moment.utc(value).local().format(dateFormat);
}


export const convertSecondsToTime = (length, duration) => {
	let seconds = 0
	if (!isValid(length)) {
		seconds = duration - length
	} else {
		seconds = duration
	}
	let hours = parseInt(seconds / 3600);
	let minutes = parseInt((seconds % 3600) / 60);
	let seconds2 = parseInt(seconds % 60);
	if (hours == 0) {
		hours = '';
	}
	if (minutes == 0) {
		minutes = "";
	}
	if (hours) {
		if (hours > 1) {
			hours = hours + " Hrs ";
		} else {
			hours = hours + " Hr ";
		}
	}
	if (minutes) {
		if (minutes > 1) {
			minutes = minutes + " Mins ";
		} else {
			minutes = minutes + " Min ";
		}
	}
	let result = "";
	if (hours) {
		result = hours + minutes + seconds2 + " Sec";
	} else if (minutes) {
		result = minutes + seconds2 + " Sec";
	} else {
		result = seconds2 + " Sec";
	}
	return result



}

export function encodeId(itemId) {
	let encodedId = base64.encode(itemId);
	return encodedId;
}

export function decodeId(encodedString) {
	let decodedId = base64.decode(encodedString);
	return decodedId;
}

export const downloadFile = (url, fileName) => {
	var request = new XMLHttpRequest();
	request.open('GET', url, true);
	request.responseType = 'blob';
	request.onload = function (callback) {
		var reader = new FileReader();
		reader.readAsDataURL(request.response);
		reader.onload = function (e) {
			var link = document.createElement("a");
			link.download = fileName;
			link.href = e.target.result;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			link.remove();
		};
	};
	request.send();
}

export function itemDateFormat(date) {
	const today = moment().format('YYYY-MM-DD');
	const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
	const yesterday = moment().add(-1, 'days').format('YYYY-MM-DD');
	if (today == date) {
		return 'Today';
	} else if (tomorrow == date) {
		return 'Tomorrow';
	} else if (yesterday == date) {
		return 'Yesterday';
	} else {
		return moment(date).format('DD MMM YYYY');
	}
}

export const firstLetters = (name) => {
	if (name && name.trim()) {
		var initials = name.match(/\b\w/g) || [];
		initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
		if (initials.trim().length == 1 && name.trim().length > 1) {
			initials = initials + name[1]
		}
		return initials
	}
}

export const makeRandomId = (length) => {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export function saveCookieFor24Hours(key, value, hours) {
	const expires = new Date();
	expires.setDate(Date.now() + 1000 * 60 * 60 * hours);
	value = base64.encode(value);
	cookie.save(
		key,
		value,
		{
			path: '/',
			expires,
			httpOnly: false,
			SameSite: 'none',
			secure: false,
			maxAge: 1000,
		}
	)
}


export const chatTimeAgo = (time) => {
	time = time.split(' ');
	let t = time[0]
	let t1 = time[1]
	switch (time[1]) {
		case 'minute':
			t = 1
			t1 = 'm'
			break;
		case 'minutes':
			t1 = 'm'
			break;
		case 'hour':
			t = 1
			t1 = 'h'
			break;
		case 'hours':
			t1 = 'h'
			break;
		case 'month':
			t = 1
			t1 = 'month'
			break;
		case 'months':
			t1 = 'months'
			break;
		case 'few':
			t = ''
			t1 = 'Now'
			break;
		default:
			break;
	}
	let rettime = t + " " + t1;
	return rettime;
}
export const htmltoReact = (text) => {
	return { __html: text };
}

/*
		Create URL For Chat
*/
export const removeHTML = (urlStr) => {
	var tmp = document.createElement("DIV");
	tmp.innerHTML = urlStr;
	return tmp.textContent || tmp.innerText || "";
}

export const getValidUrl = (url = "") => {
	let newUrl = window.decodeURIComponent(url);
	newUrl = newUrl.trim().replace(/\s/g, "");

	if (/^(:\/\/)/.test(newUrl)) {
		return `http${newUrl}`;
	}
	if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
		return `http://${newUrl}`;
	}

	return newUrl;
};
export const createLink = (text, messageStatus = false) => {
	var messageClass = (messageStatus) ? 'share-chat-url text-primary' : 'share-chat-url text-white';
	var urlRegex = /(((https?:\/\/)|| (http?:\/\/) || ((www\.)))[^\s]+)/g; var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
	return {
		__html: text.replace(urlRegex, function (url) {
			return '<a href="' + getValidUrl(removeHTML(url)) + '" target="_blank" class="' + messageClass + '">' + url + '</a>';
		})
	};
}

export const Leading0InMonth = (monthNumber) => {
	if (monthNumber) {
		monthNumber = (monthNumber >= 10) ? monthNumber : "0" + monthNumber;
		return monthNumber;
	} else {
		return "";
	}
}

export const getFormattedSlug = str => {
	let arr = str.split(' ')
	arr = arr.map(item => item.toLowerCase())
	let newString = arr.reduce((a, b) => a + '-' + b)
	return newString
}

export const getFormattedDate = (date) => {
	return moment(date).format('MM-DD-YYYY')
}


export const convertSecondsToTimeNumber = (seconds) => {
	seconds = Number(seconds)
	let hours = parseInt(seconds / 3600);
	let minutes = parseInt((seconds % 3600) / 60);
	let seconds2 = parseInt(seconds % 60);
	if (hours == 0) {
		hours = '';
	}
	// if (minutes == 0) {
	// 	minutes = "";
	// }
	if (hours) {
		if (hours > 10) {
			hours = hours;
		} else {
			hours = '0' + hours;
		}
	}
	if (minutes || minutes == 0) {
		if (minutes > 10) {
			minutes = minutes;
		} else {
			minutes = '0' + minutes;
		}
	}

	if (seconds2 || seconds2 == 0) {
		if (seconds2 > 10) {
			seconds2 = seconds2;
		} else {
			seconds2 = '0' + seconds2;
		}
	}

	let result = "";
	if (hours) {
		result = `${hours}:${minutes}:${seconds2}`;
	} else if (minutes) {
		result = `${minutes}:${seconds2}`;
	} else {
		result = `00:${seconds2}`;
	}
	return result
}

export const convertSecondsToHumanTime = (s) => {
	let time = moment.utc((s) * 1000).format('HH:mm:ss')
	let hours = time.split(':')[0]
	let minutes = time.split(':')[1]
	let seconds = time.split(':')[2]
	// if (hours > 1) {
	// 	hours = `${hours} Hours`
	// } else {
	// 	hours = `${hours} Hour`
	// }
	// if (minutes > 1) {
	// 	minutes = `${minutes} Minutes`
	// } else {
	// 	minutes = `${minutes} Minute`
	// }
	// if (seconds > 1) {
	// 	seconds = `${seconds} Seconds`
	// } else {
	// 	seconds = `${seconds} Second`
	// }
	return `${hours}:${minutes}:${seconds}`
}

export const getSecondByTime = (time) => {
	let [hour, minute, second] = time.split(':')

	hour = (Number(hour)) * 3600;
	minute = (Number(minute)) * 60;
	second = (Number(second))

	return hour + minute + second
}

export const getWatchProgress = (watchDuration, lastWatchTime) => {
	lastWatchTime = Math.floor(lastWatchTime)
	watchDuration = Math.floor(watchDuration)
	return ((lastWatchTime / watchDuration) * 100)
}


export const getLibraryType = (type) => {
	if (type === 1) return 'Course'
	if (type === 2) return 'Playlist'
	if (type === 3) return 'Video'
	return 'N/A'
}

export const getAvg = (arr) => {
	if (isEmpty(arr)) {
		return 0
	} else {
		let total = arr.reduce((a, b) => a + b)
		return (total / arr.length)
	}
}

export function flatArray(arr) {
	return arr.reduce(function (flat, toFlatten) {
		return flat.concat(Array.isArray(toFlatten) ? flatArray(toFlatten) : toFlatten);
	}, []);
}

export function isStudentLoggedIn(props, authPage = false) {

	let authToken = getCookie('token')
	let fromPanel = getCookie('from_panel')
	if (!isEmpty(authToken) && isEmpty(fromPanel)) {
		return true
	} else {
		return false
	}
}

export function fetchJsonDeeply(str) {
	let array = []
	if (str === '') {
		return array
	} else if (Array.isArray(str)) {
		return str
	} else {
		return fetchJsonDeeply(JSON.parse(str))
	}
}

export function getIdFromVimeoURL(url) {
	var regExp = /http:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;

	var match = url.match(regExp);

	if (match) {
		return match
	}
	else {
		let returnValue = null
		if (/(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url) && /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url)[3]) {
			returnValue = /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url)[3];
		}
		return returnValue
	}

}
export function shuffleArrayRandomly(array) {
	let currentIndex = array.length, randomIndex;

	// While there remain elements to shuffle.
	while (currentIndex != 0) {

		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex], array[currentIndex]];
	}
	return array;
}


export const formatTimeForDashboardCard = (length, duration) => {
	let seconds = 0
	if (!isValid(length)) {
		seconds = duration - length
	} else {
		seconds = duration
	}
	let hours = parseInt(seconds / 3600);
	let minutes = parseInt((seconds % 3600) / 60);
	let seconds2 = parseInt(seconds % 60);
	if (hours == 0) {
		hours = '';
	}
	if (minutes == 0) {
		minutes = "";
	}
	if (hours) {
		if (hours > 1) {
			hours = hours + "H ";
		} else {
			hours = hours + "H ";
		}
	}
	if (minutes) {
		if (minutes > 1) {
			minutes = minutes + "M ";
		} else {
			minutes = minutes + "M ";
		}
	}
	let result = "";
	if (hours) {
		result = hours + minutes + seconds2 + "S";
	} else if (minutes) {
		result = minutes + seconds2 + "S";
	} else {
		result = seconds2 + "S";
	}
	return result



}

export function getTimeInSeconds(time) {
	if (!isEmpty(time)) {
		var p = time.split(':'),
			s = 0, m = 1;

		while (p.length > 0) {
			s += m * parseInt(p.pop(), 10);
			m *= 60;
		}

		return s;
	} else {
		return 0;
	}

}

export function getFTPFileType(fileType) {
	// 1=> Image, 2=> Video, 3=> PDF, 4=> Excel, 5=> Docs, 6=> Audio
	let allFileTypes = {
		1: "image",
		2: "video",
		3: "pdf",
		4: "excel",
		5: "docs",
		6: "audio",
	}

	return allFileTypes[fileType]
}

export function getTranslatedName(originalText) {
	let planID = localStorage.getItem('planID')

	if (planID == 4) {
		let lan = !isEmpty(LanguageData) ? LanguageData.filter(item => item.en == originalText) : []
		if (!isEmpty(lan)) {
			let newLangData = lan[0]
			if (!isEmpty(newLangData) && (Object.keys(newLangData).length > 0)) {
				return newLangData['sw']
			} else {
				return originalText
			}
		} else {
			return originalText
		}
	} else {
		return originalText
	}
}

export function formatTimeWithMoment(totalSeconds) {

	// Calculate the minutes and seconds
	const minutes = Math.floor(totalSeconds / 60);
	const seconds = totalSeconds % 60;

	// Create a Moment.js duration object
	const duration = moment.duration({ minutes, seconds });

	const formatRelativeTime = (value, unit) => {
		const rtf = new Intl.RelativeTimeFormat('en', { style: 'long', numeric: 'auto' });
		return rtf.format(-value, unit);
	};

	// Format the duration as desired
	const formattedTime = `${formatRelativeTime(minutes, 'minute')} ${formatRelativeTime(seconds, 'second')}`;

	return formattedTime;
}

export function calculateChunkSize(fileSizeInMb) {
	const minimumChunkSize = 5; // Minimum chunk size in MB

	fileSizeInMb = Number(fileSizeInMb);
	let chunkSizeInMb;

	if (fileSizeInMb <= minimumChunkSize) {
		// If the file is smaller than the minimum chunk size, use the entire file as one chunk
		chunkSizeInMb = fileSizeInMb;
	} else {
		// Calculate the chunk size based on the minimumChunkSize
		chunkSizeInMb = Math.ceil(fileSizeInMb / Math.ceil(fileSizeInMb / minimumChunkSize));

		// Adjust the last part if it's less than the minimumChunkSize
		if (fileSizeInMb % chunkSizeInMb < minimumChunkSize) {
			chunkSizeInMb = Math.ceil(fileSizeInMb / Math.floor(fileSizeInMb / minimumChunkSize));
		}
	}

	return chunkSizeInMb;
}

export const checkSwedish = () => {
	let planID = localStorage.getItem('planID')
	if (planID == 4) return true
	return false;
}