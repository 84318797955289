import axios from 'axios';
import base64 from 'base-64'
import { DYTE_ORGID, DYTEAPIKEY } from './constants';



export const httpGetRequest = (url, params, callback) => {
  axios({
    method: 'get',
    url: url,
    params: params,
  })
    .then((result) => {
      callback(null, result.data)
    })
    .catch((error) => {
      callback(error, null)
    })
}

export const httpPostRequest = (url, data, callback) => {
  axios({
    method: 'post',
    url: url,
    data: data,
  })
    .then((result) => {
      callback(null, result.data)
    })
    .catch((error) => {
      callback(error, null)
    })
}

export const httpPostRequestFile = (url, data, headers, callback) => {
  axios({
    method: 'post',
    url: url,
    data: data,
    headers: headers,
  })
    .then((result) => {
      callback(null, result.data)
    })
    .catch((error) => {
      callback(error, null)
    })
}



// Dyte 
let dyteAccessCode = base64.encode(`${DYTE_ORGID}:${DYTEAPIKEY}`);

export const getfetchRequests = async (url, params, callback) => {
  const options = {
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${dyteAccessCode}`
    }
  };
  fetch(`https://api.dyte.io/v2/${url}`, options)
    .then(response => response.json())
    .then((response) => callback(null, response))
    .catch(err => callback(err, null));
}

export const postRequest = (url, data, callback) => {
  const options = {
    method: 'POST',
    body: data,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${dyteAccessCode}`
    }
  };
  fetch(`https://api.dyte.io/v2/${url}`, options)
    .then(response => response.json())
    .then(response => callback(null, response.data))
    .catch(error => console.log('error', error));
}
