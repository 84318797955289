import React, { useState } from 'react'
import './GoalCard.scss'
import FtpReviewCard from 'components/FtpReviewCard/FtpReviewCard'
import FeedbackCard from 'components/FeedbackCard/FeedbackCard'
import { useHistory } from 'react-router-dom'
import { encode } from 'base-64'
import PhaseConfirmationModal from "../popup/PhaseConfirmationModal"
import { checkSwedish, getTranslatedName } from 'config/functions'
import { handleGoalSubmit } from 'components/apiStore'
import Swal from 'sweetalert2'
import AwesomeCofetti from 'components/FtpReviewCard/AwesomeCofetti'
import { useSelector } from 'react-redux'




export const RenderFtpGoal = ({ status, phaseGoal, library }) => {
    const studentData = useSelector(state => state.register.userProfileData)
    const history = useHistory()
    if (status == 'pending') {
        return (
            <div className='goal-card-container'>
                <div className="goal-card">
                    {
                        checkSwedish() ? (
                            <div className="card">

                                <div className="card-body">
                                    <h4>Mål 1</h4>
                                    <div className="lesson-number">
                                        <h3>Få återkoppling på din träningsteknik</h3>
                                    </div>
                                    <p>Ladda upp din video med tekniken</p>

                                    <div className='btn-container'>
                                        <button className='continue-btn submit-video'
                                            onClick={() => history.push(`/student/goal/ftp-submit/${encode(phaseGoal?.id)}`)}
                                        >Skicka in video</button>
                                    </div>
                                </div>
                            </div>
                        ) : (

                            <div className="card">
                                <div className="card-body">
                                    <h4>Goal 1</h4>
                                    <div className="lesson-number">
                                        <h3>Get your hypopressives approved</h3>
                                    </div>
                                    <p>Upload Your video with the technique</p>

                                    <div className='btn-container'>
                                        <button className='continue-btn submit-video'
                                            onClick={() => history.push(`/student/goal/ftp-submit/${encode(phaseGoal?.id)}`)}
                                        >Submit Video</button>
                                    </div>

                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    } else if (status == 'submitted') {
        return <FtpReviewCard phaseGoal={phaseGoal} />
    } else if (status == 'feedback') {
        return <FeedbackCard phaseGoal={phaseGoal} />
    } else if (status == 'completed') {
        return <AwesomeCofetti phaseGoal={phaseGoal} library={library} />
    } else {
        return ""
    }
}

export const RenderLiveClassGoal = ({ showLiveClassButtonEnabled, phaseGoal }) => {
    const studentData = useSelector(state => state.register.userProfileData)
    const history = useHistory()

    const [showLiveClassConfirmModal, setShowLiveClassConfirmModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleJoinLiveClasses = () => {
        if (!showLiveClassButtonEnabled) return;
        setShowLiveClassConfirmModal(true)
    }

    const handleClose = () => {
        setShowLiveClassConfirmModal(false)
    }

    const handleSubmit = async (phaseGoal) => {
        setIsLoading(true)
        const bodyData = { phaseGoalId: phaseGoal.id, status: 'completed', item: phaseGoal.item, goalType: phaseGoal.goalType }
        const apiRes = await handleGoalSubmit(bodyData)
        if (apiRes.status) {
            setIsLoading(false)
            setShowLiveClassConfirmModal(false)
            history.push('/public/live-schedule')

        } else {
            setIsLoading(false)
            setShowLiveClassConfirmModal(false)
            Swal.fire({
                title: getTranslatedName('Error'),
                text: getTranslatedName(apiRes.message),
                icon: 'error',
                showConfirmButton: false,
                timer: 3000,
                borderRadius: 50
            })
        }
    }


    return (
        <React.Fragment>
            <div className='goal-card-container'>
                <div className="goal-card">
                    <div className="card">

                        {
                            checkSwedish() ? (
                                <div className="card-body">
                                    <h4>
                                        {phaseGoal.status == 'completed' && !studentData.totalRewardClasses ? "Avslutad" : `Pris`}
                                    </h4>
                                    <div className="lesson-number ">
                                        <h3>
                                            {
                                                phaseGoal.status == 'completed' ? !studentData.totalRewardClasses ? "Du har låst upp alla dina liveklasser" : `Du har ${studentData.totalRewardClasses} live klasspoäng kvar` : "Gå med i din gratis liveklass"
                                            }

                                        </h3>
                                    </div>
                                    {/* {phaseGoal.status !== 'completed' && <p>Få din anslutningskod.</p>} */}
                                    <div className='btn-container btn-grey'>
                                        {phaseGoal.status != 'completed' && <button disabled={!showLiveClassButtonEnabled} className={`${showLiveClassButtonEnabled && 'live-class-banner'} wait-btn continue-btn`} onClick={() => handleJoinLiveClasses(phaseGoal)}>
                                            {showLiveClassButtonEnabled ? `Välj din gratis liveklass` : 'Väntar på dig'}
                                        </button>}
                                        {phaseGoal.status == 'completed' && <button className='continue-btn live-class-banner' onClick={() => history.push('/public/live-schedule')}>
                                            {studentData.totalRewardClasses ? 'Kommande livekurser' : 'Köp fler klasser'}
                                        </button>}
                                    </div>
                                </div>
                            ) : (

                                <div className="card-body">
                                    <h4>
                                        {phaseGoal.status == 'completed' && !studentData.totalRewardClasses ? "Completed" : `Reward`}
                                    </h4>
                                    <div className="lesson-number">
                                        <h3>
                                            {
                                                phaseGoal.status == 'completed' ? (!studentData.totalRewardClasses ? "You have unlocked all your live classes" : `You have ${studentData.totalRewardClasses} live class credits left`) : " Join your free live class"
                                            }

                                        </h3>
                                    </div>
                                    {/* {phaseGoal.status != 'completed' && <p>Get your joining Code.</p>} */}
                                    <div className='btn-container btn-grey'>
                                        {phaseGoal.status != 'completed' && <button disabled={!showLiveClassButtonEnabled} className={`${showLiveClassButtonEnabled && 'live-class-banner'} wait-btn continue-btn`} onClick={() => handleJoinLiveClasses(phaseGoal)}>
                                            {showLiveClassButtonEnabled ? `Pick your free live class` : 'Waiting For You'}
                                        </button>}
                                        {phaseGoal.status == 'completed' && <button className='continue-btn live-class-banner' onClick={() => history.push('/public/live-schedule')}>
                                            {studentData.totalRewardClasses ? getTranslatedName('Upcoming Live Classes') : getTranslatedName('Buy more classes')}
                                        </button>}
                                    </div>

                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
            {showLiveClassConfirmModal && <PhaseConfirmationModal show={showLiveClassConfirmModal} handleClose={handleClose} handleSubmit={() => handleSubmit(phaseGoal)}
                text={getTranslatedName('Are you sure you want to reveal this reward?')}
                isBtnLoading={isLoading}
                submitButtonText={getTranslatedName('Yes')}
                closeButtonText={getTranslatedName('No')}
            />}

        </React.Fragment>
    )
}

export default function GoalCard({ goalType, status, phaseGoal, showLiveClassButtonEnabled, library }) {
    return (
        <>
            {/* {goalType === 'ftp' && renderFtpGoal(status, phaseGoal)} */}
            {goalType == 'ftp' && <RenderFtpGoal phaseGoal={phaseGoal} status={status} library={library} />}
            {goalType == 'liveclass' && <RenderLiveClassGoal showLiveClassButtonEnabled={showLiveClassButtonEnabled} phaseGoal={phaseGoal} />}
        </>
    )
}
