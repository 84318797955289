import React from 'react';
import { Alert } from 'react-bootstrap';
import { Card, Col, Row } from 'reactstrap';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <React.Fragment>
          <div className="alert-page container mx-auto py-5 mt-2">
            <div className='p-md-5 p-3'>
              <Row >
                <Col className="col-12  mx-auto">
                  <Card className="alert alert-danger alert-dismissible fade show p-md-5 p-3">
                    <div className='mb-1 text-danger fw-bolder'> <strong className='pe-2  h5 text-danger'>Error!</strong>{'Oops! Something went wrong.'}</div>   {/*  {this.state.error && this.state.error.toString()} */}
                    <button id='btn-theme-admin' type='button' onClick={() => window.location.reload()} className="btn btn-admin-theme alert-btn w-100- d-block mx-auto mt-5" >Try again</button>
                  </Card>
                </Col>
                {/* <details style={{ whiteSpace: 'pre-wrap' }}>
                  <p className="text-danger">{this.state.error && this.state.error.toString()}</p>
                  {this.state.errorInfo.componentStack}
                </details> */}
              </Row>
            </div>
          </div>

          {/* <div>
            <Alert variant="error">
              <h2 className="text-danger">Oops! Something went wrong.</h2>
              <details style={{ whiteSpace: 'pre-wrap' }}>
                <p className="text-danger">{this.state.error && this.state.error.toString()}</p>
                {this.state.errorInfo.componentStack}
              </details>
            </Alert>
          </div> */}
        </React.Fragment>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}