import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { Button, Card, CardBody, Col, Row, UncontrolledAlert } from 'reactstrap'
import { handleTrackerBannerClose } from '../apiStore';
import "../PopUps/popup.css";
import { IoClose } from 'react-icons/io5';

const SymptomBanner = () => {
  const history = useHistory()
  let planID = window.localStorage.getItem("planID")
  // Store vars
  const showBanner = useSelector(state => state.register.showSymptomBanner)
  const dispatch = useDispatch()


  const handleClick = () => {
    history.push('/student/symptoms-tracker')
  }

  const handleBannerClose = async () => {
    let bodyData = { isTrackerClosed: 1 }
    await handleTrackerBannerClose(bodyData)
    dispatch({ type: 'TOGGLE_SYMPTOM_BANNER', payload: false })
  }

  return (
    (showBanner ? <div className="message-wrapper">
      <UncontrolledAlert className='p-0 m-0 symptom-banner-alert' color='' isOpen={showBanner} >
        <Card className="bg-banner">
          <CardBody>
        <button className='btn-close-symptom' onClick={() => {
          
          handleBannerClose()
          }}><IoClose size={22} color='#fff'/></button>

            <div className='media align-items-center flex-wrap text-banner w-100 h-100 px-lg-4 px-2-'>
              <div className='media-body pe-md-5'>
                <div className="pe-0 me-md-5 py-0">
                  {planID == 4 ?
                    <p className='text-title'> 
                      Om du vill ha relevanta rekommendationer, besvara dessa frågor. Detta är frivilligt, du behöver inte svara.
                    </p>
                    : <p className='text-title'>
                      Please answer these questions to get personalised recommendations</p>}
                  {/* {planID == 4 ? <p className='text-title'>Dessa är valfria och kan hjälpa oss att guida dig på bästa sätt.</p> : <p className='text-title'>These are optional and can help us guide you better.</p>} */}
                </div>
              </div>

              <div className="btn-container mt-md-0 mt-2-">
                <Button onClick={handleClick} className="btn-join"><span className="px-0">{planID == 4 ? "Slutför hälsoutvärderingen" : "Complete the health assessment"}</span></Button>
              </div>
            </div>
            
          </CardBody>
        </Card>
      </UncontrolledAlert>
    </div> : null)
  )
}

export default SymptomBanner