import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import WaiverHeader from "./WaiverHeader"
import { useDispatch } from "react-redux"
import Footer from "views/registerStudentLayout/footer/FooterNew"
import moment from "moment"
import axios from "axios"
import { getCookie } from "config/functions"
export default function WaiverStep2(props) {
  const dispatch = useDispatch()
  const [userProfileDetails, setUserProfileDetails] = useState({})
  const [currentDate, setCurrentDate] = useState()
  const [isAgree, setIsAgree] = useState(false)

  useEffect(() => {
    callUserProfile()
    dispatch({ type: "HANDLE_HEADER", payload: { isLinkActive: true } })

    setCurrentDate(moment(new Date()).format("DD.MM.YYYY"))
     //  This code will be commenteed for dev/staging server
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.async = true
    script.innerHTML =
      "(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3507002,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
    document.head.appendChild(script)
    //   above hot jar script 25 may 23'
     //  This code will be commenteed for dev/staging server
  }, [])
  const [swedish, setSwedish] = useState()
  const [planID, setPlanID] = useState()

  useEffect(() => {
    // const sw = window.localStorage.getItem('swedish')
    // if (sw) setSwedish(sw)
    let pl = window.localStorage.getItem("planID")
    setPlanID(pl)
    if (pl == 4 || pl == 5) setSwedish(true)
  }, [swedish])

  const callUserProfile = async () => {
    try {
      const res1 = await axios({
        method: "get",
        url: "api/get_userProfile"
      })
      const user = await res1.data
      setUserProfileDetails(user.data)
    } catch (err) {
      console.log(err.response.data.message)
    }
  }

  const handleSubmit = async () => {
    const token = getCookie("token")
    axios
      .post("api/updateWaiverSign", {
        isWaiverSign: 1,
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          authorization: token
        }
      })
      .then(result => {
        props.history.push({
          pathname: `/student/dashboard`
        })
      })
  }
  const onChecked = e => {
    setIsAgree(!isAgree)
  }

  const userName = userProfileDetails.firstName + " " + userProfileDetails.lastName
  return (
    <React.Fragment>
      <WaiverHeader {...props} />
      <section className="page-inner payment-success-section payment-step-2 pt-md-5 pt-3">
        <div className="container p-0 ">
          <div className="waiver-container pt-lg-5 pt-md-4 pt-0">
            <div className="waiver-steps">
              <ul className="step d-flex flex-nowrap justify-content-center">
                <li className="step-item">
                  <Link to="/student/waiver-step-1" className="active">
                    <strong>{swedish ? "Läs avtalet" : "Read Waiver"}</strong>
                  </Link>
                </li>
                <li className="step-item">
                  <Link to="/student/waiver-step-2">
                    <strong>{swedish ? "Verifiera detaljer och signera" : "Verify Details & Sign"}</strong>
                  </Link>
                </li>
                {/* <li className="step-item">
                  <Link to="/student/waiver-step-3">
                    <strong>The Support You Need</strong>
                  </Link>
                </li> */}
                {/* <li className="step-item" disabled={true}>
                  <Link to="#">
                    <strong>Thanks</strong>
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="fieldset-steps">
              <fieldset className="fieldset-default active">
                <div className="form-card">
                  <h2 className="fs-title">
                    {swedish
                      ? "Hälsofriskrivning"
                      : planID == 3 || planID == 1
                      ? "Health Disclaimer"
                      : "Disclaimer and waiver"}
                  </h2>
                  <div className="stepdesc">
                    <div className="row">
                      <div className="col-md-12">
                        {!swedish && (
                          <p className="description">
                            Please read this {planID == 3 || planID == 1 ? "health" : "medical"} disclaimer carefully
                            before using Our Service.
                          </p>
                        )}
                      </div>

                      {(swedish && (
                        <>
                          <div className="col-md-12 policy-terms">
                            <div className="contant-wraper">
                              <p className="description">Vänligen läs följande information innan du påbörjar kursen.</p>

                              <p className="description">
                                Materialet som presenteras i våra kurser, skapat av våra samarbetspartners eller Women
                                Cycles AB (företaget), är endast utbildnings- och informations ändamål.
                              </p>
                              <p className="description">
                                Informationen i kurserna bör inte tolkas som medicinska råd, de bör heller inte ersätta
                                råd eller vård av medicinsk personal. Vid behov, rådfråga din läkare innan du deltar på
                                någon/några av våra kurser.
                              </p>
                              <p className="description">
                                Jag registrerar mig för att delta på företagets digitala hälsoprogram. Jag förstår att
                                jag ansvarar för att följa instruktionerna, kontraindikationerna och annan viktig
                                information som presenteras på hemsidan eller annat material kopplat till kursen.
                              </p>
                            </div>
                          </div>

                          <div className="col-md-12 pt-2">
                            <p className="description">
                              {/* <strong> */}
                              Skaparna, producenterna, deltagarna, instruktörerna och distributörerna av denna kurs
                              friskriver sig ansvaret kopplat till övningarna och råden som finns häri.
                              {/* </strong> */}
                            </p>
                            <p className="description">
                              Jag som signerar vill härmed delta i kursen. Jag tar fullt ansvar för min egen hälsa och
                              välmående.
                            </p>
                            {/* <p className="description">Jag, underskrivande, önskar härmed delta i denna kurs.</p> */}
                          </div>
                          {/* <div className="col-md-12 policy-terms mt-2">
                            <div className="contant-wraper">
                              <h5 className="h5 title">Ansvarsbefrielse</h5>
                              <p className="description">
                                Jag registrerar mig för att delta i digitala aktiviteter som hålls av Företaget. Om du
                                har en medicinsk oro/problem eller en hälsofråga, rådfråga din vårdcentral innan du
                                deltar. Rådfråga din vårdcentral/läkare innan du börjar det här eller något annat
                                liknande program.
                              </p>

                              <p className="description">
                                Enhällig användare av det här programmet antar risken för skada som uppstår som ett
                                resultat av att utföra de aktiviteter som rekommenderas. Skaparna, producenterna,
                                deltagarna, instruktörerna och distributörerna av det här programmet friskriver sig från
                                ansvar eller förlust i samband med övningar och råd som anges här.
                              </p>
                            </div>

                            <p className="description">
                              {" "}
                              Jag, underskrivande, önskar härmed delta i stödprogrammets klass/kurs. Jag inser att
                              min/vårt deltagande i dessa aktiviteter innebär en potential risk för skada, inklusive men
                              inte begränsat till kroppsskada, hjärtattack, stroke eller till och med död. Jag känner
                              också till att det finns andra risker som är förknippade med det här och att det inte är
                              möjligt att lista alla. Jag känner till och förstår riskerna, jag förstår att skador är en
                              möjlighet, och jag antar härmed alla risker för skada som kan uppstå till följd av mitt
                              deltagande i det här programmet. Jag tar fullt ansvar för min egen hälsa och välbefinnande
                              och samtycker till att alla instruktioner som jag får ska användas på eget ansvar.
                            </p>
                          </div> */}
                        </>
                      )) || (
                        <>
                          <div className="col-md-12 policy-terms">
                            <div className="contant-wraper">
                              {planID == 3 && <h5 className="h5 title">DISCLAIMER:</h5>}
                              <p className="description">
                                The material presented through our support program, done by our content partners or by
                                Women Cycles AB ("Company") is for educational and informational purposes only.
                              </p>

                              <p className="description">
                                {planID == 3 || planID == 1
                                  ? "This information should not be interpreted as medical advice, nor should it substitute for diagnosis and care by a licensed medical professional. We are not doctors or board-certified health professionals. By joining our platform, no health or patient relationship is created between you and the company."
                                  : "This information should not be interpreted as medical advice, nor should it substitute for diagnosis and care by a licensed medical professional. Consult your physician before beginning using the information on our platform or any other program."}
                              </p>

                              {planID == 3 || planID == 1 ? (
                                ""
                              ) : (
                                <p className="description">
                                  Because not all accepted medical treatments are effective, do your own research after
                                  seeing your doctor. Once you have all the information available from physicians and
                                  other practitioners such as the ones offered on our platform and by the Company, then
                                  make your decision about how to pursue management or resolution of your condition
                                </p>
                              )}
                              {planID == 3 || planID == 1 ? (
                                <p className="description">
                                  We urge you to make an empowered decision about your health.
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          {planID == 3 || planID == 1 ? (
                            ""
                          ) : (
                            <div className="col-md-12 pt-2">
                              <p className="description">
                                <strong>
                                  Only you should make decisions about your health as you are the primary one who will
                                  have to live with the consequences of your decision.
                                </strong>
                              </p>
                            </div>
                          )}
                          <div className="col-md-12 policy-terms mt-2">
                            <div className="contant-wraper">
                              <h5 className="h5 title">
                                {planID == 3 || planID == 1 ? "WAIVER:" : "Liability Waiver"}
                              </h5>
                              {planID == 3 || planID == 1 ? (
                                <p className="description">
                                  I am registering to participate in digital health programs held by the Company. I
                                  understand that I'm responsible for following the instructions, contra-indications and
                                  other important information presented in this website or other linked materials
                                  provided by the company.
                                </p>
                              ) : (
                                <p className="description">
                                  I am registering to participate in digital activities held by the Company. If you have
                                  a medical concern/problem or a health-related question, please consult with your GP
                                  before participating. Consult your GP/physician before beginning this or any other
                                  similar program.
                                </p>
                              )}
                              {planID == 3 || planID == 1 ? (
                                <p className="description">
                                  The creators, producers, participants, instructors and distributors of this program
                                  disclaim any liability or loss in connection with the exercises and advice contained
                                  herein.
                                </p>
                              ) : (
                                <p className="description">
                                  Any user of this program assumes the risk of injury resulting from performing the
                                  activities recommended. The creators, producers, participants, instructors and
                                  distributors of this program disclaim any liability or loss in connection with the
                                  exercises and advice contained herein.
                                </p>
                              )}
                            </div>

                            {planID == 3 || planID == 1 ? (
                              <p className="description">
                                {" "}
                                I, the undersigned, hereby state that I wish to participate in this course. I take full
                                responsibility for my own health and well-being and agree that any instructions received
                                will be used at my own risk.
                              </p>
                            ) : (
                              <p className="description">
                                I, the undersigned, hereby state that I wish to participate in the support program
                                class/course. I realize that my/our participation in these activities involves potential
                                risk of injury, including but not limited to bodily injury, heart attack, stroke, or
                                even death. I also recognize there are other risks associated with this and that it is
                                not possible to list everyone. I know and understand the risks, I understand that
                                injuries are a possibility, and I hereby assume all risks of injury which could occur by
                                reason of my participation in this program. I take full responsibility for my own health
                                and well-being and agree that any instructions received will be used at my own risk.
                              </p>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="leagel-info">
                    {(swedish && (
                      <div className="media leagel-field">
                        <h5>Ditt fullständiga namn:</h5>
                        <div className="media-body userDetails">
                          {userProfileDetails.firstName}&nbsp;
                          {userProfileDetails.lastName}
                        </div>
                      </div>
                    )) || (
                      <div className="media leagel-field">
                        <h5>Your Legal Full Name:</h5>
                        <div className="media-body userDetails">
                          {/* {userProfileDetails.firstName}
                          {userProfileDetails.lastName} */}
                          {userName}
                        </div>
                      </div>
                    )}
                    <div className="media leagel-field">
                      <h5>{swedish ? "E-postadress:" : "Email address:"}</h5>
                      <div className="media-body email-user">{userProfileDetails.email}</div>
                    </div>
                    <div className="media leagel-field">
                      <h5>{swedish ? "Giltigt datum från:" : "Valid date from:"}</h5>
                      <div className="media-body">{currentDate}</div>
                    </div>
                  </div>
                  <div className="form-group row waiver_agreement">
                    <div className="col-12">
                      {/* <label> I accept the above disclaimer &amp; waiver</label> */}
                      <label className="theme_checkbox_container">
                        {swedish
                          ? "Jag accepterar ovanstående ansvarsfriskrivning och avstående."
                          : "I accept the above disclaimer & waiver."}
                        <input
                          type="checkbox"
                          name="isAgree"
                          value={isAgree}
                          checked={isAgree}
                          onChange={() => onChecked()}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="gonext_button mt-2">
                    <button
                      type="button"
                      className="btn-theme btn-full-mobile "
                      disabled={!isAgree}
                      onClick={() => handleSubmit()}
                    >
                      {swedish ? "SIGNERA NU" : "Sign Now"}
                    </button>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </section>
      <Footer swedish={swedish} {...props} />
    </React.Fragment>
  )
}
