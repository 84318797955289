// import { getCookie } from '../../config/functions';
const initialState = {
  feturedCourses: {loading: true, data: []},
  testmonials: {loading: true, data: []},
  groupwiseCourses: {loading: true, data: []},
  // signup_successful: { loading: true, data: [] },
  // signup_failed: '',
  getAllCreators: {loading: true, data: []},
  getCreatorProfileById: [],
  categorywiseCourses: [],
  symptomwiseCourses: [],
  getIpInfo: '',
  planList: [],
  // userToken: getCookie("token"),
  userRegister: false,
  header: {activeNav: 'Browse', headerDark: false},
  setEmail: '',
  categoriesSymptoms: {loading: true, data: []},
  symptoms: {loading: true, data: []},
  symptomtrackdata: {loading: true, data: ''},
  symptomtrackerinfo: {loading: true, data: ''},
  selectedsymptoms: {loading: true, data: []},
  upcomingCourses: {loading: true, data: []},
  upcomingCoursesByCreator: [],
  reviewList: {loading: true, data: []},
  // symptomAndScores: [],
  symptomAndScores: {
    mood: '',
    confidence: '',
    symptomScores: [],
  },
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case 'USER_STATUS':
      return {...state, userRegister: payload}
    // case 'ACTIVE_NAV':
    //     return { ...state, active_nav: payload }

    case 'HANDLE_HEADER':
      return {...state, header: {...state.header, ...payload}}

    case 'GET_FEATURED_COURSES':
      return {...state, feturedCourses: {...state.feturedCourses, ...{loading: false, data: payload}}}
    case 'GET_TESTIMONIALS':
      return {...state, testmonials: {...state.testmonials, ...{loading: false, data: payload}}}
    case 'GET_GROUPWISE_COURSES':
      return {...state, groupwiseCourses: {...state.groupwiseCourses, ...{loading: false, data: payload}}}
    case 'GET_ALL_CREATOR':
      return {...state, getAllCreators: {...state.getAllCreators, ...{loading: false, data: payload}}}
    case 'GET_CREATOR_PROFILE_BY':
      return {...state, getCreatorProfileById: payload}
    case 'GET_CATOGERYWISE_COURSES_PUBLIC':
      return {...state, categorywiseCourses: payload}
    case 'GET_SYMPTOMWISE_COURSES_PUBLIC':
      return {...state, symptomwiseCourses: payload}
    case 'GET_PLAN_LIST':
      return {...state, planList: payload}
    case 'SET_EMAIL':
      return {...state, setEmail: payload}
    case 'GET_CATEGORIES_SYMPTOMS':
      return {...state, categoriesSymptoms: {...state.categoriesSymptoms, ...{loading: false, data: payload}}}
    case 'GET_UPCOMING_COURSES':
      return {...state, upcomingCourses: {...state.upcomingCourses, ...{loading: false, data: payload}}}
    case 'GET_UPCOMING_COURSES_BY_CREATOR':
      return {...state, upcomingCoursesByCreator: payload}
    case 'GET_REVIEW_LIST':
      return {...state, reviewList: payload}
    case 'GET_SYMPTOMS':
      return {...state, symptoms: {...state.symptoms, ...{loading: false, data: payload}}}
    case 'GET_SELECTED_SYMPTOMS':
      return {...state, selectedsymptoms: {...state.selectedsymptoms, ...{loading: false, data: payload}}}
    case 'GET_SYMPTOM_TRACK_DATA_BY_ID':
      return {...state, symptomtrackdata: {loading: false, data: payload}}
    case 'GET_SYMPTOM_TRACKER_INFO':
      return {...state, symptomtrackerinfo: {loading: false, data: payload}}
    case 'GET_IP_INFORMATION':
      return {...state, getIpInfo: payload}
    case 'ADDSYMPTOMANDSCORE_ACTION':
      return {
        ...state,
        symptomAndScores: {
          ...state.symptomAndScores,
          symptomScores: payload,
        },
      }
    case 'ADDMOODSCORE_ACTION':
      return {...state, symptomAndScores: {...state.symptomAndScores, mood: payload.mood}}
    case 'ADDCONFIDENCESCORE_ACTION':
      return {...state, symptomAndScores: {...state.symptomAndScores, confidence: payload.confidence}}
    default:
      return state
  }
}
