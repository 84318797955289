import axios from "axios"
import { API_BASE_URL } from "config/constants"

import { getCookie } from "config/functions"

axios.defaults.baseURL = API_BASE_URL


export const getCustomerPreviousLessons = (bodyData) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/library/lesson-history', bodyData);
        resolve(response.data)
    })
}