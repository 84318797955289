import React, {Component} from 'react';
import {Link, useHistory} from 'react-router-dom';
import  './NoMatchPage.css';

// export default class NoMatchPage extends Component {



//     render() {
//         return (
// 			<div id="notfound">
// 				<div className="notfound">
// 					<div className="notfound-404">
// 						<h1>Oops!</h1>
// 						<h2>404 - The Page cannot be found !</h2>
// 					</div> 
// 					<Link to="/" className="">Go To Homepage</Link>
// 				</div>
// 			</div>
//         );
//     }
// }

const NoMatchPage = () => {

	const history = useHistory()
	const pathname = history.location.pathname


	let url = '/'
	if(pathname.includes('admin')) {
		url = '/admin/dashboard'
	} 
	if (pathname.includes('student')) {
		url = '/student/dashboard'
	}

	return (
		<div id="notfound">
			<div className="notfound">
				<div className="notfound-404">
					<h1>Oops!</h1>
					<h2>404 - The Page cannot be found !</h2>
				</div>
				<Link to={url} className="">Go To Homepage</Link>
			</div>
		</div>
	)
}

export default NoMatchPage
