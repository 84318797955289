import React, { useEffect } from 'react';
/* import { Link } from 'react-router-dom';
import Logo from 'assets/images/WomenCycleLogo.png';
import { useSelector } from 'react-redux';
import { Navbar, } from 'react-bootstrap';
import {getCookie } from '../../../config/functions'; */

export default function Header() {
	return (
		null
	);
}


/* export default function Header() {
	const { activeNav ,showJoinBtn} = useSelector((state) => state.non_register.header);
	const [showmobilenav, setShow] = React.useState();
	let authToken = getCookie("token");
	
	useEffect(() => {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.innerHTML = "(function(w,n) {if (typeof(w[n]) == 'undefined'){ob=n+'Obj';w[ob]=[];w[n]=function(){w[ob].push(arguments);};d=document.createElement('script');d.type = 'text/javascript';d.async=1;d.src='https://s3.amazonaws.com/provely-public/w/provely-2.0.js';x=document.getElementsByTagName('script')[0];x.parentNode.insertBefore(d,x);}})(window, 'provelys', '');provelys('config', 'baseUrl', 'app.provely.io');provelys('config', 'https', 1);provelys('data', 'campaignId', '26254');provelys('config', 'widget', 1);";

		!authToken && document.body.appendChild(script);

		const script1 = document.createElement('script');
		script1.type = 'text/javascript';
		script1.async = true;
		script1.innerHTML = "(function(w,n) {if (typeof(w[n]) == 'undefined'){ob=n+'Obj';w[ob]=[];w[n]=function(){w[ob].push(arguments);};d=document.createElement('script');d.type = 'text/javascript';d.async=1;d.src='https://s3.amazonaws.com/provely-public/w/provely-2.0.js';x=document.getElementsByTagName('script')[0];x.parentNode.insertBefore(d,x);}})(window, 'provelys', '');provelys('config', 'baseUrl', 'app.provely.io');provelys('config', 'https', 1);provelys('data', 'campaignId', '26254');provelys('config', 'track', 1);"
		!authToken && document.body.appendChild(script1);

		return () => {
		!authToken && document.body.removeChild(script);
		!authToken && document.body.removeChild(script1);
		}
	}, []);

	return (
		<React.Fragment>
			<header id="navbar-sec" >
				<Navbar collapseOnSelect expand="md" fixed="top" bg="white" variant="light" className="Notregisteredcss">
					<div className="container-lg">
						<Link className="navbar-brand lg-brand" to="/">
							<img alt="Women's health issues learning platform: MOONRISE" height="80" width="80" src={Logo} className="lg-logo" />
						</Link>
						<Navbar.Toggle onClick={() => setShow(!showmobilenav)} />
						<Link className="navbar-brand sm-brand" to="/">
							<img alt="Women's health issues learning platform: MOONRISE" height="80" width="80" src={Logo} className="sm-logo" />
						</Link>
						<Link to="/login" className="btn-start btnsm-signin">Sign in</Link>
						{showJoinBtn && <Link className="nav-link get-start-mobile" to= "/membership">Join Now</Link>}


						<div className={showmobilenav ? "showmobilemenu navbar-collapse collapse" : "noshowmobilemenu navbar-collapse collapse"}>
							<button type="button" className="navclose btn btn-primary" onClick={() => setShow(!showmobilenav)}>
								<i className="material-icons">clear</i>
							</button>
							<ul className="navbar-nav">
								<li className="nav-item">
									<Link to="/browse" onClick={() => setShow(!showmobilenav)} className={activeNav === 'Browse' ? "nav-link active" : "nav-link"}
									>Library</Link>
								</li>
								<li className="nav-item">
									<Link onClick={() => setShow(!showmobilenav)} className={activeNav === 'OurStory' ? "nav-link active" : "nav-link"} to="/our-story">Our story</Link>
								</li>
								<li className="nav-item">
									<Link onClick={() => setShow(!showmobilenav)} className={activeNav === 'Creator' ? "nav-link active" : "nav-link"} to="/creators">Teachers</Link>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="https://womencycles.com/blog/" target="_blank" rel="nofollow">Blog</a>
								</li>
							</ul>
							<ul className="navbar-nav ml-auto">
								<li className="nav-item">
									<Link className="nav-link active-dark" onClick={() => setShow(!showmobilenav)} to="/login">Sign in</Link>
								</li>
								{showJoinBtn && 
								<li className="nav-item get-start-item">
									<Link className="nav-link get-start" onClick={() => setShow(!showmobilenav)} to="/membership">Join Now</Link>
								</li>}
							</ul>
						</div>
					</div>
				</Navbar>
			</header>
		</React.Fragment>
	);
} */