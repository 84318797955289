export const getFeaturedCourses = () => ({
  type: 'FEATURED_COURSES',
})

export const getTestimonial = () => ({
  type: 'TESTIMONIAL',
})

export const getAllCreators = () => ({
  type: 'GET_ALL_CREATORS',
})

export const getCreatorProfileById = (payload) => ({
  type: 'GET_CREATOR_PROFILE_BY_ID',
  payload,
})

export const getGroupwiseCourses = () => ({
  type: 'GROUPWISE_COURSES',
})

export const signUp = (payload) => ({
  type: 'SIGNUP',
  userInfo: payload,
})

export const getCategorywiseCourses = (payload) => ({
  type: 'CATEGORYWISE_COURSES_PUBLIC',
  categoryID: payload,
})

export const getSymptomwiseCourses = (payload) => ({
  type: 'SYMPTOM_WISE_COURSES_PUBLIC',
  symptomID: payload,
})

export const getPlanList = () => ({
  type: 'PLAN_LIST',
})

export const getReviewList = () => ({
  type: 'REVIEW_LIST',
})

export const getCategoriesSymptoms = () => ({
  type: 'CATEGORY_SYMPTOMS',
})

export const getUpcomingCourses = () => ({
  type: 'UPCOMING_COURSES',
})

export const getUpcomingCourseByCreator = (payload) => ({
  type: 'UPCOMING_COURSES_BY_CREATOR',
  creatorID: payload,
})

export const getSymptomList = () => ({
  type: 'SYMPTOMS',
})

export const getSelectedSymptomList = (payload) => ({
  type: 'SELECTED_SYMPTOMS',
})

export const checkSymptomTrackId = (payload) => ({
  type: 'CHECK_SYMPTOM_TRACK_ID',
  trackerInfoId: payload,
})

export const symptomTrackerInfo = () => ({
  type: 'SYMPTOM_TRACKER_INFO',
})

export const getIpInfo = () => ({
  type: 'GET_IP_INFO',
})

export const addsymptomAndScoresAction = (payload) => {
  // console.log(payload, 'add')
  return {
    type: 'ADDSYMPTOMANDSCORE_ACTION',
    payload,
  }
}
export const addMoodScoresAction = (payload) => {
  // console.log(payload, 'add')
  return {
    type: 'ADDMOODSCORE_ACTION',
    payload,
  }
}
export const addConfidenceScoresAction = (payload) => {
  // console.log(payload, 'add')
  return {
    type: 'ADDCONFIDENCESCORE_ACTION',
    payload,
  }
}
