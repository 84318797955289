import axios from "axios"
import { API_BASE_URL } from "config/constants"

import { getCookie } from "config/functions"

axios.defaults.baseURL = API_BASE_URL


export const getAllMeetingList = (bodyData) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/meeting/get-meeting-list', bodyData);
        resolve(response.data)
    })
}

export const getMeetingListForCustomer = (bodyData) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/meeting/get-customer-meeting-list', bodyData);
        resolve(response.data)
    })
}

export const getMeetingDetailsByID = (bodyData) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/meeting/get-meeting-details', bodyData);
        resolve(response.data)
    })
}

export const handleLiveClassFavorite = (bodyData) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/meeting/add-to-favourite', bodyData);
        resolve(response.data)
    })
}

export const getCustomerFavouriteClasses = (bodyData) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/meeting/get-favourite-meeting-list', bodyData);
        resolve(response.data)
    })
}

export const handleApplyLiveClassCouponCode = (bodyData) => {
    return new Promise(async (resolve, reject) => {
        const response = await axios.post('/customer/coupon/apply-live-class-coupon-code', bodyData);
        resolve(response.data)
    })
}
