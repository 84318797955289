import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import manageCategory from 'admin-assets/images/menu-icon/categories.png'
import womenCycle from 'assets/images/MOONRISE_White.svg'
import adminDefault from 'admin-assets/images/profileImage.png'
import dashboardSvg from 'admin-assets/images/menu-icon/dashboard.svg'
import symptomSvg from 'admin-assets/images/menu-icon/symptoms.png'
import groupSvg from 'admin-assets/images/menu-icon/group_icon.svg'
import courseSvg from 'admin-assets/images/menu-icon/packages.png'
import creatorSvg from 'admin-assets/images/menu-icon/teacher.png'
import customerSvg from 'admin-assets/images/menu-icon/customer.png'
import lockSvg from 'admin-assets/images/menu-icon/pauseBilling.png'
import couponSvg from 'admin-assets/images/menu-icon/statstics.png'
import productSvg from 'admin-assets/images/menu-icon/Order.svg'
import contact from 'admin-assets/images/menu-icon/contacts.png'
import librarySvg from 'admin-assets/images/menu-icon/Filled.svg'
import changePass from 'admin-assets/images/menu-icon/changePass.png'
import SignOut from 'admin-assets/images/menu-icon/Sign_out.png'
import { BsFillArrowRightCircleFill } from 'react-icons/bs'
import refresh from 'admin-assets/images/menu-icon/Refresh.png'
import liveClass from "admin-assets/images/menu-icon/meeting.svg"
import { RiCoupon5Line } from "react-icons/ri";

import { removeCookie, getCookie, toggleNav } from '../../../config/functions'
import Scrollbar from '../../common/AutoScrollToTop'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { getNotifications } from '../apiStore'
import moment from 'moment'

export default function SideBar(props) {
  // State Vars
  const [showCourseAnalytics, setShowCourseAnalytics] = React.useState(false)
  const [showmanageCourseAnalytics, setmanageShowCourseAnalytics] = React.useState(false)
  const [showCustomers, setShowCustomers] = React.useState(false)
  const [showLibrary, setShowLibrary] = React.useState(false)
  const [nav, setNav] = useState(false)
  const [showLibraryCustomers, setShowLibraryCustomers] = React.useState(false)
  const [showNotification, setShowNotification] = React.useState(false)
  const [notifications, setNotifications] = React.useState({
    data: [],
    total: 0
  })

  // Routes Vars
  const history = useHistory()
  const { pathname: pathName } = history.location

  // local Vars
  let adminProfile = getCookie('adminProfile')
  let adminProfileImage = getCookie('adminProfileImage')

  const onLogout = () => {
    removeCookie('adminToken')
    removeCookie('adminProfile')
    removeCookie('adminID')
    props.history.push({ pathname: `/admin` })
  }
  let role = getCookie('adminRole')
  let id = getCookie('adminID')
  const handleNav = (e) => {
    setNav(!nav)
  }
  /* 
    Function to handle data on mount
  */
  useEffect(() => {
    setShowLibrary(false) // When routes change hide the submenu
    setShowLibrary(false) // When routes change hide the submenu
    setShowCustomers(false)
  }, [pathName])
  let currentPage = window.location.pathname;

  const fetchNotifications = async () => {
    const bodyData = {
      userType: 'admin',
      isRead: 0,
      limit: 5
    }
    const apiRes = await getNotifications(bodyData);
    if (apiRes.status) {
      
      const notificationsData = apiRes.data?.data?.map(item => {
        return {
          ...item,
          createdOn: moment.utc(item.createdOn).local().format('YYYY-MM-DD HH:mm:ss')
        }
      
      })
      setNotifications({
        data: notificationsData,
        total: apiRes.data?.total
      })
    }
  }

  useEffect(() => {
    fetchNotifications()
  }, [])

  useEffect(() => {
    if (showNotification) {
      setShowNotification(false)
    }
  }, [history.location.pathname])

  const handleNotificationClick = (url) => {
    setShowNotification(false)
    history.push(url)
  }


  const NotificationDropDown = () => {
    return (
      <div className='admin-notification-dropdown'>
        <Card style={{
          minHeight: '300px'
        }}>
          <CardHeader className='d-flex align-items-center justify-content-between'>
            <span>Notifications</span>
            <NavLink to="/admin/notifications">View More</NavLink>
          </CardHeader>
          <CardBody className='d-flex align-items-center flex-column p-2 m-0'>
            {notifications.data.length > 0 ? notifications.data.map(item => {
              return (<div role='button' className='notification-header w-100 border-bottom p-1 mt-1' onClick={() => handleNotificationClick(item.url)}>
                <p><strong>{item.notificationHeader}</strong></p>
                <span>
                  {moment.utc(item.createdOn).local().fromNow()}
                </span>
              </div>)
            }) :
              <div className=' py-5 mt-1'>
                <p><strong>No Notifications</strong></p>
              </div>

            }

          </CardBody>
        </Card>
      </div>
    )
  }

  const showCount = (count) => {
    if (count > 9) {
      return `9+`
    }
    return count
  }

  return (
    <React.Fragment>
      <div id='admin-container'>
        <div className={`dash-wrapper ${nav ? 'slide' : 'slide-remove'}`}>
          <div id='sidebar' data-scrollbar>
            <div className='sidebar__top text-center'>
              <button type='button' className='btn-menu-toggle' onClick={(e) => toggleNav(e)}>
                <svg viewBox='0 0 24 24' width='24' height='24' stroke='#fff' strokeWidth='2' fill='none' strokeLinecap='round' strokeLinejoin='round' className='css-i6dzq1'>
                  <line x1='18' y1='6' x2='6' y2='18'></line>
                  <line x1='6' y1='6' x2='18' y2='18'></line>
                </svg>
              </button>
              <img src={womenCycle} alt='' />
            </div>
            <div className='sidebar__profile text-center'>
              {adminProfileImage && adminProfileImage !== 'null' ? <img src={adminProfileImage} alt='' /> : <img src={adminDefault} alt='' />}
              <p>{adminProfile}</p>
            </div>
            <Scrollbar>
              <ul className='sidebar__menu'>
                <li className={currentPage.includes("/admin/dashboard")
                  || currentPage.includes("admin/on-boarding")

                  ? "nav-item-active" : "nav-item"}>
                  <NavLink exact to='/admin/dashboard' title='Dashboard'>
                    <img className='icon' src={dashboardSvg} alt='' aria-hidden='true' />
                    <span>Dashboard</span>
                  </NavLink>
                </li>
                {role == '1' && role != '2' && (
                  <li className={currentPage.includes("/admin/package-categories")
                    || currentPage.includes("/edit-package-category/")
                    || currentPage.includes("/add-package-category")
                    ? "nav-item-active" : "nav-item"}>
                    <NavLink to='/admin/package-categories' title='Manage Categories' className="c">
                      <img className='icon' src={manageCategory} alt='' aria-hidden='true' />
                      <span>Manage Categories </span>
                    </NavLink>
                  </li>
                )}

                {role == '1' && role != '2' && (
                  <li className={currentPage.includes("/admin/symptoms")
                    || currentPage.includes("/edit-symptom/")
                    || currentPage.includes("/add-symptom")
                    ? "nav-item-active" : "nav-item"}>
                    <NavLink to='/admin/symptoms' title='Manage Symptoms'>
                      <img className='icon' src={symptomSvg} alt='' aria-hidden='true' />
                      <span>Manage Symptoms </span>
                    </NavLink>
                  </li>
                )}

                {/* Menu for Manage Library Start */}
                {role == '1' && role != '2' && (
                  <li className={currentPage.includes("/admin/groups")
                    || currentPage.includes("edit-group") || currentPage.includes("add-group")
                    || currentPage.includes("courses") || currentPage.includes("edit-course") || currentPage.includes("add-course")
                    || currentPage.includes("playlist") || currentPage.includes("edit-playlist")
                    || currentPage.includes("videos") || currentPage.includes("edit-video")
                    || currentPage.includes("add-video") || currentPage.includes("admin/add-review") ||
                    currentPage.includes("admin/reviews")
                    ? "nav-item-active" : "nav-item"} onClick={() => history.push('/admin/groups')}>
                    <NavLink to='/admin/groups' title='Manage Library'>
                      <img className='icon' src={librarySvg} alt='' aria-hidden='true' />
                      <span>Manage Library </span>
                    </NavLink>
                  </li>
                )}
                {role == '1' && role != '2' && (

                  <li className={currentPage.includes("/admin/manage-product")
                    || currentPage.includes("admin/view/")
                    || currentPage.includes("admin/product/assign-list")
                    ? "nav-item-active" : "nav-item"} >
                    <NavLink to='/admin/manage-product' title='Manage Product Access'>
                      <img className='icon' src={productSvg} alt='' aria-hidden='true' />
                      <span>Manage Product Access</span>
                    </NavLink>
                  </li>
                )}
                {role == '1' && role != '2' && (

                  <li className={currentPage.includes("/admin/customers")
                    || currentPage.includes("edit-customer")
                    ? "nav-item-active" : "nav-item"} >
                    <NavLink to='/admin/customers' title='Manage Customers'>
                      <img className='icon' src={customerSvg} alt='' aria-hidden='true' />
                      <span>Manage Customers</span>
                    </NavLink>
                  </li>
                )}

                {role == '1' && role != '2' && (
                  <li className={currentPage.includes("/admin/creator") || currentPage.includes("/edit-creator/") ? "nav-item-active" : "nav-item"} >
                    <NavLink to='/admin/creator' title='Manage Teachers'>
                      <img className='icon' src={creatorSvg} alt='' aria-hidden='true' />
                      <span>Manage Teachers</span>
                    </NavLink>
                  </li>
                )}
                {/* For Live Meeting */}
                {role == '1' && role != '2' && (
                  <li className={currentPage.includes("/admin/meeting") ? "nav-item-active" : "nav-item"}>
                    <NavLink to='/admin/meeting' title='Meeting'>
                      <img className='icon' src={liveClass} alt='Live Class' aria-hidden='true' />
                      <span>Manage Live Classes</span>
                    </NavLink>
                  </li>
                )}
                {role == '2' && role != '1' && (
                  <li>
                    <NavLink to='/admin/customer-chat' title='Customer chat'>
                      <img className='icon' src={creatorSvg} alt='' aria-hidden='true' />
                      <span>Customer chat</span>
                    </NavLink>
                  </li>
                )}

                {/*  put teacher id to provide access 29 // carolineID*/}

                {role == '2' && role != '1' && id == 29 && (
                  <>
                    <li
                      className={currentPage.includes("/admin/customers") ? "nav-item-active" : "nav-item"}
                      onClick={() => {
                        setShowCustomers(!showCustomers)
                        setShowLibrary(false)
                        setShowLibraryCustomers(false)
                      }}
                    >
                      <div className='submenutoggle'>
                        <img className='icon' src={customerSvg} alt='' aria-hidden='true' />
                        <span>Manage Customers</span>
                      </div>


                      {/* <ul className={showCustomers ? 'submenu d-block' : 'submenu d-none'}>
                        <li>
                          <NavLink to='/admin/package-customer' title='Package Customers'>
                            <span>Package Customers</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to='/admin/customer' title='Manage Customers'>
                            <span>Customers</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to='/admin/customer-analysis' title='Customer Analysis'>
                            <span>Customer Analysis</span>
                          </NavLink>
                        </li>

                        <li>
                          <NavLink to='/admin/on-boarding' title='Customer Manual On-Boarding'>
                            <span>Customer OnBoarding</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to='/admin/customer-support' title='Customer Manual On-Boarding'>
                            <span>Customer Support</span>
                          </NavLink>
                        </li>
                      </ul> */}
                    </li>

                    <li
                      className='dropdownnav'
                      onClick={() => {
                        setShowCustomers(false)
                        setShowLibrary(false)
                        setShowLibraryCustomers(false)
                        setShowCourseAnalytics(!showCourseAnalytics)
                      }}
                    >
                      <div className='submenutoggle'>
                        <img className='icon' src={couponSvg} alt='Course Analytics' />
                        <span>Course Statistics</span>
                      </div>

                      <ul className={showCourseAnalytics ? 'submenu d-block' : 'submenu d-none'}>
                        <li>
                          <NavLink to='/admin/library/courseStatistics' title="Creator's Course Analytics">
                            <span>Teacher</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to='/admin/library/customer-courseAnalytics' title="Student's Course Analytics">
                            <span>Customer</span>
                          </NavLink>
                        </li>
                      </ul>
                    </li>

                    {false && (
                      <li>
                        <NavLink to='/admin/pause-membership' title='Pause Billing'>
                          <img className='icon' src={lockSvg} alt='' aria-hidden='true' />
                          <span>Pause Billing Request</span>
                        </NavLink>
                      </li>
                    )}
                    <li className={currentPage.includes("/admin/change/password") ? "nav-item-active" : "nav-item"}>
                      <NavLink to='/admin/change/password' title='Change Password'>
                        <img className='icon' src={lockSvg} alt='' aria-hidden='true' />
                        <span>Change Password</span>
                      </NavLink>
                    </li>
                    <li className='' style={{ cursor: 'pointer' }} onClick={() => onLogout()}>
                      <NavLink to='/admin' title='Logout'>
                        <img className='icon ' src={SignOut} alt='' aria-hidden='true' />
                        <span>Logout</span>
                      </NavLink>
                    </li>
                  </>
                )}
                {role == '1' && role != '2' && (
                  <li className={(currentPage.includes("/admin/coupons/library") || (currentPage.includes("/admin/coupons/live-class"))) ? "nav-item-active" : "nav-item"}>
                    <NavLink to='/admin/coupons/library' title='Coupons'>
                      <RiCoupon5Line className="icon" />
                      <span>Manage Coupons</span>
                    </NavLink>
                  </li>
                )}

                {role == '1' && role != '2' && (
                  <li className={currentPage.includes("/admin/change/password") ? "nav-item-active" : "nav-item"}>
                    <NavLink to='/admin/change/password' title='Change Password'>
                      <img className='icon' src={changePass} alt='' aria-hidden='true' />
                      <span>Change Password</span>
                    </NavLink>
                  </li>
                )}

                {role == '1' && role != '2' && (
                  <li className='nav-item' style={{ cursor: 'pointer' }} onClick={() => onLogout()}>
                    <NavLink to='/admin' title='Logout'>
                      <img className='icon ' src={SignOut} alt='' aria-hidden='true' />
                      <span>Logout</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </Scrollbar>
          </div>
          <main id='main' className='admin-navbar'>
            <div className='main__top'>
              <button type='button' className='btn-menu-toggle' onClick={(e) => toggleNav(e)}>
                <svg xmlns='http://www.w3.org/2000/svg' width='21' height='16' viewBox='0 0 21 16'>
                  <g fill='none' fillRule='evenodd'>
                    <g fill='#2F2C58' fillRule='nonzero'>
                      <g>
                        <path
                          d='M19.067 12.067c1.066 0 1.933.672 1.933 1.5 0 .827-.867 1.5-1.933 1.5H1.933c-1.066 0-1.933-.673-1.933-1.5 0-.828.867-1.5 1.933-1.5zm0-6.034c1.066 0 1.933.673 1.933 1.5 0 .828-.867 1.5-1.933 1.5H1.933C.867 9.033 0 8.361 0 7.533c0-.827.867-1.5 1.933-1.5zm0-6.033C20.133 0 21 .673 21 1.5S20.133 3 19.067 3H1.933C.867 3 0 2.327 0 1.5S.867 0 1.933 0z'
                          transform='translate(-292 -26) matrix(1 0 0 -1 292 42)'
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
              {/* <div className='btn-logout font__weight--simibold' style={{ cursor: 'pointer' }} onClick={() => onLogout()}>
                Logout <BsFillArrowRightCircleFill color='#2f2c58' size={20} />
              </div> */}
              <div className='admin-notification' onClick={() => setShowNotification(!showNotification)}>
                <i className='material-icons '>notifications</i>
                <span className='notification-count'>{
                  showCount(notifications.total)
                }
                </span>
              </div>
              {showNotification && <NotificationDropDown />}
            </div>


          </main>
        </div>
      </div>
    </React.Fragment>
  )
}
