import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

function AutoScrollToTop({ history, children }) {

  const pathName = window.location.pathname
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (!pathName.includes('/checkout/')) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    }
  });

  return <Fragment>{children}</Fragment>;
}

export default withRouter(AutoScrollToTop); 
