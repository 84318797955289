import axios from "axios"
import { API_BASE_URL } from "config/constants"

axios.defaults.baseURL = API_BASE_URL

export const handleGoalSubmit = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/customer/submit-goal", bodyData)
    resolve(response.data)
  })
}

export const handleRewardAccepted = bodyData => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.post("/customer/accept-reward", bodyData)
    resolve(response.data)
  })
}

