import { getTranslatedName } from 'config/functions';
import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';

const FeedbackModal = (props) => {
  return (
    <Modal
      scrollable={true}
      isOpen={props.show}
      className="  modal-dialog modal-dialog-scrollable"
      dialogclassname="showContentPause showContentPause-css"
      centered
      size='md'
      backdrop={`static`}
    >
      <div className="bg-semiLocked">
        <ModalBody className="pauseContent p-0">
          <div className="d-flex flex-column px-2">
            <div className="w-100 text-center bg-start">
              <i className="fa fa-check-circle-o fa-5x text-success mt-5 pb-3" />
              <button
                type="button"
                className="close-modal pointer"
                onClick={() => props.handleClose({}, false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <p className="my-4 title-modal">
              {getTranslatedName("If you are seeing this message, it means one of the following")}:
            </p>

            <ul className="list-unstyled text-center">
              <li className="my-2 fs-5">
                1: {getTranslatedName("Waiting For Feedback")}
              </li>
              <span className="text-muted"> <strong>OR</strong> </span>
              <li className="my-2 fs-5">
                2: {getTranslatedName("Re submit your video")}
              </li>
            </ul>

          </div>
          <div className="w-100 text-center my-4 d-flex flex-column px-4">
            <Button
              color="secondary"
              className="btn-submit btn-risk border-0 mx-1 mb-2"
              onClick={() => props.handleCheckSubmission()}
            >
              {getTranslatedName("Click here to see your video")}
            </Button>
            <Button
              color="primary"
              className="btn-goBack border-0 mx-1 mt-2"
              onClick={async () => {
                await props.handleOpenCrisp();
              }}
            >
              {getTranslatedName("Click here to see your feedback")}
            </Button>{" "}
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default FeedbackModal