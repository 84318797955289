import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Loader from "../../../common/Loader";

export default function ShowSubscriptionExpirePopup(props) {
    let { data } = props;
    const handleShowUpdateCard = (e) => {
        e.preventDefault();
        props.handleClose();
        props.handleShowUpdateForm();
        //setShowUpdateFormPopup(true);
    }
    return (
        <React.Fragment>
            <Modal show={props.show} onHide={props.handleClose} centered className="ModalDanger" backdrop={`static`}>
                <Modal.Header closeButton className='text-center closeButton border-0'>
                    <h4 className=''>Welcome back {(data.firstName) ? data.firstName : ''}!</h4>
                </Modal.Header>
                <Modal.Body className='pb-0'>
                    <div >
                        <h6>One Little Problem</h6>
                        <p>Your NKS payment failed. Please make sure we’ve got your details right. You will loose access if you do not have a working payment method for your account.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='justify-content-start border-0'>
                    <div>
                        <Button type="button" variant="" className='btn-theme' onClick={(e) => { handleShowUpdateCard(e); }}>Update Details</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}