import React, {useState} from 'react'
import {saveCookie} from 'config/functions'
import HeaderMeta from 'views/common/HeaderMeta'
import FlashMessages from 'views/common/FlashMessages'
import {Year} from 'utility/util'
//import logoIcon from 'admin-assets/images/logo.png';
//import logoIcon from 'admin-assets/images/logo.png';
import logoIcon from '../../../assets/images/MoonRiseSec.png'
import {useSelector} from 'react-redux'
import '../../../admin-assets/admin_css/style.css'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import axios from 'axios'

export default function AdminLogin(props) {
  const [error, setError] = useState('')
  let adminData = useSelector((state) => state.admin.adminProfile)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Not a valid Email id')
        .required('Email is a required'),
      password: Yup.string()
        .min(6, 'Minimum 6 characters')
        .required('Password is a required'),
    }),
    onSubmit: (values, {resetForm, setSubmitting}) => {
      // validation(values)
      handleLogin(values)
    },
  })

  const handleLogin = async (values) => {
    try {
      const res = await axios({
        method: 'post',
        url: 'admin/adminLogin',
        data: values,
      })
      const admin = res.data
      if (admin.status) {
        adminData = admin.data
        // console.log(adminData.profile)
        const adminToken = adminData.token
        await saveCookie('adminToken', adminToken)
        await saveCookie('adminID', adminData.profile.adminID)
        let adminProfileName = adminData.profile.firstName
        let role = adminData.profile.role
        await saveCookie('adminRole', role)
        if (adminData.profile.lastName) {
          adminProfileName = adminData.profile.firstName + ' ' + adminData.profile.lastName
        }
        let adminProfileImage = null
        if (adminData.profile.imageURL) {
          adminProfileImage = adminData.profile.imageURL
        }
        await saveCookie('adminProfile', adminProfileName)
        await saveCookie('adminProfileImage', adminProfileImage)

        await props.history.push({
          pathname: `/admin/dashboard`,
        })
      } else {
        setError(admin.message)
      }
    } catch (err) {
      setError('Something went Wrong')
    }
  }

  const handleFlashUnmount = () => {
    setError('')
  }

  return (
    <React.Fragment>
      <HeaderMeta title='Admin Login' />
      <div className='bg-login admin-body'>
        <div id='admin-container'>
          <div className='main-wrapper'>
            <main id='main'>
              <div className='text-center'>
                <a href='index.html' className='logo'>
                  <img src={logoIcon} alt='Moonrise' height='120' width='120' />
                </a>
              </div>
              <form className='login-form' id='loginForm' method='POST' action='dashboard.html' onSubmit={formik.handleSubmit}>
                {error && <FlashMessages type={'error'} message={error} unmountMe={handleFlashUnmount} />}
                <div className='form-group text-center'>
                  <div className='font__weight--bold text-pantone-heading heading-18'>Admin Login</div>
                </div>
                <div className='form-group'>
                  <div className='form__addon'>
                    <span className='form__addon--start'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='18' height='23' viewBox='0 0 18 23'>
                        <g fill='none' fillRule='evenodd'>
                          <g fill='#666' fillRule='nonzero'>
                            <g>
                              <path
                                d='M13.545 10.872c2.482 1.577 4.134 4.36 4.134 7.523 0 .958-.522 2.294-3.008 3.215-1.55.574-3.621.89-5.832.89-2.21 0-4.281-.316-5.832-.89C.522 20.69 0 19.353 0 18.395c0-3.164 1.652-5.946 4.133-7.523.303.384.643.737 1.033 1.057-2.228 1.285-3.733 3.702-3.733 6.466 0 1.493 3.253 2.663 7.406 2.663s7.406-1.17 7.406-2.663c0-2.764-1.504-5.18-3.732-6.466.39-.32.73-.673 1.032-1.057zM8.84 0c2.785 0 4.931 2.707 4.931 5.911 0 3.203-2.145 5.911-4.93 5.911-2.785 0-4.932-2.707-4.932-5.91C3.908 2.707 6.053 0 8.84 0zm0 1.442c-1.928 0-3.497 2.005-3.497 4.47 0 2.464 1.569 4.469 3.497 4.469 1.929 0 3.498-2.005 3.498-4.47 0-2.464-1.57-4.47-3.498-4.47z'
                                transform='translate(-489 -377) translate(489 377)'
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <input type='email' autoFocus={true} name='email' id='email' placeholder='Email' className='form-control size-lg border-radius-8' onChange={formik.handleChange} value={formik.values.email} />
                  </div>
                  {formik.errors.email && formik.touched.email && <div className='error-msg'>{formik.errors.email}</div>}
                </div>
                <div className='form-group'>
                  <div className='form__addon'>
                    <span className='form__addon--start'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 18 24'>
                        <g fill='none' fillRule='evenodd'>
                          <g fill='#666' fillRule='nonzero'>
                            <g>
                              <path
                                d='M9 0c3.309 0 6 2.691 6 6v3h.75C16.99 9 18 10.009 18 11.25v10.5c0 1.241-1.01 2.25-2.25 2.25H2.25C1.01 24 0 22.991 0 21.75v-10.5C0 10.009 1.01 9 2.25 9H3V6c0-3.309 2.691-6 6-6zm6.75 10.5H2.25c-.413 0-.75.336-.75.75v10.5c0 .414.337.75.75.75h13.5c.413 0 .75-.336.75-.75v-10.5c0-.414-.337-.75-.75-.75zM9 13c1.103 0 2 .897 2 2 0 .838-.517 1.557-1.25 1.854v2.396c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-2.396C7.518 16.557 7 15.838 7 15c0-1.103.897-2 2-2zm0 1.5c-.275 0-.5.224-.5.5s.225.5.5.5.5-.224.5-.5-.225-.5-.5-.5zm0-13C6.519 1.5 4.5 3.519 4.5 6v3h9V6c0-2.481-2.019-4.5-4.5-4.5z'
                                transform='translate(-489 -449) translate(489 449)'
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <input type='password' name='password' id='password' placeholder='Password' className='form-control size-lg border-radius-8' onChange={formik.handleChange} value={formik.values.password} />
                  </div>
                  {formik.errors.password && formik.touched.password && <div className='error-msg'>{formik.errors.password}</div>}
                </div>
                <button type='submit' name='tower_login' value='yes' className='btn-theme-admin btn-block btn-lg border-radius-8'>
                  Login
                </button>
              </form>
              <div className='text-center font__weight--simibold btn-link-light'>
                <small>{/* <Link to="/admin/forgot/password">
                                    Reset Password
                        </Link> */}</small>
              </div>
            </main>
            <footer id='footer' className='text-center'>
              <small className='copyright text-black'>
                &copy; MOONRISE
                {' ' + Year()}. All Rights Reserved
              </small>
            </footer>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
