import { combineReducers, createStore, compose, applyMiddleware } from "redux";
// import demoReducers , { initialState as demoState } from "./components/home/reducer";
import non_register from './views/publicLayout/reducer';
import register from './views/registerStudentLayout/reducer';
import admin from './views/adminLayout/reducer';
// import blogReducers, { initialState as blogInitialState } from "blog/ducks";
// import loginReducers, { initialState as loginInitialState } from "auth/ducks";
// import teamReducers, { initialState as teamInitialState } from "profile/ducks";
// import tournamentReducers, {initialState as tournamentInitialState,} from "tournaments/ducks";

// import {
//   sideEffectMiddleware,
//   sideEffectReducer,
// } from "@faizaanceg/redux-side-effect";
// import { snitchReducer, snitchMiddleware } from "@faizaanceg/snitch";
import createSagaMiddleware from "redux-saga";
import mainSaga from "./sagas";
// import logger from "redux-logger";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [
  sagaMiddleware,
  // logger,
  //   sideEffectMiddleware("sideEffect"),
  //   snitchMiddleware("modals"),
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  non_register: non_register,
  register: register,
  admin: admin
});

// const initialState = {
//   ds : demoState,
//   userToken : tokenState
// //   blog: blogInitialState,
// //   ...loginInitialState,
// //   ...teamInitialState,
// //   ...tournamentInitialState,
// };

const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);


sagaMiddleware.run(mainSaga);


export default store
