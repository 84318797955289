import React, { useEffect, useRef } from 'react'
import arrow from 'assets/images/phaseArrow.svg'
import styles from './greet.module.scss'
import { checkSwedish, getTranslatedName } from 'config/functions'


export default function GreetCustomer({ student, video, plan }) {
    const greetRef = useRef(null)
    useEffect(() => {
        if (greetRef.current !== null && !video) {
            // Scroll to the component
            greetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [])
    const swedish = checkSwedish()
    return (
        <>
            {
                video ? (
                    swedish ? (
                        <h2 className={styles.greet}>{student.firstName}, din <span className={styles.highlight}>
                            lektion </span> för dagen
                        </h2>

                    ) : (
                        <h2 className={styles.greet}>{student.firstName}, your <span className={styles.highlight}>
                            lesson </span> of the day
                        </h2>
                    )
                ) : plan ? (

                    <h2 className={styles.greet}>
                        <span className={styles.highlight}>
                            {getTranslatedName("My health plan:")}
                        </span>
                    </h2>

                ) : (
                    swedish ? (
                        <h1 className={styles.greet}>Vackra {student.firstName}, du är här
                        </h1>
                    ) : (
                        <h1 ref={greetRef} className={styles.greet}>Beautiful {student.firstName}, you're here
                        </h1>
                    )

                )
            }
            {
                video ? (
                    <div className={styles.arrow} >
                        <img src={arrow} alt="pointer" />
                    </div>
                ) : plan ? (
                    ''
                ) : (
                    <div className={styles.arrow} >
                        <img src={arrow} alt="pointer" />
                    </div>

                )
            }
        </>
    )
}
