import React from 'react';
import { Modal } from 'react-bootstrap';


export default function Example(props) {


    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogclassname="modal-dialog modal-confirm modalConfirmBox"
        >
            <Modal.Header className="modal-header flex-column border-0">
                {/* <div className="icon-box">
                    <i className="material-icons">&#xE5CD;</i>
                </div> */}
                {/* <h4 className="modal-title w-100 justify-content-center text-center">onfirm </h4> */}
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={props.handleClose}>&times;</button>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <p className="text-center text-dark ">{"Do you really want to delete this customer?"}</p>
            </Modal.Body>
            <Modal.Footer className="modal-footer justify-content-center">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={props.handleClose}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={props.handleSubmit}>Delete</button>
            </Modal.Footer>
        </Modal>
    );
}