import React, {Component} from 'react'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import {RANGE_SLIDER_MIN, RANGE_SLIDER_MAX} from '../../../config/constants'

export default class MultiRangeSliderApp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      value2: 10,
      value3: 10,
      value4: {
        min: 5,
        max: 10,
      },
      value5: {
        min: 3,
        max: 7,
      },
      labels: props.labels ? props.labels : '',
    }
  }

  onCompleteRangeData = (value, symptomid) => {
    this.props.rangeData(value)
    if (symptomid) {
      this.props.symptomID(symptomid)
    }
  }
  render() {
    let symptomid = this.props.symptomID
    const {labels} = this.state

    return (
      <>
        {labels && labels !== '' ? (
          <div className='InputRangecss'>
            <InputRange  maxValue={RANGE_SLIDER_MAX} 
             minValue={RANGE_SLIDER_MIN} 
            value={this.state.value}
            formatLabel={(value) => ( 
              // labels[value].length < 20 ? 
              `${value}: ${labels[value]}`
              // : `${value}: 
              // ${labels[value].substring(0,16)+"..."}`
        )} 
            onChange={(value) => this.setState({value})} 
            onChangeComplete={(value) => this.onCompleteRangeData(value, symptomid)} />
        </div>
        ) : (
          <div className='InputRangecss'>
            <InputRange maxValue={RANGE_SLIDER_MAX} 
            minValue={RANGE_SLIDER_MIN} 
            value={this.state.value} 
            onChange={(value) => this.setState({value})} 
            onChangeComplete={(value) => this.onCompleteRangeData(value, symptomid)} />
          </div>
        )}
      </>
    )
  }
}
