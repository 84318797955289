import React, { useEffect, useState } from 'react';
import { Button, Alert } from 'react-bootstrap';
import UpdateFormPopup from "../pages/billing/popup/UpdateFormPopup";
import { httpGetRequest } from '../../config/services';
import { isEmpty, isEmptyObj } from '../../config/functions';
import Loader from "../common/Loader"

export default function ShowUpdateCTABlog(props) {
    const [showUpdateFormPopup, setShowUpdateFormPopup] = useState(false);
    const handleFormShowHide = () => setShowUpdateFormPopup(!showUpdateFormPopup);
    const [countries, setCountries] = useState([]);
    const [planInfo, setPlanInfo] = useState({});
    const [cardInfo, setCardInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const handleShowUpdateCard = (e) => {
        e.preventDefault();
        setShowUpdateFormPopup(!showUpdateFormPopup);
    }
    useEffect(() => {
        getCountryList()
	}, [])

    function getCountryList() {
        try {
            httpGetRequest('api/getbillinginfo', {}, (error, result) => {
                if (!isEmpty(error)) {
                    console.log("err", error);
                    setIsLoading(false);
                    //setShowUpdateFormPopup(false);
                } else {
                    let stateObj = {};
                    if (result.status) {
                        let response = result.data
                        stateObj.cardInfo = response.cardInfo;
                        stateObj.planInfo = response;
                        setPlanInfo(response);
                        setCardInfo({ ...cardInfo, ...stateObj });
                        if (response.countries) {
                            setCountries(response.countries);
                        }
                        //setShowUpdateFormPopup(true);
                    } else {
                        //setShowUpdateFormPopup(false);
                    }
                    setIsLoading(false);
                }
            });
        }
        catch (err) {
            console.log("err", err);
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <Alert variant="danger" className='UpdateDetailsBox  '>
                <Alert.Heading>One Little Problem</Alert.Heading>
                <p>Your NKS payment failed. Please make sure we’ve got your details right. You will loose access if you do not have a working payment method for your account.</p>
                <Button type="button" variant="" className='uDBtn btn-theme' onClick={(e) => { handleShowUpdateCard(e); }}>Update Details</Button>
            </Alert>
            {(isLoading) && <Loader />}
            {(!isLoading && showUpdateFormPopup) && (<UpdateFormPopup
                show={showUpdateFormPopup}
                handleClose={handleFormShowHide}
                cardInfo={(!isEmptyObj(cardInfo) && !isEmpty(cardInfo.cardInfo)) ? cardInfo.cardInfo : {}}
                STCID={(!isEmptyObj(cardInfo) && !isEmpty(cardInfo.cardInfo) && !isEmpty(cardInfo.cardInfo.customer)) ? cardInfo.cardInfo.customer : ""}
                country={countries}
                auth={true}
                {...props}
            />)}
        </React.Fragment>
    )
}