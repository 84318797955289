import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { TiTick } from "react-icons/ti";
import confettibg from 'assets/images/Confetticonfetti.svg'
import styles from './congrats.module.scss'
import { FaCheckCircle } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { getTranslatedName, isEmpty } from 'config/functions';
import { handleRewardAccepted } from 'components/apiStore';
import Swal from 'sweetalert2';
export default function CongtatsModal({ close, show, library, phaseGoal }) {

    const studentData = useSelector(state => state.register.userProfileData)
    const name = `${studentData?.firstName} ${studentData?.lastName || ''}`


    const handleGoalAccept = async () => {
        const apiRes = await handleRewardAccepted({ phaseGoalId: phaseGoal.id, isAccepted: 1 })

        if (apiRes.status) {
            window.location.reload()
        } else {
            Swal.fire({
                title: 'Error',
                text: `${apiRes.message}, please contact support team.`,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000,
                borderRadius: 50
            })
        }
    }

    return (
        <>
            <Modal
                show={show}
                // onHide={() => close(false)}
                backdrop="static"
                keyboard={false}
                size='lg'
                style={{
                    opacity: "0.5 !important",
                    backgroundColor: "#16162f",
                    width: '100vw !important',
                }}
            >

                <div className={styles["congrats-container"]}>

                    <div className={styles["green-tick"]}>
                        <img src={confettibg} alt="confetti" />
                        <TiTick size={'4em'} style={{ position: 'absolute' }} />
                    </div>

                    <div className={styles["message-body"]}>
                        <h3>{getTranslatedName("Congratulations")} {name}!</h3>
                        <p>
                            {getTranslatedName("Your teacher has approved your technique. Open the chat window to see your detailed feedback from your teacher. Here is also what you have unlocked")}:
                        </p>
                    </div>
                    <div className={styles["unlocked"]}>
                        {
                            !isEmpty(library) && library.map((item, index) => {
                                return (
                                    <div key={index} className={styles['item']}>
                                        <FaCheckCircle size={'1.5em'} color='#02a70b' />
                                        <h4>{item}</h4>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className={styles['btn-container']}>
                        <button onClick={handleGoalAccept} className={styles['btn']}>{getTranslatedName("Start watching")}</button>
                        <button onClick={() => window?.$crisp.push(['do', 'chat:open'])} className={styles['btn']}>{getTranslatedName("Teacher Feedback")}</button>
                    </div>
                </div>
                {/* <div className={styles['x']} onClick={() => close(false)} >
                    <IoClose />
                </div> */}

            </Modal >
        </>
    )
}
