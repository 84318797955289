import LibraryHeader from './LibraryHeader'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import FlashMessages from 'views/common/FlashMessages'
import Select from 'react-select'
import { isEmpty, getFormattedSlug } from 'config/functions'
import { Form, InputGroup, FormControl, Button } from 'react-bootstrap'
import ReactQuill from 'react-quill'
import base64 from 'base-64'
import { httpPostRequest, httpGetRequest } from 'config/services'
import 'react-quill/dist/quill.snow.css'

export default class AdminLibraryItemDetails extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      messageType: 'error',
      flashMessage: '',
      btnDisabled: false,
      title: '',
      brief: '',
      detail: '',
      createrID: '',
      isFeatured: 0,
      featuredBy: '',
      status: 0,
      teacherID: '',
      groupID: '',
      courseDuration: '',
      courseOptions: { groupList: [], symptomsList: [], categoryList: [], creatorList: [] },
      symptoms: [],
      category: [],
      includeText: '',
      courseInclude: [],
      slug: '',
      isUpcoming: false,
    }
    this.state = this.initialState
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      className: 'text-danger',
      validators: {
        validfeaturedBy: {
          rule: (val) => {
            if (this.state.isFeatured === '1' && this.state.featuredBy === '') {
              return true
            }
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
        },
      },
    })
  }

  componentDidMount() {
    let { courseID } = this.props.props.match.params
    try {
      this.getListFromHttp('admin/getCourseDetailOptions', 'get')
      if (!isEmpty(courseID)) {
        this.getCourseDetails(courseID)
      }
    } catch (err) {
      this.props.history.push('/admin/library-customers')
    }
  }

  getListFromHttp = (url) => {
    httpGetRequest(url, {}, (error, result) => {
      let stateObj = {}
      if (!isEmpty(error)) {
        stateObj = {
          messageType: 'error',
          flashMessage: error.message,
        }
      } else {
        if (result.status) {
          stateObj = {
            courseOptions: { groupList: result.data.groupList, symptomsList: result.data.symptoms, categoryList: result.data.categories, creatorList: result.data.creators },
          }
        } else {
          stateObj = {
            messageType: 'error',
            flashMessage: result.message,
          }
        }
      }
      this.setState(stateObj)
    })
  }

  getCourseDetails = (courseID) => {
    httpPostRequest(
      'admin/library/get_library_course_detail',
      {
        courseID: base64.decode(courseID),
      },
      (error, result) => {
        let stateObj = {}
        if (!isEmpty(error)) {
          stateObj = {
            messageType: 'error',
            flashMessage: error.message,
            btnDisabled: false,
          }
        } else {
          if (result.status) {
            stateObj.title = result.data.title
            stateObj.brief = result.data.brief
            stateObj.groupID = result.data.groupID
            stateObj.detail = result.data.detail
            stateObj.status = result.data.status
            stateObj.isFeatured = result.data.isFeatured
            stateObj.featuredBy = result.data.featuredBy
            stateObj.teacherID = result.data.creatorID
            stateObj.courseDuration = result.data.courseDuration
            stateObj.category = result.data.categories.map((x) => this.MakeOption(x.name, x.categoryID))
            stateObj.symptoms = result.data.symptoms.map((x) => this.MakeOption(x.name, x.symptomID))
            stateObj.getResource = result.data.resources
            stateObj.getReview = result.data.reviews
            stateObj.getModule = result.data.modules
            stateObj.courseInclude = !isEmpty(result.data.includes) ? JSON.parse(result.data.includes) : []
            stateObj.isUpcoming = result.data.isUpcoming
            stateObj.slug = result.data.slug
          } else {
            stateObj = {
              messageType: 'error',
              flashMessage: result.message,
              btnDisabled: false,
            }
          }
        }
        this.setState(stateObj)
      }
    )
  }

  handleMultiDropDownChange = (value, key) => {
    let stateObj = {}
    stateObj[key] = value
    stateObj['btnDisabled'] = false
    this.setState(stateObj)
  }

  MakeOption = (label, value) => {
    return { value: value, label: label }
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value,
      isUpcoming: value == 1 ? false : this.state.isUpcoming,
      btnDisabled: false,
    })
  }

  handleEditorChange = (value) => {
    this.setState({ detail: value, btnDisabled: false })
  }

  handleFlashUnmount = () => {
    this.setState({
      flashMessage: '',
    })
  }

  handleSubmit = (event) => {
    // this.setState({ btnDisabled: true })
    event.preventDefault()
    let { title, brief, detail, isFeatured, status, groupID, teacherID, symptoms, category, httpUrl, isUpcoming, courseInclude, slug, featuredBy, courseDuration } = this.state
    let symptomsID = ''

    if (!isEmpty(symptoms)) {
      for (let i = 0; i < symptoms.length; i++) {
        if (i == 0) {
          symptomsID = symptoms[i].value.toString()
        } else {
          symptomsID = symptomsID + ',' + symptoms[i].value.toString()
        }
      }
    }
    let categoryID = ''
    if (!isEmpty(category)) {
      for (let i = 0; i < category.length; i++) {
        if (i == 0) {
          categoryID = category[i].value.toString()
        } else {
          categoryID = categoryID + ',' + category[i].value.toString()
        }
      }
    }
    if (this.validator.allValid()) {
      let httpUrl = !isEmpty(this.props.props.match.params.courseID) ? 'admin/library/update-library-course' : 'admin/library/add-library-course'
      httpPostRequest(
        httpUrl,
        {
          creatorID: parseInt(teacherID),
          title: title.trim(),
          brief: brief.trim(),
          detail: detail.trim(),
          symptomID: symptomsID,
          categoryID: categoryID,
          groupID: groupID,
          status: status,
          slug: slug,
          courseDuration: courseDuration,
          isFeatured: isFeatured,
          featuredBy: featuredBy,
          includes: courseInclude.filter((val) => {
            return val
          }),
          isUpcoming,
          courseID: !isEmpty(this.props.props.match.params.courseID) ? base64.decode(this.props.props.match.params.courseID) : '',
        },
        (error, result) => {
          let stateObj = {}
          if (!isEmpty(error)) {
            stateObj = {
              messageType: 'error',
              flashMessage: error.message,
              btnDisabled: false,
            }
          } else {
            if (result.status) {
              let courseID = !isEmpty(this.props.props.match.params.courseID) ? this.props.props.match.params.courseID : base64.encode(result.courseID)
              this.props.props.history.push('/admin/edit-course/modules/' + courseID)
            } else {
              this.setState({
                messageType: 'error',
                flashMessage: result.message,
              })
            }
          }
          this.setState(stateObj)
        }
      )
    } else {
      this.validator.showMessages()
    }
  }

  viewAddCourse = (courseID) => {
    this.setState({
      courseID: courseID ? courseID : '',
      showPopup: this.state.showPopup ? false : true,
    })
  }

  modules = {
    toolbar: {
      container: [
        { header: 1 },
        { header: 2 },
        'bold',
        'underline',
        'italic',
        'strike',
        { script: 'sub' },
        { script: 'super' },
        // { 'indent': '-1' }, { 'indent': '+1' },
        { list: 'ordered' },
        { list: 'bullet' },
        'link'
        // 'image', 'link',
      ],
      // handlers: {
      // 	image: this.imageHandler
      // }
    },
  }

  createCourseInclude = () => {
    let { courseInclude, includeText } = this.state
    if (!isEmpty(includeText)) {
      courseInclude.push({ text: includeText.trim() })
      this.setState({ courseInclude: courseInclude, includeText: '' })
    }
  }

  removeCourseInclude = (key) => {
    let { courseInclude } = this.state
    delete courseInclude[key]
    this.setState(courseInclude)
  }

  handleCheckChange = (event) => {
    if (!this.state.isUpcoming) {
      this.setState({ isUpcoming: true })
    } else {
      this.setState({ isUpcoming: false })
    }
  }

  render() {
    let { courseOptions, teacherID, symptoms, category, hasChanges, status, isFeatured, featuredBy, btnDisabled, groupID, isUpcoming, courseInclude, includeText, courseID } = this.state
    let groupValue = courseOptions.groupList.map((x) => this.MakeOption(`${x.title}`, x.browseGroupID)).filter((option) => option.value === groupID)
    if (!isEmpty(groupValue)) {
      groupValue = groupValue[0]
    } else {
      groupValue = {}
    }

    let newCreator = courseOptions.creatorList.filter((item) => {
      return item.firstName === 'Filippa' || item.firstName === 'Caroline'
    })
    newCreator = newCreator.map((item) => {
      let name = item.firstName === 'Filippa' ? `Filippa's Pelvic Dysfunction Program` : `Caroline's Diastasis Recti Program`
      return {
        id: item.creatorID,
        name: name,
      }
    })
    let featuredOptions = newCreator
    return (
      <React.Fragment>
        <div id='admin-container'>
          <div className='dash-wrapper'>
            <main id='main'>
              <LibraryHeader {...this.props.props} courseID={''} tabSequence='1' />
              <Form onSubmit={this.handleSubmit}>
                <div className='container-fluid pt-40  add-category'>
                  {this.state.flashMessage ? <FlashMessages type={this.state.messageType} message={this.state.flashMessage} unmountMe={this.handleFlashUnmount} /> : ''}
                  <div className='row'>
                    <div className='col-xs-12 col-sm-12 col-md-12'>
                      <div className={hasChanges && !this.validator.fieldValid('title') ? 'form-group was-validatedform' : 'form-group'}>
                        <label className='conrol-label'>Title</label>
                        <Form.Control
                          type='text'
                          name='title'
                          value={this.state.title}
                          placeholder='Title'
                          onChange={(e) => {
                            this.handleChange(e)
                            this.setState({ slug: getFormattedSlug(e.target.value) })
                          }}
                        />
                        {this.validator.message('title', this.state.title, 'required|string', { className: 'text-danger' })}
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12'>
                      <div className={hasChanges && !this.validator.fieldValid('brief') ? 'form-group was-validatedform' : 'form-group'}>
                        <label htmlFor='name'>Brief</label>
                        <Form.Control type='text' name='brief' value={this.state.brief} placeholder='Brief' onChange={this.handleChange} />
                        {this.validator.message('brief', this.state.brief, 'required|string', { className: 'text-danger' })}
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12'>
                      <div className={hasChanges && !this.validator.fieldValid('detail') ? 'form-group was-validatedform' : 'form-group'}>
                        <label htmlFor='name'>Description</label>
                        <ReactQuill placeholder='Description' modules={this.modules} value={this.state.detail} theme='snow' onChange={this.handleEditorChange}></ReactQuill>
                        {this.validator.message('description', this.state.detail, 'required|string', { className: 'text-danger' })}
                      </div>
                    </div>

                    <div className='col-xs-12 col-sm-6 col-md-6 cstm-slug-field'>
                      <div className={hasChanges && !this.validator.fieldValid('slug') ? 'form-group was-validatedform' : 'form-group'}>
                        <label className='conrol-label'>Slug</label>
                        <Form.Control type='text' name='slug' value={this.state.slug} placeholder='Slug' readOnly={courseID ? true : false} onChange={this.handleChange} />
                        {/* {(courseID) ? null : this.validator.message('slug', this.state.slug, 'required|validSlug', { 'className': 'text-danger' })} */}
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-6 col-md-6'>
                      <div className='form-group status-cols'>
                        {/* <label htmlFor="name">Featured</label> */}
                        <label htmlFor='name'>Show for All Customers by Product ID</label>
                        <div className='radio-input'>
                          <div className='row w-100'>
                            <div className='col-lg-4'>
                              <label className='active-label radio-label'>
                                <Form.Control type='radio' name='isFeatured' value={1} checked={isFeatured == 1} onChange={this.handleChange} />
                                <span className='span-check'></span>
                                <span className='check-text'>Yes</span>
                              </label>
                              <label className='active-label radio-label'>
                                <Form.Control type='radio' name='isFeatured' value={0} checked={isFeatured == '0'} onChange={this.handleChange} />
                                <span className='span-check'></span>
                                <span className='check-text'>No</span>
                              </label>
                            </div>
                            {isFeatured == '1' && (
                              <div className='col-lg-8'>
                                <Select
                                  placeholder={'Select Product'}
                                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                  isClearable={false}
                                  isMulti={false}
                                  name='featuredBy'
                                  className='cstm-select'
                                  value={featuredOptions.map((x) => this.MakeOption(x.name, x.id)).filter((option) => option.value === featuredBy)}
                                  onChange={(option) => this.setState({ featuredBy: option.value, teacherID: option.value })}
                                  options={featuredOptions.map((x) => this.MakeOption(x.name, x.id))}
                                />
                                {isFeatured == '1' ? this.validator.message('product', featuredBy, 'required|validfeaturedBy', { className: 'text-danger' }) : ''}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-6 col-md-6'>
                      <div className={hasChanges && !this.validator.fieldValid('teacherID') ? 'form-group was-validatedform' : 'form-group'}>
                        <label htmlFor='name'>Teacher</label>
                        {/* <select className="form-control" name="teacherID" value={teacherID} onChange={this.handleChange}>
													<option value="">Select Product</option>
													{
														courseOptions.creatorList.map((value, index) => (<option value={value.creatorID} key={index}>{value.firstName + " " + value.lastName}</option>))
													}
												</select> */}
                        <Select
                          placeholder={'Select Teacher'}
                          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                          isClearable={false}
                          isMulti={false}
                          name='teacherID'
                          onChange={(option) => this.setState({ teacherID: option.value })}
                          value={courseOptions.creatorList.map((x) => this.MakeOption(`${x.firstName} ${x.lastName}`, x.creatorID)).filter((option) => option.value === teacherID)}
                          options={courseOptions.creatorList.map((x) => this.MakeOption(`${x.firstName} ${x.lastName}`, x.creatorID))}
                        />
                        {this.validator.message('teacher', this.state.teacherID, 'required', { className: 'text-danger' })}
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-6 col-md-6'>
                      <div className='form-group status-cols'>
                        <label htmlFor='name'>Status</label>
                        <div className='radio-input'>
                          <label className='active-label radio-label'>
                            <Form.Control type='radio' name='status' value={1} checked={status == 1} onChange={this.handleChange} />
                            <span className='span-check'></span>
                            <span className='check-text'>Publish</span>
                          </label>
                          <label className='active-label radio-label'>
                            <Form.Control type='radio' name='status' value={0} checked={status == '0'} onChange={this.handleChange} />
                            <span className='span-check'></span>
                            <span className='check-text'>Unpublish</span>
                          </label>
                          {false && status == '0' && (
                            <label className='active-label radio-label d-flex flax-wrap checkbox-labelcss'>
                              <input className='checkbox-css' type={'checkbox'} value={`${this.state.isUpcoming}`} checked={!!this.state.isUpcoming} onChange={this.handleCheckChange} />
                              <span className='check-text pl-2'>Upcoming Course</span>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12'>
                      <div className={hasChanges && !this.validator.fieldValid('courseDuration') ? 'form-group was-validatedform' : 'form-group'}>
                        <label htmlFor='name'>Course Duration</label>
                        <Form.Control type='number' name='courseDuration' value={this.state.courseDuration} placeholder='In Days' onChange={this.handleChange} />
                        {this.validator.message('course duration', this.state.courseDuration, 'required|number', { className: 'text-danger' })}
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12'>
                      <div className={hasChanges && !this.validator.fieldValid('groupID') ? 'form-group was-validatedform' : 'form-group'}>
                        <label htmlFor='name'>Group</label>
                        {/* <select className="form-control" name="groupID" value={groupID} onChange={this.handleChange}>
												<option value="">Select Groups</option>
												{
													courseOptions.groupList.map((value, index) => (
														<option value={value.browseGroupID} key={index}>{value.title}</option>
													))
												}
											</select> */}
                        <Select
                          placeholder={'Select Group'}
                          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                          isClearable={false}
                          isMulti={false}
                          name='groupID'
                          onChange={(option) => this.setState({ groupID: option.value })}
                          value={groupValue}
                          options={courseOptions.groupList.map((x) => this.MakeOption(`${x.title}`, x.browseGroupID))}
                        />
                        {this.validator.message('group', this.state.groupID, 'required|number', { className: 'text-danger' })}
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12'>
                      <div className={hasChanges && !this.validator.fieldValid('category') ? 'form-group was-validatedform' : 'form-group'}>
                        <label htmlFor='name'>Categories</label>
                        <Select
                          placeholder={'Select Categories'}
                          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                          isClearable={false}
                          isMulti
                          name='category'
                          value={category}
                          onChange={(value) => this.handleMultiDropDownChange(value, 'category')}
                          options={courseOptions.categoryList.map((x) => this.MakeOption(x.name, x.categoryID))}
                          closeMenuOnSelect={false}
                        />
                        {this.validator.message('category', this.state.category, 'required|array', { className: 'text-danger' })}
                      </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12'>
                      <div className='form-group'>
                        <div className={hasChanges && !this.validator.fieldValid('category') ? 'form-group was-validatedform' : 'form-group'}></div>
                        <label htmlFor='name'>Symptoms</label>
                        <Select
                          placeholder={'Select Symptoms'}
                          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                          isClearable={false}
                          isMulti
                          value={symptoms}
                          onChange={(value) => this.handleMultiDropDownChange(value, 'symptoms')}
                          options={courseOptions.symptomsList.map((x) => this.MakeOption(x.name, x.symptomID, x.status))}
                          closeMenuOnSelect={false}
                        />
                        {this.validator.message('symptom', this.state.symptoms, 'required|array', { className: 'text-danger' })}
                      </div>
                    </div>
                    {false && (
                      <div className='col-xs-12 col-sm-12 col-md-12'>
                        <div className='form-group status-cols'>
                          <label htmlFor='name'>Includes</label>
                          {courseInclude.map((value, key) => (
                            <InputGroup key={key}>
                              <InputGroup.Prepend>
                                <FormControl type='text' value={value.text} readOnly={true} />
                                <InputGroup.Text>
                                  <Button type='button' onClick={() => this.removeCourseInclude(key)} className='btn btn-primary'>
                                    -
                                  </Button>
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                            </InputGroup>
                          ))}
                          <InputGroup>
                            <InputGroup.Prepend>
                              <FormControl type='text' name='includeText' placeholder='Include Text' value={includeText} onChange={this.handleChange} />
                              <InputGroup.Text>
                                <Button type='button' onClick={this.createCourseInclude} className='btn btn-primary'>
                                  +
                                </Button>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                          </InputGroup>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Link to={'/admin/courses'} className='btn btn-primary btn-block'>
                          Back
                        </Link>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <button type='submit' disabled={btnDisabled} className='btn btn-primary btn-block'>
                          Go to Lessons
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </main>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

// export default class AdminLibraryItemDetails extends Component {
//   constructor(props) {
//     super(props)
//     let {packageID, customerPackageID, copyPackageID} = this.props.match.params
//     this.initialState = {
//       messageType: 'error',
//       flashMessage: '',
//       btnDisabled: false,
//       iSHttpRequested: false,
//       title: '',
//       detail: '',
//       status: 0,
//       packageID: packageID,
//       customerPackageID: customerPackageID,
//       copyPackageID: copyPackageID,
//       slug: '',
//       httpUrl: !isEmpty(packageID) ? 'admin/updatePackage' : !isEmpty(customerPackageID) ? 'admin/updateCustomerPackage' : !isEmpty(copyPackageID) ? 'admin/copyPackage' : 'admin/addPackage',
//       duration: '',
//     }
//     this.state = this.initialState
//     this.validator = new SimpleReactValidator({
//       autoForceUpdate: this,
//       className: 'text-danger',
//       validators: {
//         validSlug: {
//           // name the rule
//           message: 'The :attribute must be a valid slug url.',
//           rule: (val, params, validator) => {
//             const regexExp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g
//             return regexExp.test(val)
//           },
//           messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)), // optional
//           required: false, // optional
//         },
//       },
//     })
//   }
//   componentDidMount() {
//     let {packageID, customerPackageID, copyPackageID} = this.state
//     try {
//       if (!isEmpty(packageID) || !isEmpty(copyPackageID)) {
//         this.getPackageDetails()
//       }
//       if (!isEmpty(customerPackageID)) {
//         this.getCustomerPackageDetails()
//       }
//     } catch (err) {
//       console.log('err', err)
//       this.props.history.push('/admin/packages/')
//     }
//   }
//   getPackageDetails = () => {
//     let {packageID, copyPackageID} = this.state
//     packageID = !isEmpty(packageID) ? packageID : copyPackageID
//     httpPostRequest(
//       'admin/get_package_detail',
//       {
//         packageID: base64.decode(packageID),
//       },
//       (error, result) => {
//         let stateObj = {}
//         if (!isEmpty(error)) {
//           stateObj = {
//             messageType: 'error',
//             flashMessage: error.message,
//             btnDisabled: false,
//           }
//         } else {
//           if (result.status) {
//             stateObj.title = result.data.title
//             stateObj.detail = result.data.description
//             stateObj.status = result.data.status
//             stateObj.duration = result.data.duration
//             stateObj.slug = result.data.slug
//           } else {
//             stateObj = {
//               messageType: 'error',
//               flashMessage: result.message,
//               btnDisabled: false,
//             }
//           }
//         }
//         this.setState(stateObj)
//       }
//     )
//   }
//   getCustomerPackageDetails = () => {
//     let {customerPackageID} = this.state
//     httpPostRequest(
//       'admin/get_customer_package_detail',
//       {
//         customerPackageID: base64.decode(customerPackageID),
//       },
//       (error, result) => {
//         let stateObj = {}
//         if (!isEmpty(error)) {
//           stateObj = {
//             messageType: 'error',
//             flashMessage: error.message,
//             btnDisabled: false,
//           }
//         } else {
//           if (result.status) {
//             stateObj.title = result.data.title
//             stateObj.detail = result.data.description
//             stateObj.status = result.data.status
//             stateObj.duration = result.data.duration
//             stateObj.slug = result.data.slug
//           } else {
//             stateObj = {
//               messageType: 'error',
//               flashMessage: result.message,
//               btnDisabled: false,
//             }
//           }
//         }
//         this.setState(stateObj)
//       }
//     )
//   }
//   handleChange = (event) => {
//     const name = event.target.name
//     const value = event.target.value
//     this.setState({
//       [name]: value,
//       btnDisabled: false,
//     })

//     if (name == 'title') {
//       if (value && !this.state.packageID) {
//         this.setState({slug: slugify(value)})
//       }
//     }
//   }
//   handleEditorChange = (value) => {
//     this.setState({detail: value, btnDisabled: false})
//   }
//   handleFlashUnmount = () => {
//     this.setState({
//       flashMessage: '',
//     })
//   }
//   handleSubmit = (event) => {
//     this.setState({btnDisabled: true})
//     event.preventDefault()
//     let {title, detail, status, packageID, copyPackageID, customerPackageID, httpUrl, slug, duration} = this.state
//     if (this.validator.allValid()) {
//       // console.log('httpUrl',httpUrl);return false;
//       httpPostRequest(
//         httpUrl,
//         {
//           title: title.trim(),
//           detail: detail.trim(),
//           status: status,
//           slug: slug,
//           duration: duration,
//           packageID: !isEmpty(packageID) ? base64.decode(packageID) : '',
//           copyPackageID: !isEmpty(copyPackageID) ? base64.decode(copyPackageID) : '',
//           customerPackageID: !isEmpty(customerPackageID) ? base64.decode(customerPackageID) : '',
//         },
//         (error, result) => {
//           let stateObj = {}
//           if (!isEmpty(error)) {
//             stateObj = {
//               messageType: 'error',
//               flashMessage: error.message,
//               btnDisabled: false,
//             }
//           } else {
//             let resultObj = result.data
//             if (result.status) {
//               if (result.packageID) {
//                 packageID = !isEmpty(packageID) ? packageID : base64.encode(result.packageID)
//                 this.props.history.push('/admin/package/items/' + packageID)
//               } else if (result.customerPackageID) {
//                 customerPackageID = !isEmpty(customerPackageID) ? customerPackageID : base64.encode(result.customerPackageID)
//                 this.props.history.push('/admin/customer-package/items/' + customerPackageID)
//               }
//             } else {
//               this.setState({
//                 messageType: 'error',
//                 flashMessage: result.message,
//               })
//             }
//           }
//           this.setState(stateObj)
//         }
//       )
//     } else {
//       this.validator.showMessages()
//     }
//   }
//   modules = {
//     toolbar: {
//       container: [
//         {header: 1},
//         {header: 2},
//         'bold',
//         'underline',
//         'italic',
//         'strike',
//         {script: 'sub'},
//         {script: 'super'},
//         // { 'indent': '-1' }, { 'indent': '+1' },
//         {list: 'ordered'},
//         {list: 'bullet'},
//         // 'image', 'link',
//       ],
//     },
//   }
//   render() {
//     let {hasChanges, status, btnDisabled, title, detail, slug, duration, packageID, customerPackageID} = this.state
//     let backPageAction = customerPackageID ? '/admin/package-customer' : '/admin/packages'
//     return (
//       <React.Fragment>
//         <div id='admin-container'>
//           <div className='dash-wrapper'>
//             <main id='main'>
//               <LibraryHeader {...this.props} packageID={''} customerPackageID={customerPackageID} tabSequence='1' />
//               <Form onSubmit={this.handleSubmit}>
//                 <div className='container-fluid pt-40  add-category'>
//                   {this.state.flashMessage ? <FlashMessages type={this.state.messageType} message={this.state.flashMessage} unmountMe={this.handleFlashUnmount} /> : ''}
//                   <div className='row'>
//                     <div className='col-xs-12 col-sm-12 col-md-12'>
//                       <div className={hasChanges && !this.validator.fieldValid('title') ? 'form-group was-validatedform' : 'form-group'}>
//                         <label className='conrol-label'>Title</label>
//                         <Form.Control type='text' name='title' value={title} placeholder='Title' onChange={this.handleChange} />
//                         {this.validator.message('title', title, 'required|string', {className: 'text-danger'})}
//                       </div>
//                     </div>
//                     <div className='col-xs-12 col-sm-12 col-md-12'>
//                       <div className={hasChanges && !this.validator.fieldValid('detail') ? 'form-group was-validatedform' : 'form-group'}>
//                         <label htmlFor='name'>Details</label>
//                         <ReactQuill placeholder='Details' modules={this.modules} value={detail} theme='snow' onChange={this.handleEditorChange}></ReactQuill>
//                         {this.validator.message('details', detail, 'required|string', {className: 'text-danger'})}
//                       </div>
//                     </div>
//                     <div className='col-xs-12 col-sm-6 col-md-6'>
//                       <div className={hasChanges && !this.validator.fieldValid('duration') ? 'form-group was-validatedform' : 'form-group'}>
//                         <label className='conrol-label'>Package Duration</label>
//                         <Form.Control type='text' name='duration' value={duration} placeholder='In Days' onChange={this.handleChange} />
//                         {this.validator.message('duration', duration, 'required|numeric|min:1,num', {className: 'text-danger'})}
//                       </div>
//                     </div>
//                     {!customerPackageID ? (
//                       <>
//                         <div className='col-xs-12 col-sm-6 col-md-6'>
//                           <div className='form-group status-cols'>
//                             <label htmlFor='name'>Status</label>
//                             <div className='radio-input'>
//                               <label className='active-label radio-label'>
//                                 <Form.Control type='radio' name='status' value={1} checked={status == 1} onChange={this.handleChange} />
//                                 <span className='span-check'></span>
//                                 <span className='check-text'>Publish</span>
//                               </label>
//                               <label className='active-label radio-label'>
//                                 <Form.Control type='radio' name='status' value={0} checked={status == '0'} onChange={this.handleChange} />
//                                 <span className='span-check'></span>
//                                 <span className='check-text'>UnPublish</span>
//                               </label>
//                             </div>
//                           </div>
//                         </div>

//                         <div className='col-xs-12 col-sm-12 col-md-12'>
//                           <div className={hasChanges && !this.validator.fieldValid('slug') ? 'form-group was-validatedform' : 'form-group'}>
//                             <label className='conrol-label'>Slug</label>
//                             <Form.Control type='text' name='slug' value={slug} placeholder='Slug' readOnly={packageID ? true : false} onChange={this.handleChange} />
//                             {packageID ? null : this.validator.message('slug', slug, 'required|validSlug', {className: 'text-danger'})}
//                           </div>
//                         </div>
//                       </>
//                     ) : (
//                       ''
//                     )}
//                   </div>
//                   <div className='row'>
//                     <div className='col-md-6'>
//                       <div className='form-group'>
//                         <Link to={backPageAction} className='btn btn-primary btn-block'>
//                           Back
//                         </Link>
//                       </div>
//                     </div>
//                     <div className='col-md-6'>
//                       <div className='form-group'>
//                         <button type='submit' disabled={btnDisabled} className='btn btn-primary btn-block'>
//                           Go to Item
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </Form>
//             </main>
//           </div>
//         </div>
//       </React.Fragment>
//     )
//   }
// }
