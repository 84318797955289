import React from 'react'
import Card from 'react-bootstrap/Card';
import styles from './watchhistorycard.module.scss'
import { FaCirclePlay } from "react-icons/fa6";
import { IoIosTimer } from "react-icons/io";
import Preview from "../../../../assets/images/Load_circle.png"
import soundwave from "../../../../assets/images/soundwave.gif"
import moment from 'moment';
import { encode } from 'base-64';
import { useHistory } from 'react-router-dom';

export default function WatchHistoryCard({ lesson, idCSS }) {
    const history = useHistory()

    const lessonInfo = {
        title: lesson?.video ? lesson?.video?.title : lesson?.lesson?.lessonName,
        videoDuration: lesson?.video ? lesson?.video?.videoDuration : lesson?.lesson?.videoDuration,
        thumbnailUrl: lesson?.video ? lesson?.video?.thumbnailUrl : lesson?.lesson?.thumbnailUrl,
        libraryType: lesson?.video ? lesson?.video?.libraryType : lesson?.lesson?.libraryTypeID,
        id: lesson?.video ? lesson?.video?.id : lesson?.lesson?.id,
        description: lesson?.video ? lesson?.video?.shortDescription : lesson?.lesson?.descriptionTitle,
        lessonType: lesson?.video ? lesson?.video?.type : lesson?.lesson?.lessonType,
        closeVideoLengthAt: lesson?.closeVideoLengthAt,
    }

    const getThumnnailUrl = (lesson) => {
        if (lesson?.lessonType == 5) { // pdf
            return Preview
        } else if (lesson?.lessonType == 6) { // audio
            return soundwave
        } else {
            return lesson?.thumbnailUrl || 'https://images.ctfassets.net/jw8fay82wbzf/6XNMnzSVvxfGVD3Df7n5uC/ab278839ea12189e7966351615c73c89/Screenshot_2023-08-11_at_19.39_2__1_.png'
        }
    }

    const handleLessonClick = (lesson) => {
        if (lesson.libraryType == 3) {
            // history.push(`/student/watch-video/${encode(lesson.customerLibraryMapID)}`)
        } else {
            history.push(`/student/libary-lessons/${encode(lesson.id)}`)
        }
    }

    const duration = (lessonInfo?.videoDuration)
    const durationObject = moment.duration(duration, 'seconds');
    const humanReadableFormat = durationObject.humanize();




    return (
        <div className={styles['history-card']}>
            <Card>
                <div className={styles["history-card-body"]} role='button' onClick={() => handleLessonClick(lessonInfo)}>

                    <Card.Img variant="top" src={
                        getThumnnailUrl(lessonInfo)
                    } loading='lazy' />

                    <div className={styles["play-icon"]}>
                        <FaCirclePlay />
                    </div>
                </div>

                <Card.Footer className={styles["footer"]}>
                    <div className={styles["info"]}>
                        <h4>{lessonInfo?.title}</h4>
                        <p>{lessonInfo?.description}</p>
                    </div>
                    <div className={styles["bottom-progress-section"]}>
                        <p className='text-muted'>
                            <IoIosTimer />  {humanReadableFormat}
                        </p>


                        <div className={styles["completed"]}>
                            Completed
                        </div>

                        <div className={styles["green-progress-bar"]}>
                            <div className={`${styles["progress-bar"]}  w-100`} role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>




                    </div>
                </Card.Footer>
            </Card>
        </div>
    )
}
