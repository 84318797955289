import GreetCustomer from 'components/GreetCustomer'
import React, { useEffect, useState } from 'react'
import VodCard from './components/VodCard'
import { useSelector } from 'react-redux'
import FavInfo from './components/FavInfo'
// watch history page 
import UpcomingLesson from './components/UpcomingLesson'
import './components/vod.scss'
import { getCustomerRecommendedLessons } from './apiStore'
import Swal from 'sweetalert2'
import { isEmpty } from 'config/functions'
import PhaseSnapShot from './components/PhaseSnapShot'
import PhaseFooter from './components/PhaseFooter'



export default function VideoOfDay() {
    const studentData = useSelector(state => state.register.userProfileData)
    const [lessonList, setLessonList] = useState([])

    const getCustomerTodayLessons = async () => {
        const apiRes = await getCustomerRecommendedLessons()
        if (apiRes.status) {
            setLessonList(apiRes.data)
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: apiRes.message,
            })
        }
    }

    useEffect(() => {
        getCustomerTodayLessons()
    }, [studentData])

    const firstLesson = !isEmpty(lessonList) ? lessonList[0] : {}

    const restAllLessons = !isEmpty(lessonList) ? lessonList.slice(1) : []

    return (
        <div className='vod-page-container'>
            <GreetCustomer student={studentData} video={true} className="mb-2" />
            <div className="vod-hero-container d-flex justify-content-center mt-5">
                <VodCard lesson={firstLesson} />
            </div>
            <FavInfo />
            <UpcomingLesson data={restAllLessons} upcoming={true} />
            <PhaseSnapShot />
            <PhaseFooter />
        </div>
    )
}
