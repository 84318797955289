import { isEmpty } from "config/functions"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import UpdatedLibraryCards from "../DashboardNew/Library/UpdatedLibraryCards"
import ClassList from "../LiveSchedule/ClassList"
import MoreCourses from "./MoreCourses"
import Loader from "views/common/Loader"
import { useLocation, useParams } from "react-router-dom"
import UpdatedAllLessonList from "../MoreSection/UpdatedAllLessonList"
import { useHistory } from "react-router-dom/cjs/react-router-dom"

const Search = () => {
    const algoliasearch = require("algoliasearch/lite")
    const [searchWord, setSearchWord] = useState("")

    // const client = algoliasearch("U5JCU1I4XJ", "5e56d03b3903388442c594da73fa7af1")// app id, admin api key for Prod
    const client = algoliasearch("1TZ4F688ZD", "92434378ecaec755419b2418d80c5223")//DEV USER
    // const client = algoliasearch(`${ALGOLIAID}, ${ALGOLIAKEY}`)
    const loggedInCxID = useSelector(state => state.register.userProfileData.customerID)
    const [dashboardData, setDashboardData] = useState([])
    const [moreCourseData, setMoreCourseData] = useState([])
    const [meetingData, setMeetingData] = useState([])
    const [lessonData, setLessonData] = useState()
    const [loading, setLoading] = useState(false)
    // const { search } = useLocation()
    const { search } = useLocation()
    const history = useHistory()

    useEffect(() => {
        if (search != "" && search.length >= 4) {
            setSearchWord(search)
            handleSearchMultiple(search)

        }
        else {
            // setDashboardData([])
            // setMeetingData([])
            // setMoreCourseData([])
            // setLessonData([])
        }

    }, [search])
    const handleSearchMultiple = async (searchKey) => {
        const queries = [
            {
                indexName: 'dashboardIndex',
                query: searchKey,
                params: {
                    filters: `cxID=${loggedInCxID}`
                }
            },
            {
                indexName: 'allLibraryIndex',
                query: searchKey,
                params: {
                    filters: `hideFromLib=0 AND assignedByAdmin=0`
                }
            },
            {
                indexName: 'meetingIndex',
                query: searchKey,
            },
            {
                indexName: 'lessonIndex',
                query: searchKey,
                params: {
                    filters: `cxID=${loggedInCxID}`
                }
            },
        ]
        if (!isEmpty(searchKey) && searchKey.length >= 4) {
            try {
                setLoading(true)
                let filteredDashboardData = []
                let lockedDashboardData = []
                let filteredCourseData = []
                let filteredCustomerLiveClass = []
                let filteredLesson = []
                let dataArr = []
                let { results, status } = await client.multipleQueries(queries)
                if (results) {
                    // DashboardData
                    if (!isEmpty(results[0])) {
                        console.log("results[0]", results[0])
                        filteredDashboardData = results[0].hits;
                        let uniqArr = []
                        filteredDashboardData.map((item) => {
                            if (!uniqArr.find(d => (d.libId === item.libId))) {
                                console.log("item", item)
                                uniqArr.push(item)
                            }
                        })

                        setDashboardData(uniqArr)
                        dataArr = filteredDashboardData.map(item => item.libId)
                    }

                    // All Course Data
                    if (!isEmpty(results[1])) {
                        filteredCourseData = results[1].hits;
                        let uniqArr = []
                        filteredCourseData.map((item) => {
                            if (!dataArr.includes(item.libId)) {
                                if (!uniqArr.find(d => ((d.libId === item.libId)))) {
                                    uniqArr.push(item)
                                }
                            }

                        })
                        setMoreCourseData(uniqArr)
                    }

                    // Meeting Data
                    if (!isEmpty(results[2])) {
                        filteredCustomerLiveClass = results[2].hits;
                        let uniqArr = []
                        filteredCustomerLiveClass.map((item) => {
                            if (!uniqArr.find(d => (d.mTitle === item.mTitle && d.mTId == item.mTId))) {
                                uniqArr.push(item)
                            }
                        })
                        setMeetingData(uniqArr)
                    }
                    if (!isEmpty(results[3])) {
                        filteredLesson = results[3].hits;
                        let uniqArr = []
                        filteredLesson.map((item) => {
                            if (!uniqArr.find(d => (d.lessonID == item.lessonID && d.lvDur == item.lvDur))) {
                                uniqArr.push(item)
                            }
                        })
                        setLessonData(uniqArr)
                    }
                    setLoading(false)
                }
                else if (status == 400) {
                    setLoading(false)
                    history.push("/student/dashboard")
                }
            }
            catch (error) {
                console.log("error in search", error)
                setLoading(false)
                history.push("/student/dashboard")
            }
        }
        else {
            setDashboardData([])
            setMoreCourseData([])
            setMeetingData([])
            setLessonData([])
        }
    }

    return (
        <>
            <div className="dashboard">
                {search && search.length <= 3 &&
                    <div className="text-white d-flex justify-content-center pt-md-56 pt-32">
                        Please use atleast 3 characters to search
                    </div>}
                {loading ? <Loader overlay="none" /> : ""}
                {!isEmpty(dashboardData) && (
                    <div className="pt-md-56 pt-32 px-lg-0 px-md-2  px-0 mt-2- w-100">
                        <div className="d-flex justify-content-between align-items-end">
                            <h2 className="topic-title">Dashboard</h2>
                        </div>
                        < UpdatedLibraryCards
                            algolibraryData={dashboardData}
                        // dashboardLockedAlgoData={lockedStautusArray}
                        />
                    </div>
                )}
                {!isEmpty(lessonData) && (
                    <div className="pt-md-56 pt-32 px-lg-0 px-md-2  px-0 mt-2- w-100">
                        <div className="d-flex justify-content-between align-items-end">
                            <h2 className="topic-title">Lessons</h2>
                        </div>
                        < UpdatedAllLessonList
                            algoLessonData={lessonData}
                        // dashboardLockedAlgoData={lockedStautusArray}
                        />
                    </div>
                )}
                {!isEmpty(moreCourseData) && (
                    <div className=" pt-md-56 pt-32 px-lg-0 px-md-2 pb-5 px-0 mt-2- w-100">
                        <div className="d-flex justify-content-between align-items-end mb-3">
                            <h2 className="topic-title">More with this keyword</h2>
                        </div>

                        {/* <UpdatedAllLibrary
                            algoSearchedlibrary={moreCourseData}
                            total={moreCourseData.length}
                            type={2}
                        // handleMore={() => handleMoreLibrary(results)}
                        /> */}
                        <MoreCourses
                            data={moreCourseData}
                        />
                    </div>
                )}
                {!isEmpty(meetingData) && (
                    <div className=" pt-md-56 pt-32 px-lg-0 px-md-2 pb-5 px-0 mt-2- w-100 library-public">
                        <div className="d-flex justify-content-between align-items-end mb-3">
                            <h2 className="topic-title">Live Class(es)</h2>
                        </div>
                        <div className="class-list ">
                            <ClassList
                                algoSearchResults={meetingData}
                            // algoQuery={searchWord ? searchWord :}
                            />
                        </div>
                    </div>
                )}
                {isEmpty(dashboardData) && isEmpty(moreCourseData) && isEmpty(meetingData) && isEmpty(lessonData) && search.length >= 4 && !loading && (
                    <h6 className="pt-md-56 pt-32 text-white text-wrap text-center mb-lg-4 mb-2 ">
                        {`No results found. Try again with another word`}
                    </h6>
                )}
                {/* {isEmpty(dashboardData) && isEmpty(moreCourseData) && isEmpty(meetingData) && isEmpty(lessonData) && search.length < 4 && !loading && (
                    <p className="pt-md-56 pt-32 text-white text-wrap mb-lg-4 text-center mb-2 ">
                        Enter a keyword to search
                    </p>
                )} */}
            </div>
        </>
    )
}

export default Search