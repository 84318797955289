import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import FlashMessages from 'views/common/FlashMessages'
import Select from 'react-select'
import { isEmpty } from 'config/functions'
import { Form, InputGroup, FormControl, Button } from 'react-bootstrap'
import ReactQuill from 'react-quill'
import PackageHeader from './LibraryHeader'
import base64 from 'base-64'
import { httpPostRequest, httpGetRequest } from 'config/services'
import 'react-quill/dist/quill.snow.css'
import slugify from 'react-slugify'

export default class AddLibrary extends Component {
  constructor(props) {
    super(props)
    let { packageID, customerPackageID, copyPackageID } = this.props.match.params
    this.initialState = {
      messageType: 'error',
      flashMessage: '',
      btnDisabled: false,
      iSHttpRequested: false,
      title: '',
      detail: '',
      status: 0,
      lock: 0,
      type: '',
      packageID: packageID,
      customerPackageID: customerPackageID,
      copyPackageID: copyPackageID,
      slug: '',
      httpUrl: !isEmpty(packageID) ? 'admin/updatePackage' : !isEmpty(customerPackageID) ? 'admin/updateCustomerPackage' : !isEmpty(copyPackageID) ? 'admin/copyPackage' : 'admin/addPackage',
      duration: '',
    }
    this.state = this.initialState
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      className: 'text-danger',
      validators: {
        validSlug: {
          // name the rule
          message: 'The :attribute must be a valid slug url.',
          rule: (val, params, validator) => {
            const regexExp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g
            return regexExp.test(val)
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)), // optional
          required: false, // optional
        },
      },
    })
  }
  componentDidMount() {
    let { packageID, customerPackageID, copyPackageID } = this.state
    try {
      if (!isEmpty(packageID) || !isEmpty(copyPackageID)) {
        this.getPackageDetails()
      }
      if (!isEmpty(customerPackageID)) {
        this.getCustomerPackageDetails()
      }
    } catch (err) {
      console.log('err', err)
      this.props.history.push('/admin/packages/')
    }
  }
  getPackageDetails = () => {
    let { packageID, copyPackageID } = this.state
    packageID = !isEmpty(packageID) ? packageID : copyPackageID
    httpPostRequest(
      'admin/get_package_detail',
      {
        packageID: base64.decode(packageID),
      },
      (error, result) => {
        let stateObj = {}
        if (!isEmpty(error)) {
          stateObj = {
            messageType: 'error',
            flashMessage: error.message,
            btnDisabled: false,
          }
        } else {
          if (result.status) {
            stateObj.title = result.data.title
            stateObj.detail = result.data.description
            stateObj.status = result.data.status
            stateObj.duration = result.data.duration
            stateObj.slug = result.data.slug
          } else {
            stateObj = {
              messageType: 'error',
              flashMessage: result.message,
              btnDisabled: false,
            }
          }
        }
        this.setState(stateObj)
      }
    )
  }
  getCustomerPackageDetails = () => {
    let { customerPackageID } = this.state
    httpPostRequest(
      'admin/get_customer_package_detail',
      {
        customerPackageID: base64.decode(customerPackageID),
      },
      (error, result) => {
        let stateObj = {}
        if (!isEmpty(error)) {
          stateObj = {
            messageType: 'error',
            flashMessage: error.message,
            btnDisabled: false,
          }
        } else {
          if (result.status) {
            stateObj.title = result.data.title
            stateObj.detail = result.data.description
            stateObj.status = result.data.status
            stateObj.duration = result.data.duration
            stateObj.slug = result.data.slug
          } else {
            stateObj = {
              messageType: 'error',
              flashMessage: result.message,
              btnDisabled: false,
            }
          }
        }
        this.setState(stateObj)
      }
    )
  }
  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value,
      btnDisabled: false,
    })

    if (name == 'title') {
      if (value && !this.state.packageID) {
        this.setState({ slug: slugify(value) })
      }
    }
  }
  handleEditorChange = (value) => {
    this.setState({ detail: value, btnDisabled: false })
  }
  handleFlashUnmount = () => {
    this.setState({
      flashMessage: '',
    })
  }
  handleSubmit = (event) => {
    this.setState({ btnDisabled: true })
    event.preventDefault()
    let { title, detail, status, packageID, copyPackageID, customerPackageID, httpUrl, slug, duration } = this.state
    if (this.validator.allValid()) {
      // console.log('httpUrl',httpUrl);return false;
      httpPostRequest(
        httpUrl,
        {
          title: title.trim(),
          detail: detail.trim(),
          status: status,
          slug: slug,
          duration: duration,
          packageID: !isEmpty(packageID) ? base64.decode(packageID) : '',
          copyPackageID: !isEmpty(copyPackageID) ? base64.decode(copyPackageID) : '',
          customerPackageID: !isEmpty(customerPackageID) ? base64.decode(customerPackageID) : '',
        },
        (error, result) => {
          let stateObj = {}
          if (!isEmpty(error)) {
            stateObj = {
              messageType: 'error',
              flashMessage: error.message,
              btnDisabled: false,
            }
          } else {
            let resultObj = result.data
            if (result.status) {
              if (result.packageID) {
                packageID = !isEmpty(packageID) ? packageID : base64.encode(result.packageID)
                this.props.history.push('/admin/package/items/' + packageID)
              } else if (result.customerPackageID) {
                customerPackageID = !isEmpty(customerPackageID) ? customerPackageID : base64.encode(result.customerPackageID)
                this.props.history.push('/admin/customer-package/items/' + customerPackageID)
              }
            } else {
              this.setState({
                messageType: 'error',
                flashMessage: result.message,
              })
            }
          }
          this.setState(stateObj)
        }
      )
    } else {
      this.validator.showMessages()
    }
  }
  modules = {
    toolbar: {
      container: [
        { header: 1 },
        { header: 2 },
        'bold',
        'underline',
        'italic',
        'strike',
        { script: 'sub' },
        { script: 'super' },
        // { 'indent': '-1' }, { 'indent': '+1' },
        { list: 'ordered' },
        { list: 'bullet' },
        'link'
        // 'image', 'link',
      ],
    },
  }
  render() {
    let { hasChanges, status, btnDisabled, title, detail, slug, duration, packageID, customerPackageID, lock, type, videoTempID } = this.state
    let backPageAction = customerPackageID ? '/admin/package-customer' : '/admin/packages'
    return (
      <React.Fragment>
        <div id='admin-container'>
          <div className='dash-wrapper'>
            <main id='main'>
              <PackageHeader {...this.props} packageID={''} customerPackageID={customerPackageID} tabSequence='1' />
              <Form onSubmit={this.handleSubmit}>
                <div className='container-fluid pt-40  add-category'>
                  {this.state.flashMessage ? <FlashMessages type={this.state.messageType} message={this.state.flashMessage} unmountMe={this.handleFlashUnmount} /> : ''}
                  <div className='row'>
                    <div className='col-xs-12 col-sm-12 col-md-12'>
                      <div className={hasChanges && !this.validator.fieldValid('title') ? 'form-group was-validatedform' : 'form-group'}>
                        <label className='conrol-label'>Title</label>
                        <Form.Control type='text' name='title' value={title} placeholder='Title' onChange={this.handleChange} />
                        {this.validator.message('title', title, 'required|string', { className: 'text-danger' })}
                      </div>
                    </div>

                    <div className='col-xs-12 col-sm-6 col-md-6'>
                      <div className={hasChanges && !this.validator.fieldValid('type') ? 'form-group was-validatedform' : 'form-group'}>
                        <label htmlFor='type'>Select Type</label>
                        <select id='type' className='form-control' name='type' value={type} onChange={this.handleChange} disabled={videoTempID ? true : false}>
                          <option value=''>Select Type</option>
                          {/* {packageType.length > 0
                            ? packageType.map((value, index) => (
                                <option value={value.typeId} key={index}>
                                  {value.typeName}
                                </option>
                              ))
                            : ''} */}
                        </select>
                        {this.validator.message('type', type, 'required', { className: 'text-danger' })}
                      </div>
                    </div>

                    <div className='col-xs-12 col-sm-12 col-md-12'>
                      <div className={hasChanges && !this.validator.fieldValid('detail') ? 'form-group was-validatedform' : 'form-group'}>
                        <label htmlFor='name'>Description</label>
                        <ReactQuill placeholder='Details' modules={this.modules} value={detail} theme='snow' onChange={this.handleEditorChange}></ReactQuill>
                        {this.validator.message('details', detail, 'required|string', { className: 'text-danger' })}
                      </div>
                    </div>
                    {/* <div className='col-xs-12 col-sm-6 col-md-6'>
                      <div className={hasChanges && !this.validator.fieldValid('duration') ? 'form-group was-validatedform' : 'form-group'}>
                        <label className='conrol-label'>Package Duration</label>
                        <Form.Control type='text' name='duration' value={duration} placeholder='In mins' onChange={this.handleChange} />
                        {this.validator.message('duration', duration, 'required|numeric|min:1,num', {className: 'text-danger'})}
                      </div>
                    </div> */}
                    {!customerPackageID ? (
                      <>
                        <div className='col-xs-12 col-sm-6 col-md-6'>
                          <div className='form-group status-cols'>
                            <label htmlFor='name'>Status</label>
                            <div className='radio-input'>
                              <label className='active-label radio-label'>
                                <Form.Control type='radio' name='status' value={1} checked={status == 1} onChange={this.handleChange} />
                                <span className='span-check'></span>
                                <span className='check-text'>Publish</span>
                              </label>
                              <label className='active-label radio-label'>
                                <Form.Control type='radio' name='status' value={0} checked={status == '0'} onChange={this.handleChange} />
                                <span className='span-check'></span>
                                <span className='check-text'>UnPublish</span>
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* <div className='col-xs-12 col-sm-6 col-md-6'>
                          <div className='form-group status-cols'>
                            <label htmlFor='lock'>Lock in Library for Visitors</label>
                            <div className='radio-input'>
                              <label className='active-label radio-label'>
                                <Form.Control type='radio' name='lock' value={1} checked={lock == 1} onChange={this.handleChange} />
                                <span className='span-check'></span>
                                <span className='check-text'>Lock</span>
                              </label>
                              <label className='active-label radio-label'>
                                <Form.Control type='radio' name='lock' value={0} checked={lock == '0'} onChange={this.handleChange} />
                                <span className='span-check'></span>
                                <span className='check-text'>Un Lock</span>
                              </label>
                            </div>
                          </div>
                        </div> */}

                        <div className='col-xs-12 col-sm-12 col-md-12'>
                          <div className={hasChanges && !this.validator.fieldValid('slug') ? 'form-group was-validatedform' : 'form-group'}>
                            <label className='conrol-label'>Slug</label>
                            <Form.Control type='text' name='slug' value={slug} placeholder='Slug' readOnly={packageID ? true : false} onChange={this.handleChange} />
                            {packageID ? null : this.validator.message('slug', slug, 'required|validSlug', { className: 'text-danger' })}
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Link to={backPageAction} className='btn btn-primary btn-block'>
                          Back
                        </Link>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <button type='submit' disabled={btnDisabled} className='btn btn-primary btn-block'>
                          Go to Item
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </main>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
