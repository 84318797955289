// import Slider from "react-slick"
import { Badge, Button, Card, CardBody, Tooltip } from "reactstrap"
import placeholderImage from "../../../../assets/images/placeholder-xl.png"
import moreIcon from "../../../../assets/images/Arrow.png"
// import showMore from "../../../../assets/images/showMore.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { checkSwedish, getTranslatedName, isEmpty } from "config/functions"
import base64 from "base-64"
import Loader from "views/common/Loader"
import moment from "moment"
import { BsInfoCircle } from "react-icons/bs"
import { useState } from "react"
import { MdDevices } from "react-icons/md"


const ClassList = ({ data = [], hasMore = false, loading = false, algoSearchResults, algoQuery, replays = false }) => {
  const history = useHistory()

  let total = ""

  const handleLiveClass = item => {
    history.push(`/public/live-schedule/description/${base64.encode(item.id)}`)
  }
  const handleLiveClassForAlgo = item => {
    if (item && item.mID) {
      history.push(`/public/live-schedule/description/${base64.encode(item.mID)}`)
    }
    else {
      history.push(`/public/live-schedule/description/${base64.encode(item.objectID)}`)
    }
  }

  const isToday = someDate => {
    const currentDate = moment()
      .local()
      .format("DD-MM-YYYY")
    const date = moment(someDate)
      .local()
      .format("DD-MM-YYYY")
    return currentDate === date
  }

  let classItems = !isEmpty(data)
    ? data.map(item => {
      let meetingTime = moment
        .utc(item.meetingStartDateTime)
        .local()
        .format("Do MMMM YYYY hh:mm A")
      return {
        id: item.id,
        title: item.title,
        meetingType: item.meetingType,
        teacherName: item.teacherName,
        duration: `${item.duration} Min`,
        meetingStartDateTime: item.meetingStartDateTime,
        time: meetingTime,
        isToday: isToday(item.meetingStartDateTime),
        imageURL: item.imageURL ? item.imageURL : placeholderImage,
        status: item.status,
        replayUrl: item.replayUrl,
      }
    })
    : []
  const TooltipItem = props => {
    const { id, item } = props
    const [tooltipOpen, setTooltipOpen] = useState(false)

    const toggle = () => setTooltipOpen(!tooltipOpen)

    return (
      <span>
        <p id={"Tooltip-" + id} className="ms-2 d-inline- d-flex align-items-center">
          <BsInfoCircle fontSize={15} />
          {/* <span >
                        <img src={info} alt="info" />
                    </span> */}
        </p>
        <Tooltip placement="top" isOpen={tooltipOpen} target={"Tooltip-" + id} toggle={toggle}>
          Time is displayed in your current time zone
        </Tooltip>
      </span>
    )
  }


  const ReplayBanner = () => {
    return (
      <div className="replay-banner">
        <MdDevices className="fa-solid fa-laptop-mobile replay-banner-icon" />
        <span className="replay-banner-text">Replay Added</span>
      </div>
    )
  }

  return (
    <>
      {algoSearchResults == "" && (
        <div className=" explore-all live-classes-list">
          {loading && <Loader overlay="none" />}
          <div className="mt-0 course-viewers row">
            {!isEmpty(classItems) &&
              classItems.map((item, index) => (
                <div className="course-viewers col-xl-3 col-lg-4 col-md-6 py-3" key={index}>
                  <Card role="button" onClick={() => handleLiveClass(item)} className="course-footer border-0 mr-0">
                    <CardBody className="p-0">
                      <div className="overlay-thumbnail">
                        {(!isEmpty(item.replayUrl) && item.status == 4 && replays) ? <ReplayBanner /> : <Badge className={`status-tag type text-center d-flex align-items-center pb-3- course-tag `}>
                          {
                            item.isToday
                              ? (checkSwedish()
                                ? `Idag, ${moment(item.time, "Do MMMM YYYY hh:mm A").format(`hh:mm A`)}`
                                : `Today, ${moment(item.time, "Do MMMM YYYY hh:mm A").format(`hh:mm A`)}`
                              )
                              : (checkSwedish()
                                ? moment.utc(item.meetingStartDateTime).local('sv').format("Do MMM, hh:mm A")
                                : moment.utc(item.meetingStartDateTime).local().format("Do MMM, hh:mm A")
                              )
                          }

                          <TooltipItem key={index} id={index} />
                        </Badge>}
                      </div>
                      <div className="card-course-bg " style={{ backgroundImage: `url(${item.imageURL})` }}></div>
                      <div className="details-container  px-3 py-3">
                        <div className="media flex-wrap align-items-center ">
                          <div className="media-body title-container">
                            {/* <span className={`username text-white user-sm text-center  me-50`}>{item.isToday ? `Today at ${moment(item.time, 'Do MMMM YYYY hh:mm A').format(`hh:mm A`)}` : item.time}
                                                <TooltipItem key={index} id={index} />
                                            </span> */}

                            <div className=" my-auto ">
                              <p className="text-footer-contact text-white text-truncate">{item.title}</p>
                            </div>
                            <span className={`username text-white user-sm text-center  me-50`}>{item.teacherName}</span>
                          </div>
                        </div>
                        <div className="learn-more-link d-flex justify-content-end w-100">
                          <Button className="border-0 p-0 btn-learn-more mt-2" outline>
                            {getTranslatedName("Learn more")}
                            <img className="more-icon ms-1" src={moreIcon} alt="more-icon" />
                          </Button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ))}
          </div>
        </div>
      )}
      {algoSearchResults != "" && (
        <div className=" explore-all live-classes-list">
          {loading && <Loader overlay="none" />}
          {algoSearchResults.length > 0 ? (
            <div className="mt-0 course-viewers row">
              {algoSearchResults.map((item, index) => (
                <div className="course-viewers col-xl-3 col-lg-4 col-md-6 py-3" key={index}>
                  <Card
                    role="button"
                    onClick={() => handleLiveClassForAlgo(item)}
                    className="course-footer border-0 mr-0"
                  >
                    <CardBody className="p-0">
                      <div className="overlay-thumbnail">
                        <Badge className={`status-tag type text-center d-flex align-items-center pb-3- course-tag `}>
                          {item.mStartDate}
                          {/* {item.isToday
                        ? `Today, ${moment(item.time, "Do MMMM YYYY hh:mm A").format(`hh:mm A`)}`
                        : moment
                            .utc(item.mStartDate)
                            .local()
                            .format("Do MMM, hh:mm A")} */}
                          <TooltipItem key={index} id={index} />
                        </Badge>
                      </div>
                      <div className="card-course-bg " style={{ backgroundImage: `url(${item.mUrl})` }}></div>
                      <div className="details-container  px-3 py-3">
                        <div className="media flex-wrap align-items-center ">
                          <div className="media-body title-container">
                            <div className=" my-auto ">
                              <p className="text-footer-contact text-white text-truncate">{item.mTitle}</p>
                            </div>
                            <span className={`username text-white user-sm text-center  me-50`}>
                              {item.tFN} {item.tLN}
                            </span>
                          </div>
                        </div>
                        <div className="learn-more-link d-flex justify-content-end w-100">
                          <Button className="border-0 p-0 btn-learn-more mt-2" outline onClick={() => handleLiveClassForAlgo(item)}>
                            {getTranslatedName("Learn more")}
                            <img className="more-icon ms-1" src={moreIcon} alt="more-icon" />
                          </Button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  )
}





export default ClassList
