import React from 'react'
import { Button, Modal, ModalBody, Tooltip } from 'reactstrap';
import IconLock from '../../assets/images/iconLock.png'
import tick from "../../assets/images/check_circle.png"
import { getTranslatedName } from 'config/functions'
const RecommendedConfirmationModal = (props) => {
    const planID = localStorage.getItem("planID")
    const list_en = [
        "Pay once for lifetime access",
        "No recurring charges",
        "Reverse symptoms, regain your health naturally"
    ]

    const list_sw = [
        "Betala en gång för livstidsåtkomst",
        "Inga återkommande avgifter",
        "Återfå din hälsa på ett naturligt sätt"
    ]

    const list = planID == "4" ? list_sw : list_en

    return (
        <Modal
            isOpen={props.show}
            toggle={props.handleClose}
            className=" modal-dialog modal-dialog-centered modal-md"
            dialogclassname="showContentPause showContentPause-css"
            centered
            backdrop={`static`}
        >
            <div className="bg-start-modal-css">
                <ModalBody className="pauseContent pt-0">
                    <div className="d-flex flex-column">

                        <div className=" text-center  bg-start">
                            <button
                                type="button"
                                className="close-modal pointer"
                                onClick={() => props.handleClose({}, false)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <img src={IconLock} className="text-center mt-5 mb-2" height={60} width={60} />

                        </div>

                    </div>
                    <div className="mt-3 mb-5">
                        <p className="mt-3 content-modal text-center">
                            {getTranslatedName("Based on the symptoms & goals you have indicated to us, we recommend you to add this series to your routine. This title requires extra purchase and is not included in your current plan")}
                        </p>
                    </div>

                    <div>
                        <ul className="list-unstyled list-text mt-md-3 mt-2 mb-0 text-left">
                            {list.map((item, index) => (
                                <li key={index} className="list-item my-2 hypos-list-style">
                                    <span className="d-flex align-items-center">
                                        <img src={tick} alt="tick-icon" />
                                        <h6 className="m-0  bullet-points">{item} </h6>
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="w-100 text-center mt-5 mb-4 d-flex flex-column px-0">
                        <Button
                            color="secondary"
                            className="btn-submit btn-risk border-0 mx-1 mb-2"
                            onClick={() => props.handleSubmit()}
                        >
                            {(props.submitButtonText)}
                        </Button>
                        <Button
                            color="primary"
                            className="btn-goBack border-0 mx-1 mt-2"
                            onClick={async () => {
                                await props.handleClose();
                            }}
                        >
                            {props.closeButtonText ? props.closeButtonText : getTranslatedName("Close")}
                        </Button>{" "}
                    </div>
                </ModalBody>
            </div>
        </Modal>
    )
}

export default RecommendedConfirmationModal