import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import WatchHistoryCard from './WatchHistoryvideoCard';
import './History.scss'
import { getCustomerPreviousLessons } from '../apiStore';
import { toast } from 'react-toastify';
import Toast from 'views/pages/common-components/Toast';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getTranslatedName } from 'config/functions';
import favouriteSvg from "../../../../assets/images/favourite.svg";


export default function History() {
    const idCSS = "progress"

    const [lessonList, setLessonList] = useState({ rows: [], count: 0 })
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [lessonsPerPage, setLessonsPerPage] = useState(6)


    const getLessons = async () => {
        const bodyData = { page: currentPage, limit: lessonsPerPage }
        if (lessonList.rows.length == 0) {
            setIsLoading(true)
        }
        const apiRes = await getCustomerPreviousLessons(bodyData)
        if (apiRes.status) {
            setLessonList({ rows: [...lessonList.rows, ...apiRes.data.rows], count: apiRes.data.count })
        } else {
            toast.error(<Toast message={apiRes.message} />)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getLessons()
    }, [currentPage])


    return (
        <div className='watch-history-container'>
           {lessonList.rows?.length > 0 ?  <InfiniteScroll
                dataLength={lessonList.rows.length}
                next={() => setCurrentPage(currentPage + 1)}
                hasMore={
                    lessonList.rows.length < lessonList.count
                }
                loader={
                    <div className="text-center w-100 text-white">
                        {getTranslatedName("Loading")}...
                    </div>
                }
            >
                <div className='row'>
                    {
                        lessonList?.rows?.map((lesson, index) => {
                            return (
                                <div className='col-md-4 col-sm-6 col-12'>
                                    <WatchHistoryCard
                                        key={index}
                                        lesson={lesson}
                                        idCSS={idCSS}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </InfiniteScroll>
                : <div className="no-record-css d-flex  flex-column justify-content-center align-items-center">
                <p className="text-favourite more">
                 You will see your watched lessons here
                </p>
                <img src={favouriteSvg} />
              </div>  
        }
        </div>
    )
}
