import { isStudentLoggedIn } from 'config/functions';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { publicLibraryRoute } from 'routes';
import NoMatchPage from 'views/pages/NoMatchPage';
import Header from 'views/registerStudentLayout/header/Header';
import Footer from 'views/registerStudentLayout/footer/FooterNew';
import { userProfile } from '../registerStudentLayout/action';
export default function PublicLayout(props) {

	const dispatch = useDispatch()
	useEffect(() => {
		if (isStudentLoggedIn() === true) {
			dispatch(userProfile())
		}
	}, [dispatch])

	return (
		<React.Fragment>

			<div className='page-inner' >
				<Header />
				<main className='main-content-css'>

					{<Suspense fallback={props.pageLoader()}>
						<Switch>
							{
								publicLibraryRoute.map((route, idx) => {
									return (typeof route.component !== "undefined" ? (
										<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (<route.component {...props} />)} />
									) : (null))
								})
							}
							<Route component={NoMatchPage} />
						</Switch>
					</Suspense>}
				</main>

				<Footer />
			</div>
		</React.Fragment>
	)
}