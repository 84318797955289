import React, { Fragment } from 'react'
import Proptypes from 'prop-types'
import { Link } from 'react-router-dom'
import { getTranslatedName, isEmpty } from 'config/functions'
const Toast = ({ message, status, link, linktext, linkColor }) => {
  return (
    <Fragment>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <h6 className='toast-title'>{(status == 'success') ? getTranslatedName('Success') : getTranslatedName('Error')}</h6>
        </div>
      </div>
      <div className='toastify-body px-1'>
        <span className='d-block'>{message}</span>
        {(!isEmpty(link) && !isEmpty(linktext)) ? <Link className={`${linkColor ? linkColor : 'text-white'}`} to={link}> {linktext}</Link> 
        : ""}
      </div>
    </Fragment>
  )
}

Proptypes.propTypes = {
  message: Proptypes.string.isRequired,
  status: Proptypes.string.isRequired
}

export default Toast