// import { getCookie } from '../../config/functions';
const initialState = {
  // isCustomerPlanActivated: true,
  userProfile: {loading: true, data: []},
  savedCourse: '',
  categorywiseCourses: [],
  symptomwiseCourses: [],
  getUserJournal: {loading: true, data: []},
  courseDetail: {loading: true, data: []},
  groupwiseCourses: [],
  addAction: '',
  header: {activeNav: '', headerDark: false, showJoinBtn: true, isSubscriptionPage: false, isLinkActive: false},
  // showJoinBtn: true,
  userProfileData: [],
  sysmptomList: [],
  userSubscriptionPlan: [],
  showSymptomBanner: true

  // userToken: getCookie("token"),
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case 'HANDLE_HEADER':
      return {...state, header: {...state.header, ...payload}}

    case 'GET_USER_PROFILE':
      return {...state, userProfile: {...state.userProfile, ...{loading: false, data: payload}}}

    case 'GET_USER_ACTION':
      return {...state, getUserJournal: {...state.getUserJournal, ...{loading: false, data: payload}}}

    case 'SAVED_COURSE':
      return {...state, savedCourse: payload}

    case 'GET_CATOGERYWISE_COURSES':
      return {...state, categorywiseCourses: payload}

    case 'GET_SYMPTOMWISE_COURSES':
      return {...state, symptomwiseCourses: payload}

    case 'GET_COURSE_DETAIL':
      return {...state, courseDetail: {...state.courseDetail, ...payload}}

    case 'GET_GROUPWISE_COURSES_LOGGEDIN':
      return {...state, groupwiseCourses: payload}

    case 'ADDED_ACTION':
      return {...state, addAction: payload.status}

    case 'GET_USER_PROFILE_DATA':
      return {...state, userProfileData: payload}

    case 'GET_PLAN':
      return {...state, userSubscriptionPlan: payload}

    case 'SAVE_WATCH_HISTORY':
      return {...state, watchInfo: payload}
    case 'CONTENT_P_STATUS':
      return {...state, userProfileData: {...state.userProfileData, ...{contentPause: payload}}}
    case 'TOGGLE_SYMPTOM_BANNER':
      return {...state, showSymptomBanner: payload}

    // case 'SHOW_JOIN_NOW_BUTTON':
    //     return { ...state, showJoinBtn : payload }

    default:
      return state
  }
}
