import React from 'react'
import './PhaseFooter.scss'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { httpPostRequest } from 'config/services'
import { getTranslatedName } from 'config/functions'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
export default function PhaseFooter() {
    const history = useHistory()
    const activeUser = useSelector(state => state.register.userProfileData)
    const handleCommunityClick = async () => {
        return history.push('/student/community-support')
        if (activeUser.pensilUserToken) {
            window.open(`https://moonrisecircle.pensil.in?token=${activeUser.pensilUserToken}`, '_blank')
            return;
        }
        // setActiveNav('community')
        let data = {
            "email": activeUser.email,
            "referenceIdInSource": JSON.stringify(activeUser.customerID),
            "createUser": true,
            "name": activeUser.firstName + " " + activeUser.lastName,
            "picture": 'https://womencycle-media.s3.amazonaws.com/iconMoonrise.jpg',
            "description": "Moonrise User"
        }
        let headerOptions = {
            headers: {
                'communityid': '64cb8065ae9f2535392b3555',
                'clientsecret': '64cb8065ae9f2535392b3555.c4aec5a509a1d420c12489f74799ce67',
                'clientid': '64cb8065ae9f2535392b3555.9e5494108d10bbd5f9e7ae52239546c4',
                'Content-Type': 'application/json',
                'access-control-allow-origin': '*',
            }
        }
        let response = await axios.post('https://api.pensil.in/api/3pc/generate-user-token', data, headerOptions)
        try {
            if (response.status == "200") {
                let userToken = response.data.user.token
                saveUserToken(userToken, activeUser.customerID)
            }
        }
        catch (err) {
            console.log("ERROR while login to pensil", response)
        }
    }

    const saveUserToken = async (token, customerID) => {
        const bodyData = {
            token, customerID
        }

        httpPostRequest("/customer/save-pensil-token", bodyData, (err, res) => {
            if (err) {
                console.log("ERROR while login to pensil", err)
            } else {
                console.log("SUCCESS while login to pensil", res)
                window.open(`https://moonrisecircle.pensil.in?token=${token}`, '_blank')
                window.location.reload()
            }
        })

    }

    return (
        <div className='PhaseFooter'>
            <div className="content">
                <p>{getTranslatedName('Looking for more support?')}</p>
                <h3> {getTranslatedName('Here are 3 more options for you!')} </h3>
            </div>
            <div className="img-btn">
                <img src="https://images.ctfassets.net/jw8fay82wbzf/4d0xFUlbBcdIznkaiB7ymg/102bda20b4fafc710ff01a87f6720191/Practiotioners__1_.png" loading='lazy' alt="teachers" />
                <div className="btn-container">

                    <button onClick={handleCommunityClick}>{getTranslatedName("Get more support")}</button>


                </div>
            </div>
        </div>
    )
}
