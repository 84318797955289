import React from 'react';
import { Modal } from 'react-bootstrap';
//import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_KEY } from 'config/constants';
import StripeForm from "./StripeForm";

export default function UpdateFormPopup(props) {
    const stripePromise = loadStripe(STRIPE_KEY);
    return (
        <Modal onHide={props.handleClose} dialogclassname="PaymentMethodModal" aria-labelledby="contained-modal-title-vcenter" centered show={props.show} backdrop={`static`}>
            <Modal.Header closeButton className='text-center closeButton border-0'>
                <h4 className=''>Update Payment Method</h4>
            </Modal.Header>
            <Modal.Body className='pb-0'>
                <div >
                    <Elements stripe={stripePromise}>
                        <StripeForm
                            cardDetails={props.cardDetails}
                            apiName={(props.auth) ? 'updatestripecard' : 'updatestripedetails'}
                            {...props}
                        />
                    </Elements>
                </div>
            </Modal.Body>
        </Modal >
    )
}